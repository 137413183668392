import { Injectable } from '@angular/core';
import { Certificate, CertificateDataService, DownloadFileService, Relation, RelationCertificate } from '@ppa/data';
import { Observable, of } from 'rxjs';
import { delay, filter, map, pluck, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class CertificateService {
  private readonly currentDate: Date;

  constructor(private certificateDataService: CertificateDataService) {
    this.currentDate = new Date();
  }

  getCertificateOptions(): Observable<Certificate[]> {
    return this.certificateDataService.getCertificateOptions();
  }

  getDaysCertificateIsExpired(expirationDate: Date): number {
    const day = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const difference = this.currentDate.getTime() - expirationDate.getTime();
    return Math.round(difference / day);
  }

  isValid(expirationDate: Date): boolean {
    const daysExpired = this.getDaysCertificateIsExpired(expirationDate);
    return daysExpired < 0;
  }

  getAllRelationCertificates(relation$: Observable<Relation>): Observable<RelationCertificate[]> {
    return relation$.pipe(
      filter((relation) => relation !== null),
      pluck('certificates'),
      map((relationCertificates) => {
        return relationCertificates.map((relationCertificate) => {
          const certificateDate = new Date(relationCertificate.date);
          return {
            ...relationCertificate,
            certificate: {
              ...relationCertificate.certificate,
              checked: this.isValid(certificateDate),
            },
          };
        });
      }),
    );
  }

  transformCertificationInput(certificationInputs: any[]): Certificate[] {
    const encounters: number[] = [];
    return certificationInputs
      .filter((certificationInput) => certificationInput?.certificate?.checked)
      .map((certificationInput) => certificationInput.certificate)
      .filter((c) => {
        const duplicate = encounters.some((e) => e === c.id);
        encounters.push(c.id);
        return !duplicate;
      });
  }

  /**
   * Combine three sources of certificates to one array of relationCertificates
   * relationCertificates map certificate details from a relation
   * certificateOptions give the ability to select new certificates not mapped to a relation
   * selectedCertificates is the collection previously selected certificates for a enity
   */
  public combineCertificateSources(
    relationCertificates: RelationCertificate[],
    certificateOptions: Certificate[],
    selectedCertificates?: Certificate[],
    selectedRelationCertificates?: RelationCertificate[],
  ): Partial<RelationCertificate>[] {
    return certificateOptions.map((certificateOption) => {
      const isChecked = selectedCertificates ? this.isCertificateChecked(selectedCertificates) : () => false;
      if (
        relationCertificates?.some(
          (relationCertificate) => relationCertificate && relationCertificate.certificate.id === certificateOption.id,
        )
      ) {
        const matchingRelationCertificate = relationCertificates.find(
          (relationCertificate) => relationCertificate.certificate.id === certificateOption.id,
        );
        return {
          ...matchingRelationCertificate,
          certificate: {
            ...matchingRelationCertificate.certificate,
            checked: isChecked(matchingRelationCertificate.certificate.id),
          },
        };
      } else if (
        selectedRelationCertificates &&
        selectedRelationCertificates.some(
          (relationCertificate) => relationCertificate && relationCertificate.id === certificateOption.id,
        )
      ) {
        const matchingRelationCertificate = selectedRelationCertificates.find(
          (relationCertificate) => relationCertificate && relationCertificate.id === certificateOption.id,
        );
        return {
          ...matchingRelationCertificate,
          certificate: {
            ...certificateOption,
            checked: isChecked(matchingRelationCertificate.id),
          },
        };
      } else {
        return {
          certificate: { ...certificateOption, checked: isChecked(certificateOption.id) },
          name: certificateOption.name,
        };
      }
    });
  }

  public downloadCertificate(certificate: RelationCertificate): Observable<any> {
    if (certificate.base64) {
      const blob = DownloadFileService.getBlob({
        base64: certificate.base64,
        mime: certificate.mime,
      });
      const headers = DownloadFileService.getHeaders({
        file: certificate.filename,
        mime: certificate.mime,
      });

      DownloadFileService.downloadAndOpenFile(blob, headers);

      return of(true).pipe(delay(1000));
    } else {
      return this.certificateDataService
        .downloadCertificate(certificate.id)
        .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
    }
  }

  private isCertificateChecked = (selectedCertificates: Certificate[]) => (certificateId: number): boolean => {
    return selectedCertificates.some((selectedCertificate) => selectedCertificate?.id === certificateId);
  };
}
