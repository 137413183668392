import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounce, debounceTime, map, throttleTime } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import HaversineGeolocation from 'haversine-geolocation';

interface Feature {
  place_name: string;
}

interface GeoJSON {
  features: Feature[];
}

export interface Address {
  street: string;
  number: string;
  zipcode: string;
  city: string;
  country: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private coordinates$ = new BehaviorSubject<any>(null);
  private watchId = null;

  constructor(private http: HttpClient) {
    this.setCurrentLocation();
  }

  // Fetch array of places from GeoJSON object.
  private fetchPlaceNamesFromGeoJSON(geo: GeoJSON): string[] {
    return geo.features.map((l) => l.place_name);
  }

  // Seperate the last part of the string following a space.
  private seperateLastSection(address: string): [string, string] {
    const sections = address.split(' ');
    const trail = sections.pop().trim();
    const lead = sections.join(' ').trim();
    return [lead, trail];
  }

  // Seperate the zipcode and city from given string in this order.
  private seperateZipCodeAndCity(zipcodeAndCity: string): [string, string] {
    const sections = zipcodeAndCity.trim().split(' ');
    const zipcode = sections.slice(0, 2).join(' ').trim();
    const city = sections.slice(2).join(' ').trim();
    return [zipcode, city];
  }

  // Transform comma seperated place string into Address object.
  toAddress(place: string): Address {
    const [address, zipcodeAndCity, rawCountry] = place.split(',');
    const [street, number] = this.seperateLastSection(address);
    const [zipcode, city] = this.seperateZipCodeAndCity(zipcodeAndCity);
    const country = rawCountry.trim();

    return {
      street,
      number,
      zipcode,
      city,
      country,
    };
  }

  // Fetch location for lng and lat coordinates.
  reverseGeolocation(lng: number, lat: number): Observable<Address> {
    const uri = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${environment.mapBoxToken}`;
    return this.http.get<GeoJSON>(uri).pipe(
      map((json) => this.fetchPlaceNamesFromGeoJSON(json)),
      map((places) => places.shift()),
      map((place) => this.toAddress(place)),
    );
  }

  // Watch GPS location
  setCurrentLocation() {
    if (navigator.geolocation) {
      if (this.watchId) {
        navigator.geolocation.clearWatch(this.watchId);
      }
      this.watchId = navigator.geolocation.watchPosition(
        (pos) => {
          this.coordinates$.next(pos.coords);
        },
        (_) => {
        },
        {
          enableHighAccuracy: true,
          timeout: 1000,
          maximumAge: 0,
        },
      );
    }
  }

  getCurrentLocationObserver()   {
    return this.coordinates$;
  }

  // Last GPS location
  getCurrentLocation() {
    return this.coordinates$.value;
  }

  hasLocationEnabled(): BehaviorSubject<any> {
    return this.coordinates$;
  }

  getDistance(lng: number, lat: number): Observable<string> {
    return this.coordinates$.pipe(
      throttleTime(30 * 1000),
      map((location) => {
        if (!location) {
          return '';
        }

        const point = { longitude: lng, latitude: lat };
        const coordinates = { longitude: location?.longitude, latitude: location?.latitude };
        let distance = HaversineGeolocation.getDistanceBetween(coordinates, point, 'm');

        if (isNaN(distance)) {
          return '';
        }

        let unit = 'm';
        if (distance > 1000) {
          distance = distance / 1000;
          unit = 'km';
          distance = Math.round(distance * 100) / 100;
        } else {
          distance = Math.round(distance);
        }

        return `${distance}${unit}`;
      }),
    );
  }
}
