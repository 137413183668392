<ng-container *ngIf="showAddRelation === false">
  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <ppa-select-relation (selectRelation)="handleRelationSelected($event)"></ppa-select-relation>
  </div>
</ng-container>
<ppa-create-relation
  (toggleAddRelation)="toggleAddRelation($event)"
  (createRelation)="handleRelationCreated($event)"
  class="{{ showAddRelation ? 'grow' : '' }}"
></ppa-create-relation>
