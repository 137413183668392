<ppa-spinner [status$]="saving$" message="global.saving" icon="save"></ppa-spinner>

<div class="ppa-modal ppa-modal--no-bottom-padding" data-cy="modal-contract-edit">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h2>
      {{ 'modules.contract.modal.edit.title' | transloco: { relation: companyName } }}
    </h2>
  </div>

  <div class="ppa-modal__content">
    <ppa-contract-form
      #form
      [contract]="contract"
      [orderMatch]="orderMatch"
      [order]="order"
      (save)="handleDuplicateContractSave($event)"
    ></ppa-contract-form>
  </div>
</div>

<div class="ppa-modal__fader"></div>
