import { SizeSorting, SizeSortingType } from '@ppa/data';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { SizeSortingTypes } from '../contracts/size-sorting-types';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SizeSortingDataService extends BaseService<SizeSorting> {
  path = 'size_sorting';

  listDefaultSizeSorting(): Observable<string[]> {
    return this.listSizeSorting(SizeSortingTypes.Default);
  }

  listChicoryPensSizeSorting(): Observable<string[]> {
    return this.listSizeSorting(SizeSortingTypes.ChicoryPens);
  }

  private listSizeSorting(type: string): Observable<string[]> {
    return this.http.get(`${this.apiConfig.baseUrl}/${this.path}/type/${type}`) as Observable<string[]>;
  }

  listSizeSortingTypes(): Observable<SizeSortingType[]> {
    const list = [
      {
        id: 1,
        value: SizeSortingTypes.Default,
        label: translate('modules.size_sorting.options.type.' + SizeSortingTypes.Default),
      },
      {
        id: 2,
        value: SizeSortingTypes.ChicoryPens,
        label: translate('modules.size_sorting.options.type.' + SizeSortingTypes.ChicoryPens),
      },
    ];
    return of(list);
  }
}
