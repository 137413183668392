import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, FormBuilder } from '@angular/forms';

@Component({
  selector: 'ppa-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  timeForm: FormGroup;

  value: string;

  hours: string[] = [];
  minutes: string[] = [];
  times: string[] = [];

  @Input() shouldEmitEventOnStateChange: boolean = undefined;
  @Input() timeSelect = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private fb: FormBuilder) {
    this.getHours();
    this.getMinutes();
    this.getTime();

    this.timeForm = this.fb.group({
      hour: [],
      minute: [],
      time: [],
    });
  }

  ngOnInit(): void {
    this.timeForm.valueChanges.subscribe((value) => {
      const { hour, minute, time } = value;
      let val = null;

      if (this.timeSelect) {
        val = time;
      } else if (hour && minute) {
        val = `${hour}:${minute}`;
      }

      this.value = val;
      this.onChange(val);
      this.onTouch(val);
    });
  }

  private getHours() {
    for (let i = 0; i < 24; i++) {
      this.hours.push(`${i}`.padStart(2, '0'));
    }
  }

  private getMinutes() {
    for (let i = 0; i < 60; i++) {
      this.minutes.push(`${i}`.padStart(2, '0'));
    }
  }

  private getTime() {
    for (let i = 0; i < 24; i++) {
      const hour = `${i}`.padStart(2, '0');
      this.times.push(hour + ':00');
      this.times.push(hour + ':30');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.timeForm.disable({
          ...(this.shouldEmitEventOnStateChange !== undefined && { emitEvent: this.shouldEmitEventOnStateChange }),
        })
      : this.timeForm.enable({
          ...(this.shouldEmitEventOnStateChange !== undefined && { emitEvent: this.shouldEmitEventOnStateChange }),
        });
  }

  writeValue(value: any): void {
    // Initial writeValue, update timeForm
    if (value) {
      let [hour, minute] = `${value}`.split(':');

      if (this.timeSelect) {
        const min = parseInt(minute, 10);

        if (min > 45) {
          if (hour === '23') {
            hour = '00';
          } else {
            hour = parseInt(hour, 10) + '';
          }
          minute = '00';
        } else if (min < 15) {
          minute = '00';
        } else {
          minute = '30';
        }

        value = `${hour}:${minute}`;
      }

      this.timeForm.patchValue(
        {
          hour,
          minute: minute.padStart(2, '0'),
          time: value,
        },
        { emitEvent: false },
      );
    } else {
      this.timeForm.reset();
    }

    this.value = value;
  }
}
