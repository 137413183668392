import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewFilteredOnRelationComponent } from './overview-filtered-on-relation.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [OverviewFilteredOnRelationComponent],
  imports: [CommonModule, MatButtonModule, TranslocoModule],
  exports: [OverviewFilteredOnRelationComponent],
})
export class OverviewFilteredOnRelationModule {}
