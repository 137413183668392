import { Dossier, DossierReminder, KeyValuePairs, Order, OrderType, PaginatedResponse, Photo } from '../contracts';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { ListResponse } from '../contracts/list-response';
import { map, tap } from 'rxjs/operators';
import { ReadResponse } from '../contracts/read-response';
import { DownloadFileService } from './download-file.service';
import { translate } from '@ngneat/transloco';
import * as moment from 'moment';
import { DetailField } from '../contracts/detail-field';
import { HttpResponse } from '@angular/common/http';
import { PublicDownload } from '../contracts/public-download';

@Injectable({
  providedIn: 'root',
})
export class DossierDataService extends BaseService<Dossier> {
  path = 'dossiers';

  listPaged(params?: { [key: string]: any }): Observable<PaginatedResponse<Dossier>> {
    return super.listPaged(params);
  }

  read(id: number): Observable<ReadResponse<Dossier>> {
    return super.read(id).pipe(
      map((dossier) => {
        if (dossier.photos && dossier.photos.length > 0) {
          dossier.photos.sort(this.sortPhotos);
        }

        if (dossier.location && dossier.location[0] && dossier.location[0].street) {
          const location = dossier.location[0];
          dossier.locationLine = `${location.street} ${location.number}, ${location.city}`;
        }

        dossier.localPhotos = dossier.photos;
        if (dossier.expectedAmount) {
          dossier.expectedAmountSeed = dossier.expectedAmount.toString().replace('.', ',');
          dossier.expectedAmountSale = dossier.expectedAmount.toString().replace('.', ',');
        }
        if (dossier.estimatedAmount) {
          dossier.estimatedAmount = dossier.estimatedAmount.toString().replace('.', ',');
        }
        dossier.surfaceCultivation = dossier.surface;
        if (dossier.indicationPrice) {
          dossier.indicationPrice = dossier.indicationPrice.toString().replace('.', ',');
        }
        if (dossier.amountOfUnit) {
          dossier.amountOfUnit = dossier.amountOfUnit.toString().replace('.', ',');
        }
        return dossier;
      }),
    );
  }

  getEligibleDossiersToMatch(params: { [header: string]: string }) {
    return this.http.get<any>(`${this.apiConfig.baseUrl}/${this.path}/eligible_dossiers_to_match`, {
      observe: 'response',
      params,
    });
  }

  promoteDossierToOrder(dossier: Dossier) {
    return this.http.post<ReadResponse<Order>>(
      `${this.apiConfig.baseUrl}/${this.path}/${dossier.id}/promote_dossier_to_order`,
      {},
    );
  }

  openDossier(dossier: Dossier) {
    return this.http.post<ReadResponse<Order>>(`${this.apiConfig.baseUrl}/${this.path}/${dossier.id}/open_dossier`, {});
  }

  closeDossier(dossier: Dossier) {
    return this.http.post<ReadResponse<Order>>(
      `${this.apiConfig.baseUrl}/${this.path}/${dossier.id}/close_dossier`,
      {},
    );
  }

  downloadDossier(dossier: Dossier) {
    const windowReference = window.open('', '_blank');

    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${dossier.id}/download_dossier`, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        tap((response: HttpResponse<PublicDownload>) => {
          if (windowReference == null || windowReference.closed) {
            window.open(response.body.downloadUrl, '_blank');
          } else {
            windowReference.location.href = response.body.downloadUrl;
          }
        }),
      );
  }

  addReminder(reminder: DossierReminder) {
    return this.http.post<ReadResponse<DossierReminder>>(`${this.apiConfig.baseUrl}/${this.path}/reminder`, reminder);
  }

  getDossierProperties() {
    return this.http.get<ListResponse<string>>(`${this.apiConfig.baseUrl}/${this.path}/properties`);
  }

  getOrigins() {
    return this.http.get<ListResponse<string>>(`${this.apiConfig.baseUrl}/${this.path}/origins`);
  }

  sortPhotos(a: Photo, b: Photo): number {
    const aDate = a.date?.substr(0, 10) || moment().format('YYYY-MM-DD');
    const bDate = b.date?.substr(0, 10) || moment().format('YYYY-MM-DD');

    if (aDate === bDate) {
      return a.id > b.id ? -1 : 1;
    }

    return aDate > bDate ? -1 : 1;
  }

  get harvestYears(): KeyValuePairs<string> {
    const years = [];
    const date = new Date().getFullYear();
    const max = date + 3;

    for (let y = max; y > 2020; y--) {
      years.push({ key: '' + y, value: '' + y });
    }

    return years;
  }

  get deliveryYears(): KeyValuePairs<string> {
    const years = [];
    const date = new Date().getFullYear();
    const max = date + 3;

    for (let y = max; y > 2020; y--) {
      years.push({ key: '' + y, value: '' + y });
    }

    return years;
  }

  percentFulfilled(fulfilled: number, amount: number): string {
    return ((fulfilled / amount) * 100).toFixed(0);
  }

  getDetailFields(details): DetailField[] {
    return [
      {
        label: 'modules.dossier.create.sections.contact_moments',
        section: true,
        show: ['contactMoment.description', 'contactMoment.mediator', 'contactMoment.date'],
      },
      {
        label: 'modules.order.create.mediator_label',
        field: 'contactMoment',
        valueCallback: (contactMoment) => {
          const value = contactMoment.mediator;
          if (!value) {
            return 'Onbekend';
          }
          return value.firstname + ' ' + value.lastname;
        },
      },
      {
        label: 'sorting.created_at',
        field: 'contactMoment',
        valueCallback: (contactMoment) => {
          const value = contactMoment.contactDate;
          const date = new Date(Date.parse(value));
          return moment(date).format('DD-MM-YYYY HH:mm');
        },
      },
      {
        label: 'modules.dossier.create.contact_moments.description',
        field: 'contactMoment',
        bindLabel: 'description',
      },
      {
        label: 'modules.order.create.sections.common',
        section: true,
        show: ['relation.companyName', 'title', 'status', 'mediator.lastname', 'location', 'createdAt', 'updatedAt'],
      },
      {
        label: 'modules.order.create.order_relation_label',
        field: 'relation',
        bindLabel: 'companyName',
      },
      {
        label: 'modules.dossier.create.title_label',
        field: 'title',
      },
      {
        label: 'modules.dossier.create.location_detail_label',
        field: 'location',
        valueCallback: (value) => {
          let street = value[0]?.street ?? '';
          let number = value[0]?.number ?? '';
          let zipcode = value[0]?.zipcode ?? '';
          let city = value[0]?.city ?? '';
          let country = value[0]?.country ?? '';

          if (number !== '') {
            number = ' ' + number;
          }

          if (zipcode !== '') {
            zipcode = '<br />' + zipcode;
          }

          if (city !== '') {
            city = ' ' + city;
          }

          if (country !== '') {
            country = '<br />' + country;
          }

          return street + number + zipcode + city + country;
        },
      },
      {
        label: 'modules.dossier.create.current_place_label',
        field: 'status',
        valueCallback: (value) => translate('modules.dossier.create.current_place_options.' + value),
      },
      {
        label: 'modules.order.create.mediator_label',
        field: 'mediator',
        valueCallback: (value) => value.firstname + ' ' + value.lastname,
      },
      {
        label: 'sorting.created_at',
        field: 'createdAt',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          return moment(date).format('DD-MM-YYYY HH:mm');
        },
      },
      {
        label: 'sorting.updated_at',
        field: 'updatedAt',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          return moment(date).format('DD-MM-YYYY HH:mm');
        },
      },
      {
        label: 'modules.order.create.sections.product',
        section: true,
        show: [
          'product.title',
          'productVariety.title',
          'organic',
          'bakingFactory',
          'badOnions',
          'harvestYear',
          'deliveryYear',
          'amountOfUnit',
          'amountUnit.title',
          'amount',
          'treatment',
        ],
      },
      {
        label: 'modules.order.create.product_label',
        field: 'product',
        bindLabel: 'title',
      },
      {
        label: 'modules.order.create.product_variety_label',
        field: 'productVariety',
        bindLabel: 'title',
      },
      {
        label: 'modules.dossier.create.organic_label',
        field: 'organic',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.baking_factory_label',
        field: 'bakingFactory',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.bad_onions_label',
        field: 'badOnions',
      },
      {
        label: 'modules.dossier.create.harvest_year_label',
        field: 'harvestYear',
      },
      {
        label: 'modules.dossier.create.delivery_year_label',
        field: 'deliveryYear',
      },
      {
        label: 'modules.order.create.amount_label',
        field: 'amountOfUnit',
        suffix: {
          field: 'amountUnit',
          bindLabel: 'title',
        },
      },
      {
        label: false,
        field: 'amount',
        suffix: {
          value: 'ton',
        },
      },
      {
        label: 'modules.dossier.create.treatment_label',
        field: 'treatmentCollection',
        bindLabel: 'name',
      },
      {
        label: 'modules.relations.create.note',
        field: 'productNote',
      },
      {
        label: 'modules.dossier.create.sections.cultivation',
        section: true,
        show: ['surfaceCultivation', 'cultivationNote'],
      },
      {
        label: 'modules.dossier.create.surface_label',
        field: 'surfaceCultivation',
        suffix: {
          value: 'ha',
        },
      },
      {
        label: 'modules.relations.create.note',
        field: 'cultivationNote',
      },
      {
        label: 'modules.dossier.create.sections.cultivation',
        section: true,
        show: ['sowingDate'],
      },
      {
        label: 'modules.dossier.create.sowing_date_label',
        field: 'sowingDate',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          return moment(date).format('DD-MM-YYYY');
        },
      },
      {
        label: 'modules.dossier.create.sections.sale',
        section: true,
        show: ['sizeSorting', 'packaging', 'disinfected', 'onCall', 'onCallNote', 'saleNote'],
      },
      {
        label: 'modules.dossier.create.size_sorting_label',
        field: 'sizeSorting',
      },
      {
        label: 'modules.dossier.create.packaging_label',
        field: 'packaging',
      },
      {
        label: 'modules.dossier.create.disinfected_label',
        field: 'disinfected',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.order.create.on_call_label',
        field: 'onCall',
        showCallback: (d) => !d.hasOwnProperty('orderType') || d.orderType === OrderType.Sell,
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.order.create.on_call_note_label',
        field: 'onCallNote',
      },
      {
        label: 'modules.relations.create.note',
        field: 'saleNote',
      },
      {
        label: 'modules.dossier.create.sections.seeds',
        section: true,
        show: ['fungicideCoating', 'seedsNote'],
      },
      {
        label: 'modules.order.create.on_call_note_label',
        field: 'fungicideCoating',
      },
      {
        label: 'modules.relations.create.note',
        field: 'seedsNote',
      },
      {
        label: 'modules.dossier.create.sections.stage',
        section: true,
        show: [
          'stage',
          'barnDiseases',
          'preferredDeliveryTime',
          'storageBox',
          'colorGrade',
          'meadowDiseases',
          'preferenceDelivery',
          'expectedHarvest',
        ],
      },
      {
        label: 'modules.dossier.create.stage_label',
        field: 'stage',
        valueCallback: (value) => translate('modules.dossier.create.stage_options.' + value),
      },
      {
        label: 'modules.dossier.create.preferred_delivery_time_label',
        field: 'preferredDeliveryTime',
        valueCallback: (value) => translate('months.' + value),
        showCallback: (d) => !d.hasOwnProperty('stage') || d.stage === 'barn',
      },
      {
        label: 'modules.dossier.create.barn_diseases_label',
        field: 'barnDiseases',
        bindLabel: 'name',
        showCallback: (d) => !d.hasOwnProperty('stage') || d.stage === 'barn',
      },
      {
        label: 'modules.dossier.create.storage_box_label',
        field: 'storageBox',
        valueCallback: (value) => translate('modules.dossier.create.storage_box_options.' + value),
        showCallback: (d) => !d.hasOwnProperty('stage') || d.stage === 'barn',
      },
      {
        label: 'modules.dossier.create.color_grade_label',
        field: 'colorGrade',
        valueCallback: (value) => translate('modules.dossier.create.color_options.' + value),
        showCallback: (d) => !d.hasOwnProperty('stage') || d.stage === 'barn',
      },
      {
        label: 'modules.dossier.create.expected_harvest_exact_date_label',
        field: 'expectedHarvestExactDate',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
        showCallback: (d) => !d.hasOwnProperty('stage') || d.stage === 'off_meadow',
      },
      {
        label: 'modules.dossier.create.preference_delivery_label',
        field: 'preferenceDelivery',
        valueCallback: (value) => translate('modules.dossier.create.preference_delivery_options.' + value),
      },
      {
        label: 'modules.dossier.create.expected_harvest_label',
        field: 'expectedHarvest',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          if (details.expectedHarvestExactDate) {
            return moment(date).format('DD-MM-YYYY');
          }
          return 'Week ' + moment(date).format('W');
        },
        showCallback: (d) => !d.hasOwnProperty('stage') || d.stage === 'off_meadow',
      },
      {
        label: 'modules.dossier.create.meadow_diseases_label',
        field: 'meadowDiseases',
        bindLabel: 'name',
        showCallback: (d) => !d.hasOwnProperty('stage') || d.stage === 'off_meadow',
      },
      {
        label: 'modules.dossier.create.surface_label',
        field: 'surface',
        suffix: {
          value: 'ha',
        },
      },
      {
        label: 'modules.relations.create.note',
        field: 'stageNote',
      },
      {
        label: 'modules.dossier.create.sections.quality',
        section: true,
        show: [
          'tare',
          'tareTestSample',
          'gritGrade',
          'grit10Onions',
          'grit10Roots',
          'grit10OnionsMeadow',
          'gritTestSample',
          'quality',
          'industryQuality',
          'naktNumber',
          'primed',
          'fungicideTreated',
          'checmical',
          'fungicideAndInsectTreated',
          'piled',
          'sproutInhibitor',
          'chlorineProfam',
          'qualityNote',
        ],
      },
      {
        label: 'modules.dossier.create.tare_label',
        field: 'tare',
        valueCallback: (value) => translate('modules.dossier.create.tare_options.info.' + value),
      },
      {
        label: 'modules.dossier.create.tare_test_sample_label',
        field: 'tareTestSample',
        suffix: {
          value: '%',
        },
      },
      {
        label: 'modules.dossier.create.grit_grade_label',
        field: 'gritGrade',
        valueCallback: (value) => translate('modules.dossier.create.grit_grade_options.info.' + value),
      },
      {
        label: 'modules.dossier.create.grit10_onions_meadow_label',
        field: 'grit10OnionsMeadow',
        suffix: {
          value: 'cm',
        },
        showCallback: (d) => !d.hasOwnProperty('stage') || d.stage === 'off_meadow',
      },
      {
        label: 'modules.dossier.create.grit10_onions_label',
        field: 'grit10Onions',
      },
      {
        label: 'modules.dossier.create.grit10_roots_label',
        field: 'grit10Roots',
      },
      {
        label: 'modules.dossier.create.grit_test_sample_label',
        field: 'gritTestSample',
        suffix: {
          value: '%',
        },
      },
      {
        label: 'modules.dossier.create.quality_label',
        field: 'quality',
        valueCallback: (value) => translate('modules.dossier.create.quality_options.' + value),
      },
      {
        label: 'modules.dossier.create.industry_quality_label',
        field: 'industryQuality',
        valueCallback: (value) => translate('modules.dossier.create.industry_quality_options.' + value),
        showCallback: (d) => !d.hasOwnProperty('stage') || d.quality === 'industry',
      },
      {
        label: 'modules.dossier.create.nakt_number_label',
        field: 'naktNumber',
      },
      {
        label: 'modules.dossier.create.primed_label',
        field: 'primed',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.fungicide_treated_label',
        field: 'fungicideTreated',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.chemical_label',
        field: 'checmical',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.fungicide_and_insect_treated_label',
        field: 'fungicideAndInsectTreated',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.piled_label',
        field: 'piled',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.sprout_inhibitor_label',
        field: 'sproutInhibitor',
        valueCallback: (value) => translate('modules.dossier.create.sprout_inhibitor_options.' + value),
      },
      {
        label: 'modules.dossier.create.chlorine_profam',
        field: 'chlorineProfam',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.relations.create.note',
        field: 'qualityNote',
      },
      {
        label: 'modules.dossier.create.sections.trade',
        section: true,
        show: ['expiresAt', 'indicationPrice', 'indicationPriceUnit', 'participation', 'tradeNote'],
      },
      {
        label: 'modules.dossier.create.expires_at_label',
        field: 'expiresAt',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          return moment(date).format('DD-MM-YYYY HH:mm').replace(' 00:00', '');
        },
      },
      {
        label: 'modules.dossier.create.indication_price',
        field: 'indicationPrice',
        valueCallback: (value) => {
          if (value) {
            if (details.indicationPriceUnit?.prefix) {
              value = value + ' ' + details.indicationPriceUnit.prefix.toLowerCase();
            }
            if (details.indicationPriceUnit?.title !== 'eenheden') {
              value = value + ' ' + details.indicationPriceUnit?.title;
            }
            return '€' + value;
          }

          return false;
        },
      },
      {
        label: 'modules.dossier.create.participation',
        field: 'participation',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.relations.create.note',
        field: 'tradeNote',
      },
      {
        label: 'modules.dossier.create.sections.others',
        section: true,
        show: ['certification', 'otherNote'],
      },
      {
        label: 'modules.dossier.create.certificates',
        field: 'certification',
        bindLabel: 'name',
      },
      {
        label: 'modules.relations.create.note',
        field: 'otherNote',
      },
      {
        label: 'modules.photo.title',
        photos: true,
        files: 'photos',
      },
    ];
  }
}
