import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { DeliveryReport, OrderMatch } from '@ppa/data';
import { Observable } from 'rxjs';
import { ReadResponse } from '../contracts/read-response';
import { DownloadFileService } from './download-file.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeliveryReportDataService extends BaseService<DeliveryReport> {
  path = 'delivery_reports';

  storeDeliveryReport(deliveryReport: DeliveryReport): Observable<DeliveryReport> {
    return this.http.post<DeliveryReport>(`${this.apiConfig.baseUrl}/${this.path}`, deliveryReport);
  }

  updateDeliveryReport(
    orderMatch: OrderMatch,
    deliveryReport: DeliveryReport,
  ): Observable<ReadResponse<DeliveryReport>> {
    return this.http.put<ReadResponse<DeliveryReport>>(`${this.apiConfig.baseUrl}/${this.path}/${deliveryReport.id}`, {
      deliveryReport,
      orderMatchId: orderMatch.id,
    });
  }

  downloadDeliveryReport(id: number): Observable<any> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${id}/download`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  sendDeliveryReport(deliveryReport: DeliveryReport, messages: any[]) {
    return this.http.post(`${this.apiConfig.baseUrl}/${this.path}/${deliveryReport.id}/send`, messages);
  }
}
