<div class="container container--flex-direction-column">
  <div class="mb-1">
    {{ 'search.overview_intro' | transloco }} <b>{{ relation?.companyName }}</b
    >.
  </div>
  <div class="container container--no-padding">
    <button mat-flat-button type="button" class="column" (click)="backClicked.emit()">
      {{ 'search.overview_back_button' | transloco }}
    </button>
    <span class="column"></span>
    <button mat-flat-button type="button" class="column" (click)="showAllClicked.emit()">
      {{ 'search.overview_view_all_button' | transloco }}
    </button>
  </div>
</div>
