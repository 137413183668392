import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigDataService } from './config-data.service';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageKey } from '../contracts';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  isUpToDate: boolean;
  liveVersion: string;
  localVersion: string;
  readonly localStorageVersion = 'appVersion';
  @Output() upToDate$ = new Subject<boolean>();

  constructor(
    protected http: HttpClient,
    private authService: AuthService,
    private configDataService: ConfigDataService,
    private storageService: LocalStorageService,
  ) {
    this.upToDate$.next(true);
  }

  checkVersion() {
    if (this.authService.isLoggedIn()) {
      this.configDataService.version().subscribe((version: string) => {
        this.localVersion = this.storageService.getItem(LocalStorageKey.AppVersion);
        this.liveVersion = version.trim().toLowerCase();
        this.isUpToDate = this.localVersion === this.liveVersion;
        this.upToDate$.next(this.isUpToDate);
      });
    }
  }

  startCheckingVersion() {
    this.checkVersion();
  }
}
