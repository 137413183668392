<div class="container container--flex-direction-column container--bg-light" *transloco="let t">
  <ppa-header class="mb-1" [data]="headerData"></ppa-header>

  <div class="heading">
    <h1 class="subheading-enabled">{{ t('modules.pool.overview.heading.title') }}</h1>
    <h4 class="subheading">{{ t('modules.pool.overview.heading.subtitle') }}</h4>
  </div>

  <div class="details" *ngIf="groupedOverview$ && order">
    <dl>
      <dt>{{ t('modules.order.detail.summary.relation') }}</dt>
      <dd>
        {{ order.relation.companyName }} <ppa-relation-status [label]="order.relation.status"></ppa-relation-status>
      </dd>
      <dt>{{ t('modules.order.detail.summary.order_type') }}</dt>
      <dd>
        <i class="uil uil-arrow-to-bottom icon-green" *ngIf="order.orderType === OrderType.Buy"></i>
        <i class="uil uil-top-arrow-from-top icon-blue" *ngIf="order.orderType === OrderType.Sell"></i>
        {{ t('modules.order.detail.order_type.' + order.orderType) }}
      </dd>
      <dt>{{ t('modules.order.detail.summary.product_and_product_variety') }}</dt>
      <dd data-cy="order-detail-product-variety">
        {{ order.product.title }}{{ order.productVariety ? ',' : '' }} {{ order.productVariety?.title }}
      </dd>
    </dl>
  </div>
</div>

<div class="container container--flex-direction-column" *transloco="let t">
  <ng-container *ngIf="groupedOverview$ | async as groupedOverview; else loading">
    <div *ngFor="let group of groupedOverview | overviewCardGroup" [attr.data-cy]="group.key + '_container'">
      <h3>{{ 'modules.pool.overview.group.' + group.key | transloco }}</h3>
      <ppa-pool-overview-card *ngFor="let card of group.value" [config]="card" [cyIdentifier]="group.key + '_card'">
        <div class="card-info">
          <div class="card-info__row">
            <span>{{ card.cardInfo.poolProvisionalStatement.product }}</span>
            <span *ngIf="card.cardInfo.poolProvisionalStatement.productVariety">, </span>
            <span>{{ card.cardInfo.poolProvisionalStatement.productVariety }}</span>
          </div>

          <div *ngIf="card.cardInfo.poolProvisionalStatement.statementReferenceNumber" class="card-info__row">
            <span>{{ t('modules.pool.overview.card.reference_number') }}: </span>
            <span>{{ card.cardInfo.poolProvisionalStatement.statementReferenceNumber }}</span>
          </div>
          <div *ngIf="card.cardInfo.poolProvisionalStatement.contractReferenceNumber" class="card-info__row">
            <span>{{ t('modules.pool.overview.card.contract_reference_number') }}: </span>
            <span>{{ card.cardInfo.poolProvisionalStatement.contractReferenceNumber }}</span>
          </div>

          <div class="card-info__row">
            <span>{{ t('modules.pool.overview.card.sent_at') }}: </span>
            <span *ngIf="card.cardInfo.poolProvisionalStatement.poolProvisionalStatementSentAt" class="primary">{{ card.cardInfo.poolProvisionalStatement.poolProvisionalStatementSentAt | date: 'dd-MM-YYYY HH:mm' }}</span>
            <span *ngIf="!card.cardInfo.poolProvisionalStatement.poolProvisionalStatementSentAt" class="warn">{{ t('modules.pool.overview.card.not_yet_sent') }}</span>
          </div>

        </div>
      </ppa-pool-overview-card>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <ppa-loading></ppa-loading>
</ng-template>

<div class="background"></div>
