<!-- carousel -->
<div class="carousel--container" [class]="class">
  <div class="carousel">
    <ng-container *ngFor="let slide of slides; let i = index">
      <ng-container *ngIf="i === currentSlide">
        <button class="control view" type="button">
          <i class="uil uil-eye"></i>
        </button>
        <img *ngIf="slide.id < 0" [src]="slide.base64" class="slide" (click)="openPhoto(slide)" @carouselAnimation />
        <img *ngIf="slide.id > 0" [src]="slide.url" class="slide" (click)="openPhoto(slide)" @carouselAnimation />
      </ng-container>
    </ng-container>

    <div class="dots" *ngIf="slides.length > 1">
      <div class="dot" *ngFor="let slide of slides; let i = index" [class.active]="i === currentSlide"></div>
    </div>

    <!-- controls -->
    <ng-container *ngIf="slides.length > 1">
      <button class="control prev" (click)="previous()" type="button">
        <i class="uil uil-arrow-left"></i>
      </button>
      <button class="control next" (click)="next()" type="button">
        <i class="uil uil-arrow-right"></i>
      </button>
    </ng-container>
  </div>
</div>
<div class="carousel--text" *ngIf="showText">
  <ng-container *ngFor="let slide of slides; let i = index">
    <ng-container *ngIf="i === currentSlide">
      <div class="slide--text">
        <h3>{{ slide.date | date: 'dd-MM-yyyy' }}</h3>
        <p class="description">{{ slide.description }}</p>
      </div>
    </ng-container>
  </ng-container>
</div>
