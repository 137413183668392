import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CanDeactivateComponent } from '../components/component-can-deactivate/can-deactivate.component';
import { ConfirmDialogComponent, ModalService } from '@ppa/layout';
import { translate } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
  constructor(private modalService: ModalService) {}

  canDeactivate(
    component: CanDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {
      return true;
    }

    const confirmModal = this.modalService.createModal(ConfirmDialogComponent, {
      message: translate('navigation_guard.title'),
      cancelButtonText: translate('navigation_guard.buttons.cancel'),
      confirmButtonText: translate('navigation_guard.buttons.discard'),
    });
    return confirmModal.dialog.onSave();
  }
}
