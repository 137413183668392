import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractWizardStepComponent } from '@ppa/layout';
import { DossierService } from '../../services/dossier.service';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { RelationCertificate, KeyValuePairs, Unit, Dossier, Treatment } from '@ppa/data';
import { BehaviorSubject, combineLatest, ConnectableObservable, Observable, of, Subject, Subscription } from 'rxjs';
import { UnitService } from '../../../../services/unit.service';
import {
  distinctUntilChanged,
  map,
  pluck,
  switchMap,
  take,
  takeUntil,
  tap,
  publishReplay,
  filter,
} from 'rxjs/operators';
import { CertificateService } from '../../../../services/certificate.service';
import { RelationService } from '../../../relation/services/relation.service';

@Component({
  selector: 'ppa-dossier-wizard-specifications-three',
  templateUrl: './dossier-wizard-specifications-three.component.html',
  styleUrls: ['./dossier-wizard-specifications-three.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DossierWizardSpecificationsThreeComponent
  extends AbstractWizardStepComponent
  implements OnInit, OnDestroy {
  dossierFormSection: FormGroup;
  tareGradeOptions: KeyValuePairs<string>;
  gritGradeOptions: KeyValuePairs<string>;
  qualityOptions: KeyValuePairs<string>;
  industryQualityOptions: KeyValuePairs<string>;
  sproutInhibitorOptions: KeyValuePairs<string>;

  qualityIsIndustrySubscription: Subscription;

  dossierProductMatrix$: Observable<Map<string, string | null>>;
  qualityIsIndustry$: Observable<boolean>;
  relationCertificates$: Observable<Partial<RelationCertificate>[]>;
  priceUnitOptions$: Observable<Unit[]>;

  qualitySection$: Observable<boolean>;
  tradeSection$: Observable<boolean>;
  othersSection$: Observable<boolean>;

  isOrganic$ = new BehaviorSubject<boolean>(false);
  saving$: Subject<boolean> = new Subject<boolean>();

  expiresAtBeforeToday = false;

  currentStage: string;

  treatments$: Observable<Treatment[]>;

  @ViewChild('form') form: NgForm;

  get controls() {
    return this.dossierFormSection.controls;
  }

  get certification() {
    return this.dossierFormSection.get('certification') as FormArray;
  }

  constructor(
    private dossierService: DossierService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private certificateService: CertificateService,
    private unitService: UnitService,
    private relationService: RelationService,
  ) {
    super();
    const dossierFormSectionFields = this.dossierService.clearValidators([Validators.required], {
      ...this.dossierService.dossierFormSectionFields['quality'],
      ...this.dossierService.dossierFormSectionFields['trade'],
      ...this.dossierService.dossierFormSectionFields['others'],
      certification: this.fb.array([]),
    });
    this.dossierFormSection = this.fb.group(dossierFormSectionFields);
    this.saving$.next(false);
  }

  ngOnInit(): void {
    this.tareGradeOptions = this.dossierService.tareGradeOptions;
    this.gritGradeOptions = this.dossierService.gritGradeOptions;
    this.qualityOptions = this.dossierService.qualityOptions;
    this.industryQualityOptions = this.dossierService.industryQualityOptions;
    this.sproutInhibitorOptions = this.dossierService.sproutInhibitorOptions;

    this.dossierProductMatrix$ = this.dossierService.dossierProductMatrix;

    this.qualityIsIndustry$ = this.controls.quality.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(this.onQualityChange.bind(this)),
      switchMap((quality) => of(quality === 'industry')),
      publishReplay(1),
    );
    this.qualityIsIndustrySubscription = (this.qualityIsIndustry$ as ConnectableObservable<boolean>).connect();

    this.dossierFormSection.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleFormChanges.bind(this));
    this.onFinish$.pipe(takeUntil(this.destroy$)).subscribe(this.handleSubmit.bind(this));
    this.onPrevious$.pipe(takeUntil(this.destroy$)).subscribe(this.handleReset.bind(this));

    this.qualitySection$ = this.dossierService.getSectionVisibility('quality');
    this.tradeSection$ = this.dossierService.getSectionVisibility('trade');
    this.othersSection$ = this.dossierService.getSectionVisibility('others');

    this.priceUnitOptions$ = this.unitService.getPriceUnits();

    this.relationCertificates$ = this.fetchSelectableCertificates(this.dossierService.getDossierWizardState());

    this.dossierFormSection
      .get('expiresAt')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.expiresAtBeforeToday = this.dossierService.checkDateTimeBeforeNow(value)));

    this.dossierService
      .getDossierWizardState()
      .pipe(take(1))
      .subscribe((dossierWizardState) => {
        this.dossierFormSection.patchValue(dossierWizardState);
        this.dossierService.setProductMatrix(this.dossierFormSection, dossierWizardState.product);
        this.isOrganic$.next(dossierWizardState.organic);
        this.treatments$ = this.dossierService.setTreatmentOptions(dossierWizardState.product.defaultValues);
        this.checkBreedingSellingFields(dossierWizardState.breedingSelling);
        this.checkSectionVisibility();
        this.dirty$.next(this.dossierFormSection.invalid);
        this.currentStage = dossierWizardState.stage;
      });

    this.certification.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((relationCertificates) => {
      if (
        relationCertificates.some((relationCertificate) => {
          const { certificate } = relationCertificate;
          return certificate.organic && certificate.checked;
        })
      ) {
        this.dossierService.setDossierWizardState({ ...{ organic: true } });
      }
    });

    this.priceUnitOptions$
      .pipe(
        take(1),
        map((priceUnits) => priceUnits.filter((priceUnit) => priceUnit.divisor === 100).pop()),
        filter((defaultPriceUnit) => defaultPriceUnit !== undefined),
      )
      .subscribe((defaultPriceUnit) => this.dossierFormSection.get('indicationPriceUnit').patchValue(defaultPriceUnit));
  }

  private fetchSelectableCertificates(dossier$: Observable<Dossier>) {
    return combineLatest([
      dossier$.pipe(
        take(1),
        pluck('relation'),
        pluck('id'),
        switchMap((relation) => this.relationService.getRelation(relation)),
        pluck('certificates'),
      ),
      this.certificateService.getCertificateOptions(),
    ]).pipe(
      map((set) => {
        const [relationCertificates, certificateOptions] = set;

        return this.certificateService.combineCertificateSources(relationCertificates, certificateOptions);
      }),
      tap((certificates) => this.addCertificateFormControls(certificates)),
    );
  }

  private addCertificateFormControls(certificates: Partial<RelationCertificate>[]): void {
    this.certification.clear();
    certificates.map((certificate) => {
      this.certification.push(this.fb.group(certificate));
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.qualityIsIndustrySubscription) {
      this.qualityIsIndustrySubscription.unsubscribe();
    }
  }

  handleSubmit() {
    this.dossierFormSection.markAllAsTouched();
    this.cdr.detectChanges();
    if (this.dossierFormSection.valid) {
      this.dossierService.setDossierWizardState({
        ...this.dossierFormSection.value,
        certification: this.certificateService.transformCertificationInput(this.certification.value),
      });
      this.dossierService
        .getDossierWizardState()
        .pipe(take(1))
        .subscribe((dossierWizardState) => {
          this.saving$.next(true);
          this.dossierService.saveDossier(dossierWizardState).subscribe(
            () => {
              this.saving$.next(false);
              this.blockingFinish$.next(false);
              this.requestSave$.next();
            },
            () => {
              this.saving$.next(false);
            },
          );
        });
    } else {
      this.blockingFinish$.next(true);
    }
  }

  handleReset() {
    this.dossierService.resetDossierProductMatrix();
  }

  handleFormChanges() {
    this.dossierService.setDossierWizardState({ ...this.dossierFormSection.value });

    if (this.dossierFormSection.touched) {
      this.dirty$.next(true);
    }
  }

  handleUnload() {
    super.handleUnload();

    this.dossierService.clearDossierWizardState();
  }

  onQualityChange(quality: string) {
    const industryQualityField = this.controls.industryQuality;
    const industrial = quality === 'industry';
    if (industrial) {
      industryQualityField.enable();
    } else {
      industryQualityField.updateValueAndValidity();
      industryQualityField.disable({ emitEvent: true });
    }
    this.controls.quality.markAsTouched();
    this.checkSectionVisibility();
  }

  private checkBreedingSellingFields(value: string) {
    if (value === undefined) {
      return;
    } else if (value === 'breeding') {
      this.controls.treatment.disable();
    } else {
      this.controls.treatment.enable();
    }

    this.checkSectionVisibility();
  }

  private checkSectionVisibility() {
    this.dossierService.checkSectionVisibility(this.dossierFormSection, ['qualityNote', 'tradeNote', 'othersNote']);
  }
}
