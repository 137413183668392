import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe} from '@angular/common';

@Pipe({
  name: 'ppaNumber',
})
export class PPANumberPipe extends DecimalPipe implements PipeTransform {
  constructor() {
    super('nl');
  }
}
