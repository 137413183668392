<ng-container>
  <h3 *ngIf="showHeading">{{ 'form.elements.files.heading' | transloco }}</h3>
  <ul class="list file__list">
    <ng-container *ngFor="let attachment of attachments.controls; index as i">
      <li class="list__item">
        <button
          mat-flat-button
          pre-icon
          fixed
          type="button"
          (click)="downloadAttachment(attachment.value)"
          class="file__list--name"
        >
          <i class="uil uil-download-alt"></i>
          {{ attachment.value.file }}
        </button>
        <span class="file__list--delete"><i class="uil uil-times" (click)="removeAttachment($event, i)"></i></span>
      </li>
    </ng-container>
  </ul>

  <input
    class="file__input"
    type="file"
    accept="application/pdf"
    [attr.multiple]="multiple ? 'multiple' : null"
    #hiddenfileinput
    (change)="addAttachment($event)"
  />

  <button mat-flat-button pre-icon fixed type="button" class="file__button" (click)="hiddenfileinput.click()" *ngIf="multiple || attachments.length == 0">
    <i class="uil uil-upload-alt"></i>
    {{ 'form.elements.files.add_file' | transloco }}
  </button>
</ng-container>
