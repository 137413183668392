import { Injectable } from '@angular/core';
import {
  Pool,
  PoolDataService,
  PoolProvisionalStatement,
  PoolProvisionalStatementRow
} from '@ppa/data';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PoolService {
  constructor(private poolDataService: PoolDataService) {}

  options(params?: { [key: string]: any }): Observable<Pool[]> {
    return this.poolDataService.list(params).pipe(
      pluck('data'),
      map((pools: Pool[]) => {
        return pools.map((pool) => {
          return {
            id: pool.id,
            name: pool.name + ' ' + pool.harvestYear,
          } as Pool;
        });
      }),
    );
  }

  getPoolById(id: number): Observable<Pool> {
    return this.poolDataService.read(id).pipe(
      map((pool) => {
        pool.price = pool.price?.toString().replace('.', ',');
        return pool;
      }),
    );
  }

  getProvisionalStatementRows(id: number) {
    return this.poolDataService.getProvisionalStatementRows(id);
  }

  downloadProvisionalStatement(id: number) {
    return this.poolDataService.downloadProvisionalStatement(id);
  }

  patchProvisionalStatementRows(id: number, rows: PoolProvisionalStatementRow[]) {
    return this.poolDataService.patchProvisionalStatementRows({
      id,
      poolProvisionalStatementRows: rows,
    });
  }

  sendProvisionalStatement(provisionalStatement: PoolProvisionalStatement, sendPoolForm: { message: string }) : Observable<any> {
    return this.poolDataService.sendProvisionalStatement(provisionalStatement, sendPoolForm);
  }

}
