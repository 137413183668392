import { Injectable } from '@angular/core';
import { AuthService, DossierReminder, Reminder, ReminderDataService } from '@ppa/data';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReminderService {
  constructor(private reminderDataService: ReminderDataService, private authService: AuthService) {}

  create(reminder: Reminder): Observable<Reminder> {
    return this.reminderDataService.create(reminder);
  }

  public getDossierAndOrderReminders(): Observable<{ [key: string]: Reminder[] }> {
    const currentLoggedInMediator = this.authService.getUserIdFromToken().toString();
    return this.reminderDataService.getDossierAndOrderReminders(currentLoggedInMediator).pipe(
      map((reminders) => {
        const keys = Object.keys(reminders);

        keys.forEach((key) => {
          const checkKey = key.replace('Reminders', '');
          const allowed = this.authService.componentOrModuleAllowed(checkKey);
          if (!allowed) {
            delete reminders[key];
          }
        });

        return reminders;
      }),
    );
  }

  public deleteReminder(reminder: Reminder): Observable<Reminder> {
    return this.reminderDataService.deleteReminder(reminder);
  }

  public getOpenStatusReminderCounts(): Observable<{ [key: string]: string }> {
    const currentLoggedInMediator = this.authService.getUserIdFromToken().toString();
    return this.reminderDataService.getOpenStatusReminderCounts(currentLoggedInMediator).pipe(
      map((reminderStatuses) => {
        const keys = Object.keys(reminderStatuses);

        keys.forEach((key) => {
          const item: any = reminderStatuses[key];
          const allowed = this.authService.componentOrModuleAllowed(item.type);
          if (!allowed) {
            delete reminderStatuses[key];
          }
        });

        return reminderStatuses;
      }),
    );
  }
}
