import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Ledger } from "../contracts/ledger";

@Injectable({
  providedIn: 'root',
})
export class LedgerDataService extends BaseService<Ledger> {
  path = 'ledgers';
}
