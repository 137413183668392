import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';

export interface Period {
  key: string;
  value: string;
  valueRaw: string;
  display?: string[];
}

const PERIODS: Period[] = [
  {
    key: 'between',
    value: 'delivery_period.options.between.value',
    valueRaw: 'delivery_period.options.between.raw',
    display: ['fromDate', 'toDate'],
  },
  {
    key: 'consultation',
    value: 'delivery_period.options.consultation.value',
    valueRaw: 'delivery_period.options.consultation.raw',
  },
  {
    key: 'grown',
    value: 'delivery_period.options.grown.value',
    valueRaw: 'delivery_period.options.grown.raw',
  },
  {
    key: 'off_meadow_between',
    value: 'delivery_period.options.off_meadow_between.value',
    valueRaw: 'delivery_period.options.off_meadow_between.raw',
    display: ['fromDate', 'toDate'],
  },
  {
    key: 'off_meadow_on',
    value: 'delivery_period.options.off_meadow_on.value',
    valueRaw: 'delivery_period.options.off_meadow_on.raw',
    display: ['onDate'],
  },
  {
    key: 'barn_consultation',
    value: 'delivery_period.options.barn_consultation.value',
    valueRaw: 'delivery_period.options.barn_consultation.raw',
  },
  {
    key: 'barn_between',
    value: 'delivery_period.options.barn_between.value',
    valueRaw: 'delivery_period.options.barn_between.raw',
    display: ['fromDate', 'toDate'],
  },
  {
    key: 'barn_on',
    value: 'delivery_period.options.barn_on.value',
    valueRaw: 'delivery_period.options.barn_on.raw',
    display: ['onDate'],
  },
  {
    key: 'barn_from',
    value: 'delivery_period.options.barn_from.value',
    valueRaw: 'delivery_period.options.barn_from.raw',
    display: ['fromDate'],
  },
  {
    key: 'barn_to',
    value: 'delivery_period.options.barn_to.value',
    valueRaw: 'delivery_period.options.barn_to.raw',
    display: ['onDate'],
  },
  {
    key: 'harvest_date',
    value: 'delivery_period.options.harvest_date.value',
    valueRaw: 'delivery_period.options.harvest_date.raw',
    display: ['onDate'],
  },
];

@Injectable({
  providedIn: 'root',
})
export class PeriodService {
  getPeriods() {
    return PERIODS.map((period) => {
      return {
        ...period,
        value: translate(period.value),
        valueRaw: translate(period.valueRaw),
      };
    });
  }
}
