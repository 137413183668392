<div class="ppa-modal" data-cy="modal-dossier-create-wizard">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__wizard">
    <div class="ppa-modal__heading">
      <h3>{{ (activeWizardStep$ | async)?.title }}</h3>
    </div>

    <div
      class="{{ (activeWizardStep$ | async).ownContentTemplate ? 'ppa-modal__content-flex' : 'ppa-modal__content' }}"
    >
      <div #wizardStep></div>
    </div>

    <div class="ppa-modal__actions" *ngIf="activeWizardStep$ | async as activeWizardStep">
      <div>
        <button
          mat-button
          *ngIf="activeWizardStep.hasPrevBtn$ ? (activeWizardStep.hasPrevBtn$ | async) : true"
          (click)="previous()"
        >
          <i class="uil uil-arrow-left"></i>
          Vorige
        </button>
      </div>
      <div>
        <ng-container *ngIf="(reachedLastWizardStep$ | async) === false">
          <button
            data-cy="button-wizard-next"
            mat-flat-button
            *ngIf="activeWizardStep.hasNextBtn$ ? (activeWizardStep.hasNextBtn$ | async) : true"
            (click)="next()"
          >
            <ng-container *ngIf="(activeWizardStep.isDirty$ | async) === false">Overslaan</ng-container>
            <ng-container *ngIf="(activeWizardStep.isDirty$ | async) === true">Volgende</ng-container>
            <i class="uil uil-arrow-right"></i>
          </button>
        </ng-container>
        <ng-container *ngIf="(reachedLastWizardStep$ | async) === true">
          <button data-cy="button-wizard-finish" mat-flat-button color="primary" (click)="finish()">Afronden</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="ppa-modal__fader"></div>
