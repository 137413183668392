<div class="ppa-modal ppa-modal--no-bottom-padding" data-cy="modal-on-call">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h2>
      {{ 'modules.order.modal.detail.title' | transloco }}
    </h2>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column pb-1">
    <ng-container *ngIf="orders; else loading">
      <ng-container *ngIf="orders.length > 0; else noResult">
        <ppa-order-card
          *ngFor="let order of orders"
          [orderCardInfo]="order"
          [phone]="false"
          [expiresAtDate]="true"
          [price]="order.indicationPrice"
          [cardType]="'action'"
          [actionCard]="true"
          (click)="viewOrder(order.id)"
        ></ppa-order-card>
      </ng-container>

      <button mat-flat-button color="primary" (click)="createOrder()" class="button--full-width">
        {{ 'modules.order.modal.detail.create_new' | transloco }}
      </button>
    </ng-container>
  </div>
</div>

<div class="ppa-modal__fader"></div>

<ng-template #loading>
  <ppa-loading [class]="'loading--inline'"></ppa-loading>
</ng-template>

<ng-template #noResult>
  <ppa-no-result></ppa-no-result>
</ng-template>
