import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ReadResponse } from '../contracts/read-response';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Contract, Order } from '../contracts';
import { DownloadFileService } from './download-file.service';

@Injectable({
  providedIn: 'root',
})
export class ContractDataService extends BaseService<Contract> {
  path = 'contracts';

  read(id: number): Observable<ReadResponse<Contract>> {
    return super.read(id).pipe(
      map((contract) => {
        return {
          ...contract,
          ...(contract.price && { price: contract.price.toString().replace('.', ',') }),
          ...(contract.commission && { commission: contract.commission.toString().replace('.', ',') }),
          ...(contract.amount && { amount: contract.amount.toString().replace('.', ',') }),
          ...(contract.amountOfUnit && { amountOfUnit: contract.amountOfUnit.toString().replace('.', ',') }),
        };
      }),
    );
  }

  downloadContract(contract: Contract) {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${contract.id}/download`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  send(contract: Contract, messages: { message: string }[]) {
    return this.http.post(`${this.apiConfig.baseUrl}/${this.path}/${contract.id}/send`, messages);
  }

  cancel(contract: Contract, data: string) {
    return this.http.put(`${this.apiConfig.baseUrl}/${this.path}/${contract.id}/cancel`, data);
  }

  updateOwnReference(data: Partial<Contract>) {
    data = this.transformContractData(data);
    return this.http.put<ReadResponse<Contract>>(`${this.apiConfig.baseUrl}/${this.path}/${data.id}/reference`, data);
  }
  private transformContractData(data: Partial<Contract>) {
    const dataCopy = JSON.parse(JSON.stringify(data));

    Object.keys(dataCopy).forEach((key) => {
      let obj = data[key];
      if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
        if (Object.keys(obj).some((foundKey) => foundKey === 'id')) {
          Object.keys(obj).forEach((childKey) => {
            if (childKey !== 'id') {
              delete obj[childKey];
            }
          });
        }
      }
    });
    return dataCopy;
  }
}
