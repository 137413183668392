import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { saveAs as importedSaveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  static downloadAndOpenFile(blob: Blob, headers: HttpHeaders) {
    const fileName = headers.get('X-Filename');
    importedSaveAs(blob, fileName);
  }

  static getHeaders(data: { file: string; mime: string }) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', data.mime);
    headers = headers.set('X-Filename', data.file);
    return headers;
  }

  static getBlob(data: { base64: string; mime: string }) {
    return DownloadFileService.createBlob(data);
  }

  static getBlobUrl(data: { base64: string; mime: string }) {
    const blob = DownloadFileService.getBlob(data);
    return (window as any).URL.createObjectURL(blob);
  }

  static createBlob(data: { base64: string; mime: string }) {
    const base64ImageData = data.base64;
    const contentType = data.mime;
    const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
}
