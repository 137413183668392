import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  scrollToFirstInvalid(form: FormGroup): void {
    const firstInvalid = this.getFirstInvalidFieldName(form);
    if (firstInvalid) {
      const el = this.getFirstInvalidElement(firstInvalid);

      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  private getFirstInvalidFieldName = (form: FormGroup): string | null => {
    for (const [key, value] of Object.entries(form.controls)) {
      if (!value.valid && value.enabled) {
        return key;
      }
    }
    return null;
  };

  private getFirstInvalidElement(identifier: string): Element | null {
    let el = document.querySelector(`[formControlName='${identifier}']`);
    if (!el) {
      el = document.querySelector(`[scrollTo='${identifier}']`);
    }

    return el;
  }
}
