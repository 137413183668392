import { BehaviorSubject } from 'rxjs';

export class OverviewCard {
  showLoader$ = new BehaviorSubject<boolean>(false);

  startLoading() {
    this.showLoader$.next(true);
  }

  stopLoading() {
    this.showLoader$.next(false);
  }
}
