import { Injectable } from '@angular/core';
import { VatCode, VatCodeDataService } from '@ppa/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VatCodeService {
  constructor(private vatCodeDataService: VatCodeDataService) {}

  getVatCodes(params?: { [key: string]: any }): Observable<VatCode[]> {
    return this.vatCodeDataService.list(params);
  }
}
