import { Component, OnDestroy, OnInit } from '@angular/core';
import { DossierEditComponent } from '../dossier-edit/dossier-edit.component';

@Component({
  selector: 'ppa-dossier-create',
  templateUrl: '../dossier-edit/dossier-edit.component.html',
  styleUrls: ['../dossier-edit/dossier-edit.component.scss'],
})
export class DossierCreateComponent extends DossierEditComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    this.isCreateComponent = true;
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
