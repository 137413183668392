import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { Inject, Injectable, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { API_CONFIG, ApiConfig } from '@ppa/data';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, @Inject(API_CONFIG) public apiConfig: ApiConfig) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`${this.apiConfig.baseUrl}/translations/ppa`);
  }
}

// tslint:disable-next-line: max-classes-per-file
@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['nl'],
        defaultLang: 'nl',
        fallbackLang: 'nl',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
