import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { PriceTemplate } from '@ppa/data';
import { Observable, of } from 'rxjs';
import { ListResponse } from '../contracts/list-response';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PriceTemplateDataService extends BaseService<PriceTemplate> {
  path = 'price_templates';

  list(params?: { [p: string]: any }): Observable<ListResponse<PriceTemplate>> {
    return super.listPaginated({}).pipe(switchMap((templates) => of(templates.data)));
  }

  getPriceTemplateOptions(): Observable<ListResponse<PriceTemplate>> {
    return this.http.get<ListResponse<PriceTemplate>>(`${this.apiConfig.baseUrl}/${this.path}/options`);
  }
}
