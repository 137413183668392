import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { UserFilter, FilterModule, UserFilterData } from '../contracts';
import { Observable, of } from 'rxjs';
import { ReadResponse } from '../contracts/read-response';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserFilterService extends BaseService<UserFilter> {
  path = 'user-filters';

  filters(module: FilterModule): Observable<UserFilter[]> {
    return this.http.get<UserFilter[]>(`${this.apiConfig.baseUrl}/${this.path}/filters/${module}`);
  }

  createFilter(data: UserFilterData): Observable<UserFilter> {
    return this.http.post<ReadResponse<UserFilter>>(`${this.apiConfig.baseUrl}/${this.path}`, data);
  }

  deleteFilter(userFilter: UserFilter): Observable<UserFilter> {
    const params = new HttpParams({
      fromObject: {
        userFilter: JSON.stringify(userFilter),
      },
    });

    return this.http.delete<UserFilter>(`${this.apiConfig.baseUrl}/${this.path}/${userFilter.id}`, { params });
  }
}
