import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ppa-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.scss'],
})
export class NoResultComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
