<div class="ppa-modal ppa-modal--nested">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>
  <div class="ppa-modal__heading">
    <h2>{{ title }}</h2>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <ng-container *ngIf="contentType === 'functions' && !contentSelect">
      <ng-container *ngFor="let category of functions">
        <div class="variables-list">
          <h3>{{ category.label }}</h3>
          <ng-container *ngFor="let function of category.functions">
            <div>
              <ppa-action-card (click)="selectFunction(function)" actionIcon="uil-plus">
                <strong>{{ function.value }}</strong>
                <em>{{ '{' + function.key + '}' }}</em>
              </ppa-action-card>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="contentType === 'variables' || contentSelect === 'variables'">
      <ng-container *ngFor="let category of variables">
        <div class="variables-list">
          <h3>{{ category.label }}</h3>
          <ng-container *ngFor="let variable of category.variables">
            <div>
              <ppa-action-card (click)="selectVariable(variable)" actionIcon="uil-plus">
                <strong>{{ variable.value }}</strong>
                <em>{{ '{' + variable.key + '}' }}</em>
              </ppa-action-card>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="contentSelect === 'values'">
      <div [formGroup]="conditionForm">
        <button mat-raised-button (click)="selectValue(true)" class="button--full-width">
          {{ 'modals.editor.condition-exists' | transloco }}
        </button>

        <h3>{{ 'modals.editor.condition-operators' | transloco }}</h3>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'modals.editor.condition-value' | transloco }}</mat-label>
          <input type="text" formControlName="value" matInput />
        </mat-form-field>

        <div class="buttons">
          <button mat-raised-button color="primary" (click)="selectValue('=')">Gelijk aan<br />=</button>
          <button mat-raised-button color="primary" (click)="selectValue('!=')">Ongelijk aan<br />!=</button>
          <button mat-raised-button color="primary" (click)="selectValue('>')">Groter dan<br />&gt;</button>
          <button mat-raised-button color="primary" (click)="selectValue('<')">Kleiner dan<br />&lt;</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="ppa-modal__fader ppa-modal__fader--nested"></div>
