import { Directive, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { Observable, of } from 'rxjs';

@Directive({
  selector: '[ppaNumberInput]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: NumberInputDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumberInputDirective,
      multi: true,
    },
  ],
})
export class NumberInputDirective {
  // tslint:disable-next-line:variable-name
  private _value: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  private formatValue(value: string | null) {
    if (value !== null && value !== undefined) {
      this.elementRef.nativeElement.value = ('' + value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    if (value) {
      /* @ts-ignore */
      this._value = parseFloat(('' + value).replace(',', '.'));
    } else {
      this._value = value;
    }
    this._onChange(this._value); // here to notify Angular Validators
  }

  @HostListener('blur')
  _onBlur() {
    if (this._value) {
      /* @ts-ignore */
      this._value = parseFloat(('' + this._value).replace(',', '.'));
    }
    this.formatValue(this._value);
    this._onChange(this._value); // here to notify Angular Validators
    this._onTouched();
  }

  _onChange(value: any): void {}

  _onTouched(): void {}

  writeValue(value: any) {
    if (value) {
      /* @ts-ignore */
      this._value = parseFloat(('' + value).replace(',', '.'));
    } else {
      this._value = value;
    }
    this.formatValue(this._value); // format Value
  }

  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
}
