import { ReactiveFormsModule } from '@angular/forms';
import { AddPhotoModalComponent } from './modals/add-photo-modal/add-photo-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { EditPhotoModalComponent } from './modals/edit-photo-modal/edit-photo-modal.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AddPhotoComponent } from './components/add-photo/add-photo.component';
import { SpinnerModule } from '@ppa/layout';

@NgModule({
  declarations: [AddPhotoModalComponent, EditPhotoModalComponent, AddPhotoComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    MatGridListModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    SpinnerModule,
  ],
  exports: [AddPhotoModalComponent, EditPhotoModalComponent, AddPhotoComponent],
  providers: [{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
})
export class PhotoModule {}
