import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractOverviewCardComponent } from './contract-overview-card.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { LoadingModule } from '../loading';
import { RelationStatusModule } from '../relation-status';

@NgModule({
  declarations: [ContractOverviewCardComponent],
  imports: [CommonModule, MatButtonModule, TranslocoModule, LoadingModule, RelationStatusModule],
  exports: [ContractOverviewCardComponent],
})
export class ContractOverviewCardModule {}
