import { Component, Input, OnInit } from '@angular/core';
import { Modal, ModalService } from '@ppa/layout';
import { Observable } from 'rxjs';
import { Address } from '../../../../services/location.service';
import { NgNavigatorShareService } from 'ng-navigator-share';

@Component({
  selector: 'ppa-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationComponent extends Modal {
  location: [number, number];
  @Input() address: Address;
  @Input() marker: [number, number];
  @Input() relationLocation$: Observable<[number, number]>;
  @Input() useCurrentLocationOnLoad = true;
  @Input() editable = true;

  constructor(protected modalService: ModalService, private shareService: NgNavigatorShareService) {
    super(modalService);
  }

  locationPicked($event: [Address, [number, number]]) {
    const [address, marker] = $event;
    this.address = address;
    this.marker = marker;
  }

  save(data: [Address, [number, number]]) {
    super.save(data);
  }

  saveLocation(): void {
    this.save([this.address, this.marker]);
  }

  get locationGoogleUrl() {
    const location = this.address;
    const marker = this.marker;
    const address =
      location && location.number
        ? `${location.street}+${location.number}, ${location.zipcode}+${location.city}`
        : `${location.street}, ${location.zipcode}+${location.city}`;

    return marker
      ? `https://www.google.com/maps/dir//${marker[1]},${marker[0]}/@${marker[1]},${marker[0]},1000m/data=!3m1!1e3`
      : `https://www.google.com/maps/dir//${address},1000m/data=!3m1!1e3`;
  }

  get locationMapsUrl() {
    const location = this.address;
    const marker = this.marker;
    const address =
      location && location.number
        ? `${location.street}+${location.number}, ${location.zipcode}+${location.city}`
        : `${location.street}, ${location.zipcode}+${location.city}`;

    return marker
      ? `https://maps.apple.com/?t=h&address=${marker[1]},${marker[0]}&ll=${marker[1]},${marker[0]}&z=17`
      : `https://maps.apple.com/?t=h&daddr=${address}&z=17`;
  }

  get canShare() {
    return this.shareService.canShare();
  }

  shareLocation() {
    const data = {
      title: this.addressToDisplayString(this.address),
      url: this.locationGoogleUrl,
    };

    this.shareService.share(data);
  }

  addressToDisplayString(address: Address): string {
    return `${address.street} ${address.number}, ${address.city}` ?? null;
  }
}
