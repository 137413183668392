import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoolTotalsComponent } from './pool-totals.component';
import { TranslocoModule } from '@ngneat/transloco';
import {LayoutModule} from "../../layout.module";


@NgModule({
  declarations: [PoolTotalsComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    LayoutModule
  ],
  exports: [
    PoolTotalsComponent
  ]
})
export class PoolTotalsModule { }
