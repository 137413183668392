import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import {
  ActionCardModule,
  CardModule,
  CarouselModule,
  DatepickerModule,
  DatetimePickerModule,
  FloatingButtonModule,
  HeaderModule,
  LoadingModule,
  NoResultModule,
  NotationModule,
  OverviewFilteredOnRelationModule,
  SelectModule,
  CertificateCheckboxModule,
  TimePickerModule,
  FilterFormModule,
  SpinnerModule,
  StatusModule,
  LayoutModule,
  PaginationModule,
  OrderCardModule,
  RelationStatusModule,
} from '@ppa/layout';
import { ToastrModule } from 'ngx-toastr';
import { RelationPickerModule } from '../relation-picker/relation-picker.module';
import { LocationModule } from '../location/location.module';
import { PhotoModule } from '../photo/photo.module';
import { DossierEditComponent } from './components/dossier-edit/dossier-edit.component';
import { DossierOverviewComponent } from './components/dossier-overview/dossier-overview.component';
import { DossierWizardAddPhotoComponent } from './components/dossier-wizard-add-photo/dossier-wizard-add-photo.component';
import { DossierWizardSelectLocationComponent } from './components/dossier-wizard-select-location/dossier-wizard-select-location.component';
import { DossierWizardSelectRelationComponent } from './components/dossier-wizard-select-relation/dossier-wizard-select-relation.component';
import { DossierWizardSpecificationsOneComponent } from './components/dossier-wizard-specifications-one/dossier-wizard-specifications-one.component';
import { DossierWizardSpecificationsThreeComponent } from './components/dossier-wizard-specifications-three/dossier-wizard-specifications-three.component';
import { DossierWizardSpecificationsTwoComponent } from './components/dossier-wizard-specifications-two/dossier-wizard-specifications-two.component';
import { DOSSIER_ROUTES } from './dossier.routing';
import { MatTabsModule } from '@angular/material/tabs';
import { DossierReminderOldComponent } from './components/dossier-reminder-old/dossier-reminder.component';
import { DossierCreateComponent } from './components/dossier-create/dossier-create.component';
import { DossierMapComponent } from './modals/dossier-map/dossier-map.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DossierDetailComponent } from './components/dossier-detail/dossier-detail.component';
import { DossierContactMomentComponent } from './components/dossier-contact-moment/dossier-contact-moment.component';
import { DossierReminderComponent } from './components/dossier-reminder/dossier-reminder.component';

@NgModule({
  declarations: [
    DossierDetailComponent,
    DossierContactMomentComponent,
    DossierCreateComponent,
    DossierEditComponent,
    DossierOverviewComponent,
    DossierWizardSelectRelationComponent,
    DossierWizardSelectLocationComponent,
    DossierWizardAddPhotoComponent,
    DossierWizardSpecificationsOneComponent,
    DossierWizardSpecificationsTwoComponent,
    DossierWizardSpecificationsThreeComponent,
    DossierReminderOldComponent,
    DossierReminderComponent,
    DossierMapComponent,
  ],
  imports: [
    LayoutModule,
    CommonModule,
    DOSSIER_ROUTES,
    MatButtonModule,
    FloatingButtonModule,
    NotationModule,
    TranslocoModule,
    CardModule,
    NgSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    RelationPickerModule,
    LocationModule,
    PhotoModule,
    CarouselModule,
    ToastrModule.forRoot(),
    MatDatepickerModule,
    DatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    SelectModule,
    ActionCardModule,
    LoadingModule,
    NoResultModule,
    CertificateCheckboxModule,
    MatTabsModule,
    SpinnerModule,
    StatusModule,
    RelationStatusModule,
    OverviewFilteredOnRelationModule,
    OrderCardModule,
    HeaderModule,
    DatetimePickerModule,
    FilterFormModule,
    PaginationModule,
    MatTooltipModule,
  ],
})
export class DossierModule {}
