import { RouterModule, Routes } from '@angular/router';
import { DossierEditComponent } from './components/dossier-edit/dossier-edit.component';
import { DossierOverviewComponent } from './components/dossier-overview/dossier-overview.component';
import { CanDeactivateGuard } from '../../guards/can-deactivate.guard';
import { DossierCreateComponent } from './components/dossier-create/dossier-create.component';
import { DossierDetailComponent } from './components/dossier-detail/dossier-detail.component';
import { DossierContactMomentComponent } from './components/dossier-contact-moment/dossier-contact-moment.component';
import { DossierReminderComponent } from './components/dossier-reminder/dossier-reminder.component';
import { AuthenticatedGuard } from '@ppa/data';

const routes: Routes = [
  {
    path: '',
    component: DossierOverviewComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'mine',
    component: DossierOverviewComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: ':id',
    component: DossierDetailComponent,
    pathMatch: 'full',
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'edit/:id',
    component: DossierEditComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticatedGuard],
  },
  {
    path: ':id/contact_moment',
    component: DossierContactMomentComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticatedGuard],
  },
  {
    path: ':id/reminder',
    component: DossierReminderComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticatedGuard],
  },
  {
    path: ':id/edit',
    component: DossierEditComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'create/:id',
    component: DossierCreateComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthenticatedGuard],
  },
];

export const DOSSIER_ROUTES = RouterModule.forChild(routes);
