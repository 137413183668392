import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { LayoutModule } from '../../layout.module';

@NgModule({
  declarations: [DataTableComponent],
  imports: [
    CommonModule,
    NgxDatatableModule,
    TranslocoModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    LayoutModule,
  ],
  exports: [DataTableComponent],
})
export class DataTableModule {}
