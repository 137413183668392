<div class="contract-field" [formGroup]="fieldForm">
  <h2 class="contract-field--header">
    {{ label(identifier) }} <i class="uil uil-sort" *ngIf="positionOverwritten$ | async" title="Verplaatst"></i>
  </h2>

  <div class="contract-field--fields">
    <div class="contract-field--column contract-field--label" *ngIf="fieldType === 'keyvalue'">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'modules.contract_template.form.elements.label' | transloco }}</mat-label>
        <input type="text" formControlName="label" matInput />
      </mat-form-field>
    </div>
    <div class="contract-field--column contract-field--content">
      <ngx-wig
        buttons="bold,italic,underline,variables,functions"
        placeholder="{{ 'modules.contract_template.form.elements.content' | transloco }}"
        formControlName="content"
        data-cy="contract-field-content"
      ></ngx-wig>
    </div>
    <div class="contract-field--column contract-field--settings">
      <mat-slide-toggle formControlName="active">
        <mat-label>{{ 'modules.contract_template.form.elements.active' | transloco }}</mat-label>
      </mat-slide-toggle>

      <mat-slide-toggle formControlName="required">
        <mat-label>{{ 'modules.contract_template.form.elements.required' | transloco }}</mat-label>
      </mat-slide-toggle>

      <ng-container *ngIf="allowOverwrite">
        <ng-container *ngIf="overwrite; else overwriteButton">
          <button class="clear-input" mat-button color="warn" type="button" (click)="clearContent()">
            <i class="uil uil-times"></i>
            {{ 'modules.contract_template.form.elements.reset' | transloco }}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #overwriteButton>
  <button class="fill-input" mat-button color="primary" type="button" (click)="overwriteContent()">
    <i class="uil uil-times"></i>
    {{ 'modules.contract_template.form.elements.overwrite' | transloco }}
  </button>
</ng-template>
