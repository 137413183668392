import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine',
})
export class NewLinePipe implements PipeTransform {
  transform(value: any, replace: string = '<br />', separator: string = null): unknown {
    let retVal = value;

    if (separator === undefined || separator === null) {
      separator = '|';
    }

    retVal = retVal.split(separator).join(replace);

    return retVal;
  }
}
