import { Model } from './model';
import { Delivery } from './delivery';

export interface DeliveryReportRow extends Model {
  deliveryDate: Date;
  deliveryTime: string;
  amount: string;
  licensePlate?: string;
}

export interface DeliveryReport extends Model {
  totalAmount?: string;
  grit?: string;
  humidity?: string;
  tare?: string;
  class?: string;
  color?: number;
  delivery?: Delivery;
  reportSentAt?: Date;
  remarks?: string;
  documents?: [];
  type?: DeliveryReportType;
  file?: any;
  receiverType?: ReceiverType;
}

export enum DeliveryReportType {
  Default = 'default',
  Upload = 'upload',
}

export enum ReceiverType {
  Both = 'both',
  Buyer = 'buyer',
  Other = 'other',
  Seller = 'seller',
}

export interface DeliveryReportDocument extends Model {
  path?: string;
  status?: string;
  type?: string;
  base64?: string;
  mime?: string;
  name?: string;
}
