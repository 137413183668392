import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { zip } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { PPAToastrComponent } from '../components/toastr';
import { Intention } from '../contracts';
import { ToastrConfig } from '../contracts/toastr-config';

@Injectable({
  providedIn: 'root',
})
export class PPAToastrService {
  private toastRef: ToastRef<any>;

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      if (this.toastRef) {
        this.toastRef.close();
      }
    });
  }

  // Display toastr with given config, returns observable if button is configured.
  displayToastr(options: ToastrConfig): void {
    this.toastRef = this.toastrService.show('', '', {
      ...(options.duration && { timeOut: options.duration }),
      ...(!options.duration && { disableTimeOut: true }),
      toastComponent: PPAToastrComponent,
    }).toastRef;

    this.toastRef.componentInstance.config = options;
  }

  clear(): void {
    this.toastrService.clear();
  }

  // Display error toastr telling given resource is not found.
  resourceNotFound(resource: string): void {
    zip(this.translocoService.selectTranslate('not_found.resource'), this.translocoService.selectTranslate(resource))
      .pipe(
        map(([sentence, resource]) => ({ sentence, resource })),
        first(),
      )
      .subscribe((translation) => {
        this.displayToastr({
          icon: 'uil-exclamation-octagon',
          intention: Intention.Error,
          title: `${translation.resource} ${translation.sentence}`,
          duration: 5000,
        });
      });
  }
}
