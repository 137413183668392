<div>
  <form #form="ngForm" [formGroup]="reminderForm" autocomplete="off">
    <div class="reminder__moment-title mb-1">
      <i class="uil uil-calender type-icon"></i>
      {{ 'modules.dossier.create.reminders.title' | transloco }} {{ count + 1 }}
      <i class="uil uil-trash-alt reminder__remove" (click)="handleRemoveReminder()"></i>
    </div>

    <ppa-datetime-picker
      formControlName="reminderDate"
      [required$]="isRequiredField('reminderDate')"
      defaultTime="09:00"
      [timeSelect]="true"
    ></ppa-datetime-picker>
    <div class="form-error" *ngIf="(parentForm.submitted || reminderDate.touched) && reminderDate.invalid">
      <span>{{ 'form_errors.required' | transloco }}</span>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'modules.dossier.create.reminders.description' | transloco }}
        <span class="required" *ngIf="isRequiredField('description') | async">*</span>
      </mat-label>
      <textarea cdkTextareaAutosize formControlName="description" matInput rows="10"></textarea>
    </mat-form-field>
    <div class="form-error" *ngIf="(parentForm.submitted || description.touched) && description.invalid">
      <span>{{ 'form_errors.required' | transloco }}</span>
    </div>
  </form>
</div>
