import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent, Intention, Modal, ModalService, PPAToastrService } from '@ppa/layout';
import { ContractType, ContractTypes, Order, Pool } from '@ppa/data';
import { translate } from '@ngneat/transloco';
import { ErrorHandlerService } from '@ppa/layout';
import { ContractFormComponent } from '../../components/contract-form/contract-form.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderService } from '../../../order/services/order.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScrollService } from '../../../../services/scroll-service';
import { shareReplay, tap } from 'rxjs/operators';
import { PoolService } from '../../../pool/services/pool.service';

@Component({
  selector: 'ppa-contract-type-modal',
  templateUrl: './contract-type-modal.component.html',
  styleUrls: ['./contract-type-modal.component.scss'],
})
export class ContractTypeModalComponent extends Modal implements OnInit {
  sendContractTypeForm: FormGroup;
  order: Order;
  hasContractTypeAndTemplate: boolean;
  contractTypes: ContractType[];

  @ViewChild('form', { read: ContractFormComponent }) form: ContractFormComponent;

  private enablePool$ = new BehaviorSubject<boolean>(false);
  displayPool$: Observable<boolean>;
  pools$: Observable<Pool[]>;

  get controls() {
    return this.sendContractTypeForm.controls;
  }

  constructor(
    _modalService: ModalService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private toastrService: PPAToastrService,
    private errorHandler: ErrorHandlerService,
    private scrollService: ScrollService,
    private poolService: PoolService,
  ) {
    super(_modalService);

    this.sendContractTypeForm = this.fb.group({
      contractType: [null, [Validators.required]],
      pool: [],
    });

    this.contractTypes = this.orderService.getContractTypes();

    this.displayPool$ = this.enablePool$.pipe(
      tap((val) => this.sendContractTypeForm.get('pool').setValidators(val ? [Validators.required] : [])),
    );
  }

  ngOnInit(): void {
    if (this.order) {
      this.pools$ = this.poolService.options({
        'finished': 0,
        'product': this.order?.product?.id,
      }).pipe(shareReplay(1));

      this.sendContractTypeForm.patchValue(this.order);

      if (this.order.contractType === ContractTypes.Pool) {
        this.enablePool$.next(true);
      }

      this.sendContractTypeForm.get('contractType')?.valueChanges.subscribe((val) => {
        this.enablePool$.next(val === ContractTypes.Pool);
      });
    }
  }

  close() {
    if (this.sendContractTypeForm.touched) {
      this.modalService
        .createModal(ConfirmDialogComponent, {
          message: translate('navigation_guard.title'),
          cancelButtonText: translate('navigation_guard.buttons.cancel'),
          confirmButtonText: translate('navigation_guard.buttons.discard'),
        })
        .dialog.onSave()
        .then(() => {
          super.close();
        });
    } else {
      super.close();
    }
  }

  send() {
    if (this.sendContractTypeForm.valid) {
      if (this.order.hasSentContracts) {
        return;
      }

      if (this.hasContractTypeAndTemplate && this.order.hasContracts) {
        if (this.order.contractType !== this.sendContractTypeForm.get('contractType').value) {
          this.modalService
            .createModal(ConfirmDialogComponent, {
              message: translate('modules.contract.modal.contracttype.deletion_warning'),
            })
            .dialog.onSave()
            .then(() => {
              this.saveContractTypeSubmit(true);
            });
        } else {
          // geen wijzigingen, gewoon sluiten
          super.close();
        }
      } else if (!this.hasContractTypeAndTemplate || (this.hasContractTypeAndTemplate && !this.order.hasContracts)) {
        if (this.order.contractType !== this.sendContractTypeForm.get('contractType').value) {
          this.saveContractTypeSubmit();
        } else {
          // geen wijzigingen, gewoon sluiten
          super.close();
        }
      }
    } else {
      this.scrollService.scrollToFirstInvalid(this.sendContractTypeForm);
    }
  }

  private saveContractTypeSubmit(deleteContracts: boolean = false) {
    this.orderService.saveContactType(this.order, this.sendContractTypeForm.value, deleteContracts).subscribe(
      () => {
        this.toastrService.displayToastr({
          intention: Intention.Success,
          title: translate('modules.contract.modal.contracttype.confirmation'),
          icon: 'uil-check',
          duration: 3000,
        });
        this.save(true);
      },
      (error) => {
        this.errorHandler.handleAPIError(translate(''), error);
      },
    );
  }
}
