import { Component, Input, OnInit } from '@angular/core';
import { Modal, ModalService } from '@ppa/layout';
import { Observable, of, Subject } from 'rxjs';
import { Address } from '../../../../services/location.service';
import { Dossier } from '@ppa/data';

@Component({
  selector: 'ppa-dossier-map',
  templateUrl: './dossier-map.component.html',
  styleUrls: ['./dossier-map.component.scss'],
})
export class DossierMapComponent extends Modal {
  location: [number, number];
  @Input() dossiers: Dossier[];
  clickedDossiers$: Observable<Dossier[]> = new Observable<Dossier[]>();

  constructor(protected modalService: ModalService) {
    super(modalService);

    this.clickedDossiers$.subscribe((dossiers) => {
    });
  }

  clickedDossiers(dossiers): void {
    this.clickedDossiers$ = of(dossiers);
  }

  // save(data: [Address, [number, number]]) {
  //   super.save(data);
  // }
  //
  // saveLocation(): void {
  //   this.save([this.address, this.marker]);
  // }
}
