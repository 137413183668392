import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BindLabelPipe} from './pipes/bind-label.pipe';
import {OverviewCardGroupPipe} from './pipes/overview-card-group.pipe';
import {NewLinePipe} from './pipes/new-line.pipe';
import {PPADatePipe} from './pipes/date.pipe';
import {EscapeHtmlPipe} from './pipes/keep-html.pipe';
import {NumberInputDirective} from './directives/number-input.directive';
import {AutocompleteOffDirective} from './directives/autocomplete-off.directive';
import {PPADebugPipe} from "./pipes/debug.pipe";
import {PPANumberPipe} from "./pipes/number.pipe";
import {PPACurrencyPipe} from "./pipes/currency.pipe";
import {DebugModule} from "./components/debug";

@NgModule({
  imports: [CommonModule],
  declarations: [
    BindLabelPipe,
    PPADebugPipe,
    PPANumberPipe,
    PPACurrencyPipe,
    OverviewCardGroupPipe,
    NewLinePipe,
    PPADatePipe,
    EscapeHtmlPipe,
    NumberInputDirective,
    AutocompleteOffDirective
  ],
  exports: [
    BindLabelPipe,
    PPADebugPipe,
    PPANumberPipe,
    PPACurrencyPipe,
    OverviewCardGroupPipe,
    NewLinePipe,
    PPADatePipe,
    EscapeHtmlPipe,
    NumberInputDirective,
    AutocompleteOffDirective,
    DebugModule
  ],
})
export class LayoutModule {}
