import { Photo } from '@ppa/data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Modal } from '@ppa/layout';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalFormIsRequiredComponent } from '../../../../components/modal-form-is-required/modal-form-is-required.component';

@Component({
  selector: 'ppa-edit-photo-modal',
  templateUrl: './edit-photo-modal.component.html',
  styleUrls: ['./edit-photo-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPhotoModalComponent extends ModalFormIsRequiredComponent implements OnInit {
  @Input() photo: Photo = {
    id: -1,
    description: '',
  };

  form: FormGroup;

  get controls() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.createForm();
  }

  createForm() {
    return new FormGroup({
      date: new FormControl(this.photo.date ? this.photo.date : new Date(), [Validators.required]),
      description: new FormControl(this.photo.description),
    });
  }

  returnPhoto() {
    if (typeof this.form.value.date === 'object') {
      this.form.value.date = this.form.value.date.format();
    }
    this.save({ ...this.photo, ...this.form.value });
  }
}
