import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from './token';
import { ApiConfig, Config, ListResponse, LocalStorageKey, User } from '../contracts';
import { LocalStorageService } from './local-storage.service';
import { ConfigDataService } from './config-data.service';
import { UserDataService } from './user-data.service';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private path = 'config';

  public version = null;

  public harvest_year = null;
  public payment_terms = null;
  public commission = null;
  public commission_unit = null;

  public constructor(
    protected http: HttpClient,
    @Inject(API_CONFIG) public apiConfig: ApiConfig,
    private storageService: LocalStorageService,
    private configDataService: ConfigDataService,
    private userDataService: UserDataService,
  ) {}

  load(): Promise<any> {
    let action = 'list';
    if (!this.storageService.exists(LocalStorageKey.AccessToken)) {
      action = 'public';
    } else {
      this.configDataService
        .version()
        .toPromise()
        .then((version) => {
          version = version.trim().toLowerCase();
          this.storageService.setItem(LocalStorageKey.AppVersion, version);
          this.version = version;
        });

      if (!this.storageService.exists(LocalStorageKey.Mediator) || this.isNewUser()) {
        const userId = this.getUserId();
        this.userDataService.read(userId).subscribe((user) => {
          this.storageService.setItem(LocalStorageKey.Mediator, user);
        });
      }
    }

    return this.http
      .get<ListResponse<Config>>(`${this.apiConfig.baseUrl}/${this.path}/${action}`)
      .toPromise()
      .then((configs) => {
        configs.map((config) => {
          this[config.key] = config.convertedValue;
        });

        return configs;
      });
  }

  getUserId() {
    const helper = new JwtHelperService();
    const { token } = this.storageService.getItem(LocalStorageKey.AccessToken);

    return helper.decodeToken(token).userId;
  }

  isNewUser() {
    const userId = this.getUserId();
    const mediator: User = this.storageService.getItem(LocalStorageKey.Mediator);

    return mediator && mediator.hasOwnProperty('id') && mediator.id !== userId;
  }
}
