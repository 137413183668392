import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Metadata } from '@ppa/data';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'ppa-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() metaData: Metadata;
  @Output() page = new EventEmitter<PageEvent>();

  constructor() {}

  ngOnInit(): void {}

  setPage(pageInfo: PageEvent) {
    this.page.emit(pageInfo);
  }
}
