import { NgModule } from '@angular/core';
import { WizardComponent } from './wizard.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

const COMPONENTS = [WizardComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, MatButtonModule],
  exports: [...COMPONENTS],
})
export class WizardModule {}
