import { Pipe, PipeTransform } from '@angular/core';
import { Card } from '@ppa/layout';

@Pipe({
  name: 'overviewCardGroup',
})
export class OverviewCardGroupPipe implements PipeTransform {
  transform<T>(value: { [p: string]: Card<T>[] }): { key: string; value: Card<T>[] }[] {
    return Object.keys(value).map((key) => {
      return { key, value: value[key] };
    });
  }
}
