import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';
import { KeyValuePairs, Relation } from '../contracts';
import { ListResponse } from '../contracts/list-response';
import { BaseService } from './base.service';
import { ReadResponse } from '../contracts/read-response';
import { RelationSummary } from '../contracts/relation-summary';
import { DownloadFileService } from './download-file.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RelationDataService extends BaseService<Relation> {
  path = 'relations';

  list(params?: { [key: string]: any }): Observable<ListResponse<Relation>> {
    return this.listPaginated(params ?? {}).pipe(
      pluck('data'),
      map((relations: Relation[]) => {
        return relations.map((relation) => {
          return Object.assign({}, relation, {
            addressLine: `${relation.street} ${relation.houseNumber}, ${relation.city}`,
          });
        });
      }),
    );
  }

  read(id: number): Observable<ReadResponse<Relation>> {
    return super.read(id).pipe(
      map((item: Relation) => {
        return {
          ...item,
          ...(item.commissionAmount && { commissionAmount: item.commissionAmount?.toString()?.replace('.', ',') }),
        };
      }),
    );
  }

  create(data: Relation): Observable<ReadResponse<Relation>> {
    return super.create(this.transformRelation(data));
  }

  update(data: Relation): Observable<ReadResponse<Relation>> {
    return super.update(this.transformRelation(data));
  }

  exportNewsletter(): Observable<HttpResponse<Blob>> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/exportNewsletter`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  private transformRelation(data: Relation) {
    data.commissionAmount = data.commissionAmount?.toString()?.replace(',', '.');

    /* Prevent debtorId to be send to backend */
    delete data.afasDebtorId;

    return data;
  }

  getSummary(id: number): Observable<RelationSummary> {
    const summaryEndpoint = 'summary';
    return this.http
      .get<ReadResponse<RelationSummary[]>>(`${this.apiConfig.baseUrl}/${this.path}/${id}/${summaryEndpoint}`)
      .pipe(map((summary) => summary.pop()));
  }

  afasRelationCheck(id: number) {
    return this.http.get<ReadResponse<{ afasRelation: boolean }>>(
      `${this.apiConfig.baseUrl}/${this.path}/${id}/afas_relation_check`,
    );
  }

  getPPACompanyOptions(ppaOnly = false): Observable<KeyValuePairs<string>> {
    const extra = ppaOnly ? 1 : 0;
    return this.http.get<KeyValuePairs<string>>(`${this.apiConfig.baseUrl}/${this.path}/ppa_company_options/${extra}`);
  }

  getOptions(): Observable<Relation[]> {
    return this.http.get<Relation[]>(`${this.apiConfig.baseUrl}/${this.path}/options`);
  }

  getLogo(relation: Relation): Observable<any> {
    return this.http.get<any>(`${this.apiConfig.baseUrl}/${this.path}/${relation.id}/logo`);
  }
}
