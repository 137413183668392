import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import {
  ContactMoment,
  Disease,
  DiseaseDataService,
  Dossier,
  DossierDataService,
  KeyValuePairs,
  Photo,
  Product,
  ProductDataService,
  ProductMatrixRow,
  Relation,
  RelationCertificate,
  DossierReminder,
  Unit,
  UserDataService,
  UserOption,
  PriceUnitIdentifier,
  Order,
  OrderDataService,
  Treatment,
  AuthService,
  Reminder,
  NavigateBackService,
} from '@ppa/data';
import {
  ConfirmDialogComponent,
  HeaderData,
  Intention,
  MenuService,
  ModalService,
  PPAToastrService,
  StatusLabel,
} from '@ppa/layout';
import { BehaviorSubject, combineLatest, ConnectableObservable, Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  pluck,
  publishReplay,
  share,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CertificateService } from '../../../../services/certificate.service';
import { ErrorHandlerService } from '@ppa/layout';
import { Address } from '../../../../services/location.service';
import { ScrollService } from '../../../../services/scroll-service';
import { UnitService } from '../../../../services/unit.service';
import { SelectLocationComponent } from '../../../location/modals/select-location/select-location.component';
import { AddPhotoModalComponent } from '../../../photo/modals/add-photo-modal/add-photo-modal.component';
import { RelationService } from '../../../relation/services/relation.service';
import { DossierService } from '../../services/dossier.service';
import { DossierTabbarPage } from '../../contracts/dossierTabbarPage';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from '../../../../../environments/environment';
import { FormIsRequiredComponent } from '../../../../components/form-is-required/form-is-required.component';
import { OrderSelectModalComponent } from '../../../order/modals/order-select-modal/order-select-modal.component';
import { ReminderService } from '../../../../services/reminder.service';

const NORMAL_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'ppa-dossier-reminder',
  templateUrl: './dossier-reminder.component.html',
  styleUrls: ['./dossier-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: NORMAL_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  ],
})
export class DossierReminderComponent extends FormIsRequiredComponent implements OnInit, OnDestroy {
  activeModules = environment.activeModules;

  destroy$ = new Subject();
  navigateBack$ = new Subject<void>();

  id = 0;
  dossierForm: FormGroup;
  status: StatusLabel;

  products$: Observable<Product[]>;
  mediators$: Observable<UserOption[]>;
  priceUnitOptions$: Observable<Unit[]>;

  displayTonSuffix$ = new BehaviorSubject<boolean>(true);

  productConditionalFields: Map<string, string | null> = new Map<string, string | null>();
  stageOptions: KeyValuePairs<string>;
  preferenceDeliveryOptions: KeyValuePairs<string>;
  monthOptions: KeyValuePairs<number>;
  colorGrades: number[];
  tareGradeOptions: KeyValuePairs<string>;
  gritGradeOptions: KeyValuePairs<string>;
  qualityOptions: KeyValuePairs<string>;
  industryQualityOptions: KeyValuePairs<string>;
  sproutInhibitorOptions: KeyValuePairs<string>;
  sellTypeOptions: KeyValuePairs<string>;
  storageBoxOptions: KeyValuePairs<string>;

  packagingOptions: KeyValuePairs<string>;
  amountUnitOptions$: Observable<Unit[]>;

  selectedStage$: Observable<string>;
  relationCertificates$: Observable<Partial<RelationCertificate>[]>;

  currentRelation$ = new BehaviorSubject<Relation>(null);

  subscriptions: Subscription[] = [];
  exporting$: Subject<boolean> = new Subject<boolean>();
  saving$: Subject<boolean> = new Subject<boolean>();

  _noRedirect = false;

  headerData: HeaderData = {
    left: {
      type: 'back',
      action: {
        icon: 'uil-arrow-left',
        actionClick$: this.navigateBack$,
        label: 'modules.dossier.buttons.cancel',
      },
    },
    right: [
      {
        icon: 'uil-check',
        color: 'primary',
        type: 'submit',
        label: 'modules.dossier.buttons.save',
      },
    ],
  };

  sizeSorting$: Observable<string[]>;

  treatments$: Observable<Treatment[]>;

  @ViewChild('form') form: NgForm;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dossierService: DossierService,
    private reminderService: ReminderService,
    private menuService: MenuService,
    private toastrService: PPAToastrService,
    private scrollService: ScrollService,
    private navigateBackService: NavigateBackService,
  ) {
    super();

    const { id } = this.activatedRoute.snapshot.params;
    this.id = id;

    // Make sure the menu is visible before anything is checked
    this.menuService.setMenuVisible(false);
    this.saving$.next(false);
  }

  get controls() {
    return this.dossierForm.controls;
  }

  ngOnInit(): void {
    this.dossierForm = this.formBuilder.group({
      description: [null, Validators.required],
      reminderDate: [null, Validators.required],
    });

    this.navigateBack$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      if (this.navigateBackService.hasBackRoute()) {
        this.router.navigate([this.navigateBackService.getBackRoute()]);
      } else if (this.navigateBackService.hasBackUrl()) {
        this.navigateBackService.navigateBack();
      } else {
        this.router.navigate(['/dossier']);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  handleSubmit() {
    if (!this.dossierForm.valid) {
      this.scrollService.scrollToFirstInvalid(this.dossierForm);
      return;
    }

    this.saving$.next(true);

    const reminder = {
      dossier: { id: this.id },
      ...this.dossierForm.value,
    };

    this.reminderService.create(reminder).subscribe(
      (savedReminder: Reminder) => {
        this.saving$.next(false);
        this.form.reset();
        this.router.navigate(['/dossier', this.id]);

        this.toastrService.displayToastr({
          icon: 'uil-check',
          title: translate('modules.dossier.toastr.reminder_added'),
          intention: Intention.Success,
        });
      },
      () => {
        this.saving$.next(false);
        this.toastrService.displayToastr({
          icon: 'uil-exclamation-triangle',
          title: translate('modules.dossier.toastr.reminder_error'),
          intention: Intention.Error,
          duration: 3000,
        });
      },
    );
  }

  isRequiredField(field: string, controls?: any): Observable<boolean> {
    try {
      if (!controls) {
        controls = this.controls;
      } else if (controls.controls && controls.controls.length > 0) {
        controls = controls.controls[0].controls;
      }
      const control = controls[field];
      if (control) {
        // @ts-ignore
        const validators = control.validator('');
        if (validators.required) {
          return of(true);
        }
      }
    } catch (e) {
      return of(false);
    }

    return of(false);
  }

  handleToggleNoRedirect($event: boolean) {
    this._noRedirect = $event;
  }
}
