<mat-card class="mat-card-flat">
  <mat-card-header>
    <mat-card-title *ngIf="title">{{ title }}</mat-card-title>
    <mat-card-subtitle *ngIf="subtitle">{{ subtitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ng-content> </ng-content>
  </mat-card-content>
  <mat-card-actions *ngIf="statusLabel">
    <ppa-status [label]="statusLabel"></ppa-status>
  </mat-card-actions>
  <mat-card-actions *ngIf="actionLabel">
    <ppa-status [manualLabel]="actionLabel" [class]="actionLabelClass"></ppa-status>
  </mat-card-actions>
</mat-card>
