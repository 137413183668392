<ng-container *transloco="let t">
  <ppa-spinner [status$]="saving$" message="global.saving" icon="save"></ppa-spinner>

  <div class="ppa-modal ppa-modal--no-bottom-padding" data-cy="modal-contract-edit">
    <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

    <div class="ppa-modal__heading">
      <h2>
        {{ 'modules.contract.modal.edit.title' | transloco: { relation: companyName } }}
      </h2>
    </div>

    <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
      <form #form="ngForm" [formGroup]="contractForm" (ngSubmit)="handleSubmit()" autocomplete="off" class="pb-1">
        <mat-form-field appearance="fill">
          <mat-label>
            {{ t('modules.contract.create.own_numbering_for_relation') }}
            <span class="required" *ngIf="isRequiredField('ownNumberingForRelation') | async">*</span>
          </mat-label>
          <input
            matInput
            formControlName="ownNumberingForRelation"
            data-cy="contract-form-own-numbering-for-relation"
          />
        </mat-form-field>

        <button mat-raised-button class="button--full-width" color="primary" data-cy="button-contract-save">
          {{ t('modules.contract.buttons.save_contract') }}
        </button>
      </form>
    </div>
  </div>

  <div class="ppa-modal__fader"></div>
</ng-container>
