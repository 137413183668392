<div class="container" *transloco="let t">
  <ppa-floating-button
    cyIdentifier="button-dossier-create"
    [title]="t('modules.dossier.buttons.create')"
    icon="uil-plus"
    (onClick)="createDossier()"
    [disabled]="!canCreateDossier"
  >
  </ppa-floating-button>

  <ppa-header [hasFilter]="true" [data]="headerData"></ppa-header>
</div>

<ppa-filter-form
  [module]="module"
  [export]="true"
  [filters]="filters"
  [sortingFields]="sortingFields"
  [relationQueryParam$]="relationState$"
  (filterValues)="handleFilterValues($event)"
></ppa-filter-form>

<ng-container *ngIf="filteredRelation$ | async as relation">
  <ppa-overview-filtered-on-relation
    [relation]="relation"
    (backClicked)="navigateBack()"
    (showAllClicked)="showAll()"
  ></ppa-overview-filtered-on-relation>
</ng-container>

<div class="ppa-card-list" *ngIf="dossierList$ | async as dossiers; else loading">
  <ng-container *ngIf="dossiers.length > 0; else noResult">
    <ng-container *ngFor="let dossier of dossiers">
      <ppa-card
        [routerLink]="['/dossier', dossier.id]"
        [title]="dossier.relation?.companyName + (dossier.title ? ' - ' + dossier.title : '')"
        [subtitle]="dossier.locationLine || dossier.relation?.addressLine"
        [statusLabel]="dossier.status"
        [attr.data-cy]="'dossier-overview-card-' + dossier.id"
      >
        <div class="container">
          <div class="column" data-cy="dossier-overview-card-product-label" *ngIf="dossier.product?.title">
            <span class="title">{{ 'modules.dossier.overview.card.product_label' | transloco }}</span>
            <span class="product">
              <ng-container *ngIf="!dossier.productVariety">
                {{ dossier.product?.title }}
              </ng-container>
              <ng-container *ngIf="dossier.productVariety">
                {{ dossier.product?.title }}, {{ dossier.productVariety.title }}
              </ng-container>
              <ppa-status
                class="ppa-status--newline"
                *ngIf="dossier.bakingFactory"
                [manualLabel]="'modules.dossier.detail.summary.baking_factory' | transloco"
              ></ppa-status>
            </span>
          </div>

          <div class="column" data-cy="dossier-overview-card-estimated-amount-label">
            <ng-container *ngIf="dossier.estimatedAmount || dossier.expectedAmount">
              <span class="title">{{ 'modules.dossier.overview.card.amount_label' | transloco }}</span>
              <span class="amount">
                <ng-container *ngIf="showAmountUnitSuffix(dossier); else noUnitDivisor">
                  <ng-container *ngIf="dossier.amountOfUnit">
                    {{ dossier.amountOfUnit }} {{ dossier.amountUnit?.title }} -
                  </ng-container>
                  {{ dossier.estimatedAmount ? dossier.estimatedAmount : dossier.expectedAmount }}
                  {{ 'general.ton' | transloco }}
                </ng-container>
                <ng-template #noUnitDivisor>
                  <ng-container *ngIf="dossier.amountOfUnit && dossier.amountUnit; else noUnit">
                    {{ dossier.amountOfUnit }} {{ dossier.amountUnit?.title }}
                  </ng-container>
                </ng-template>
                <ng-template #noUnit>
                  {{ dossier.estimatedAmount ? dossier.estimatedAmount : dossier.expectedAmount }}
                  {{ 'general.ton' | transloco }}
                </ng-template>
              </span>
            </ng-container>
          </div>

          <div class="column" data-cy="dossier-overview-card-available-amount-label">
            <ng-container *ngIf="dossier.availableAmount !== null">
              <span class="title">{{ 'modules.dossier.overview.card.available_amount_label' | transloco }}</span>
              <span class="amount">
                <ng-container *ngIf="dossier.availableAmount > 0; else noValue">
                  {{ dossier.availableAmount }} {{ 'general.ton' | transloco }} ({{
                    (dossier.availableAmount / (dossier.expectedAmount || dossier.estimatedAmount)) * 100
                      | ppaNumber: '1.0-2'
                  }}%)
                </ng-container>
              </span>
            </ng-container>
          </div>

          <div class="column" data-cy="dossier-overview-card-available-amount-label">
            <ng-container *ngIf="dossier.fulfilledAmount !== null">
              <span class="title">{{ 'modules.dossier.overview.card.fulfilled_amount_label' | transloco }}</span>
              <span class="amount">
                <ng-container *ngIf="dossier.fulfilledAmount > 0; else noValue">
                  {{ dossier.fulfilledAmount }} {{ 'general.ton' | transloco }} ({{
                    (dossier.fulfilledAmount / (dossier.expectedAmount || dossier.estimatedAmount)) * 100
                      | ppaNumber: '1.0-2'
                  }}%)
                </ng-container>
              </span>
            </ng-container>
          </div>

          <ng-template #noValue>-</ng-template>

          <div class="column" data-cy="dossier-overview-card-indication-price-label">
            <ng-container *ngIf="dossier.indicationPrice">
              <span class="title">{{ 'modules.dossier.overview.card.indication_price_label' | transloco }}</span>
              <span class="amount">
                € {{ dossier.indicationPrice }}
                <div *ngIf="dossier.indicationPriceUnit && dossier.indicationPriceUnit.title">
                  {{ dossier.indicationPriceUnit.prefix }} {{ dossier.indicationPriceUnit.title }}
                </div>
                <ppa-status
                  class="ppa-status--newline"
                  *ngIf="dossier.participation"
                  [manualLabel]="'modules.dossier.detail.summary.pp_icon' | transloco"
                  [title]="'modules.dossier.detail.summary.participation' | transloco"
                ></ppa-status>
              </span>
            </ng-container>
          </div>
          <div class="column" data-cy="dossier-overview-card-mediator-label">
            <ng-container *ngIf="dossier.mediator">
              <span class="title">{{ 'modules.dossier.overview.card.mediator_label' | transloco }}</span>
              <span class="amount">
                <a class="phone" (click)="$event.stopPropagation()" href="tel:{{ dossier.mediator.phoneNumber }}"
                  ><i class="uil uil-phone"></i> {{ dossier.mediator.firstname }} {{ dossier.mediator.lastname }}</a
                >
              </span>
            </ng-container>
          </div>
          <div class="column" data-cy="dossier-overview-card-last-visit-label">
            <ng-container *ngIf="dossier.lastContactMoment">
              <span class="title">{{ 'modules.dossier.overview.card.last_visit_label' | transloco }}</span>
              <span class="amount">
                {{ dossier.lastContactMoment | ppaDate: 'DD-MM-YYYY HH:mm' }}
              </span>
            </ng-container>
          </div>
          <ng-container *ngIf="dossier?.$distance | async as distance">
            <div *ngIf="distance !== ''" class="column distanceColumn" data-cy="dossier-overview-card-distance-label">
              <span class="distance">
                <i class="uil uil-map-marker"></i>
                {{ distance }}
              </span>
            </div>
          </ng-container>
          <div class="column harvestYearColumn" data-cy="dossier-overview-card-harvest-year-label">
            <ng-container *ngIf="dossier?.harvestYear">
              <span class="harvestYear">{{ dossier.harvestYear }}</span>
            </ng-container>
          </div>
        </div>
        <div class="card-dates">
          <div class="card-date card-date--created">
            <span class="card-date--label">{{ 'sorting.created_at' | transloco }}:&nbsp;</span>
            <span class="card-date--date">{{ dossier.createdAt | ppaDate: 'DD-MM-YYYY HH:mm' }}</span>
          </div>
          <div class="card-date card-date--updated">
            <span class="card-date--label">{{ 'sorting.updated_at' | transloco }}:&nbsp;</span>
            <span class="card-date--date">{{ dossier.updatedAt | ppaDate: 'DD-MM-YYYY HH:mm' }}</span>
          </div>
        </div>
      </ppa-card>
    </ng-container>

    <ppa-pagination [metaData]="metadata" (page)="setPage($event)"></ppa-pagination>
  </ng-container>

  <div class="spacer"></div>
</div>

<ng-template #loading>
  <ppa-loading></ppa-loading>
</ng-template>

<ng-template #noResult>
  <ppa-no-result></ppa-no-result>
</ng-template>
