import { Component, Input, OnInit } from '@angular/core';
import { PoolTotals } from '../../contracts';

@Component({
  selector: 'ppa-pool-totals',
  templateUrl: './pool-totals.component.html',
  styleUrls: ['./pool-totals.component.scss']
})
export class PoolTotalsComponent implements OnInit {

  @Input() totalsData: PoolTotals;

  constructor() { }

  ngOnInit(): void {
  }

}
