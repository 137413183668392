import { NgModule } from '@angular/core';
import { PPAToastrComponent } from './toastr.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

const COMPONENTS = [PPAToastrComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, MatButtonModule],
  exports: [...COMPONENTS],
  providers: [],
})
export class PPAToastrModule {}
