import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ApiConfig } from '../contracts/api-config';
import { API_CONFIG } from '../services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, @Inject(API_CONFIG) public apiConfig: ApiConfig) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only fetch the auth token on requests directed to the PPA API.
    if (request.url.includes(this.apiConfig.baseUrl)) {
      const tokenResponse = this.authService.getToken();

      if (tokenResponse && !request.url.includes('/auth/login')) {
        const { token } = tokenResponse;
        // add authorization header with jwt token if available
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }

    return next.handle(request).pipe(
      tap(
        (_) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }

            this.authService.logout();
            this.authService.redirectOnFailedLogin();
          }
        },
      ),
    );
  }
}
