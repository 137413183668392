import { ConfirmDialogComponent, ModalService } from '@ppa/layout';
import { Component, HostBinding, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VersionService } from '@ppa/data';
import { throttleTime } from 'rxjs/operators';
import { translate } from '@ngneat/transloco';
import {LocationService} from "./services/location.service";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'ppa-root',
  template: `
    <router-outlet></router-outlet>
    <div #dynamic></div>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class.mobile') isMobile = false;
  @HostBinding('class.tablet') isTablet = false;
  @HostBinding('class.desktop') isDesktop = false;

  @ViewChild('dynamic', { read: ViewContainerRef, static: true })
  modalContainer: ViewContainerRef;
  confirmReloadModal;
  public coordinates$ = new BehaviorSubject<any>(null);

  constructor(
    protected modalService: ModalService,
    private deviceDetectorService: DeviceDetectorService,
    private versionService: VersionService,
    private locationService: LocationService
  ) {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.isDesktop = this.deviceDetectorService.isDesktop();
    this.isTablet = this.deviceDetectorService.isTablet();
    this.locationService.setCurrentLocation();
    this.coordinates$ = this.locationService.getCurrentLocationObserver();
  }

  ngOnInit() {
    this.modalService.setModalContainerRef(this.modalContainer);
    this.versionService.upToDate$.pipe(throttleTime(5 * 60 * 1000)).subscribe((upToDate) => {
      if (!upToDate && !this.confirmReloadModal) {
        this.confirmReloadModal = this.modalService.createModal(ConfirmDialogComponent, {
          message: this.translation('global.version.title', 'PPAUI heeft een update gehad!'),
          information: this.translation(
            'global.version.message',
            'Om gebruik volledig gebruik te kunnen maken van alle mogelijkheden, ' +
              'dient de app ververst te worden! Wilt u doorgaan met verversen?<br />' +
              '<br /><strong>LET OP! Niet opgeslagen gegevens gaan verloren!!</strong>',
          ),
          confirmButtonText: this.translation('global.version.confirm', 'Opnieuw laden'),
          buttonColor: 'primary',
          cancelButtonColor: 'warn',
        });

        this.confirmReloadModal.dialog.onSave().then(() => {
          window.location.reload();
        });

        this.confirmReloadModal.dialog.onClose().then(() => {
          delete this.confirmReloadModal;
        });
      }
    });
    this.versionService.startCheckingVersion();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.locationService.setCurrentLocation();
    this.versionService.startCheckingVersion();
  }

  translation(key: string, fallback: string): string {
    const translation = translate(key);

    if (fallback && translation === key) {
      return fallback;
    }

    return translation;
  }
}
