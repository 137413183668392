<div class="mail-header" [formGroup]="formGroup">
  <div class="mail-header--field">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'mails.to' | transloco }}</mat-label>
      <mat-chip-list #toList multiple formControlName="to">
        <mat-chip
          *ngFor="let email of toEmails"
          [value]="email"
          (removed)="removeEmail(toEmails, email)"
          [selected]="true"
        >
          {{ email }}

          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <input
          type="email"
          placeholder="..."
          (keyup)="keydownValidate($event)"
          (focusout)="addEmail(toEmails, $event)"
          [matChipInputFor]="toList"
          (matChipInputTokenEnd)="addEmail(toEmails, $event)"
        />
      </mat-chip-list>
    </mat-form-field>
    <div
      class="circle-button circle-button__margin circle-button__relative circle-button--disabled"
      (click)="searchContacts(toEmails)"
    >
      <i [class]="'uil uil-plus'"></i>
    </div>
  </div>

  <div *ngIf="formChecked && controls.to.invalid">
    <div class="form-error" *ngIf="controls.to.errors.required">
      <span>{{ 'form_errors.required' | transloco }}</span>
    </div>
  </div>

  <div *ngIf="!showCc" class="mail-header--button" (click)="showCc = true">{{ 'mails.cc' | transloco }}</div>
  <div *ngIf="showCc" class="mail-header--field">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'mails.cc' | transloco }}</mat-label>
      <mat-chip-list #ccList multiple formControlName="cc">
        <mat-chip *ngFor="let email of ccEmails" [value]="email" (removed)="removeEmail(ccEmails, email)">
          {{ email }}

          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <input
          type="email"
          placeholder="..."
          (keyup)="keydownValidate($event)"
          (focusout)="addEmail(ccEmails, $event)"
          [matChipInputFor]="ccList"
          (matChipInputTokenEnd)="addEmail(ccEmails, $event)"
        />
      </mat-chip-list>
    </mat-form-field>
    <div
      class="circle-button circle-button__margin circle-button__relative circle-button--disabled"
      (click)="searchContacts(ccEmails)"
    >
      <i [class]="'uil uil-plus'"></i>
    </div>
  </div>

  <div *ngIf="!showBcc" class="mail-header--button" (click)="showBcc = true">{{ 'mails.bcc' | transloco }}</div>
  <div *ngIf="showBcc" class="mail-header--field">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'mails.bcc' | transloco }}</mat-label>
      <mat-chip-list #bccList multiple formControlName="bcc">
        <mat-chip *ngFor="let email of bccEmails" [value]="email" (removed)="removeEmail(bccEmails, email)">
          {{ email }}

          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <input
          type="email"
          placeholder="..."
          (keyup)="keydownValidate($event)"
          (focusout)="addEmail(bccEmails, $event)"
          [matChipInputFor]="bccList"
          (matChipInputTokenEnd)="addEmail(bccEmails, $event)"
        />
      </mat-chip-list>
    </mat-form-field>
    <div
      class="circle-button circle-button__margin circle-button__relative circle-button--disabled"
      (click)="searchContacts(bccEmails)"
    >
      <i [class]="'uil uil-plus'"></i>
    </div>
  </div>
</div>
