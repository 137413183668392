import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@ppa/data';

@Directive({
  selector: '[ppaIsMediator]',
})
export class IsMediatorDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {}

  @Input() ppaIsMediator() {
    if (!this.authService.isMediator()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.authService.isMediator()) {
      this.viewContainer.clear();
    }
  }
}
