import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  Disease,
  DiseaseDataService,
  KeyValuePairs,
  Model,
  Unit,
  ProductDataService,
  PriceUnitIdentifier,
} from '@ppa/data';
import { AbstractWizardStepComponent } from '@ppa/layout';
import { UnitService } from '../../../../services/unit.service';
import { BehaviorSubject, combineLatest, ConnectableObservable, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, publishReplay, shareReplay, skip, take, takeUntil, tap } from 'rxjs/operators';
import { DossierService } from '../../services/dossier.service';

@Component({
  selector: 'ppa-dossier-wizard-specifications-two',
  templateUrl: './dossier-wizard-specifications-two.component.html',
  styleUrls: ['./dossier-wizard-specifications-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DossierWizardSpecificationsTwoComponent extends AbstractWizardStepComponent implements OnInit, OnDestroy {
  dossierFormSection: FormGroup;
  monthOptions: KeyValuePairs<number>;
  colorGrades: number[];
  storageBoxOptions: KeyValuePairs<string>;
  stageOptions: KeyValuePairs<string>;
  fungicideCoatingOptions: KeyValuePairs<boolean>;
  preferenceDeliveryOptions: KeyValuePairs<string>;
  packagingOptions: KeyValuePairs<string>;

  dossierProductMatrix$: Observable<Map<string, string | null>>;
  selectedStage$: Observable<string>;
  offMeadowDiseases$: Observable<Disease[]>;
  barnDiseases$: Observable<Disease[]>;

  cultivationSection$: Observable<boolean>;
  saleSection$: Observable<boolean>;
  seedsSection$: Observable<boolean>;
  stageSection$: Observable<boolean>;

  displaySurface$ = new BehaviorSubject<boolean>(false);
  displayExpectedAmount$ = new BehaviorSubject<boolean>(false);
  displayTonSuffix$ = new BehaviorSubject<boolean>(true);

  selectedStageSubscription: Subscription;

  amountUnitOptions$: Observable<Unit[]>;

  sizeSorting$: Observable<string[]>;

  @ViewChild('form') form: NgForm;

  get controls() {
    return this.dossierFormSection.controls;
  }

  constructor(
    private dossierService: DossierService,
    private fb: FormBuilder,
    private productDataService: ProductDataService,
    private diseaseDataService: DiseaseDataService,
    private cdr: ChangeDetectorRef,
    private unitService: UnitService,
  ) {
    super();
    const dossierFormSectionFields = this.dossierService.clearValidators([Validators.required], {
      ...this.dossierService.dossierFormSectionFields['cultivation'],
      ...this.dossierService.dossierFormSectionFields['sale'],
      ...this.dossierService.dossierFormSectionFields['seeds'],
      ...this.dossierService.dossierFormSectionFields['stage'],
      amountOfUnit: [
        { value: null, disabled: false },
        [Validators.required, Validators.pattern(/^\d+(([.,])\d{1,2})?$/)],
      ],
      amountUnit: [{ value: null, disabled: false }, [Validators.required]],
    });

    this.dossierFormSection = this.fb.group(dossierFormSectionFields);
  }

  ngOnInit(): void {
    this.amountUnitOptions$ = this.unitService.getAmountUnits();
    this.monthOptions = this.dossierService.monthOptions;
    this.colorGrades = this.dossierService.colorGrades;
    this.storageBoxOptions = this.dossierService.storageBoxOptions;
    this.stageOptions = this.dossierService.stageOptions;
    this.fungicideCoatingOptions = this.dossierService.fungicideCoatingOptions;
    this.preferenceDeliveryOptions = this.dossierService.preferenceDeliveryOptions;
    this.packagingOptions = this.dossierService.packagingOptions;

    this.dossierProductMatrix$ = this.dossierService.dossierProductMatrix.pipe(shareReplay(1));

    this.offMeadowDiseases$ = this.diseaseDataService.listOffMeadowDiseases();
    this.barnDiseases$ = this.diseaseDataService.listBarnDiseases();

    this.selectedStage$ = this.controls.stage.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(this.onStageChange.bind(this)),
      publishReplay(1),
    );

    this.selectedStageSubscription = (this.selectedStage$ as ConnectableObservable<string>).connect();

    this.dossierFormSection.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleFormChanges.bind(this));
    this.onNext$.pipe(takeUntil(this.destroy$)).subscribe(this.handleSubmit.bind(this));
    this.onPrevious$.pipe(takeUntil(this.destroy$)).subscribe(this.handleReset.bind(this));

    this.cultivationSection$ = this.dossierService.getSectionVisibility('cultivation');
    this.saleSection$ = this.dossierService.getSectionVisibility('sale');
    this.seedsSection$ = this.dossierService.getSectionVisibility('seeds');
    this.stageSection$ = this.dossierService.getSectionVisibility('stage');

    this.dossierService
      .getDossierWizardState()
      .pipe(take(1))
      .subscribe((dossierWizardState) => {
        this.dossierService.setProductMatrix(this.dossierFormSection, dossierWizardState.product);
        this.sizeSorting$ = this.dossierService.setSizeSortingOptions(dossierWizardState.product.defaultValues);
        this.dossierFormSection.patchValue(dossierWizardState);
        this.checkBreedingSellingFields(dossierWizardState.breedingSelling);
        this.checkSectionVisibility();
        this.dirty$.next(this.dossierFormSection.invalid || this.dossierFormSection.touched);
        this.stageOptions = this.dossierService.stageOptionsForProduct(
          dossierWizardState.product,
          this.dossierFormSection.get('stage'),
        );
      });

    // verplichte validatie van amountUnit wanneer amountOfUnit een waarde heeft
    this.dossierFormSection.get('amountOfUnit').valueChanges.subscribe((value) => {
      const amountUnitField = this.controls.amountUnit;
      amountUnitField.setValidators(value ? [Validators.required] : []);
      amountUnitField.updateValueAndValidity({ emitEvent: false });
    });

    this.setupUnitFields();
    this.setupSurfaceField();
  }

  handleSubmit() {
    this.dossierFormSection.markAllAsTouched();
    this.cdr.detectChanges();
    if (this.dossierFormSection.valid) {
      this.dossierService.setDossierWizardState(this.dossierFormSection.value);
      this.blockingNext$.next(false);
    } else {
      this.blockingNext$.next(true);
    }
  }

  handleReset() {
    this.dossierService.resetDossierProductMatrix();
  }

  handleFormChanges() {
    this.dossierService.setDossierWizardState({ ...this.dossierFormSection.value });

    if (this.dossierFormSection.touched) {
      this.dirty$.next(true);
    }
  }

  handleUnload() {
    super.handleUnload();

    this.dossierService.clearDossierWizardState();
  }

  onStageChange(stage) {
    this.dossierProductMatrix$.pipe(take(1)).subscribe((matrix) => {
      matrix.forEach((propertyStage, property) => {
        if (!propertyStage) return;

        const field = this.dossierFormSection.get(property);
        if (propertyStage.indexOf(stage) > -1) {
          field.enable();
        } else {
          field.updateValueAndValidity();
          field.disable();
        }
      });
      this.checkSectionVisibility();
    });
    this.controls.stage.markAsTouched();
  }

  idCompare(o1: Model, o2: Model) {
    return o1?.id === o2?.id;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.selectedStageSubscription) {
      this.selectedStageSubscription.unsubscribe();
    }
  }

  private checkStageVisibility() {
    console.log(this.dossierFormSection.get('product'));
  }

  private checkBreedingSellingFields(value: string) {
    if (value === undefined) {
      return;
    } else if (value === 'breeding') {
      this.controls.surfaceCultivation.enable();
      this.controls.expectedAmountSale.disable();
      this.controls.sizeSorting.disable();
    } else {
      this.controls.surfaceCultivation.disable();
      this.controls.expectedAmountSale.enable();
      this.controls.sizeSorting.enable();
    }

    this.displaySurface$.next(value === 'breeding');
    this.displayExpectedAmount$.next(value !== 'breeding');

    this.checkSectionVisibility();
  }

  private checkSectionVisibility() {
    this.dossierService.checkSectionVisibility(this.dossierFormSection, [
      'expectedHarvestExactDate',
      'cultivationNote',
      'saleNote',
      'seedsNote',
      'stageNote',
    ]);
  }

  private setupUnitFields() {
    // when this is an edit form skip the first emits from the amountOfUnit and amountUnit
    combineLatest([
      this.dossierFormSection.get('amountOfUnit').valueChanges.pipe(filter(Boolean), debounceTime(1000)) as Observable<
        string
      >,
      this.dossierFormSection.get('amountUnit').valueChanges.pipe(filter(Boolean)) as Observable<Unit>,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([amount, unit]) => {
        if (!amount || !unit) {
          return;
        }
        const amountFields = [
          this.dossierFormSection.get('expectedAmountSale'),
          this.dossierFormSection.get('expectedAmountSeed'),
          this.dossierFormSection.get('expectedAmount'),
          this.dossierFormSection.get('estimatedAmount'),
        ];
        // only one of these fields is enabled
        const enabledField = amountFields.find((f) => f.enabled);
        if (enabledField && (enabledField.value == null || enabledField.value === '')) {
          if (unit.identifier === PriceUnitIdentifier.Price_unit_per_stuk) {
            enabledField.setValue(amount);
            this.displayTonSuffix$.next(false);
          } else if (unit.divisor === null) {
            enabledField.setValue(null);
            this.displayTonSuffix$.next(true);
          } else {
            const convertedAmount = parseFloat(amount.toString().replace(',', '.'));
            enabledField.setValue(((convertedAmount * unit.divisor) / 1000).toFixed(2));
            this.displayTonSuffix$.next(true);
          }
        }
      });
  }

  public calculateAmount(forceAmount: boolean = false) {
    const amountFields = [
      this.dossierFormSection.get('expectedAmountSale'),
      this.dossierFormSection.get('expectedAmountSeed'),
      this.dossierFormSection.get('expectedAmount'),
      this.dossierFormSection.get('estimatedAmount'),
    ];
    const enabledField = amountFields.find((f) => f.enabled);
    if (enabledField && (enabledField.value == null || enabledField.value === '' || forceAmount)) {
      if (forceAmount) {
        enabledField.patchValue('');
      }
      this.dossierFormSection.get('amountOfUnit').updateValueAndValidity();
    }
  }

  private setupSurfaceField() {
    this.controls.surface.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe((value) => {
      if (value !== null && value !== '') {
        if (typeof this.controls.amountOfUnit !== undefined) {
          const oldValue = this.controls.amountOfUnit.value;
          const oldUnit = this.controls.amountUnit.value;
          if (
            ((oldValue === '' || oldValue == null) && (oldUnit === '' || oldUnit == null)) ||
            oldUnit?.identifier === PriceUnitIdentifier.Price_unit_per_1_ha
          ) {
            this.controls.amountOfUnit.reset();
            this.controls.amountOfUnit.patchValue(value);

            this.amountUnitOptions$.subscribe((units) => {
              const unit = units.find(
                (amountUnit) => amountUnit.identifier === PriceUnitIdentifier.Price_unit_per_1_ha,
              );
              this.controls.amountUnit.patchValue(unit);
            });
          }
        }
      }
    });
  }
}
