import { Order } from './order';
import { Model } from './model';

export interface PoolFinalStatementDocument extends Model {
  path: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface PoolFinalStatementRow extends Model {
  description: string;
  deliveryWeek?: any;
  amount: number;
  price: number;
  vatCodePercentage: number;
  vatTotal: number;
  netTotal: number;
  type?: PoolFinalStatementRowType;
  createdAt: string;
  updatedAt: string;

  groupIndex?: number;
}

export enum PoolFinalStatementRowType {
  Manual = 'manual',
  DeliveryReport = 'delivery_report',
  WeighingCost = 'weighing_cost',
}

export interface PoolFinalStatement extends Model {
  poolFinalStatementDocuments: PoolFinalStatementDocument[];
  poolFinalStatementRows: PoolFinalStatementRow[];
  companyName: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  vatNumber: string;
  afasDebtorId: string;
  statementReferenceNumber: string;
  date: string;
  contractReferenceNumber: string;
  product: string;
  productVariety: string;
  certification: string;
  color?: any;
  comment?: any;
  poolFinalStatementSentAt?: any;
  order: Order;
  iban: string;
  createdAt: string;
  updatedAt: string;
}
