import { Component, Input } from '@angular/core';
import { StatusLabel } from '../../contracts';

@Component({
  selector: 'ppa-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() statusLabel: StatusLabel = null;
  @Input() actionLabel = '';
  @Input() actionLabelClass = '';
}
