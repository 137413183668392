import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { TreatmentTypes, TreatmentType, Treatment } from '../contracts/';

@Injectable({
  providedIn: 'root',
})
export class TreatmentDataService extends BaseService<Treatment> {
  path = 'treatment';

  listDefaultTreatments(): Observable<Treatment[]> {
    return this.listTreatments(TreatmentTypes.Default);
  }

  listChicorySeedTreatments(): Observable<Treatment[]> {
    return this.listTreatments(TreatmentTypes.ChicorySeed);
  }

  private listTreatments(type: string): Observable<Treatment[]> {
    return this.http.get(`${this.apiConfig.baseUrl}/${this.path}/type/${type}`) as Observable<Treatment[]>;
  }

  listTreatmentsAll(): Observable<Treatment[]> {
    return this.http.get(`${this.apiConfig.baseUrl}/${this.path}/options`) as Observable<Treatment[]>;
  }

  listTreatmentTypes(): Observable<TreatmentType[]> {
    const list = [
      {
        id: 1,
        value: TreatmentTypes.Default,
        label: translate('modules.treatment.options.type.' + TreatmentTypes.Default),
      },
      {
        id: 2,
        value: TreatmentTypes.ChicorySeed,
        label: translate('modules.treatment.options.type.' + TreatmentTypes.ChicorySeed),
      },
    ];
    return of(list);
  }
}
