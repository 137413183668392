import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { SelectModule } from '../select';
import { FilterFormComponent } from './filter-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SpinnerModule } from '../spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';

const COMPONENTS = [FilterFormComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
    TranslocoModule,
    SelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    SpinnerModule,
  ],
  exports: [...COMPONENTS],
})
export class FilterFormModule {}
