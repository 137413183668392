export const environment = {
  env: 'acc',
  production: true,
  dataLib: {
    baseUrl: 'https://api.acc.ppaui.nl',
    serverSentEventUrl: 'https://46a5d456-dcff-4c9b-8c79-a9d62e4b3861.mercure.rocks/.well-known/mercure',
  },
  mapBoxToken: 'pk.eyJ1Ijoid2VibmwiLCJhIjoiY2wzc282dG80MHkyMTNycW9kdWw1Y2J3ZyJ9.H-Cgtx5iSBm3IrtKYTcmQg',
  sentryDsn: 'https://2a8309d711ab477da1543c9d580e18ed@o445840.ingest.sentry.io/6459075',
  activeModules: {
    dossier: true,
    order: true,
    relation: true,
    contract: true,
    delivery: true,
    invoice: true,
    search: true,
  },
  search: {
    location: {
      relation: true,
    },
  },
};
