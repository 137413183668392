import { Injectable } from '@angular/core';
import { Country, CountryDataService } from '@ppa/data';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private countryDataService: CountryDataService) {}

  getCountries(): Observable<Country[]> {
    return this.countryDataService.getCountries().pipe(
      map((countries) => {
        return countries.sort((country) => (country.shortName === 'NL' ? -1 : 0));
      }),
    );
  }

  getCountryByShortName(shortName: string): Observable<Country> {
    return this.countryDataService
      .getCountries()
      .pipe(map((countries) => countries.filter((country) => country.shortName === shortName).pop()));
  }
}
