import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceOverviewCardComponent } from './invoice-overview-card.component';
import { MatButtonModule } from '@angular/material/button';
import { LoadingModule } from '../loading';
import { RelationStatusModule } from "../relation-status";

@NgModule({
  declarations: [InvoiceOverviewCardComponent],
  imports: [CommonModule, MatButtonModule, LoadingModule, RelationStatusModule],
  exports: [InvoiceOverviewCardComponent],
})
export class InvoiceOverviewCardModule {}
