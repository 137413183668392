import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {
  ErrorHandlerService,
  Modal,
  ModalService,
  ConfirmDialogComponent,
  Intention,
  PPAToastrService,
} from '@ppa/layout';
import { translate } from '@ngneat/transloco';
import { CancelContract, Contract, KeyValuePairs, Order } from '@ppa/data';
import { ContractService } from '../../services/contract.service';
import { BehaviorSubject } from 'rxjs';
import { ScrollService } from '../../../../services/scroll-service';

@Component({
  selector: 'ppa-contract-cancel-modal',
  templateUrl: './contract-cancel-modal.component.html',
})
export class ContractCancelModalComponent extends Modal implements OnInit {
  cancelContractForm: FormGroup;
  order: Order;
  matchedOrder: Order;
  contract: Contract;
  cancelReasonOptions: KeyValuePairs<string>;
  enableReasonOther$ = new BehaviorSubject<boolean>(false);

  get controls() {
    return this.cancelContractForm.controls;
  }

  constructor(
    _modalService: ModalService,
    private fb: FormBuilder,
    private contractService: ContractService,
    private toastrService: PPAToastrService,
    private errorHandler: ErrorHandlerService,
    private scrollService: ScrollService,
  ) {
    super(_modalService);

    this.cancelContractForm = this.fb.group({
      cancel_reason: [null, [Validators.required]],
      cancel_reason_other: [null, [Validators.maxLength(65535)]],
    });
  }

  ngOnInit() {
    this.cancelReasonOptions = this.contractService.getCancelReasonOptions;

    this.cancelContractForm.get('cancel_reason')?.valueChanges.subscribe((val) => {
      this.enableReasonOther$.next(val === CancelContract.Other);

      this.cancelContractForm
        .get('cancel_reason_other')
        .setValidators(val === CancelContract.Other ? [Validators.required] : []);
      this.cancelContractForm.get('cancel_reason_other').updateValueAndValidity({ emitEvent: false });
    });
  }

  send() {
    if (this.cancelContractForm.valid) {
      this.modalService
        .createModal(ConfirmDialogComponent, {
          message: translate('modules.contract.cancel.modal.confirm'),
          confirmButtonText: translate('modules.contract.send.modal.ok'),
          buttonColor: 'warn',
        })
        .dialog.onSave()
        .then(() => {
          this.contractService.cancelContract(this.contract, this.cancelContractForm.value).subscribe(
            () => {
              this.toastrService.displayToastr({
                intention: Intention.Success,
                title: translate('modules.contract.cancel.modal.confirmation'),
                icon: 'uil-check',
                duration: 3000,
              });
              this.save(true);
            },
            (error) => {
              this.errorHandler.handleAPIError(translate(''), error);
            },
          );
        });
    } else {
      this.scrollService.scrollToFirstInvalid(this.cancelContractForm);
    }
  }
}
