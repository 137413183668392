<div class="totals" *ngIf="totalsData">
  <h3>{{ 'modules.pool.pool_totals.title' | transloco }}</h3>
  <div class="totals__row">
    <div class="flex-1-6 totals__row__column">
      <strong>{{ 'modules.pool.pool_totals.sold' | transloco }}</strong>
    </div>
    <div class="flex-1-6 totals__row__column">
      {{ totalsData.amountRequested | ppaNumber: '1.0' }} kg
    </div>
    <div class="flex-1-6 totals__row__column">
      <strong>{{ 'modules.pool.pool_totals.delivered' | transloco }}</strong>
    </div>
    <div class="flex-1-6 totals__row__column">
      {{ totalsData.amountDelivered | ppaNumber: '1.0' }} kg
    </div>
    <div class="flex-1-6 totals__row__column">
      <strong>{{ 'modules.pool.pool_totals.pool_amount' | transloco }}</strong>
    </div>
    <div class="flex-1-6 totals__row__column">
      {{ totalsData.amount | ppaNumber: '1.0' }} kg
    </div>
  </div>
  <div class="totals__row">
    <div class="flex-1-6 totals__row__column">
      <strong>{{ 'modules.pool.pool_totals.to_sell' | transloco }}</strong>
    </div>
    <div class="flex-1-6 totals__row__column">
      {{ totalsData.amount - totalsData.amountRequested | ppaNumber: '1.0' }} kg
    </div>
    <div class="flex-1-6 totals__row__column">
      <strong>{{ 'modules.pool.pool_totals.to_deliver' | transloco }}</strong>
    </div>
    <div class="flex-1-6 totals__row__column">
      {{ totalsData.amountRequested - totalsData.amountDelivered | ppaNumber: '1.0'   }} kg
    </div>
    <div class="flex-1-6 totals__row__column">
      <strong>{{ 'modules.pool.pool_totals.price' | transloco }}</strong>
    </div>
    <div class="flex-1-6 totals__row__column">
      {{ totalsData.price | ppaCurrency: 'EUR' }}
    </div>
  </div>
</div>
