import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoolComponent } from "./components/pool/pool.component";
import { PoolOverviewComponent } from "./components/pool-overview/pool-overview.component";
import { PoolModalComponent } from "./modals/pool-modal/pool-modal.component";
import { PoolSendModalComponent } from "./modals/pool-send-modal/pool-send-modal.component";
import { RouterModule } from "@angular/router";
import {
  ActionCardModule, DataTableModule,
  HeaderModule,
  InvoiceOverviewCardModule,
  LayoutModule,
  LoadingModule, MailHeaderModule, PoolOverviewCardModule, RelationStatusModule,
  SelectModule,
  SpinnerModule, StatusModule
} from "@ppa/layout";
import {TranslocoModule} from "@ngneat/transloco";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
  declarations: [
    PoolComponent,
    PoolModalComponent,
    PoolOverviewComponent,
    PoolSendModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    InvoiceOverviewCardModule,
    HeaderModule,
    TranslocoModule,
    LoadingModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    LayoutModule,
    MatCheckboxModule,
    MatButtonModule,
    LoadingModule,
    SelectModule,
    SpinnerModule,
    MailHeaderModule,
    RelationStatusModule,
    ActionCardModule,
    StatusModule,
    DataTableModule,
    MatInputModule,
    MatInputModule,
    PoolOverviewCardModule,
  ],
})
export class PoolModule {}
