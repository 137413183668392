import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Renderer2,
  RendererFactory2,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { WizardStep } from '../contracts';
import { AbstractWizardStepComponent } from '../classes';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  private wizardStepContainer: ViewContainerRef;
  private wizardSteps: WizardStep[];

  private activeWizardStep$: BehaviorSubject<WizardStep> = new BehaviorSubject<WizardStep>(null);
  private reachedLastWizardStep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private last: number;
  private current: number;
  private pristine = true;

  private next$ = new Subject<void>();
  private previous$ = new Subject<void>();
  private finish$ = new Subject<void>();
  private save$ = new Subject<void>();

  private requestPreviousSubscription: Subscription;
  private requestNextSubscription: Subscription;
  private requestSaveSubscription: Subscription;

  private renderer: Renderer2;

  constructor(private resolver: ComponentFactoryResolver, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  cleanup() {
    this.requestNextSubscription?.unsubscribe();
    this.activeWizardStep$.value.componentInstance.handleUnload();
    this.reachedLastWizardStep$.next(false);
  }

  getActiveWizardStep(): Observable<WizardStep> {
    return this.activeWizardStep$.asObservable();
  }

  hasReachedLastWizardStep(): Observable<boolean> {
    return this.reachedLastWizardStep$.asObservable();
  }

  shouldSave(): Observable<void> {
    return this.save$.asObservable();
  }

  initialize(wizardStepContainer: ViewContainerRef, wizardSteps: WizardStep[], startAt: number = 0) {
    this.wizardStepContainer = wizardStepContainer;
    this.wizardSteps = wizardSteps;

    this.last = this.wizardSteps.length - 1;
    this.current = startAt;

    this.loadStep(this.current);
  }

  next(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.current === this.last) {
        reject('Trying to next the last step');
        return;
      }

      this.next$.next();

      const wizardStep = this.wizardSteps[this.current];
      wizardStep.isBlockingNext$.pipe(take(1)).subscribe((blocking) => {
        if (blocking) {
          reject('Next operation is being blocked from within the wizard step component');
          return;
        }

        this.current++;
        if (this.current === this.last) {
          this.reachedLastWizardStep$.next(true);
        }

        this.loadStep(this.current);
        this.pristine = false;

        resolve();
      });
    });
  }

  previous(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.current === 0) {
        reject('Already at first step');
        return;
      }

      this.previous$.next();

      const wizardStep = this.wizardSteps[this.current];
      wizardStep.isBlockingPrev$.pipe(take(1)).subscribe((blocking) => {
        if (blocking) {
          reject('Previous operation is being blocked from within the wizard step component');
          return;
        }

        this.current--;
        if (this.current !== this.last) {
          this.reachedLastWizardStep$.next(false);
        }

        this.loadStep(this.current);
        this.pristine = false;

        resolve();
      });
    });
  }

  finish() {
    return new Promise((resolve, reject) => {
      this.finish$.next();
      const wizardStep = this.wizardSteps[this.current];
      wizardStep.isBlockingFinish$.pipe(take(1)).subscribe((dirty) => {
        if (dirty) {
          reject('Finish operation is being blocked from within the wizard step component');
          return;
        }

        resolve();
      });
    });
  }

  unload(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.pristine) {
        resolve(true);
        return;
      }

      resolve(false);
    });
  }

  markAsPristine() {
    this.pristine = true;
  }

  private loadStep(index: number) {
    const wizardStep = this.wizardSteps[index];
    this.loadComponent(wizardStep);
    this.activeWizardStep$.next(wizardStep);
  }

  private loadComponent(wizardStep: WizardStep) {
    if (!wizardStep) return;

    const factory = this.resolver.resolveComponentFactory(wizardStep.component);

    /* clear contents of the wizard step container */
    this.wizardStepContainer.clear();

    /* create the component inside the wizardStepContainer */
    const componentRef: ComponentRef<AbstractWizardStepComponent> = this.wizardStepContainer.createComponent(factory);
    const instance = componentRef.instance;

    /* Set data-cy attribute when cyIdentifier is provided in wizard step */
    if (wizardStep.cyIdentifier) {
      this.renderer.setAttribute(componentRef.location.nativeElement, 'data-cy', wizardStep.cyIdentifier);
    }

    /* bind to next, prev and dirty observables in the wizard step */
    wizardStep.isBlockingPrev$ = instance.isBlockingPrev();
    wizardStep.isBlockingNext$ = instance.isBlockingNext();
    wizardStep.isBlockingFinish$ = instance.isBlockingFinish();
    wizardStep.isDirty$ = instance.isDirty();

    /* pass observables to listen to next, prev and finish actions from the wrapper component */
    instance.onPrevious$ = this.previous$.asObservable();
    instance.onNext$ = this.next$.asObservable();
    instance.onFinish$ = this.finish$.asObservable();

    /* listen to the complete observable directly */
    this.requestPreviousSubscription?.unsubscribe();
    this.requestPreviousSubscription = instance.isRequestingPrevious().subscribe(() => {
      this.previous()
        .then(() => {})
        .catch(() => {});
    });

    /* listen to the complete observable directly */
    this.requestNextSubscription?.unsubscribe();
    this.requestNextSubscription = instance.isRequestingNext().subscribe(() => {
      this.next()
        .then(() => {})
        .catch(() => {});
    });

    /* listen to the complete observable directly */
    this.requestSaveSubscription?.unsubscribe();
    this.requestSaveSubscription = instance.isRequestingSave().subscribe(() => {
      this.save$.next();
    });

    /* Fill all component inputs with data */
    componentRef.hostView.detectChanges();

    /* Add reference to the created wizard step */
    wizardStep.componentInstance = componentRef.instance;

    return instance;
  }
}
