import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { User, UserDataService, UserOption } from '@ppa/data';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public getRelation(): number {
    return Number(this.getter('relation_id'));
  }

  public setRelation(val: number) {
    this.setter('relation_id', val);
  }

  public getMediator(): User {
    return this.getter('mediator');
  }

  public setMediator(val: User): void {
    this.setter('mediator', val);
  }

  public getFilters(module: string, key: string): any {
    return this.getter('filters_' + module, key);
  }

  public setFilters(module: string, key: string, val: any) {
    this.setter('filters_' + module, val, key);
  }

  private getter(property: string, key?: string): any {
    // controleren of item in localstorage zit
    let value = localStorage.getItem(property);
    if (value) {
      value = JSON.parse(value);
    }

    // als er een key/value is specifieke key eruit halen
    if (key && value && value.hasOwnProperty(key)) {
      return value[key];
    }

    return value;
  }

  private setter(property: string, val: any, key?: string) {
    // als er een key/value is object maken
    if (key) {
      const newFilter = this.getter(property) ?? {};
      newFilter[key] = val;

      val = newFilter;
    }

    // opslaan in localstorage
    localStorage.setItem(property, JSON.stringify(val));
  }
}
