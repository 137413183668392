import { ComponentRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Modal } from './modal';

export class PopoverDialog {
  private onClose$ = new Subject<void>();
  private onSave$ = new Subject<object | boolean>();

  onClose(): Promise<void> {
    return this.onClose$.toPromise();
  }

  onCloseObservable() {
    return this.onClose$.asObservable();
  }

  close(): void {
    this.onClose$.next();
    this.onClose$.complete();
  }

  onSaveObservable() {
    return this.onSave$.asObservable();
  }

  onSave(): Promise<any> {
    return this.onSave$.toPromise();
  }

  save(value: object | boolean): void {
    this.onSave$.next(value);
    this.onSave$.complete();
  }
}

export interface PopoverDialogRef {
  dialog: PopoverDialog;
  componentRef: ComponentRef<Modal>;
}
