import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ContractCreateModalComponent } from './modals/contract-create-modal/contract-create-modal.component';
import { ContractFormComponent } from './components/contract-form/contract-form.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  ContractOverviewCardModule,
  HeaderModule,
  ActionCardModule,
  SelectModule,
  LoadingModule,
  LayoutModule,
  DataTableModule,
  CertificateCheckboxModule,
  FilterBarModule,
  BreadcrumbsModule,
  FileUploadModule,
  SpinnerModule,
  DeliveryPeriodModule,
  MailHeaderModule,
  RelationStatusModule,
} from '@ppa/layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ContractOverviewComponent } from './components/contract-overview/contract-overview.component';
import { ContractComponent } from './components/contract/contract.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NgxWigModule } from 'ngx-wig';
import { ContractSendModalComponent } from './modals/contract-send-modal/contract-send-modal.component';
import { ContractEditModalComponent } from './modals/contract-edit-modal/contract-edit-modal.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { ContractTypeModalComponent } from './modals/contract-type-modal/contract-type-modal.component';
import { ContractCancelModalComponent } from './modals/contract-cancel-modal/contract-cancel-modal.component';
import { ContractHistoryModalComponent } from './modals/contract-history-modal/contract-history-modal.component';
import { ContractDuplicateModalComponent } from './modals/contract-duplicate-modal/contract-duplicate-modal.component';
import { ContractOwnReferenceModalComponent } from './modals/contract-own-reference-modal/contract-own-reference-modal.component';

@NgModule({
  declarations: [
    ContractOverviewComponent,
    ContractComponent,
    ContractCreateModalComponent,
    ContractFormComponent,
    ContractEditModalComponent,
    ContractSendModalComponent,
    ContractTypeModalComponent,
    ContractCancelModalComponent,
    ContractHistoryModalComponent,
    ContractDuplicateModalComponent,
    ContractOwnReferenceModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    SelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    ActionCardModule,
    ContractOverviewCardModule,
    HeaderModule,
    NgxWigModule.forChild(),
    LoadingModule,
    LayoutModule,
    DataTableModule,
    CertificateCheckboxModule,
    DeliveryPeriodModule,
    FilterBarModule,
    BreadcrumbsModule,
    MatGridListModule,
    SpinnerModule,
    FileUploadModule,
    MailHeaderModule,
    RelationStatusModule,
  ],
  exports: [ContractComponent],
  providers: [{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
})
export class ContractModule {}
