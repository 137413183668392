import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { DownloadFileService } from './download-file.service';
import { BaseService } from './base.service';
import { Model } from '../contracts/model';

@Injectable({
  providedIn: 'root'
})
export class LogDataService extends BaseService<Model>{
  path = 'logs';

  exportLog(): Observable<HttpResponse<Blob>> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/export`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

}
