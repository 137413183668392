import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, Form, FormGroup, NgControl } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ppa-dossier-reminder-old',
  templateUrl: './dossier-reminder.component.html',
  styleUrls: ['./dossier-reminder.component.scss'],
})
export class DossierReminderOldComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() count = 0;
  @Input() reminder?: FormGroup;
  @Input() parentForm;
  @Output() remove = new EventEmitter<number>();

  reminderForm: FormGroup;
  destroy$: Subject<void> = new Subject();

  disabled = false;
  onChanged: any = () => {};
  onTouched: any = () => {};

  get reminderDate() {
    return this.reminderForm.get('reminderDate');
  }

  get description() {
    return this.reminderForm.get('description');
  }

  get controls() {
    return this.reminderForm.controls;
  }

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      control.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.reminderForm = this.reminder;

    this.reminderForm?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((reminder) => {
      this.onChanged(reminder);
      this.onTouched();
    });
  }

  writeValue(obj: any): void {
    this.reminder = obj;
  }

  registerOnChange(fn: (_: boolean) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleRemoveReminder(): void {
    this.remove.emit(this.count);
  }

  isRequiredField(field: string, controls?: any): Observable<boolean> {
    try {
      if (!controls) {
        controls = this.controls;
      } else if (controls.controls && controls.controls.length > 0) {
        controls = controls.controls[0].controls;
      }
      const control = controls[field];
      if (control) {
        // @ts-ignore
        const validators = control.validator('');
        if (validators.required) {
          return of(true);
        }
      }
    } catch (e) {
      return of(false);
    }

    return of(false);
  }
}
