import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { PoolProvisionalStatement, PoolProvisionalStatementRow, PoolProvisionalStatementRowType } from '@ppa/data';
import { ConfirmDialogComponent, ErrorHandlerService, Intention, ModalService, PPAToastrService } from '@ppa/layout';
import { merge, Observable, of, Subject } from 'rxjs';
import { map, scan, tap } from 'rxjs/operators';
import { ModalFormIsRequiredComponent } from '../../../../components/modal-form-is-required/modal-form-is-required.component';
import { PoolService } from "../../services/pool.service";

@Component({
  selector: 'ppa-pool-modal',
  templateUrl: './pool-modal.component.html',
  styleUrls: ['./pool-modal.component.scss'],
})
export class PoolModalComponent extends ModalFormIsRequiredComponent implements OnInit, OnDestroy {
  @Input() poolProvisionalStatement: PoolProvisionalStatement;

  readonly poolForm: FormGroup;
  rows$: Observable<Partial<PoolProvisionalStatementRow>[]>;
  subtotals$: Observable<number[]>;
  vats$: Observable<number[]>;
  saving$: Subject<boolean> = new Subject<boolean>();

  readonly newRow$: Subject<Partial<PoolProvisionalStatementRow>[]> = new Subject<PoolProvisionalStatementRow[]>();
  readonly removeRow$: Subject<number> = new Subject<number>();
  readonly PoolProvisionalStatementRowType = PoolProvisionalStatementRowType;

  constructor(
    protected modalService: ModalService,
    private poolService: PoolService,
    private fb: FormBuilder,
    private errorHandler: ErrorHandlerService,
    private toastrService: PPAToastrService
  ) {
    super(modalService);
    this.poolForm = this.fb.group({
      rows: this.fb.array([]),
    });

    this.saving$.next(false);
  }

  get rows(): FormArray {
    return this.poolForm.get('rows') as FormArray;
  }

  get controls() {
    return this.poolForm.controls;
  }

  get controlsArray() {
    return this.rows.controls;
  }

  ngOnInit(): void {
    const addRows$ = this.addedRows$();
    const removedRows$ = this.removedRows$();

    this.rows$ = merge(addRows$, removedRows$).pipe(
      scan((acc: Partial<PoolProvisionalStatementRow>[], curr: Partial<PoolProvisionalStatementRow>[] | number) => {
        if (this.isRows(curr)) {
          return [...acc, ...curr];
        } else {
          acc.splice(curr as number, 1);
          return acc;
        }
      }),
      tap(() => {
        this.subtotals$ = this.calculateSubtotals();
        this.vats$ = this.calculateVats();
      }),
    ) as Observable<Partial<PoolProvisionalStatementRow>[]>;

    if (this.poolProvisionalStatement) {
      this.poolForm.patchValue(this.poolProvisionalStatement);
    }
  }

  ngOnDestroy(): void {
    this.newRow$.complete();
    this.removeRow$.complete();
  }

  private addedRows$() {
    return merge(this.poolService.getProvisionalStatementRows(this.poolProvisionalStatement.id), this.newRow$).pipe(
      tap((rows: PoolProvisionalStatementRow[]) => {
        rows.map((row) =>
          this.rows.push(this.createRowFormGroup(row)),
        );
      }),
    );
  }

  private removedRows$(): Observable<number> {
    return this.removeRow$.pipe(tap((indexToRemove) => this.rows.removeAt(indexToRemove)));
  }

  private isRows(subject: Partial<PoolProvisionalStatementRow>[] | number): subject is PoolProvisionalStatementRow[] {
    return Array.isArray(subject);
  }

  private createRowFormGroup(row: PoolProvisionalStatementRow): FormGroup {
    return this.fb.group({
      ...row,
      description: [row.description, Validators.required],
      amount: [row.amount, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      price: [row.price, [Validators.required, Validators.pattern(/^\d+(\.\d{1,5})?$/)]],
      vatCodePercentage: [row.vatCodePercentage, Validators.required],
      vatTotal: [row.vatTotal, [Validators.required, Validators.pattern(/^\d+(\.\d{1,5})?$/)]],
      netTotal: [row.netTotal, [Validators.required, Validators.pattern(/^\d+(\.\d{1,5})?$/)]],
    });
  }

  private calculateSubtotals(): Observable<number[]> {
    return merge(this.rows.valueChanges, of(this.rows.value)).pipe(
      map((rows) => {
        return rows.map((row: PoolProvisionalStatementRow) => this.calculateRowSubtotal(row));
      }),
    );
  }

  private calculateVats(): Observable<number[]> {
    return merge(this.rows.valueChanges, of(this.rows.value)).pipe(
      map((rows) => {
        return rows.map((row: PoolProvisionalStatementRow) => this.calculateRowVat(row));
      }),
    );
  }

  private calculateRowSubtotal(row: PoolProvisionalStatementRow): number {
    return row.price * row.amount;
  }

  private calculateRowVat(row: PoolProvisionalStatementRow): number {
    const vatPercentage = (row.vatCodePercentage ? (row.vatCodePercentage / 100) : 0);
    const netTotal = row.netTotal || this.calculateRowSubtotal(row);
    return netTotal * vatPercentage;
  }

  addRow(): void {
    const newRow = {
      description: '',
      deliveryReportReferenceNumber: '',
      deliveryWeek: '',
      amount: null,
      price: null,
      vatCodePercentage: null,
      vatTotal: null,
      netTotal: null,
      type: PoolProvisionalStatementRowType.Manual,
    };

    this.newRow$.next([newRow]);
  }

  removeRow(e: Event, index: number): void {
    e.preventDefault();

    this.modalService
      .createModal(ConfirmDialogComponent, {
        message: translate('modules.invoice.modal.edit.remove_invoice_row_confirmation'),
      })
      .dialog.onSave()
      .then(() => {
        this.removeRow$.next(index);
      });
  }

  close(): void {
    if (this.poolForm.touched) {
      this.modalService
        .createModal(ConfirmDialogComponent, {
          message: translate('navigation_guard.title'),
          cancelButtonText: translate('navigation_guard.buttons.cancel'),
          confirmButtonText: translate('navigation_guard.buttons.discard'),
        })
        .dialog.onSave()
        .then(() => {
          super.close();
        });
    } else {
      super.close();
    }
  }

  private transFormRows(rows: any) {
    return rows.map((invoiceRow) => ({ ...invoiceRow }));
  }

  handleSubmit(): void {
    if (!this.poolForm.valid) {
      return;
    }

    this.saving$.next(true);
    this.poolService.patchProvisionalStatementRows(this.poolProvisionalStatement.id, this.transFormRows(this.rows.value)).subscribe({
      next: () => {
        this.saving$.next(false);
        this.save(true);
        this.toastrService.displayToastr({
          icon: 'uil-check',
          title: translate('modules.pool.modal.edit.save_success'),
          intention: Intention.Success,
          duration: 3000,
          identifier: 'modules-pool-toastr-edit-invoice-success',
        });
      },
      error: (error) => {
        this.saving$.next(false);
        this.errorHandler.handleAPIError(translate('modules.pool.modal.edit.save_error'), error);
      },
    });
  }
}
