<ppa-spinner [status$]="saving$" message="global.saving" icon="save"></ppa-spinner>

<div class="ppa-modal ppa-modal--no-bottom-padding" *transloco="let t">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h1 class="subheading-enabled">{{ t('modules.pool.modal.edit.heading') }}</h1>
    <h4 class="subheading">{{ t('modules.pool.modal.edit.subheading') }}</h4>
  </div>

  <div *ngIf="rows$ | async as rows" class="ppa-modal__content ppa-modal__content--flex-direction-column pb-1">
    <form #form="ngForm" [formGroup]="poolForm" (ngSubmit)="handleSubmit()" autocomplete="off">
      <div>
      <div formArrayName="rows" class="pool-row" *ngFor="let row of rows; let i = index">
        <div class="pool-row-heading">
          <h3>{{ t('modules.pool.modal.edit.form.row_heading') }} {{ i + 1 }}</h3>
          <i *ngIf="controlsArray[i].get('type')?.value === PoolProvisionalStatementRowType.Manual && rows.length > 1" (click)="removeRow($event, i)" class="uil uil-trash-alt"></i>
        </div>

        <div class="pool-row--fields" [formGroupName]="i">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.pool.modal.edit.form.description') }}
              <span class="required" *ngIf="isRequiredField('description', controlsArray[i]) | async">*</span>
            </mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controlsArray[i].get('description').touched) && controlsArray[i].get('description').invalid">
            <div class="form-error" *ngIf="controlsArray[i].get('description').errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>

          <ng-container *ngIf="controlsArray[i].get('type')?.value === PoolProvisionalStatementRowType.DeliveryReport">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.pool.modal.edit.form.deliveryReportReferenceNumber') }}
                <span class="required" *ngIf="isRequiredField('deliveryReportReferenceNumber', controlsArray[i]) | async">*</span>
              </mat-label>
              <input matInput formControlName="deliveryReportReferenceNumber" />
            </mat-form-field>
            <div *ngIf="(form.submitted || controlsArray[i].get('deliveryReportReferenceNumber').touched) && controlsArray[i].get('deliveryReportReferenceNumber').invalid">
              <div class="form-error" *ngIf="controlsArray[i].get('deliveryReportReferenceNumber').errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>

            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.pool.modal.edit.form.deliveryWeek') }}
                <span class="required" *ngIf="isRequiredField('deliveryWeek', controlsArray[i]) | async">*</span>
              </mat-label>
              <input matInput formControlName="deliveryWeek" />
            </mat-form-field>
            <div *ngIf="(form.submitted || controlsArray[i].get('deliveryWeek').touched) && controlsArray[i].get('deliveryWeek').invalid">
              <div class="form-error" *ngIf="controlsArray[i].get('deliveryWeek').errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.pool.modal.edit.form.amount') }}
              <span class="required" *ngIf="isRequiredField('amount', controlsArray[i]) | async">*</span>
            </mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="amount" />
            <span matSuffix>
              <ng-container *ngIf="controlsArray[i].get('type')?.value === PoolProvisionalStatementRowType.DeliveryReport">{{ 'general.kg' | transloco }}</ng-container>
            </span>
          </mat-form-field>
          <div *ngIf="(form.submitted || controlsArray[i].get('amount').touched) && controlsArray[i].get('amount').invalid">
            <div class="form-error" *ngIf="controlsArray[i].get('amount').errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controlsArray[i].get('amount').errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>

          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.pool.modal.edit.form.price') }}
              <span class="required" *ngIf="isRequiredField('price', controlsArray[i]) | async">*</span>
            </mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="price" />
            <i matSuffix class="uil uil-euro"></i>
          </mat-form-field>
          <div *ngIf="(form.submitted || controlsArray[i].get('price').touched) && controlsArray[i].get('price').invalid">
            <div class="form-error" *ngIf="controlsArray[i].get('price').errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controlsArray[i].get('price').errors.pattern">
              <span>{{ 'form_errors.invalid_currency' | transloco }}</span>
            </div>
          </div>

          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.pool.modal.edit.form.vatCodePercentage') }}
              <span class="required" *ngIf="isRequiredField('vatCodePercentage', controlsArray[i]) | async">*</span>
            </mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="vatCodePercentage" />
            <i matSuffix class="uil uil-percentage"></i>
          </mat-form-field>
          <div *ngIf="(form.submitted || controlsArray[i].get('vatCodePercentage').touched) && controlsArray[i].get('vatCodePercentage').invalid">
            <div class="form-error" *ngIf="controlsArray[i].get('vatCodePercentage').errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>

          <ng-container *ngIf="vats$ | async as vats">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.pool.modal.edit.form.vatTotal') }}
                <span class="required" *ngIf="isRequiredField('vatTotal', controlsArray[i]) | async">*</span>
              </mat-label>
              <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="vatTotal" value="{{ vats[i] | number: '1.0-2' }}" />
              <i matSuffix class="uil uil-euro"></i>
            </mat-form-field>
            <div *ngIf="(form.submitted || controlsArray[i].get('vatTotal').touched) && controlsArray[i].get('vatTotal').invalid">
              <div class="form-error" *ngIf="controlsArray[i].get('vatTotal').errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controlsArray[i].get('vatTotal').errors.pattern">
                <span>{{ 'form_errors.invalid_currency' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="subtotals$ | async as subtotals">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.pool.modal.edit.form.netTotal') }}
                <span class="required" *ngIf="isRequiredField('netTotal', controlsArray[i]) | async">*</span>
              </mat-label>
              <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="netTotal" value="{{ subtotals[i] | number: '1.0-2' }}" />
              <i matSuffix class="uil uil-euro"></i>
            </mat-form-field>
            <div *ngIf="(form.submitted || controlsArray[i].get('netTotal').touched) && controlsArray[i].get('netTotal').invalid">
              <div class="form-error" *ngIf="controlsArray[i].get('netTotal').errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controlsArray[i].get('netTotal').errors.pattern">
                <span>{{ 'form_errors.invalid_currency' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      </div>

      <div class="mb-2">
        <button mat-flat-button type="button" (click)="addRow()">
          <i class="uil uil-plus"></i>
          {{ t('modules.pool.modal.edit.form.add_row') }}
        </button>
      </div>

      <button mat-raised-button class="button--full-width" color="primary">
        {{ t('modules.pool.modal.edit.form.save') }}
      </button>
    </form>
  </div>
</div>

<div class="ppa-modal__fader"></div>
