import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { CreateRelationComponent } from './components/create-relation/create-relation.component';
import { SelectRelationComponent } from './components/select-relation/select-relation.component';
import { SelectRelationModalComponent } from './modals/select-relation-modal/select-relation-modal.component';
import { LayoutModule, LoadingModule, NoResultModule, SelectModule } from '@ppa/layout';

@NgModule({
  declarations: [SelectRelationComponent, CreateRelationComponent, SelectRelationModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    LoadingModule,
    NoResultModule,
    SelectModule,
    LayoutModule,
  ],
  exports: [SelectRelationComponent, CreateRelationComponent],
})
export class RelationPickerModule {}
