<div [id]="id" [class]="'order ' + class" [class.action-card]="actionCard">
  <i *ngIf="preIcon" [class]="'pre-icon uil ' + preIcon"></i>
  <div class="card-container">
    <div class="card__heading">
      <div>
        <h4 class="card__title">
          {{ orderCardInfo.relation?.companyName }}
          <ng-container *ngIf="showDetailTitle && orderCardInfo.title"> - {{ orderCardInfo.title }}</ng-container>
          <ppa-relation-status [label]="orderCardInfo.relation?.status"></ppa-relation-status>
        </h4>
      </div>

      <ng-container *ngIf="status || orderCardInfo.dossier">
        <div class="card__right">
          <i *ngIf="orderCardInfo.dossier" class="status uil uil-folder-open"></i>

          <div class="card__badge" *ngIf="status">
            <i *ngIf="statusIcon" class="uil {{ statusIcon }}"></i>
            <span>
              {{ status }}
              <ng-container *ngIf="statusNoSuffix !== true"> {{ 'general.ton' | transloco }}</ng-container>
            </span>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="container {{ cardType }}">
      <div class="column">
        <div class="content">{{ orderCardInfo.locationLine || orderCardInfo.relation?.addressLine }}</div>
      </div>
      <div class="column">
        <div class="content" *ngIf="!orderCardInfo.productVariety; else variety">
          {{ orderCardInfo.product?.title }}
          <ppa-status
            class="ppa-status--newline"
            *ngIf="orderCardInfo.bakingFactory"
            [manualLabel]="'modules.dossier.detail.summary.baking_factory' | transloco"
          ></ppa-status>
        </div>
      </div>
      <div class="column">
        <ng-container *ngIf="orderCardInfo.amount; else noAmount">
          <div class="content">
            <span class="label">{{ 'modules.order.card.amount' | transloco }}</span>
            <ng-container *ngIf="showAmountUnitSuffix(orderCardInfo); else noUnitDivisor">
              <ng-container *ngIf="orderCardInfo.amountOfUnit">
                {{ orderCardInfo.amountOfUnit }} {{ orderCardInfo.amountUnit?.title }} -
              </ng-container>
              {{ orderCardInfo.amount }}
              <ng-container *ngIf="showAmountUnitSuffix(orderCardInfo)">{{ 'general.ton' | transloco }}</ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noAmount>
          <ng-container *ngIf="orderCardInfo.expectedAmount || orderCardInfo.estimatedAmount">
            <div class="content">
              <span class="label">{{ 'modules.order.card.amount' | transloco }}</span>
              <ng-container *ngIf="orderCardInfo.amountOfUnit">
                {{ orderCardInfo.amountOfUnit }} {{ orderCardInfo.amountUnit?.title }} -
              </ng-container>
              {{
                orderCardInfo.stage === 'off_meadow' || orderCardInfo.stage === null
                  ? orderCardInfo.expectedAmount
                  : orderCardInfo.estimatedAmount
              }}
              <ng-container *ngIf="showAmountUnitSuffix(orderCardInfo)">{{ 'general.ton' | transloco }}</ng-container>
            </div>
          </ng-container>
        </ng-template>
      </div>
      <div class="column">
        <ng-container *ngIf="orderCardInfo.matchedOrdersAmount">
          <div class="content">
            <span class="label">{{ 'modules.order.card.fulfilled' | transloco }}</span>
            {{ orderCardInfo.matchedOrdersAmount }}
            <ng-container *ngIf="showAmountUnitSuffix(orderCardInfo)">{{ 'general.ton' | transloco }}</ng-container> -
            {{ percentFulfilled(orderCardInfo.matchedOrdersAmount, orderCardInfo.amount) }}%
            <span
              *ngIf="orderCardInfo.matchedOrdersAmount > orderCardInfo.amount"
              matTooltip="{{ 'modules.order.card.fulfilled_warning' | transloco }}"
              class="exclamation"
            >
              <i class="uil uil-exclamation-triangle"></i>
            </span>
          </div>
        </ng-container>
      </div>
      <div class="column">
        <ng-container *ngIf="orderCardInfo.availableAmount > 0">
          <div class="content">
            <span class="label">{{ 'modules.order.card.available' | transloco }}</span>
            {{ orderCardInfo.availableAmount }}
            <ng-container *ngIf="showAmountUnitSuffix(orderCardInfo)">{{ 'general.ton' | transloco }}</ng-container> -
            {{ percentFulfilled(orderCardInfo.availableAmount, orderCardInfo.amount) }}%
          </div>
        </ng-container>
        <ng-container *ngIf="orderCardInfo.availableAmount <= 0">
          <div class="content">
            <span class="label">{{ 'modules.order.card.available' | transloco }}</span>
            -
          </div>
        </ng-container>
      </div>
      <div class="column">
        <ng-container *ngTemplateOutlet="productPrice"></ng-container>
      </div>
    </div>

    <div
      *ngIf="expiresAtDate && orderCardInfo.expiresAt"
      class="expires-at"
      [class.expires-at--passed]="checkDateTimeBeforeNow(orderCardInfo.expiresAt)"
    >
      {{ 'modules.order.create.expires_at_label' | transloco }}:
      {{ orderCardInfo.expiresAt | date: 'dd-MM-YYYY HH:mm' }}
    </div>

    <div class="phone-action">
      <a
        class="phone"
        *ngIf="phone"
        (click)="$event.stopPropagation()"
        href="tel:{{ orderCardInfo.mediator.phoneNumber }}"
        ><i class="uil uil-phone"></i> {{ orderCardInfo.mediator.firstname }} {{ orderCardInfo.mediator.lastname }}</a
      >
    </div>
    <div class="order__detail-action" *ngIf="showDetailButtons()">
      <button *ngIf="showDetailButton" mat-flat-button type="button" (click)="viewDetail()">
        {{ detailButtonLabel | transloco }}
      </button>
      <button *ngIf="showExtraDetailButton" mat-flat-button type="button" (click)="viewExtraDetail()">
        {{ extraDetailButtonLabel | transloco }}
      </button>
      <ng-content></ng-content>
    </div>
  </div>

  <div class="action-card__action" *ngIf="actionCard">
    <i data-cy="action-card-button" [class]="'uil ' + actionIcon"></i>
  </div>

  <div class="card-dates card-dates--no-padding">
    <div class="card-date card-date--created">
      <span class="card-date--label">{{ 'sorting.created_at' | transloco }}:&nbsp;</span>
      <span class="card-date--date">{{ orderCardInfo.createdAt | ppaDate: 'DD-MM-YYYY HH:mm' }}</span>
    </div>
    <div class="card-date card-date--updated">
      <span class="card-date--label">{{ 'sorting.updated_at' | transloco }}:&nbsp;</span>
      <span class="card-date--date">{{ orderCardInfo.updatedAt | ppaDate: 'DD-MM-YYYY HH:mm' }}</span>
    </div>
  </div>
</div>

<ng-template #variety>
  <div class="content">
    {{ orderCardInfo.product.title }}, {{ orderCardInfo.productVariety.title }}
    <ng-container *ngIf="orderCardInfo.bakingFactory">
      ({{ 'modules.order.detail.summary.baking_factory' | transloco }})
    </ng-container>
  </div>
</ng-template>

<ng-template #productPrice>
  <ng-container *ngIf="price">
    <div class="content">
      <span class="label">{{ 'modules.order.card.indication_price' | transloco }}</span>
      € {{ price }}
      <span *ngIf="orderCardInfo.indicationPriceUnit && orderCardInfo.indicationPriceUnit.title">
        {{ orderCardInfo.indicationPriceUnit.prefix }} {{ orderCardInfo.indicationPriceUnit.title }}
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #noUnitDivisor>
  <ng-container *ngIf="orderCardInfo.amountOfUnit">
    {{ orderCardInfo.amountOfUnit }} {{ orderCardInfo.amountUnit?.title }}
  </ng-container>
</ng-template>
