import { NgModule } from '@angular/core';
import { StatusComponent } from './status.component';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

const COMPONENTS = [StatusComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, TranslocoModule],
  exports: [...COMPONENTS],
})
export class StatusModule {}
