import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {}

  isMenuVisible(): Observable<boolean> {
    return this.menuVisible$.asObservable();
  }

  setMenuVisible(value: boolean) {
    this.menuVisible$.next(value);
  }
}
