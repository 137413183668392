import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DeliveryOverviewCard } from '../../contracts';
import { OverviewCard } from '../../classes';

@Component({
  selector: 'ppa-delivery-overview-card',
  templateUrl: './delivery-overview-card.component.html',
  styleUrls: ['./delivery-overview-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryOverviewCardComponent extends OverviewCard {
  @Input() config: DeliveryOverviewCard;
  @Input() cyIdentifier: string;
}
