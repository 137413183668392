import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { User, UserOption } from '../contracts';
import { Observable, of } from 'rxjs';
import { KeyValuePairs } from '../contracts';
import { switchMap } from 'rxjs/operators';
import { ReadResponse } from '../contracts/read-response';

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends BaseService<User> {
  path = 'users';

  options(): Observable<UserOption[]> {
    return this.http.get<KeyValuePairs<number>>(`${this.apiConfig.baseUrl}/${this.path}/options`).pipe(
      switchMap((users) => {
        return of(
          users.map((u) => {
            return { id: u.value, label: u.key } as UserOption;
          }),
        );
      }),
    );
  }

  updatePassword(password: string): Observable<ReadResponse<User>> {
    return this.http.put<ReadResponse<User>>(`${this.apiConfig.baseUrl}/${this.path}/password`, { password });
  }

  getSignature(user: User): Observable<any> {
    return this.http.get<any>(`${this.apiConfig.baseUrl}/${this.path}/${user.id}/signature`);
  }
}
