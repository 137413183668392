<div class="ppa-modal ppa-modal--confirm" *transloco="let t">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h3>{{ 'modules.contract.history.modal.title' | transloco: { relation: companyName } }}</h3>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <div class="ppa-modal__intro mb-1">{{ t('modules.contract.history.modal.intro') }}</div>

    <ng-container>
      <div *ngFor="let contract of contracts" class="contract-overview-card card card--elevate-1">
        <div class="card-info">
          <div class="card-info__row">
            <span class="card-info__column">{{ t('modules.contract.history.modal.referenceNumber') }}</span>
            <span class="card-info__column">{{ contract.referenceNumber }}</span>
          </div>
          <div class="card-info__row">
            <span class="card-info__column">{{ t('modules.contract.history.modal.contractDate') }}</span>
            <span>{{ contract.contractDate | date: 'dd-MM-YYYY HH:mm:ss' }}</span>
          </div>
          <div class="card-info__row">
            <span class="card-info__column">{{ t('modules.contract.history.modal.status') }}</span>
            <span class="card-info__column {{ contract.cancelReason ? 'warn' : '' }}"
              >{{ t('modules.contract.history.modal.status_' + (contract.cancelReason ? 'canceled' : 'current')) }}
              <span *ngIf="!contract.cancelReason && !contract.contractSentAt">
                - {{ t('modules.contract.overview.notSent') }}
              </span>
            </span>
          </div>

          <ng-container *ngIf="contract.cancelReason">
            <div class="card-info__row">
              <span class="card-info__column">{{ t('modules.contract.history.modal.canceledAt') }}</span>
              <span>{{ contract.canceledAt | date: 'dd-MM-YYYY HH:mm:ss' }}</span>
            </div>
            <div class="card-info__row">
              <span class="card-info__column">{{ t('modules.contract.history.modal.cancelReason') }}</span>
              <span>{{
                contract.cancelReason === cancelContractOther
                  ? contract.cancelReasonOther
                  : t('modules.contract.cancel.modal.reason_options.' + contract.cancelReason)
              }}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="!contract.cancelReason">
            <div class="card-info__row" *ngIf="contract.contractSentAt">
              <span class="card-info__column">{{ t('modules.contract.overview.contractSentAt') }}</span>
              <span>{{ contract.contractSentAt | date: 'dd-MM-YYYY HH:mm:ss' }}</span>
            </div>
          </ng-container>
        </div>

        <div class="card__buttons">
          <button mat-flat-button (click)="viewContract(contract)">
            {{ 'modules.contract.buttons.view_contract' | transloco }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="ppa-modal__fader"></div>
