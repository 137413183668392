<ng-container *ngIf="formType === 'login'; else forgotPassword">
  <form [formGroup]="loginForm" class="login-form" autocomplete="off">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'modules.auth.login.email' | transloco }}</mat-label>
      <input matInput type="email" formControlName="email" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'modules.auth.login.password' | transloco }}</mat-label>
      <input matInput type="password" autocomplete="current-password" formControlName="password" />
    </mat-form-field>

    <button
      type="submit"
      mat-flat-button
      fixed
      color="primary"
      class="login-form__button"
      [disabled]="loginForm.invalid"
      (click)="login()"
    >
      {{ 'modules.auth.login.button_login' | transloco }}
    </button>

    <button
      type="button"
      mat-flat-button
      fixed
      color="secondary"
      class="login-form__button login-form__button--forgot-password"
      (click)="switchForm()"
    >
      {{ 'modules.auth.login.button_forgot_password' | transloco }}
    </button>
  </form>
</ng-container>
<ng-template #forgotPassword>
  <form [formGroup]="passwordForm" class="login-form" autocomplete="off">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'modules.auth.login.email' | transloco }}</mat-label>
      <input matInput type="email" formControlName="email" />
    </mat-form-field>

    <button
      type="submit"
      mat-flat-button
      fixed
      color="primary"
      class="login-form__button"
      [disabled]="passwordForm.invalid"
      (click)="forgot()"
    >
      {{ 'modules.auth.login.button_login_link' | transloco }}
    </button>

    <button
      type="button"
      mat-flat-button
      fixed
      color="secondary"
      class="login-form__button login-form__button--forgot-password"
      (click)="switchForm()"
    >
      {{ 'modules.auth.login.button_back_login' | transloco }}
    </button>
  </form>
</ng-template>
