<div class="checkbox-container">
  <div class="checkbox-check">
    <mat-checkbox [disabled]="disabled" *ngIf="!hideCheckbox" [checked]="checked" (change)="toggleChange($event)">{{
      title
    }}</mat-checkbox>
    <span [class.dummy_label]="!hideCheckbox">{{ title }}</span>
    <ng-container *ngIf="checked && certificate?.filename">
      <div class="button-download" (click)="showCertificate(certificate)">
        <i class="uil uil-file-landscape-alt certificates__certificate__file-icon"></i>
      </div>
    </ng-container>
  </div>
  <div *ngIf="checked" class="checkbox-labels" [class.noPadding]="hideCheckbox">
    <ng-container [ngSwitch]="certificateStatus">
      <ng-container *ngSwitchCase="'NODATE_FILLED_IN'">
        <span class="status expired">{{ 'certificate.nodate_filled_in' | transloco }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'EXPIRED'">
        <span class="status expired">{{ 'certificate.certificate_expired' | transloco }}</span>
        <span *ngIf="_hasExpirationDate" class="status expired">{{ _expirationDate | date: 'dd-MM-yyyy' }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'ALMOST_EXPIRED'">
        <span class="status almostExpired">{{ 'certificate.certificate_almost_expired' | transloco }}</span>
        <span class="status almostExpired">{{ _expirationDate | date: 'dd-MM-yyyy' }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'VALID'">
        <span class="status valid">{{ _expirationDate | date: 'dd-MM-yyyy' }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'MISSING_FILE'">
        <span class="status expired">{{ 'certificate.missing_file' | transloco }}</span>
        <span *ngIf="_hasExpirationDate" class="status valid">{{ _expirationDate | date: 'dd-MM-yyyy' }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'UNEXISTING'">
        <span class="status expired">{{ 'certificate.unexisting' | transloco }}</span>
        <span *ngIf="_hasExpirationDate" class="status valid">{{ _expirationDate | date: 'dd-MM-yyyy' }}</span>
      </ng-container>
    </ng-container>
  </div>
</div>
