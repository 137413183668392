import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { DebugComponent } from "./debug.component";

const COMPONENTS = [DebugComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, TranslocoModule],
  exports: [...COMPONENTS],
})
export class DebugModule {}
