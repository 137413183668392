import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { translate } from '@ngneat/transloco';
import {
  Order,
  OrderMatch,
  OrderType,
  ServerSentEventAction,
  ServerSentEventService,
  ServerSentEventTopic,
} from '@ppa/data';
import {
  Card,
  ErrorHandlerService,
  HeaderData,
  MenuService,
  ModalService,
  OverviewCardButton,
  PoolOverviewCardComponent,
  PoolInfo,
} from '@ppa/layout';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OrderMatchService } from '../../../../services/order-match.service';
import { OverviewCardService } from '../../../../services/overview-card.service';
import { OrderService } from '../../../order/services/order.service';
import { PoolModalComponent } from "../../modals/pool-modal/pool-modal.component";
import { PoolSendModalComponent } from "../../modals/pool-send-modal/pool-send-modal.component";
import { PoolService } from "../../services/pool.service";

@Component({
  selector: 'ppa-pool-overview',
  templateUrl: './pool-overview.component.html',
})
export class PoolOverviewComponent implements OnInit, OnDestroy {
  readonly headerData: HeaderData;
  readonly orderId: number;

  readonly groupedOverview$: Observable<{ [key: string]: Card<PoolInfo>[] }>;
  readonly refreshPoolOverview$ = new BehaviorSubject<void>(null);
  readonly OrderType = OrderType;

  destroy$ = new Subject<void>();
  order: Order;

  @ViewChildren(PoolOverviewCardComponent) overviewCards: QueryList<PoolOverviewCardComponent>;

  constructor(
    _menuService: MenuService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private overviewCardService: OverviewCardService,
    private sse: ServerSentEventService,
    private orderMatchService: OrderMatchService,
    private errorHandler: ErrorHandlerService,
    private poolService: PoolService,
  ) {
    _menuService.setMenuVisible(false);
    this.orderId = this.route.parent.snapshot.params.id;
    this.headerData = this.createHeaderData();

    this.groupedOverview$ = this.refreshPoolOverview$.pipe(
      share(),
      switchMap(() =>
        this.overviewCardService.transformToOverviewPoolCards<PoolInfo>(
          this.orderService.getOrderForPoolOverview(this.orderId).pipe(
            tap((orderForPoolOverview) => {
              this.order = orderForPoolOverview.order;
            }),
          ),
          this.poolInfoResolver,
          this.poolButtonResolver.bind(this),
        ),
      ),
    );
  }

  ngOnInit(): void {
    this.refreshPoolOverview$.next();

    this.sse
      .getServerSentEvent(`${ServerSentEventTopic.Order}/${this.orderId}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        const { orderMatchId } = event;
        const card = this.findCard(orderMatchId);

        switch (event.action) {
          case ServerSentEventAction.StartRefresh:
            card?.startLoading();
            break;
          case ServerSentEventAction.Refresh:
            if (card) {
              this.updateCard(card, orderMatchId);
            } else {
              this.refreshPoolOverview$.next();
            }
            break;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  findCard(orderMatchId: number): PoolOverviewCardComponent {
    return this.overviewCards.find((card) => {
      return card.config.cardInfo.orderMatch.id === orderMatchId;
    });
  }

  updateCard(card: PoolOverviewCardComponent, orderMatchId: number) {
    this.orderMatchService.getOrderMatch(orderMatchId).subscribe((res) => {
      const info = this.poolInfoResolver(res.order, res);
      card.config.cardInfo = info;
      card.config.buttons = this.poolButtonResolver(res.currentPlace, info);
      card.stopLoading();
    });
  }

  private poolInfoResolver(order: Order, orderMatch: OrderMatch): PoolInfo {
    const poolProvisionalStatement = orderMatch.poolProvisionalStatements.slice(-1)[0];

    return {
      orderMatch,
      order,
      poolProvisionalStatement,
    };
  }

  private poolButtonResolver(group: string, info: PoolInfo): OverviewCardButton[] {
    switch (group) {
      case 'created': {
        return [
          this.createViewPoolButton(info),
          this.createEditPoolButton(info),
          this.createSendPoolButton(info),
        ];
      }
      case 'sent': {
        return [this.createViewPoolButton(info)];
      }
      default: {
        return [];
      }
    }
  }

  private createViewPoolButton(info: PoolInfo): OverviewCardButton {
    return {
      title: translate(`modules.pool.overview.buttons.view_pool_provisional_statement`),
      action: () => {
        this.poolService
          .downloadProvisionalStatement(info.poolProvisionalStatement.id)
          .pipe(take(1))
          .subscribe(
            () => {},
            (errorBlob) => {
              errorBlob.error.text().then((errorText) => {
                const error = JSON.parse(errorText);
                this.errorHandler.handleAPIError(translate('modules.pool.overview.download.failed'), {
                  error,
                  status: 400,
                });
              });
            },
          );
      },
      enabled: true,
    };
  }

  private createSendPoolButton(info: PoolInfo): OverviewCardButton {
    const hasEmail = !!(info.order.relation.email || info.order.relation.invoiceEmail);

    return {
      enabled: hasEmail,
      title: translate(`modules.pool.overview.buttons.send_pool_provisional_statement`),
      action: () => {
        this.modalService
          .createModal(PoolSendModalComponent, {
            poolProvisionalStatement: info.poolProvisionalStatement,
            relation: info.order.relation,
          })
          .dialog.onSave()
          .then(() => this.refreshPoolOverview$.next());
      },
      color:
        hasEmail && !info.poolProvisionalStatement.poolProvisionalStatementSentAt ? 'primary' : undefined,
    };
  }

  private createEditPoolButton(info: PoolInfo, primary?: boolean): OverviewCardButton {
    return {
      enabled: true,
      title: translate('modules.pool.overview.buttons.edit_pool_provisional_statement'),
      action: () => {
        this.modalService
          .createModal(PoolModalComponent, {
            poolProvisionalStatement: info.poolProvisionalStatement,
          })
          .dialog.onSave()
          .then(() => this.refreshPoolOverview$.next());
      },
      ...(primary && { color: 'primary' }),
    };
  }

  private createHeaderData(): HeaderData {
    return {
      left: {
        type: 'back',
        action: {
          icon: 'uil-arrow-left',
          routerLink: `order/${this.orderId}`,
          label: 'modules.pool.overview.buttons.back',
          type: 'back-button',
        },
      },
      right: [],
    };
  }
}
