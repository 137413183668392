import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Relation, Unit } from '../contracts';
import { Observable } from 'rxjs';
import { ListResponse } from '../contracts/list-response';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UnitDataService extends BaseService<Unit> {
  path = 'units';

  listWithout(exclude?: string): Observable<ListResponse<Unit>> {
    const params = exclude ? new HttpParams().set('exclude', exclude) : {};
    return this.http.get<ListResponse<Unit>>(`${this.apiConfig.baseUrl}/${this.path}`, {
      params,
    });
  }
}
