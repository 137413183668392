<form [formGroup]="dateTimeForm">
  <button
    *ngIf="dateTimeForm.controls.date.value !== null && !hideReset"
    class="clear-input"
    mat-button
    color="warn"
    type="button"
    (click)="clearForm()"
  >
    <i class="uil uil-times"></i>
    {{ dateLabel }} {{ 'form.elements.datetime_picker.reset' | transloco }}
  </button>

  <div class="datetime-picker">
    <div class="datetime-picker__date" [class.flex-1-1]="!timeSelect" [class.flex-1-2]="timeSelect">
      <mat-form-field appearance="fill">
        <mat-label>
          {{ dateLabel }}
          <span class="required" *ngIf="required$ | async">*</span>
        </mat-label>
        <input readonly matInput [matDatepicker]="picker" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="timeSelect" class="datetime-picker__time flex-1-2">
      <ppa-time-picker
        formControlName="time"
        [shouldEmitEventOnStateChange]="false"
        [timeSelect]="timeSelect"
      ></ppa-time-picker>
    </div>
  </div>
</form>
