<div class="ppa-modal">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h3>{{ 'modules.photo.modal.title' | transloco }}</h3>
    <h4 class="subheading">{{ 'general.images_saved' | transloco }}</h4>
  </div>

  <div class="ppa-modal__content">
    <ppa-add-photo [localPhotos]="localPhotos" (update)="handleUpdate($event)"></ppa-add-photo>
  </div>
</div>

<div class="ppa-modal__fader"></div>
