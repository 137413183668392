import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageService, Photo } from '@ppa/data';
import { ConfirmDialogComponent, ModalService } from '@ppa/layout';
import { EditPhotoModalComponent } from '../../modals/edit-photo-modal/edit-photo-modal.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'ppa-add-photo',
  templateUrl: './add-photo.component.html',
  styleUrls: ['./add-photo.component.scss'],
})
export class AddPhotoComponent implements OnInit, AfterViewInit {
  @Input() localPhotos: Photo[] = [];
  @Input() autoUpload = true;

  @Output() update = new EventEmitter<Photo[]>();
  @ViewChild('hiddenfileinput') hiddenfileinput: ElementRef;

  loading$: Subject<boolean> = new Subject<boolean>();
  loadingImages = 0;

  constructor(protected service: ModalService, private imageService: ImageService) {}

  ngOnInit(): void {
    this.loading$.next(false);
  }

  ngAfterViewInit(): void {
    if (this.autoUpload && this.localPhotos.length === 0) {
      this.hiddenfileinput.nativeElement.click();
    }
  }

  renderPhoto(event: { [key: string]: any }) {
    const { files } = event.target;
    if (files) {
      const fileList: File[] = Object.values(files);
      this.loading$.next(true);
      this.loadingImages = fileList.length;

      fileList.forEach((file) => {
        if (file.type.match(/image\/*/) == null) {
          return;
        }

        this.imageService.convertIfHEIC(file).then((convertedFile: File) => {
          const reader = new FileReader();
          reader.readAsDataURL(convertedFile);
          reader.onload = (_event) => {
            const photo: Photo = {
              id: this.newId(),
              base64: _event.target.result as string,
              date: new Date().toISOString(),
              mime: convertedFile.type,
            };
            this.localPhotos.push(photo);
            this.resizePhotos();
            this.update.emit(this.localPhotos);

            this.loadingImages--;

            if (this.loadingImages <= 0) {
              this.loading$.next(false);
            }
          };
        });
      });
    }
  }

  /**
   * Generate a new Id below 0, this will allow us to see that these are new photos.
   * These are deletable because of the unique id.
   */
  newId() {
    let min = Math.min(...this.localPhotos.map((image) => image.id)) as number;
    if (min === Infinity) {
      return -1;
    }

    if (min > 0) {
      min = 0;
    }

    return min - 1;
  }

  removePhoto(photo: Photo) {
    const modal = this.service.createModal(ConfirmDialogComponent, {
      message: 'Weet je zeker dat je deze foto wilt verwijderen?',
    });

    modal.dialog.onSave().then((_) => {
      const index = this.localPhotos.findIndex((image) => image.id === photo.id);
      this.localPhotos.splice(index, 1);

      this.update.emit(this.localPhotos);
    });
  }

  editPhoto(photo: Photo) {
    const modal = this.service.createModal(EditPhotoModalComponent, { photo });
    modal.dialog.onSave().then((savedPhoto: Photo) => {
      const index = this.localPhotos.findIndex((item) => item.id === savedPhoto.id);

      Object.assign(this.localPhotos[index], savedPhoto);
      this.update.emit(this.localPhotos);
    });
  }

  resizePhotos() {
    this.localPhotos.forEach((photo) => {
      this.imageService
        .resizePhoto(photo)
        .then((resizedPhoto) => {
          photo.base64 = resizedPhoto.base64;
          photo.mime = resizedPhoto.mime;
        })
        .catch((_) => {});
    });
  }
}
