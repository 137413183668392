import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { NotationComponent } from './notation.component';

const COMPONENTS = [NotationComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, MatButtonModule, MatFormFieldModule, MatInputModule, FormsModule, TranslocoModule],
  exports: [...COMPONENTS],
})
export class NotationModule {}
