<div *transloco="let t">
  <button class="add-note" *ngIf="!showNotation" mat-button color="primary" type="button" (click)="setShowNotation()">
    <i class="uil uil-plus"></i>
    {{ t('modules.note.buttons.add_note') }}
  </button>

  <ng-container *ngIf="showNotation">
    <mat-form-field appearance="fill" class="textarea">
      <mat-label>
        {{ label }}
        <span class="required" *ngIf="isRequired() | async">*</span>
      </mat-label>
      <textarea
        [value]="value"
        cdkTextareaAutosize
        matInput
        rows="3"
        (input)="onChange($event.target.value)"
      ></textarea>
    </mat-form-field>
  </ng-container>

  <button class="remove-note" *ngIf="showNotation" mat-button color="warn" type="button" (click)="clearNotation()">
    <i class="uil uil-times"></i>
    {{ t('modules.note.buttons.remove_note') }}
  </button>
</div>
