import { NgModule } from '@angular/core';
import { DatepickerWeekExactSwitchComponent } from './datepicker-week-exact.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoModule } from '@ngneat/transloco';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LayoutModule } from '../../layout.module';

const COMPONENTS = [DatepickerWeekExactSwitchComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    MatButtonModule,
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    TranslocoModule,
    MatInputModule,
    MatDatepickerModule,
    LayoutModule,
  ],
  exports: [...COMPONENTS],
})
export class DatepickerModule {}
