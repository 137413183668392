import { Component, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { Relation, RelationDataService, RelationType } from '@ppa/data';
import { Subject, Observable, of } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { LocationService } from '../../../../services/location.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ppa-select-relation',
  templateUrl: './select-relation.component.html',
  styleUrls: ['./select-relation.component.scss'],
})
export class SelectRelationComponent implements OnChanges {
  @Output() selectRelation: EventEmitter<Relation> = new EventEmitter<Relation>();
  @Input() relationType: RelationType;
  @Input() hideBlocked = false;

  relations$: Observable<Relation[]>;
  readonly searchForm: FormGroup;

  showLoader: boolean;
  initialLoad = true;
  getRelations$ = new Subject<string>();

  private latitude = null;
  private longitude = null;

  constructor(private relationDataService: RelationDataService, private locationService: LocationService) {
    this.searchForm = new FormGroup({
      term: new FormControl(''),
    });
    this.initSelectors(true);
  }

  ngOnChanges() {
    this.initSelectors(true);
  }

  initSelectors(showLoader: boolean) {
    this.showLoader = showLoader;

    if (environment.search.location.relation) {
      this.locationService.getCurrentLocationObserver().subscribe((coords) => {
        if (coords) {
          this.latitude = coords.latitude;
          this.longitude = coords.longitude;

          if (this.initialLoad) {
            this.initialLoad = false;
            const term = this.searchForm.get('term').value;
            this.getRelations$.next(term);
          }
        }
      });
    } else {
      if (this.initialLoad) {
        this.initialLoad = false;
        const term = this.searchForm.get('term').value;
        this.getRelations$.next(term);
      }
    }

    const relationType = this.relationType;
    this.relations$ = this.getRelations$.pipe(
      switchMap((term) =>
        this.relationDataService
          .list({
            query: term,
            ...(relationType && { relationType }),
            ...(this.latitude && { latitude: this.latitude }),
            ...(this.longitude && { longitude: this.longitude }),
            ...(!term && { limit: 5 }),
            ...(this.hideBlocked && { hideBlocked: true }),
          })
          .pipe(
            tap(() => (this.showLoader = false)),
            tap(() => (this.initialLoad = false)),
          ),
      ),
    );

    this.searchForm
      .get('term')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val) => {
        this.showLoader = true;
        if (val.length < 1) {
          this.getRelations$.next(null);
        } else if (val.length > 1) {
          this.getRelations$.next(val);
        }
      });

    this.searchForm.get('term').patchValue('');
  }

  handleSelectRelation(relation: Relation) {
    this.selectRelation.emit(relation);
  }
}
