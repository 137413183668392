<ng-container *ngIf="orderTree$ | async">
  <div class="order-tree" [class]="'level' + level" [class.level2]="level === 2 || overwriteColor">
    <ng-container *ngIf="order$ | async as order">
      <ng-container *ngIf="order.id === -1">
        <ppa-order-card
          [orderCardInfo]="order"
          [phone]="false"
          [cardType]="'history'"
          class="history-card history-card-dummy"
        ></ppa-order-card>
      </ng-container>
      <ng-container *ngIf="order.id > -1">
        <ppa-order-card
          [orderCardInfo]="order"
          [phone]="false"
          [cardType]="'history'"
          [statusNoSuffix]="true"
          [showDetailButton]="true"
          (viewDetailClicked)="viewDetail($event)"
          (click)="focusOrderClicked(order.id)"
          id="order{{ order.id }}"
          [class]="'history-card'"
          [preIcon]="
            order.orderType === 'buy' ? 'uil-arrow-to-bottom color-green' : 'uil-top-arrow-from-top color-blue'
          "
        ></ppa-order-card>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="children$ | async as children; else noChildren">
      <ng-container *ngIf="children.length > 0; else noChildren">
        <div class="children">
          <div *ngFor="let orderTree of children; index as i">
            <ppa-order-tree
              [orderTree]="orderTree"
              (loaded)="orderTreeLoaded($event)"
              (focusOrder)="focusOrderClicked($event)"
            ></ppa-order-tree>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #noChildren>
  <ng-container *ngIf="level < highest">
    <div class="children">
      <ppa-order-tree [orderTree]="emptyOrderTree"></ppa-order-tree>
    </div>
  </ng-container>
</ng-template>
