import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import {Invoice, PoolFinalStatement} from '../contracts';
import { Observable } from 'rxjs';
import { ReadResponse } from '../contracts/read-response';
import {tap} from "rxjs/operators";
import {DownloadFileService} from "./download-file.service";

@Injectable({
  providedIn: 'root',
})
export class FinalStatementDataService extends BaseService<PoolFinalStatement> {
  path = 'final-statements';

  update(data: Partial<PoolFinalStatement>): Observable<ReadResponse<PoolFinalStatement>> {
    return this.http.put<ReadResponse<PoolFinalStatement>>(`${this.apiConfig.baseUrl}/${this.path}/${data.id}`, data);
  }

  downloadStatement(id: number): Observable<any> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${id}/download`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }
}
