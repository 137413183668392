<div class="relation-list__wrapper">
  <div>
    <form [formGroup]="searchForm">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'modules.relations.picker.search' | transloco }}</mat-label>
        <input matInput type="search" autocomplete="off" formControlName="term" />
        <i matSuffix class="uil uil-search"></i>
      </mat-form-field>
    </form>
  </div>

  <div class="relation-list">
    <ng-container *ngIf="relations$ | async as relations; else loading">
      <ng-container *ngIf="relations?.length; else noResult">
        <div class="relation" *ngFor="let relation of relations" (click)="handleSelectRelation(relation)">
          <div class="title">
            {{ relation.companyName }}
            <span class="distance" *ngIf="relation.distance">{{ relation.distance | number: '1.1-1' }} km</span>
          </div>
          <div class="info">
            <div class="person">{{ relation.firstname }} {{ relation.lastname }}</div>
            <div class="street">{{ relation.street }} {{ relation.houseNumber }},</div>
            <div class="city">{{ relation.zipcode }} {{ relation.city }}</div>

            <span class="next"><i class="uil uil-arrow-right"></i></span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #loading>
    <ppa-loading *ngIf="showLoader"></ppa-loading>
  </ng-template>

  <ng-template #noResult>
    <ppa-no-result></ppa-no-result>
  </ng-template>
</div>
