import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'ppaDate',
})
export class PPADatePipe extends DatePipe implements PipeTransform {
  constructor() {
    super('nl');
  }

  transform(value, format?: string, timezone?: string, locale?: string): any {
    moment.locale('nl');
    return moment(value).format(format);
  }
}
