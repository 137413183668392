import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent, Intention, Modal, ModalService, PPAToastrService } from '@ppa/layout';
import { ContractService } from '../../services/contract.service';
import { Contract, ContractTypes, Order, OrderMatch, OrderType, purify } from '@ppa/data';
import { translate } from '@ngneat/transloco';
import { ErrorHandlerService } from '@ppa/layout';
import { ContractFormComponent } from '../../components/contract-form/contract-form.component';
import { Subject } from 'rxjs';
import { ModalFormIsRequiredComponent } from '../../../../components/modal-form-is-required/modal-form-is-required.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ppa-contract-own-reference-modal',
  templateUrl: './contract-own-reference-modal.component.html',
})
export class ContractOwnReferenceModalComponent extends ModalFormIsRequiredComponent implements OnInit {
  orderMatch: OrderMatch;
  contract: Contract;
  order: Order;
  companyName: string;
  saving$: Subject<boolean> = new Subject<boolean>();

  readonly contractForm: FormGroup;

  @ViewChild('form', { read: ContractFormComponent }) form: ContractFormComponent;

  constructor(
    _modalService: ModalService,
    protected fb: FormBuilder,
    protected contractService: ContractService,
    protected toastrService: PPAToastrService,
    protected errorHandler: ErrorHandlerService,
  ) {
    super(_modalService);
    this.saving$.next(false);
    this.contractForm = fb.group({
      ownNumberingForRelation: [{ value: null, disabled: false }],
    });
  }

  get controls() {
    return this.contractForm.controls;
  }

  ngOnInit(): void {
    this.companyName = this.order.relation.companyName;

    if (this.contract) {
      this.contractForm.patchValue(this.contract);
    }
  }

  close() {
    if (this.contractForm.touched) {
      this.modalService
        .createModal(ConfirmDialogComponent, {
          message: translate('navigation_guard.title'),
          cancelButtonText: translate('navigation_guard.buttons.cancel'),
          confirmButtonText: translate('navigation_guard.buttons.discard'),
        })
        .dialog.onSave()
        .then(() => {
          super.close();
        });
    } else {
      super.close();
    }
  }

  handleSubmit() {
    if (this.contractForm.valid) {
      const contract = {
        ownNumberingForRelation: this.controls.ownNumberingForRelation.value,
      };
      this.handleCreateContractSave(contract);
    }
  }

  handleCreateContractSave(contract: Partial<Contract>) {
    this.saving$.next(true);
    contract.id = this.contract.id;
    this.contractService.updateOwnReference(contract).subscribe(
      () => {
        this.saving$.next(false);
        this.save(true);
        this.toastrService.displayToastr({
          icon: 'uil-check',
          title: translate('modules.contract.edit.edit_success.title'),
          intention: Intention.Success,
          duration: 3000,
        });
      },
      (error) => {
        this.saving$.next(false);
        this.errorHandler.handleAPIError(translate('modules.contract.edit.edit_failed.title'), error);
      },
    );
  }
}
