import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dossier, Order, OrderDataService, OrderTree, OrderType } from '@ppa/data';
import { Observable, of, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ppa-order-tree',
  templateUrl: './order-tree.component.html',
  styleUrls: ['./order-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTreeComponent implements OnInit {
  @Input() orderTree$: Observable<OrderTree>;
  @Input() orderTree: OrderTree;
  @Output() loaded = new EventEmitter<number>();
  @Output() focusOrder = new EventEmitter<number>();

  children$: Observable<OrderTree[]>;
  order$: Observable<Partial<Order>>;
  level: number;
  emptyOrderTree: OrderTree;
  highest = 0;
  orderId: number;
  overwriteColor = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if (this.orderTree) {
      this.orderTree$ = of(this.orderTree);
    }

    this.orderTree$.subscribe((orderTree) => {
      if (orderTree) {
        if (orderTree.level === 1 && orderTree.order.orderType === OrderType.Buy) {
          this.overwriteColor = true;
        }

        this.order$ = of(orderTree.order);
        this.children$ = of(orderTree.children).pipe(
          map((childTrees) => {
            return childTrees.map((childTree) => {
              childTree.highest = orderTree.highest;
              return childTree;
            });
          }),
        );
        this.level = orderTree.level;
        if (orderTree.highest) {
          this.highest = orderTree.highest;
        }

        this.emptyOrderTree = {
          // @ts-ignore
          order: { id: -1, relation: { companyName: '' }, product: { title: '' } },
          level: this.level + 1,
          highest: this.highest,
          children: [],
        };

        this.loaded.emit(orderTree.order.id);
      }
    });
  }

  orderTreeLoaded($event): void {
    this.loaded.emit($event);
  }

  viewDetail($event: Order | Dossier): void {
    this.router.navigate(['order', $event.id]);
  }

  focusOrderClicked(id): void {
    this.focusOrder.emit(id);
  }
}
