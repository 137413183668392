import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import {
  Dossier,
  DossierDataService,
  KeyValuePairs,
  NavigateBackService,
  PaginationOptions,
  Relation,
  ServerSentEventAction,
  ServerSentEventService,
  ServerSentEventTopic,
  FilterModule,
  Metadata,
} from '@ppa/data';
import {
  Filter,
  FilterService,
  HeaderData,
  Intention,
  ModalService,
  PPAToastrService,
  StateService,
  WizardComponent,
  WizardStep,
} from '@ppa/layout';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  pluck,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { RelationService } from '../../../relation/services/relation.service';
import { DossierService } from '../../services/dossier.service';
import { DossierWizardAddPhotoComponent } from '../dossier-wizard-add-photo/dossier-wizard-add-photo.component';
import { DossierWizardSelectLocationComponent } from '../dossier-wizard-select-location/dossier-wizard-select-location.component';
import { DossierWizardSelectRelationComponent } from '../dossier-wizard-select-relation/dossier-wizard-select-relation.component';
import { DossierWizardSpecificationsOneComponent } from '../dossier-wizard-specifications-one/dossier-wizard-specifications-one.component';
import { DossierWizardSpecificationsThreeComponent } from '../dossier-wizard-specifications-three/dossier-wizard-specifications-three.component';
import { DossierWizardSpecificationsTwoComponent } from '../dossier-wizard-specifications-two/dossier-wizard-specifications-two.component';
import { environment } from '../../../../../environments/environment';
import { LocationService } from '../../../../services/location.service';
import { PageEvent } from '@angular/material/paginator';
import { DossierMapComponent } from '../../modals/dossier-map/dossier-map.component';

@Component({
  selector: 'ppa-dossier-overview',
  templateUrl: './dossier-overview.component.html',
  styleUrls: ['./dossier-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DossierOverviewComponent implements OnInit {
  readonly dossiers$: Observable<Dossier[]>;
  readonly dossierList$ = new BehaviorSubject<Dossier[]>(null);
  readonly triggerRefreshOverview$ = new Subject();
  readonly destroy$ = new Subject();
  readonly filteredRelation$ = new BehaviorSubject<Relation>(null);
  readonly showMap$ = new Subject<void>();
  relationState$: Observable<number>;

  metadata: Metadata;

  readonly paginationOptions$ = new BehaviorSubject<PaginationOptions>({});
  readonly filterTrigger$ = new BehaviorSubject<{ [key: string]: string }>({});
  readonly module = FilterModule.dossier;

  filters: Filter[];
  sortingFields: KeyValuePairs<string>;

  headerData: HeaderData = {
    left: {
      type: 'title',
      title: 'modules.dossier.overview.title',
    },
    right: [
      {
        icon: 'uil-map-marker',
        type: 'circle-button',
        label: 'modules.dossier.modal.map.title',
        actionClick$: this.showMap$,
        hidden$: this.hasLocationEnabled(),
      },
    ],
  };
  canCreateDossier = false;

  constructor(
    private stateService: StateService,
    private dossierDataService: DossierDataService,
    private dossierService: DossierService,
    private modalService: ModalService,
    private toastrService: PPAToastrService,
    private fb: FormBuilder,
    private router: Router,
    private navigateBackService: NavigateBackService,
    private relationService: RelationService,
    private sse: ServerSentEventService,
    private filterService: FilterService,
    private locationService: LocationService,
  ) {
    this.dossiers$ = combineLatest([
      this.triggerRefreshOverview$.pipe(startWith('start')),
      this.filterTrigger$.pipe(distinctUntilChanged(), debounceTime(400)),
    ]).pipe(
      withLatestFrom(this.filterTrigger$),
      map((values) => {
        this.dossierList$.next(null);
        const [, filterValue] = values;

        const relationId = this.stateService.getRelation();

        const coordinates = this.locationService.getCurrentLocation();

        return {
          ...this.paginationOptions$.value,
          ...(relationId && { relation: relationId }),
          ...filterValue,
          ...(coordinates && { latitude: coordinates.latitude, longitude: coordinates.longitude }),
        };
      }),
      distinctUntilChanged(),
      switchMap((params) => this.listDossiers(params)),
      catchError((error: any) => {
        this.toastrService.displayToastr({
          icon: 'uil-exclamation-triangle',
          title: translate('modules.dossier.toastr.overview-error'),
          intention: Intention.Error,
          identifier: 'overview-error',
        });
        const items: Dossier[] = [];
        return of(items);
      }),
    );

    this.dossiers$.subscribe((dossiers) => {
      this.dossierList$.next(dossiers);
    });

    this.filters = this.createFilters();
    this.sortingFields = this.createSortingFields();

    this.showMap$.subscribe(() => {
      this.showMap();
    });
  }

  ngOnInit(): void {
    this.relationState$ = of(this.stateService.getRelation());
    this.sse
      .getServerSentEvent(`${ServerSentEventTopic.Dossier}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((serverEvent) => {
        switch (serverEvent.action) {
          case ServerSentEventAction.Refresh:
            this.triggerRefreshOverview$.next();
            break;
        }
      });

    this.setFilteredRelation();
  }

  private listDossiers(params?: { [key: string]: any }) {
    return this.dossierDataService.listPaged(params).pipe(
      tap((pagination) => {
        this.metadata = pagination.metadata;
      }),
      map((response) => {
        return response.data.map((dossier) => {
          let hasDistance = false;
          if (dossier.location && dossier.location[0] && dossier.location[0].street) {
            const location = dossier.location[0];
            dossier.locationLine = `${location.street} ${location.number}, ${location.city}`;
            dossier.$distance = this.locationService.getDistance(dossier.longitude, dossier.latitude);
            hasDistance = true;
          }

          if (!dossier.relation) {
            return dossier;
          } else {
            const relation = dossier.relation;
            if (relation && !hasDistance) {
              dossier.$distance = this.locationService.getDistance(relation.longitude, relation.latitude);
            }
          }

          return {
            ...dossier,
            ...(dossier.indicationPrice && { indicationPrice: dossier.indicationPrice.toString().replace('.', ',') }),
          };
        });
      }),
    );
  }

  setFilteredRelation() {
    const relation_id = this.stateService.getRelation();
    if (relation_id) {
      this.relationService.getRelation(relation_id).subscribe((data) => {
        this.filteredRelation$.next(data);
        this.canCreateDossier = true;
      });
    } else {
      this.filteredRelation$.next(null);
      this.canCreateDossier = true;
    }
  }

  setPage(pageInfo: PageEvent) {
    this.paginationOptions$.next({
      ...this.paginationOptions$.value,
      offset: pageInfo.pageIndex,
    });
    this.triggerRefreshOverview$.next();
  }

  navigateBack() {
    this.router.navigate(['search']);
  }

  showAll() {
    this.stateService.setRelation(null);
    this.filteredRelation$.next(null);
    this.triggerRefreshOverview$.next();
  }

  createDossier() {
    this.toastrService.clear();

    const dossierCreateWizard: WizardStep[] = [
      // add each step to this array
      {
        title: 'Selecteer een relatie',
        component: DossierWizardSelectRelationComponent,
        cyIdentifier: 'modal-dossier-create-wizard-relation',
        hasPrevBtn$: of(false),
        hasNextBtn$: of(false),
        ownContentTemplate: true,
      },
      {
        title: 'Selecteer een locatie',
        component: DossierWizardSelectLocationComponent,
        cyIdentifier: 'modal-dossier-create-wizard-location',
      },
      {
        title: 'Afbeeldingen toevoegen',
        component: DossierWizardAddPhotoComponent,
        cyIdentifier: 'modal-dossier-create-wizard-photo',
      },
      {
        title: 'Specificaties',
        component: DossierWizardSpecificationsOneComponent,
        cyIdentifier: 'modal-dossier-create-wizard-specification-one',
      },
      {
        title: 'Specificaties',
        component: DossierWizardSpecificationsTwoComponent,
        cyIdentifier: 'modal-dossier-create-wizard-specification-two',
      },
      {
        title: 'Specificaties',
        component: DossierWizardSpecificationsThreeComponent,
        cyIdentifier: 'modal-dossier-create-wizard-specification-three',
        hasNextBtn$: of(false),
      },
    ];

    let relationdDossierCreateWizard: WizardStep[];

    this.filteredRelation$.subscribe((relation) => {
      if (relation) {
        this.dossierService.setDossierWizardState({ relation });
        relationdDossierCreateWizard = dossierCreateWizard.splice(1, 5);
        if (
          relationdDossierCreateWizard &&
          relationdDossierCreateWizard[0] &&
          relationdDossierCreateWizard[0]['hasPrevBtn$']
        ) {
          relationdDossierCreateWizard[0]['hasPrevBtn$'] = of(false);
        }
      }
    });

    const modal = this.modalService.createModal(WizardComponent, {
      steps: relationdDossierCreateWizard ?? dossierCreateWizard,
    });

    modal.dialog.onSave().then(() => {
      this.triggerRefreshOverview$.next();
      this.canCreateDossier = false;
      this.setFilteredRelation();

      const makeAssignmentAction$ = new Subject<void>();
      const viewDossierAction$ = new Subject<void>();

      combineLatest([viewDossierAction$, this.dossierService.getLastSavedDossier()])
        .pipe(
          map((combined) => combined[1]),
          pluck('id'),
        )
        .subscribe((id) => this.router.navigate([`/dossier/${id}`]));

      combineLatest([makeAssignmentAction$, this.dossierService.getLastSavedDossier()])
        .pipe(map((combined) => combined[1]))
        .subscribe((dossier) =>
          this.router.navigate(['/order', 'create'], {
            queryParams: { dossier: dossier.id, ...(dossier.sellType && { sellType: dossier.sellType }) },
          }),
        );

      const messageDossierAdded = 'modules.dossier.toastr.dossier_added';
      this.toastrService.displayToastr({
        icon: 'uil-check',
        title: translate(messageDossierAdded),
        intention: Intention.Success,
        buttons: [
          {
            text: translate('modules.dossier.toastr.view_dossier'),
            sub: viewDossierAction$,
          },
          ...(environment.activeModules.order
            ? [
                {
                  text: translate('modules.dossier.toastr.make_order'),
                  sub: makeAssignmentAction$,
                },
              ]
            : []),
        ],
        identifier: messageDossierAdded,
      });
    });
  }

  handleFilterValues(filterValues: { [key: string]: string }): void {
    this.paginationOptions$.next({
      ...this.paginationOptions$.value,
      offset: 0,
    });

    if (filterValues.search === '' || filterValues.search === '0' || Number(filterValues.search) === 0) {
      delete filterValues.search;
    }
    this.filterTrigger$.next(filterValues);
  }

  private createFilters(): Filter[] {
    return [
      {
        name: 'search',
        label: 'filter.search',
        type: 'search',
        allowedOperators: ['li', 'no'],
      },
      {
        name: 'currentplace',
        label: 'filter.current_place',
        type: 'fixed',
        data: this.filterService.getStatuses(this.module),
        dataBindLabel: 'key',
        dataBindValue: 'value',
        translateLabel: true,
        allowedOperators: ['eq', 'neq'],
      },
      {
        name: 'product',
        label: 'filter.product',
        type: 'fixed',
        data: this.filterService.getProductOptions(),
        dataBindLabel: 'title',
        dataBindValue: 'title',
        allowedOperators: ['eq', 'neq'],
      },
      {
        name: 'variety',
        label: 'filter.variety',
        type: 'fixed',
        data: this.filterService.getProductVarietyOptions(),
        dataBindLabel: 'title',
        dataBindValue: 'value',
        allowedOperators: ['eq', 'neq'],
      },
      {
        name: 'bakingFactory',
        label: 'filter.baking_factory',
        type: 'fixed',
        data: this.dossierService.numericBooleanOptions,
        dataBindLabel: 'key',
        dataBindValue: 'value',
        translateLabel: true,
        allowedOperators: ['bool'],
      },
      {
        name: 'amount',
        label: 'filter.amount',
        type: 'search',
        allowedOperators: ['gt', 'lt', 'eq', 'neq'],
      },
      {
        name: 'stage',
        label: 'filter.stage',
        type: 'fixed',
        data: this.dossierService.stageOptions,
        dataBindLabel: 'key',
        dataBindValue: 'value',
        translateLabel: true,
        allowedOperators: ['eq', 'neq'],
      },
      {
        name: 'delivery',
        label: 'filter.preferred_delivery',
        type: 'fixed',
        data: this.dossierService.preferenceDeliveryOptions,
        dataBindLabel: 'key',
        dataBindValue: 'value',
        translateLabel: true,
        allowedOperators: ['eq', 'neq'],
      },
      {
        name: 'harvestyear',
        label: 'filter.harvest_year',
        type: 'fixed',
        data: this.dossierService.harvestYears,
        dataBindLabel: 'key',
        dataBindValue: 'value',
        allowedOperators: ['eq', 'neq', 'gt', 'lt'],
      },
      {
        name: 'relation',
        label: 'filter.relation',
        type: 'fixed',
        data: this.filterService.getRelationOptions(true),
        dataBindLabel: 'companyName',
        dataBindValue: 'companyName',
        allowedOperators: ['eq', 'neq'],
      },
      {
        name: 'sproutinhibitor',
        label: 'filter.sprout_inhibitor',
        type: 'fixed',
        data: this.dossierService.sproutInhibitorOptions,
        dataBindLabel: 'key',
        dataBindValue: 'value',
        translateLabel: true,
        allowedOperators: ['eq', 'neq'],
      },
      {
        name: 'dossiertype',
        label: 'filter.sell_type',
        type: 'fixed',
        data: this.dossierService.sellTypeOptions,
        dataBindLabel: 'key',
        dataBindValue: 'value',
        translateLabel: true,
        allowedOperators: ['eq', 'neq'],
      },
      { ...this.dossierService.filterDate },
      { ...this.dossierService.filterPeriod },
      {
        name: 'mediator',
        label: 'filter.mediator',
        type: 'fixed',
        data: this.filterService.getMediatorOptions(),
        dataBindLabel: 'label',
        dataBindValue: 'label',
        allowedOperators: ['eq', 'neq'],
      },
      {
        name: 'certificate',
        label: 'filter.certificate',
        type: 'fixed',
        data: this.filterService.getCertificateOptions(),
        dataBindLabel: 'name',
        dataBindValue: 'name',
        allowedOperators: ['eq', 'neq'],
      },
    ];
  }

  private createSortingFields(): KeyValuePairs<string> {
    return [
      { key: 'sorting.distance', value: 'distance' },
      { key: 'sorting.created_at', value: 'createdAt' },
      { key: 'sorting.updated_at', value: 'updatedAt' },
      { key: 'sorting.product', value: 'product' },
      { key: 'sorting.relation', value: 'relation' },
    ];
  }

  showAmountUnitSuffix(dossier: Dossier): boolean {
    return this.dossierService.showAmountUnitSuffix(dossier);
  }

  showMap(): void {
    const props = {
      dossiers: this.dossierList$.value,
    };

    const modal = this.modalService.createModal(DossierMapComponent, props);
  }

  hasLocationEnabled(): Observable<boolean> {
    return this.locationService.hasLocationEnabled().pipe(
      map((coords) => {
        return !coords;
      }),
    );
  }
}
