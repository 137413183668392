<ng-container *ngIf="addRelationShown$ | async; let addRelationShown; else: addRelation">
  <div class="relation-create" *ngIf="addRelationShown">
    <ng-container *transloco="let t">
      <form #form="ngForm" [formGroup]="relationForm" (ngSubmit)="handleSubmit()" autocomplete="off">
        <div class="flex-1-1">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.relations.create.company_name_label') }}
              <span class="required" *ngIf="isRequiredField('companyName') | async">*</span>
            </mat-label>
            <input type="text" matInput formControlName="companyName" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.companyName.touched) && controls.companyName.invalid">
            <div class="form-error" *ngIf="controls.companyName.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.companyName.errors.maxlength">
              <span>{{ 'form_errors.max_length' | transloco: { max: 255 } }}</span>
            </div>
          </div>
        </div>
        <div class="flex-2-3">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.relations.create.street_label') }}
              <span class="required" *ngIf="isRequiredField('street') | async">*</span>
            </mat-label>
            <input type="text" matInput formControlName="street" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.street.touched) && controls.street.invalid">
            <div class="form-error" *ngIf="controls.street.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.street.errors.maxlength">
              <span>{{ 'form_errors.max_length' | transloco: { max: 255 } }}</span>
            </div>
          </div>
        </div>
        <div class="flex-1-3">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.relations.create.house_number_label') }}
              <span class="required" *ngIf="isRequiredField('houseNumber') | async">*</span>
            </mat-label>
            <input type="text" matInput formControlName="houseNumber" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.houseNumber.touched) && controls.houseNumber.invalid">
            <div class="form-error" *ngIf="controls.houseNumber.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </div>
        <div class="flex-1-2">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.relations.create.zipcode_label') }}
              <span class="required" *ngIf="isRequiredField('zipcode') | async">*</span>
            </mat-label>
            <input type="text" matInput formControlName="zipcode" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.zipcode.touched) && controls.zipcode.invalid">
            <div class="form-error" *ngIf="controls.zipcode.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </div>
        <div class="flex-1-2">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.relations.create.city_label') }}
              <span class="required" *ngIf="isRequiredField('city') | async">*</span>
            </mat-label>
            <input type="text" matInput formControlName="city" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.city.touched) && controls.city.invalid">
            <div class="form-error" *ngIf="controls.city.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.city.errors.maxlength">
              <span>{{ 'form_errors.max_length' | transloco: { max: 255 } }}</span>
            </div>
          </div>
        </div>
        <div class="flex-1-1">
          <ppa-select
            [items]="countries$ | async"
            bindLabel="name"
            bindValue="shortName"
            label="modules.relations.create.country"
            formControlName="country"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.country.touched) && controls.country.invalid">
            <div class="form-error" *ngIf="controls.country.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </div>
        <div class="flex-1-1">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.relations.create.phonenumber_label') }}
              <span class="required" *ngIf="isRequiredField('phoneNumber') | async">*</span>
            </mat-label>
            <input type="text" inputmode="decimal" matInput formControlName="phoneNumber" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.phoneNumber.touched) && controls.phoneNumber.invalid">
            <div class="form-error" *ngIf="controls.phoneNumber.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </div>

        <button
          pre-icon
          mat-raised-button
          data-cy="button-wizard-add-relation-shown"
          color="primary"
          [disabled]="form.invalid"
        >
          <i class="uil uil-check"></i>
          {{ t('modules.relations.buttons.add') }}
        </button>
      </form>
    </ng-container>
  </div>

  <button
    id="add-relation-button"
    data-cy="button-wizard-add-relation"
    pre-icon
    type="button"
    mat-flat-button
    (click)="toggleAddRelationPanel()"
  >
    <i class="uil uil-search"></i>
    {{ 'modules.relations.buttons.select' | transloco }}
  </button>
</ng-container>

<ng-template #addRelation>
  <button
    id="add-relation-button"
    data-cy="button-wizard-add-relation"
    pre-icon
    type="button"
    mat-flat-button
    (click)="toggleAddRelationPanel()"
  >
    <i class="uil uil-plus"></i>
    {{ 'modules.relations.buttons.add' | transloco }}
  </button>
</ng-template>
