import { OrderCardComponent } from './order-card.component';
import { RelationStatusModule } from '../relation-status';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '../../layout.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusModule } from '../status';

@NgModule({
  declarations: [OrderCardComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslocoModule,
    RelationStatusModule,
    LayoutModule,
    MatTooltipModule,
    StatusModule,
  ],
  exports: [OrderCardComponent],
})
export class OrderCardModule {}
