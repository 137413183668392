import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { Dossier, Order, OrderDataService, PriceUnitIdentifier } from '@ppa/data';

@Component({
  selector: 'ppa-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCardComponent {
  @Input() orderCardInfo: Order | Dossier;

  @Input() cardType = 'overview';
  @Input() price: string; // TODO implement price PPA-262
  @Input() phone: boolean; // Show phone
  @Input() status = '';
  @Input() statusNoSuffix = false;
  @Input() expiresAtDate = false;
  @Input() showDetailButton = false;
  @Input() showDetailTitle = false;
  @Input() showExtraDetailButton = false;
  @Input() detailButtonLabel = 'modules.order.card.view_detail';
  @Input() extraDetailButtonLabel = 'modules.order.card.view_detail';
  @Input() actionCard = false;
  @Input() actionIcon = 'uil-angle-right';
  @Input() class = '';
  @Input() preIcon: string;
  @Input() id = '';
  @Input() statusIcon = '';

  @Output() viewDetailClicked = new EventEmitter<Order | Dossier>();
  @Output() viewExtraDetailClicked = new EventEmitter<Order | Dossier>();

  readonly orderDataService: OrderDataService;

  constructor(orderDataService: OrderDataService) {
    this.orderDataService = orderDataService;
  }

  showDetailButtons() {
    return this.showExtraDetailButton || this.showDetailButton;
  }

  checkDateTimeBeforeNow(date: string): boolean {
    return date ? new Date(date) < new Date() : false;
  }

  viewDetail() {
    this.viewDetailClicked.emit(this.orderCardInfo);
  }

  viewExtraDetail() {
    this.viewExtraDetailClicked.emit(this.orderCardInfo);
  }

  percentFulfilled(fulfilled: number, amount: number): string {
    return this.orderDataService.percentFulfilled(fulfilled, amount);
  }

  showAmountUnitSuffix(order: Order): boolean {
    return this.orderDataService.showAmountUnitSuffix(order);
  }
}
