import { Injectable } from '@angular/core';
import { Unit, UnitDataService, PriceUnitIdentifier } from '@ppa/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  constructor(private unitDataService: UnitDataService) {}
  static readonly EXCLUDE_AMOUNT = 'exclude_amount';
  static readonly EXCLUDE_PRICE = 'exclude_price';
  static readonly EXCLUDE_YIELD = 'exclude_yield';

  /**
   * @description Use localTitle instead of title
   */
  getPriceUnits(): Observable<Unit[]> {
    return this.getUnitOptions(UnitService.EXCLUDE_PRICE).pipe(
      map((units) => {
        return units.map((unit) => {
          let localTitle = `${unit.prefix} ${unit.title}`;
          if (unit.prefix === 'Per eenheid') {
            localTitle = unit.prefix;
          }
          return { ...unit, localTitle };
        });
      }),
    );
  }

  getAmountUnits(): Observable<Unit[]> {
    return this.getUnitOptions(UnitService.EXCLUDE_AMOUNT);
  }

  getYieldUnits(): Observable<Unit[]> {
    return this.getUnitOptions(UnitService.EXCLUDE_YIELD);
  }

  getUnitOptions(exclude?: string): Observable<Unit[]> {
    return this.unitDataService.listWithout(exclude);
  }

  toKg(priceUnit: Unit, input: number) {
    if (priceUnit.divisor === 0) {
      return input;
    }
    return input / priceUnit.divisor;
  }
  //   Old code only worked for 100kg prices now it works for all types of prices
  //   switch (priceUnit.identifier) {
  //     case PriceUnitIdentifier.Price_unit_per_100_kg:
  //       return this.hundredKgToKg(input);
  //   }
  // }
  //
  // /**
  //  * 100kg per kg
  //  */
  // private hundredKgToKg(input: number) {
  //   return input / 100;
  // }
}
