import { Delivery } from './delivery';
import { Model } from './model';
import { Order } from './order';
import { Contract } from './contract';
import { DeliveryReport } from './delivery-report';
import { Invoice } from './invoice';
import {PoolProvisionalStatement} from "./pool-provisional-statement";

export interface OrderMatch extends Model {
  amount: number;
  originalAmount: number;
  order: Order;
  matchedOrder: Order;
  currentPlace?: string;
  contracts: Contract[];
  disabled: boolean;
  deliveries: Delivery[];
  deliveryReports?: DeliveryReport[];
  invoices?: Invoice[];
  contractAmount?: number;
  poolProvisionalStatements?: PoolProvisionalStatement[];
}

export enum DeliveryNumberOfLoads {
  Estimation = 'estimation',
  Exact = 'exact',
}

export enum DeliveryRequestPer {
  Directly = 'directly',
  EndOfTheDay = 'end_of_the_day',
  OnDate = 'on_date',
}

export enum DeliveryRequestAt {
  Buyer = 'buyer',
  Seller = 'seller',
  Weighbridge = 'weighbridge',
  Unknown = 'unknown',
}

export enum DeliveryWeighingCostsFor {
  Buyer = 'buyer',
  Seller = 'seller',
}
