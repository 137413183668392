import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoResultComponent } from './no-result.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [NoResultComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [NoResultComponent],
})
export class NoResultModule {}
