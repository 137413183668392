import { Injectable } from '@angular/core';
import { Delivery, OrderMatch, OrderMatchDataService } from '@ppa/data';

@Injectable({
  providedIn: 'root',
})
export class OrderMatchService {
  constructor(private orderMatchDataService: OrderMatchDataService) {}

  storeDeliveries(id: number, deliveries: Pick<OrderMatch, 'deliveries'>) {
    return this.orderMatchDataService.storeDeliveries(id, deliveries);
  }

  deliveryInfoToProcess(id: number, delivery: Partial<Delivery>) {
    return this.orderMatchDataService.deliveryInfoToProcess(id, delivery);
  }

  deliveryDelivered(id: number, delivery: Partial<Delivery>) {
    return this.orderMatchDataService.deliveryDelivered(id, delivery);
  }

  sendWeightRequests(orderMatch: OrderMatch, messages: any[]) {
    return this.orderMatchDataService.sendWeightRequests(orderMatch, messages);
  }

  downloadInvoice(orderMatch: OrderMatch) {
    return this.orderMatchDataService.downloadInvoice(orderMatch);
  }

  sendInvoice(orderMatch: OrderMatch, data: any[]) {
    return this.orderMatchDataService.sendInvoice(orderMatch, data);
  }

  getOrderMatch(id: number) {
    return this.orderMatchDataService.read(id);
  }
}
