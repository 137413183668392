import { Model } from '@ppa/data';

export interface Config extends Model {
  key: string;
  value: string;
}

export enum ConfigConvertType {
  None = 'none',
  Number = 'number',
  Float = 'float',
}
