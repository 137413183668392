<div class="ppa-modal">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h2>{{ 'modules.location.modal.title' | transloco }}</h2>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <ppa-select-location-map
      [marker]="marker"
      [address]="address"
      [relationLocation]="relationLocation$ | async"
      (locationSelected)="locationPicked($event)"
      [useCurrentLocationOnLoad]="useCurrentLocationOnLoad"
      [editable]="editable"
      class="mb-1"
    ></ppa-select-location-map>

    <button
      *ngIf="canShare"
      (click)="shareLocation()"
      mat-flat-button
      color="primary"
      class="button mat-flat-button mb-1"
    >
      <i class="uil uil-share"></i>
      {{ 'modules.location.modal.share_location' | transloco }}
    </button>

    <a [href]="locationMapsUrl" *ngIf="address" color="default" class="button mat-flat-button mb-1" target="_blank">
      <i class="uil uil-map"></i>
      {{ 'modules.location.modal.open_location_apple' | transloco }}
    </a>

    <a [href]="locationGoogleUrl" *ngIf="address" color="default" class="button mat-flat-button mb-1" target="_blank">
      <i class="uil uil-map"></i>
      {{ 'modules.location.modal.open_location_google' | transloco }}
    </a>
  </div>
  <div class="ppa-modal__action">
    <button
      *ngIf="editable"
      mat-flat-button
      color="primary"
      class="button--full-width"
      pre-icon
      (click)="saveLocation()"
    >
      <i class="uil uil-save"></i>
      {{ 'modules.location.modal.save_location_button' | transloco }}
    </button>
    <button *ngIf="!editable" mat-flat-button color="primary" class="button--full-width" (click)="close()">
      {{ 'modules.location.modal.close_location_button' | transloco }}
    </button>
  </div>
</div>

<div class="ppa-modal__fader"></div>
