import { Inject, Injectable } from '@angular/core';
import { API_CONFIG, ApiConfig, DownloadFileService } from '@ppa/data';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(protected http: HttpClient, @Inject(API_CONFIG) public apiConfig: ApiConfig) {}

  downloadFile(attachment: any): Observable<HttpResponse<Blob>> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/file/${attachment.id}/download`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  downloadBlob(attachment: any) {
    const blob = DownloadFileService.getBlob(attachment);
    const headers = DownloadFileService.getHeaders(attachment);

    DownloadFileService.downloadAndOpenFile(blob, headers);
  }
}
