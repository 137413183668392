<div class="contract-overview-card card card--elevate-1" [attr.data-cy]="cyIdentifier">
  <div class="card__heading">
    <div>
      <h4 class="card__title">
        {{ config?.cardInfo.title }}
        <ppa-relation-status [label]="config?.cardInfo?.order?.relation?.status"></ppa-relation-status>
      </h4>
    </div>
    <div class="card__badge">
      <span>{{ config?.cardInfo.order?.contractTemplate?.title }}</span>
    </div>
  </div>
  <ng-content></ng-content>
  <div class="card__buttons">
    <ng-container
      *ngTemplateOutlet="(showLoader$ | async) ? loading : buttons; context: { $implicit: config }"
    ></ng-container>
  </div>
</div>

<ng-template #buttons let-config>
  <button
    mat-flat-button
    *ngFor="let button of config?.buttons"
    (click)="button.action(config?.cardInfo)"
    [disabled]="!button.enabled"
    [color]="button.color"
    coloroverwrite
    [attr.data-cy]="button?.cyIdentifier"
  >
    <i *ngIf="button.icon?.left" [class]="'uil ' + button.icon.left"></i>
    {{ 'modules.contract.buttons.' + button.title | transloco }}
    <i *ngIf="button.icon?.right" [class]="'uil ' + button.icon.right"></i>
  </button>
</ng-template>

<ng-template #loading>
  <ppa-loading [class]="'loading--inline'" [noPadding]="true"></ppa-loading>
</ng-template>
