<div class="ppa-modal ppa-modal--confirm">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h3>{{ 'modules.contract.send.modal.title' | transloco }}</h3>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <div class="ppa-modal__intro mb-1">{{ 'modules.contract.send.modal.intro' | transloco }}</div>

    <div class="form-warning form-warning--skip-before" *ngIf="relationChangedAfterContract">
      {{ 'modules.contract.send.relation_changed' | transloco }}
    </div>

    <form #form="ngForm" [formGroup]="sendContractForm">
      <div formArrayName="messages">
        <ng-container *ngFor="let control of messages.controls; let i = index">
          <h3 class="subheading pb-1">{{ attentionOf[i].companyName }}</h3>

          <ppa-mail-header [form]="form" [formChecked]="formChecked" [formGroup]="control" [relation]="attentionOf[i]">
          </ppa-mail-header>

          <mat-form-field appearance="fill" [formGroupName]="i">
            <mat-label>{{ 'modules.contract.send.modal.message' | transloco }}</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              formControlName="message"
              rows="4"
              [attr.data-cy]="cyIdentifiers[i]"
            ></textarea>
          </mat-form-field>
          <div *ngIf="(form.submitted || control.get('message').touched) && control.get('message').invalid">
            <div class="form-error" *ngIf="control.get('message').errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>
      </div>

      <button
        mat-raised-button
        class="button--full-width"
        color="primary"
        data-cy="button-contract-sent-with-messages"
        (click)="send()"
      >
        {{ 'modules.contract.send.modal.button_send' | transloco }}
      </button>
    </form>
  </div>
</div>

<div class="ppa-modal__fader"></div>
