import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ListResponse, Product, ProductVariety } from '../contracts';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductVarietyDataService extends BaseService<ProductVariety> {
  path = 'product_varieties';

  public getProductVarietiesByProduct(product: Product): Observable<ProductVariety[]> {
    return this.http.get<ListResponse<ProductVariety>>(`${this.apiConfig.baseUrl}/${this.path}/product/${product.id}`);
  }
}
