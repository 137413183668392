<div class="ppa-modal" [class.ppa-modal--nested]="nested">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <ng-container *ngIf="relationType !== null; else defaultTitle">
      <h2>
        {{ 'modules.relations.modal.title-type' | transloco: { type: '' } }}
        {{ 'modules.delivery.delivered.weighbridge_type_options.' + relationType | transloco }}
      </h2>
    </ng-container>
    <ng-template #defaultTitle>
      <h2 *ngIf="showAddRelation === false">{{ 'modules.relations.modal.title' | transloco }}</h2>
      <h2 *ngIf="showAddRelation !== false">{{ 'modules.relations.modal.title-create' | transloco }}</h2>
    </ng-template>
  </div>

  <ng-container *ngIf="showAddRelation === false">
    <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
      <ppa-select-relation
        [relationType]="relationType"
        [hideBlocked]="hideBlocked"
        (selectRelation)="handleRelationSelected($event)"
      ></ppa-select-relation>
    </div>
  </ng-container>
  <ng-container *ngIf="relationType !== relationTypeEnum.Weighbridge && !hideAddRelation">
    <div
      class="{{
        showAddRelation ? 'ppa-modal__content ppa-modal__content--flex-direction-column' : 'ppa-modal__footer'
      }}"
    >
      <ppa-create-relation
        (toggleAddRelation)="toggleAddRelation($event)"
        (createRelation)="handleRelationCreated($event)"
        class="{{ showAddRelation ? 'grow' : '' }}"
      ></ppa-create-relation>
    </div>
  </ng-container>
</div>

<div class="ppa-modal__fader" [class.ppa-modal__fader--nested]="nested"></div>
