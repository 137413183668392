<div
  [class]="'action-card ' + cardClass"
  [class.action-card--disabled]="disabled"
  [class.action-card--mb]="addMarginBottom"
  [style.--backgroundColor]="backgroundColor"
>
  <div
    class="action-card__icon"
    *ngIf="preIcon"
    [style.--iconBackgroundColor]="iconBackgroundColor"
    [style.--iconTextColor]="iconTextColor"
    [class.action-card__icon--small]="iconSize === 'small'"
  >
    <i [class]="'uil ' + icon"></i>
  </div>
  <div
    class="action-card__content"
    [class.action-card__content--padding-left]="preIcon"
    (click)="handleContentClick()"
    [style.--textColor]="textColor"
  >
    <ng-content></ng-content>
  </div>
  <div class="action-card__action" (click)="handleActionClick()">
    <i data-cy="action-card-button" [class]="'uil ' + actionIcon"></i>
  </div>
  <div *ngIf="clearable" class="action-card__action" (click)="handleClearClick()">
    <i data-cy="action-card-button" class="uil uil-times"></i>
  </div>
</div>
