import { HeaderData, HeaderAction } from './../../contracts/header';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: 'ppa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() data: HeaderData;

  @Input() floating = false;
  @Input() hasFilter = false;
  @Input() class = '';

  @Output() toggleNoRedirect = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  emitAction(action: HeaderAction) {
    if (action.actionClick$) {
      action.actionClick$.next();
    }
  }

  handleToggleNoRedirect(event: MatSlideToggleChange) {
    this.toggleNoRedirect.emit(event.checked);
  }
}
