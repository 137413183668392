import { Intention } from './../../../../layout/src/lib/contracts/intention';
import { PPAToastrService } from './../../../../layout/src/lib/services/toastr.service';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TokenResponse, LocalStorageKey } from '../contracts';
import { ApiConfig } from '../contracts/api-config';
import { LocalStorageService } from './local-storage.service';
import { API_CONFIG } from './token';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '../contracts/role';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    @Inject(API_CONFIG) public apiConfig: ApiConfig,
    private router: Router,
    private translate: TranslocoService,
    private toastr: PPAToastrService,
    private storage: LocalStorageService,
  ) {}

  login(email: string, password: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.apiConfig.baseUrl}/auth/login`, { email, password });
  }

  loginLink(email: string, website: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.apiConfig.baseUrl}/auth/login-link`, { email, website });
  }

  loginByHash(id: number, hash: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.apiConfig.baseUrl}/auth/login-check`, { id, hash });
  }

  isLoggedIn(): boolean {
    return this.storage.exists(LocalStorageKey.AccessToken);
  }

  logout(): void {
    this.storage.removeItem(LocalStorageKey.AccessToken);
  }

  getToken(): TokenResponse {
    return this.storage.getItem<TokenResponse>(LocalStorageKey.AccessToken);
  }

  getUserIdFromToken(): number {
    const helper = new JwtHelperService();
    return helper.decodeToken(this.getToken().token).userId;
  }

  getRoleFromToken(): string {
    const helper = new JwtHelperService();
    return this.getToken() ? helper.decodeToken(this.getToken().token).role : null;
  }

  isExternal(): boolean {
    const role = this.getRoleFromToken();
    return role === Role.EXTERNAL;
  }

  isMediator(): boolean {
    const role = this.getRoleFromToken();
    return role === Role.MEDIATOR;
  }

  isAdministrator(): boolean {
    const role = this.getRoleFromToken();
    return role === Role.ADMINISTRATOR || role === Role.GLOBAL_ADMINISTRATOR || role === Role.WEBNL;
  }

  isGlobalAdministrator(): boolean {
    const role = this.getRoleFromToken();
    return role === Role.GLOBAL_ADMINISTRATOR || role === Role.WEBNL;
  }

  isWebNL(): boolean {
    const role = this.getRoleFromToken();
    return role === Role.WEBNL;
  }

  redirectOnFailedLogin(): void {
    this.router.navigate(['/auth/login']).then(() => {
      window.location.reload();
    });

    const errorTranslationKey = 'modules.auth.guard.unauthorized';
    this.translate.selectTranslate(errorTranslationKey).subscribe((message) => {
      this.toastr.displayToastr({
        title: message,
        icon: 'uil-exclamation-triangle',
        intention: Intention.Error,
        duration: 3000,
        fullWidth: true,
        identifier: errorTranslationKey,
      });
    });
  }

  componentOrModuleAllowed(name, role?: any): boolean {
    let valid = true;

    if (role === undefined) {
      role = this.getRoleFromToken();
    }

    if (role === Role.EXTERNAL) {
      const allowedModules = ['dossier', 'search'];

      if (name.indexOf('Dossier') === -1 && name.indexOf('Search') === -1 && allowedModules.indexOf(name) === -1) {
        valid = false;
      }
    }

    return valid;
  }
}
