import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from './token';
import { ApiConfig } from '../contracts/api-config';
import { Observable } from 'rxjs';
import { ListResponse } from '../contracts/list-response';
import { NewsletterTag } from '../contracts';

@Injectable({
  providedIn: 'root',
})
export class NewsLetterTagDataService {
  protected path = 'newsletter_tags';

  protected constructor(protected http: HttpClient, @Inject(API_CONFIG) public apiConfig: ApiConfig) {}

  list(): Observable<ListResponse<NewsletterTag>> {
    return this.http.get<ListResponse<NewsletterTag>>(`${this.apiConfig.baseUrl}/${this.path}`);
  }

  create(newsletterTag: Partial<NewsletterTag>) {
    return this.http.post<NewsletterTag>(`${this.apiConfig.baseUrl}/${this.path}`, newsletterTag);
  }
}
