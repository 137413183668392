import { Modal } from '@ppa/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Photo } from '@ppa/data';

@Component({
  selector: 'ppa-photo-modal',
  templateUrl: './add-photo-modal.component.html',
  styleUrls: ['./add-photo-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPhotoModalComponent extends Modal {
  localPhotos: Photo[] = [];

  handleUpdate(localPhotos: Photo[]) {
    this.localPhotos = localPhotos;
  }

  close() {
    this.save(this.localPhotos);
  }
}
