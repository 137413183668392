import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TreatmentDataService } from './treatment-data.service';
import { PaginatedResponse, PaginationOptions, Treatment } from '../contracts';

@Injectable({
  providedIn: 'root',
})
export class TreatmentService {
  constructor(private treatmentDataService: TreatmentDataService) {}

  /**
   * For Ordermatch filter
   */
  getTreatmentOptions(): Observable<Treatment[]> {
    return this.treatmentDataService.listTreatmentsAll();
  }

  /**
   * Backoffice
   * @param options
   */
  getAllTreatments(options: PaginationOptions): Observable<PaginatedResponse<Treatment>> {
    return this.treatmentDataService.listPaginated(options);
  }

  getTreatmentById(id: number): Observable<Treatment> {
    return this.treatmentDataService.read(id);
  }

  updateTreatment(treatment: Treatment): Observable<Treatment> {
    return this.treatmentDataService.update(treatment);
  }
}
