import { Model, OrderMatch, PoolAdvanceEntry } from '.';

export interface PoolAdvance extends Model {
  orderMatch: OrderMatch;
  poolAdvanceEntry: PoolAdvanceEntry;
  companyName?: string;
  street?: string;
  housNumber?: string;
  zipCode?: string;
  city?: string;
  vatNumber?: string;
  afasDebtorId?: string;
  advanceReferenceNumber?: string;
  date?: Date;
  contractReferenceNumber?: string;
  product?: string;
  productVariety?: string;
  certification?: string;
  color?: string;
  comment?: string;
  previousSettlementFromProvisionalStatements?: number;
  poolAdvanceSentAt?: Date;
  iban?: string;
  poolAdvanceRows?: PoolAdvanceRow[];
}

export interface PoolAdvanceRow extends Model {
  poolAdvance?: PoolAdvance;
  description?: string;
  deliveryWeek?: string;
  amount?: number;
  price?: number;
  vatCodePercentage?: number;
  vatTotal?: number;
  netTotal?: number;
  deliveryReportReferenceNumber?: string;
  poolAdvanceEntrySerialNumber?: number;
  type?: PoolAdvanceRowType;
}

export enum PoolAdvanceRowType {
  Manual = 'manual',
  DeliveryReport = 'delivery_report',
}
