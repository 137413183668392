<div class="container container--flex-direction-column container--bg-light" *transloco="let t">
  <ppa-header class="mb-1" [data]="headerData"></ppa-header>

  <div class="heading">
    <h1 class="subheading-enabled">{{ t('modules.contract.heading.title') }}</h1>
    <h4 class="subheading">{{ t('modules.contract.heading.subtitle') }}</h4>
  </div>

  <div class="details" *ngIf="groupedOverview$ && order">
    <dl>
      <dt>{{ t('modules.order.detail.summary.relation') }}</dt>
      <dd>
        {{ order.relation.companyName }} <ppa-relation-status [label]="order.relation.status"></ppa-relation-status>
      </dd>
      <dt>{{ t('modules.order.detail.summary.order_type') }}</dt>
      <dd>
        <i class="uil uil-arrow-to-bottom icon-green" *ngIf="order.orderType === OrderType.Buy"></i>
        <i class="uil uil-top-arrow-from-top icon-blue" *ngIf="order.orderType === OrderType.Sell"></i>
        {{ t('modules.order.detail.order_type.' + order.orderType) }}
      </dd>
      <dt>{{ t('modules.order.detail.summary.product_and_product_variety') }}</dt>
      <dd data-cy="order-detail-product-variety">
        {{ order.product.title }}{{ order.productVariety ? ',' : '' }} {{ order.productVariety?.title }}
      </dd>

      <ng-container *ngIf="hasContractTypeAndTemplate">
        <dt>{{ t('modules.order.detail.summary.contract_type') }}</dt>
        <dd data-cy="order-detail-contract-type">
          {{ t(selectedContractTypeObject.label) }}
        </dd>

        <ng-container *ngIf="isPool && order.pool">
          <dt>{{ t('modules.order.detail.summary.pool') }}</dt>
          <dd data-cy="order-detail-contract-type">
            {{ order.pool.name }}
          </dd>
        </ng-container>
      </ng-container>
    </dl>
  </div>

  <div class="contracttype">
    <ng-container *ngIf="!hasSentContracts">
      <button (click)="contractType(order, hasContractTypeAndTemplate)" mat-raised-button type="button" color="primary">
        {{ t('modules.contract.buttons.save_contract_type') }}
      </button>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="createPurchaseContract">
  <div class="container container--flex-direction-column" *transloco="let t">
    <button
      (click)="handlePurchaseContractCreate(order)"
      mat-raised-button
      type="button"
      class="button--full-width"
      color="primary"
      data-cy="button-contract-create-pre-match"
    >
      <i class="uil-plus"></i>
      {{ t('modules.contract.buttons.create_contract_for_trade.' + order.orderType, { relation: order.relation.companyName}) }}
    </button>
  </div>
</ng-container>

<div class="container container--flex-direction-column" *transloco="let t">
  <ng-container *ngIf="isCommission && hasMatch === false && createPurchaseContract === false && hasContractTypeAndTemplate">
    {{ t('modules.contract.overview.no_matches') }}
  </ng-container>
  <ng-container *ngIf="groupedOverview$ | async as groupedOverview; else loading">
    <div *ngFor="let group of groupedOverview | overviewCardGroup" [attr.data-cy]="group.key + '_container'">
      <h3>{{ 'modules.contract.headers.' + group.key | transloco }}</h3>
      <ppa-contract-overview-card *ngFor="let card of group.value" [config]="card" [cyIdentifier]="group.key + '_card'">
        <div class="card-info">
          <div class="card-info__row">
            <span>{{ card.cardInfo.address }}</span>
          </div>
          <div class="card-info__row">
            <span>{{ card.cardInfo.amount | number }} {{ t('general.ton') }} </span>
            <span>{{ card.cardInfo.product }}</span>
            <span *ngIf="card.cardInfo.productVariety">, </span>
            <span>{{ card.cardInfo.productVariety }}</span>
          </div>

          <ng-container *ngIf="card.cardInfo.currentContract && !card.cardInfo.currentContract?.cancelReason">
            <div class="card-info__row">
              <span>{{ card.cardInfo.currentContract.referenceNumber }}</span>
            </div>
            <div *ngIf="card.cardInfo.currentContract.ownNumberingForRelation" class="card-info__row">
              <span>{{ card.cardInfo.currentContract.ownNumberingForRelation }}</span>
            </div>
            <div class="card-info__row">
              <span *ngIf="card.cardInfo.currentContract.contractSentAt" class="primary"
                >{{ t('modules.contract.overview.contractSentAt') }}:
                {{ card.cardInfo.currentContract.contractSentAt | date: 'dd-MM-YYYY HH:mm:ss' }}</span
              >
              <span *ngIf="!card.cardInfo.currentContract.contractSentAt" class="warn">{{
                t('modules.contract.overview.notSent')
              }}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="card.cardInfo.currentContract?.cancelReason">
            <div class="card-info__row">
              <span class="warn"
                >{{ t('modules.contract.history.modal.canceledAt') }}:
                {{ card.cardInfo.currentContract.canceledAt | date: 'dd-MM-YYYY HH:mm:ss' }}</span
              >
            </div>
            <div class="card-info__row">
              <span
                >{{ t('modules.contract.history.modal.cancelReason') }}:
                {{
                  card.cardInfo.currentContract.cancelReason === cancelContractOther
                    ? card.cardInfo.currentContract.cancelReasonOther
                    : t('modules.contract.cancel.modal.reason_options.' + card.cardInfo.currentContract.cancelReason)
                }}</span
              >
            </div>
          </ng-container>
        </div>
      </ppa-contract-overview-card>
    </div>
  </ng-container>
</div>

<div class="background"></div>

<ng-template #loading>
  <ppa-loading></ppa-loading>
</ng-template>
