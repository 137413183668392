import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { ErrorHandler, NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialExtensionsExperimentalModule, MaterialExtensionsModule } from '@ng-matero/extensions';
import { ApiInterceptor, API_CONFIG, JwtInterceptor, ConfigService, ConfigDataService, ENV } from '@ppa/data';
import { ActionCardModule, CertificateCheckboxModule, DebugModule, MenuModule, SpinnerModule } from '@ppa/layout';
import { ToastrModule } from 'ngx-toastr';
import { NgxWigModule } from 'ngx-wig';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ContractModule } from './modules/contract/contract.module';
import { DossierModule } from './modules/dossier/dossier.module';
import { getErrorHandler } from './sentry/sentry-error-handler';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { SearchComponent } from './components/search/search.component';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { PoolModule } from './modules/pool/pool.module';
import { IsMediatorDirective } from './directives/is-mediator.directive';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

registerLocaleData(localeNl);

export function appInit(config: ConfigService) {
  return () => config.load();
}

@NgModule({
  declarations: [AppComponent, MainComponent, NotFoundComponent, SearchComponent, IsMediatorDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    TranslocoMessageFormatModule.init(),
    MenuModule,
    DossierModule,
    ToastrModule.forRoot({ positionClass: 'inline' }),
    MatButtonModule,
    MatIconModule,
    ContractModule,
    ActionCardModule,
    MaterialExtensionsModule,
    MaterialExtensionsExperimentalModule,
    NgxWigModule.forRoot({
      buttonsConfig: {},
    }),
    PoolModule,
    MatPasswordStrengthModule,
    SpinnerModule,
    DebugModule,
    CertificateCheckboxModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'nl',
    },
    {
      provide: API_CONFIG,
      useValue: environment.dataLib,
    },
    {
      provide: ENV,
      useValue: environment.env,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'DD-MM-YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
  exports: [IsMediatorDirective],
})
export class AppModule {}
