import { Model } from './model';
import { Relation } from './relation';
import { DeliveryRequestPer, DeliveryWeighingCostsFor, OrderMatch } from './order-match';
import { DeliveryReport } from './delivery-report';
import {Order} from "./order";

export interface Delivery extends Model {
  deliveryRows: DeliveryRow[];
  grit?: string;
  humidity?: string;
  tare?: string;
  class?: string;
  color?: number;
  requestPer?: DeliveryRequestPer;
  requestPerOnDate?: Date;
  weighingCostsFor?: DeliveryWeighingCostsFor;
  contractCompleted?: boolean;
  comment?: string;
  hasInfoToProcess?: boolean;
  hasAllDeliveryRowsDelivered?: boolean;
  requestedAt?: Date;
  requestedAtFormatted?: string;
  deliveryReports?: DeliveryReport[];
  orderMatch?: OrderMatch;
  order?: Order;
  countDeliveryRowsDelivered?: number;
  totalAmount?: string;
  hasMissingTare?: boolean;
}

export interface DeliveryRow extends Model {
  scheduledAt?: string;
  licensePlate?: string;
  amount?: string;
  grit?: string;

  humidity?: string;

  tare?: string;
  notesInternal?: string;
  notesExternal?: string;
  weighbridgeType?: RequestAt;
  weighbridgeRelation?: Relation;
  weighbridgeUnknown?: string;
  deliveredAt?: Date;
  weighbridgeName?: string;
}

export enum RequestAt {
  Buyer = 'buyer',
  Seller = 'seller',
  Weighbridge = 'weighbridge',
  Unknown = 'unknown',
}
