import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Metadata, SortType } from '@ppa/data';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Column, ColumnAction } from '../../contracts';

@Component({
  selector: 'ppa-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  readonly ColumnMode = ColumnMode;
  readonly horizontalScrollBar: boolean = false;

  @Input() columns: Column[];
  @Input() forceActionDots = false;
  @Input() rows: any[];
  @Input() metaData: Metadata;
  @Input() sorts: { prop: string; dir: 'asc' | 'desc' };
  @Input() rowAction;
  @Input() sortable = true;

  @Output() page = new EventEmitter<{ offset: number }>();
  @Output() sort = new EventEmitter<{ column: { prop: string }; newValue: SortType }>();

  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChildren('actionButton', { read: ElementRef }) actionButtons: QueryList<ElementRef>;
  @ViewChild('translateTemplate', { static: true }) translateTemplate: TemplateRef<any>;
  @ViewChild('invoiceTypeTemplate', { static: true }) invoiceTypeTemplate: TemplateRef<any>;
  @ViewChild('currencyTemplate', { static: true }) currencyTemplate: TemplateRef<any>;
  @ViewChild('numberTemplate', { static: true }) numberTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;
  @ViewChild('phoneTemplate', { static: true }) phoneTemplate: TemplateRef<any>;
  @ViewChild('mailTemplate', { static: true }) mailTemplate: TemplateRef<any>;
  @ViewChild('datetimeTemplate', { static: true }) datetimeTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('weekTemplate', { static: true }) weekTemplate: TemplateRef<any>;
  @ViewChild('postfixTemplate', { static: true }) postfixTemplate: TemplateRef<any>;
  @ViewChild('postfixColumnTemplate', { static: true }) postfixColumnTemplate: TemplateRef<any>;
  @ViewChild('booleanTemplate', { static: true }) booleanTemplate: TemplateRef<any>;
  @ViewChild('fromObject', { static: true }) fromObject: TemplateRef<any>;

  constructor(private deviceDetectorService: DeviceDetectorService) {
    this.horizontalScrollBar = this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
  }

  ngOnInit(): void {
    this.columns.map((column) => {
      if (column.template) {
        column.cellTemplate = this.resolveColumnTemplate(column.template);
      }
      column.headerTemplate = this.headerTemplate;
      column.sortable = this.sortable;
      return column;
    });
  }

  resolveColumnTemplate(template: string) {
    switch (template) {
      case 'translate':
        return this.translateTemplate;
      case 'invoice-type':
        return this.invoiceTypeTemplate;
      case 'currency':
        return this.currencyTemplate;
      case 'number':
        return this.numberTemplate;
      case 'mail':
        return this.mailTemplate;
      case 'phone':
        return this.phoneTemplate;
      case 'action':
        return this.actionTemplate;
      case 'datetime':
        return this.datetimeTemplate;
      case 'date':
        return this.dateTemplate;
      case 'week':
        return this.weekTemplate;
      case 'postfix':
        return this.postfixTemplate;
      case 'postfix-column':
        return this.postfixColumnTemplate;
      case 'boolean':
        return this.booleanTemplate;
      case 'from-object':
        return this.fromObject;
      default:
        throw new Error('Unsupported column template option!');
    }
  }

  setPage(pageInfo: { offset: number }) {
    this.page.emit(pageInfo);
  }

  onSort(event: { column: { prop: string }; newValue: SortType }) {
    this.sort.emit(event);
  }

  blurButtons() {
    this.actionButtons.forEach((action) => action.nativeElement.blur());
  }

  getItemFromObject(key: string, objectToSearch: { key: string; value: string }[]) {
    return objectToSearch.find((item) => item.key === key)?.value;
  }

  showOnConditionTrue(action: ColumnAction, row: any) {
    if (typeof action.condition === 'undefined') {
      return true;
    } else return action.condition(row, (action.type ?? 'general'));
  }

  onActivate(event) {
    if (event.type === 'click') {
      if (event.column.template !== 'action') {
        if (typeof this.rowAction === 'function') {
          this.rowAction(event.row);
        }
      }
    } else if (event.type === 'mouseenter') {
      if (typeof this.rowAction === 'function') {
        event.rowElement.classList.add('datatable-row-has-action');
      }
    }
  }

  getBoolean(value): boolean {
    switch (value) {
      case true:
      case 'true':
      case 1:
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  }
}
