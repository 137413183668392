import { Address } from '../../../../services/location.service';
import { Component, OnInit } from '@angular/core';
import { AbstractWizardStepComponent } from '@ppa/layout';
import { DossierService } from '../../services/dossier.service';
import { take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'ppa-dossier-wizard-select-location',
  templateUrl: './dossier-wizard-select-location.component.html',
  styleUrls: ['./dossier-wizard-select-location.component.scss'],
})
export class DossierWizardSelectLocationComponent extends AbstractWizardStepComponent implements OnInit {
  address: Address;
  marker: [number, number];
  relationLocation: [number, number];

  dossierSectionForm: FormGroup;

  constructor(private dossierService: DossierService, private fb: FormBuilder) {
    super();
    this.dossierSectionForm = this.fb.group({
      location: [],
      locationNote: [null],
    });
  }

  ngOnInit() {
    // Left it here intentionally so you can see how it works
    this.dossierService
      .getDossierWizardState()
      .pipe(take(1))
      .subscribe((data) => {
        if (data.location) {
          const [address, marker] = data.location;
          this.marker = marker;
          this.address = address;
        }

        if (data.relation) {
          this.relationLocation = [data.relation.longitude, data.relation.latitude];
        }
      });

    this.dossierSectionForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleFormChanges.bind(this));
  }

  handleUnload() {
    super.handleUnload();

    this.dossierService.clearDossierWizardState();
  }

  handleFormChanges() {
    this.dossierService.setDossierWizardState(this.dossierSectionForm.value);
  }

  locationPicked(location: [Address, [number, number]]) {
    const [, marker] = location;
    this.dossierSectionForm.controls.location.patchValue(location);

    this.dirty$.next(!!marker);
  }
}
