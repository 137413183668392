import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListResponse } from '../contracts/list-response';
import { ReadResponse } from '../contracts/read-response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Model } from '../contracts/model';
import { ApiConfig } from '../contracts/api-config';
import { API_CONFIG } from './token';
import { PaginatedResponse } from '../contracts/paginated-response';
import { PaginationOptions } from '../contracts';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService<T extends Model> {
  abstract path: string;

  protected constructor(protected http: HttpClient, @Inject(API_CONFIG) public apiConfig: ApiConfig) {}

  list(params?: { [key: string]: any }): Observable<ListResponse<T>> {
    return this.http.get<ListResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}`, { params });
  }

  listPaged(params?: { [key: string]: any }): Observable<PaginatedResponse<T>> {
    return this.http.get<PaginatedResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}`, { params });
  }

  listForFilter(params?: { [key: string]: any }): Observable<ListResponse<T>> {
    return this.http.get<ListResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}/for-filter`, { params });
  }

  listPaginated(options: PaginationOptions): Observable<PaginatedResponse<T>> {
    const params = new HttpParams({
      fromObject: {
        ...(options.limit && { limit: options.limit.toString() }),
        ...(options.offset && { offset: options.offset.toString() }),
        ...(options.query && { query: options.query }),
        ...(options.sortColumn && { sortColumn: options.sortColumn }),
        ...(options.sortType && { sortType: options.sortType }),
        ...(options.relationId && { relationId: options.relationId.toString() }),
        ...(options.latitude && { latitude: options.latitude.toString() }),
        ...(options.longitude && { longitude: options.longitude.toString() }),
        ...(options.filter && { filter: JSON.stringify(options.filter) }),
        ...(options.relationType && { relationType: options.relationType }),
        ...(options.hideBlocked && { hideBlocked: options.hideBlocked.toString() }),
      },
    });

    return this.http.get<PaginatedResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}`, { params });
  }

  create(data: T): Observable<ReadResponse<T>> {
    data = this.transformData(data);
    return this.http.post<ReadResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}`, data);
  }

  read(id: number): Observable<ReadResponse<T>> {
    return this.http.get<ReadResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}/${id}`);
  }

  update(data: T): Observable<ReadResponse<T>> {
    data = this.transformData(data);
    return this.http.put<ReadResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}/${data.id}`, data);
  }

  duplicate(data: T, original_id: number): Observable<ReadResponse<T>> {
    data = this.transformData(data);
    return this.http.post<ReadResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}/duplicate/${original_id}`, data);
  }

  private transformData(data: T) {
    const dataCopy = JSON.parse(JSON.stringify(data));

    Object.keys(dataCopy).forEach((key) => {
      let obj = data[key];
      if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
        if (Object.keys(obj).some((foundKey) => foundKey === 'id')) {
          Object.keys(obj).forEach((childKey) => {
            if (childKey !== 'id') {
              delete obj[childKey];
            }
          });
        }
      }
    });
    return dataCopy;
  }

  delete(data: T): Observable<ReadResponse<T>> {
    return this.http.delete<ReadResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}/${data.id}`);
  }

  updateProperties(data: { id: any }): Observable<ReadResponse<T>> {
    return this.http.put<ReadResponse<T>>(`${this.apiConfig.baseUrl}/${this.path}/properties/${data.id}`, data);
  }
}
