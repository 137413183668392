import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import {
  ListResponse, OrderMatch,
  OrderType,
  PaginatedResponse,
  Pool, PoolAdvance,
  PoolAdvanceEntry,
  PoolBuyListResponse,
  PoolPricePerWeek,
  PoolProvisionalStatement,
  PoolProvisionalStatementRow,
  PoolSellListResponse,
} from '../contracts';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadFileService } from './download-file.service';
import { HttpResponse } from '@angular/common/http';
import { ReadResponse } from "../contracts/read-response";

@Injectable({
  providedIn: 'root',
})
export class PoolDataService extends BaseService<Pool> {
  path = 'pools';

  getPricePerWeeks(pool_id: number) {
    return this.http.get<PoolPricePerWeek[]>(`${this.apiConfig.baseUrl}/${this.path}/${pool_id}/price-per-week`);
  }

  getSellList(pool_id: number): Observable<PoolSellListResponse> {
    return this.http.get<PoolSellListResponse>(`${this.apiConfig.baseUrl}/${this.path}/${pool_id}/sell-list`);
  }

  finalStatement(pool_id: number): Observable<any> {
    return this.http.post<any>(`${this.apiConfig.baseUrl}/${this.path}/${pool_id}/final-statement`, {});
  }

  sendFinalStatement(finalStatementId: number, data: any): Observable<any> {
    return this.http.post<any>(`${this.apiConfig.baseUrl}/${this.path}/final-statement/${finalStatementId}/send`, data);
  }

  getBuyList(pool_id: number): Observable<PoolBuyListResponse> {
    return this.http.get<PoolBuyListResponse>(`${this.apiConfig.baseUrl}/${this.path}/${pool_id}/buy-list`);
  }

  updatePricePerWeek(pool_id: number, poolPricePerWeeks: PoolPricePerWeek[]) {
    return this.http.patch<PoolPricePerWeek[]>(
      `${this.apiConfig.baseUrl}/${this.path}/${pool_id}/price-per-week`,
      poolPricePerWeeks,
    );
  }

  exportSubscriptions(pool_id: number, type: OrderType): Observable<HttpResponse<Blob>> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${pool_id}/export_subscriptions/${type}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  getPoolAdvanceEntries(id: number): Observable<PaginatedResponse<PoolAdvanceEntry>> {
    return this.http.get<PaginatedResponse<PoolAdvanceEntry>>(
      `${this.apiConfig.baseUrl}/${this.path}/${id}/pool-advance-entries`,
    );
  }

  getPoolAdvances(id: number): Observable<PaginatedResponse<PoolAdvance>> {
    return this.http.get<PaginatedResponse<PoolAdvance>>(
      `${this.apiConfig.baseUrl}/${this.path}/${id}/pool-advances`,
    );
  }

  getPoolAdvance(id: number): Observable<ReadResponse<PoolAdvance>> {
    return this.http.get<ReadResponse<PoolAdvance>>(`${this.apiConfig.baseUrl}/${this.path}/pool-advances/${id}`);
  }

  updatePoolAdvance(data: Partial<PoolAdvance>): Observable<ReadResponse<PoolAdvance>> {
    return this.http.put<ReadResponse<PoolAdvance>>(`${this.apiConfig.baseUrl}/${this.path}/pool-advances/${data.id}`, data);
  }

  downloadPoolAdvance(id: number): Observable<any> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${id}/download_advance`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  sendPoolAdvance(poolAdvance: PoolAdvance, data: any) {
    return this.http.post<OrderMatch>(`${this.apiConfig.baseUrl}/${this.path}/${poolAdvance.id}/send_advance`, data);
  }

  getProvisionalStatementRows(poolId: number): Observable<ListResponse<PoolProvisionalStatementRow>> {
    return this.http.get<ListResponse<PoolProvisionalStatementRow>>(`${this.apiConfig.baseUrl}/${this.path}/${poolId}/provisional_statement_rows`);
  }

  downloadProvisionalStatement(id: number): Observable<any> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${id}/download_provisional_statement`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  patchProvisionalStatementRows(data: Partial<PoolProvisionalStatement>): Observable<ReadResponse<PoolProvisionalStatement>> {
    return this.http.patch<ReadResponse<PoolProvisionalStatement>>(
      `${this.apiConfig.baseUrl}/${this.path}/${data.id}/update_provisional_statement_rows`, data);
  }

  sendProvisionalStatement(provisionalStatement: PoolProvisionalStatement, sendPoolForm: { message: string }) {
    return this.http.post<OrderMatch>(`${this.apiConfig.baseUrl}/${this.path}/${provisionalStatement.id}/send_provisional_statement`, sendPoolForm);
  }
}
