<div class="ppa-modal ppa-modal--nested">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>
  <div class="ppa-modal__heading">
    <h2>{{ 'modals.contacts.title' | transloco }}</h2>

    <form #form="ngForm" [formGroup]="searchForm">
      <mat-form-field appearance="fill">
        <mat-label *ngIf="inRelation">{{ 'modals.contacts.placeholder' | transloco }}</mat-label>
        <mat-label *ngIf="!inRelation">{{ 'modals.contacts.placeholderWithRelation' | transloco }}</mat-label>
        <input matInput type="search" formControlName="term" />
        <i matSuffix class="uil uil-search"></i>
      </mat-form-field>

      <ng-container *ngIf="relation">
        <mat-slide-toggle formControlName="inRelation">
          {{ 'modals.contacts.searchWithin' | transloco }} {{ relation.companyName }}
        </mat-slide-toggle>
      </ng-container>
    </form>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <div class="contacts-list">
      <ng-container *ngIf="!showLoader; else loading">
        <ng-container *ngIf="contacts$ | async as contacts">
          <ng-container *ngIf="contacts?.length; else noResult">
            <div class="contact" *ngFor="let contact of contacts" (click)="toggleContact($event, contact)">
              <mat-checkbox [checked]="isChecked(contact)" (change)="toggleChange($event, contact)"></mat-checkbox>
              <div class="contact--info">
                <div class="title">{{ contact.firstname }} {{ contact.lastname }}</div>
                <div class="relation" *ngIf="contact.relation">
                  {{ contact.relation?.companyName || '' }}
                </div>
                <div class="email">
                  {{ contact.email.split(';').join(', ') }}
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #loading>
      <ppa-loading></ppa-loading>
    </ng-template>

    <ng-template #noResult>
      <ppa-no-result></ppa-no-result>
    </ng-template>
  </div>

  <div class="ppa-modal__footer">
    <button
      mat-raised-button
      class="button--full-width contacts-list--button"
      color="primary"
      data-cy="button-mail-add-contacts"
      (click)="addContacts()"
    >
      {{ 'modals.contacts.addContacts' | transloco }}
    </button>
  </div>
</div>

<div class="ppa-modal__fader ppa-modal__fader--nested"></div>
