import { Injectable } from '@angular/core';
import { NgxWigComponent } from 'ngx-wig';
import { ModalService } from '../services/modal.service';
import { EditorModalComponent } from '../modals/editor-modal/editor-modal.component';
import { EditorModalVariable } from '@ppa/data';
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class EditorButtonService {
  variables = {
    label: 'Variables',
    title: 'Variables',
    command: (ctx: NgxWigComponent) => {
      this.openModal(ctx, 'variables');
    },
    styleClass: 'nw-button',
    icon: 'icon-variables',
  };

  functions = {
    label: 'Functies',
    title: 'Functies',
    command: (ctx: NgxWigComponent) => {
      this.openModal(ctx, 'functions');
    },
    styleClass: 'nw-button',
    icon: 'icon-functions',
  };

  constructor(private modalService: ModalService, private fb: FormBuilder) {}

  openModal(ctx: NgxWigComponent, type: string): void {
    const confirmModal = this.modalService.createModal(EditorModalComponent, { contentType: type, fb: this.fb });
    const selection = window.getSelection();
    const parentNode = selection?.anchorNode?.parentNode || null;
    const parentParentNode = selection?.anchorNode?.parentNode?.parentNode || null;
    const textNode = selection?.anchorNode || null;
    const caretPosition = selection?.anchorOffset || 0;
    const rangeEndPosition = selection?.focusOffset || 0;
    const selectionType = selection?.type || null;
    let deleteCount = 0;

    if (selectionType === 'Range') {
      deleteCount = rangeEndPosition - caretPosition;
    }

    confirmModal.dialog.onSaveObservable().subscribe((result: EditorModalVariable) => {
      let variable = '';
      if (result.variable && !result.function) {
        variable = '{' + result.variable + '}';
      } else if (result.variable && result.function) {
        let extra = '';
        if (result.operator && result.value) {
          extra = result.operator;
          if (isNaN(result.value)) {
            extra += "'" + result.value + "'";
          } else {
            extra += result.value;
          }
        }
        variable = '{' + result.function + ':' + result.variable + extra + '}';
      } else {
        variable = '{' + result.function + '}';
      }

      if (result.function === 'if') {
        variable += '{endif}';
      }

      if (variable !== '') {
        if (parentNode === ctx.container || parentParentNode === ctx.container) {
          let content = [];
          if (textNode.nodeName !== '#text') {
            /* @ts-ignore */
            content = textNode.innerHTML.split('');
          } else {
            content = textNode.nodeValue.split('');
          }
          content.splice(caretPosition, deleteCount, variable);
          if (textNode.nodeName !== '#text') {
            /* @ts-ignore */
            textNode.innerHTML = content.join('');
          } else {
            textNode.nodeValue = content.join('');
          }
          ctx.content = ctx.container.innerHTML;
        } else {
          ctx.content += variable;
          ctx.container.innerHTML = ctx.content;
        }

        ctx.onContentChange(ctx.content);
      }
    });
  }
}
