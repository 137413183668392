import { PopoverDialog, PopoverDialogRef } from '../classes/popover-dialog';
import { ComponentFactoryResolver, ComponentRef, Injectable, OnDestroy, Type } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Modal } from '../classes';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

@Injectable({
  providedIn: 'root',
})
export class ModalService implements OnDestroy {
  protected modalContainerRef: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver) {}

  setModalContainerRef(modalContainerRef: ViewContainerRef) {
    this.modalContainerRef = modalContainerRef;

    return modalContainerRef;
  }

  createModal(component: Type<Modal>, props: { [key: string]: any } = {}) {
    const factory = this.resolver.resolveComponentFactory(component);

    /* create the component inside the modalContainerRef */
    const componentRef: ComponentRef<Modal> = this.modalContainerRef.createComponent(factory);
    const instance = componentRef.instance;

    // Disable body scroll
    disableBodyScroll(componentRef.location.nativeElement, { allowTouchMove: () => true });

    /* Add references to array so we can do something with it later on */
    const popoverDialogRef: PopoverDialogRef = {
      componentRef,
      dialog: new PopoverDialog(),
    };

    /* reference the popover created */
    instance.popoverDialog = popoverDialogRef;

    /* Fill all component inputs with data */
    Object.keys(props).forEach((key) => (instance[key] = props[key]));
    componentRef.hostView.detectChanges();

    return popoverDialogRef;
  }

  close(popoverDialog: PopoverDialogRef) {
    // Enable body scroll
    enableBodyScroll(popoverDialog.componentRef.location.nativeElement);

    popoverDialog.componentRef.destroy();
  }

  ngOnDestroy() {
    // 5. Useful if we have called disableBodyScroll for multiple target elements,
    // and we just want a kill-switch to undo all that.
    // OR useful for if the `hideTargetElement()` function got circumvented eg. visitor
    // clicks a link which takes him/her to a different page within the app.
    clearAllBodyScrollLocks();
  }
}
