import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Address, LocationService } from '../../../../services/location.service';
import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { GeolocateControl, MapMouseEvent } from 'mapbox-gl';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Dossier } from '@ppa/data';

const SATELLITE = 'mapbox://styles/mapbox/satellite-streets-v11';
const STREET = 'mapbox://styles/mapbox/outdoors-v11';
@Component({
  selector: 'ppa-select-location-map',
  templateUrl: './select-location-map.component.html',
  styleUrls: ['./select-location-map.component.scss'],
})
export class SelectLocationMapComponent implements OnInit {
  viewUrl: string;
  location: [number, number];
  @Input() marker: [number, number];
  @Input() relationLocation: [number, number];
  @Input() dossiers: Dossier[];
  @Input() useCurrentLocationOnLoad = true;
  @Input() editable = true;

  defaultZoom = [15];
  zoomOut = [5];
  zoom: number[];
  geoLocateButton: any;
  geoLocateButtonInterval: any;
  locationSet = false;
  map: any;

  centerNetherlands: [number, number] = [5.2793703, 52.2129919];
  markers: { coords: [number, number]; dossier: Dossier }[] = [];

  _address: Address;
  @Input() set address(value: Address) {
    this._address = value;

    if (value) {
      this.locationForm.get('address').setValue(this.addressToDisplayString(value));
    }
  }

  get address(): Address {
    return this._address;
  }

  get useCurrentLocation() {
    return this.locationForm.get('useCurrentLocation');
  }

  get cantUseCurrentLocation() {
    return this.useCurrentLocation.hasError('cantUseCurrentLocation');
  }

  locationForm: FormGroup;

  mapRender$ = new BehaviorSubject<boolean>(null);

  @Output() locationSelected = new EventEmitter<[Address, [number, number]]>();
  @Output() clickedDossiers = new EventEmitter<Dossier[]>();

  constructor(private locationService: LocationService, private formBuilder: FormBuilder) {
    this.locationForm = this.formBuilder.group({
      useCurrentLocation: this.useCurrentLocationOnLoad,
      address: [
        {
          value: this.address ? this.addressToDisplayString(this.address) : '',
          disabled: true,
        },
      ],
    });
    this.viewUrl = SATELLITE;

    this.locationForm.get('useCurrentLocation').valueChanges.subscribe((active) => {
      if (this.geoLocateButton) {
        if (active && !this.geoLocateButton.classList.contains('mapboxgl-ctrl-geolocate-active')) {
          this.geoLocateButton.click();
        } else if (!active && this.geoLocateButton.classList.contains('mapboxgl-ctrl-geolocate-active')) {
          this.geoLocateButton.click();
        }
      }
    });
  }

  get locationCanBeRemoved() {
    return !this.locationForm.get('address').value;
  }

  async ngOnInit() {
    this.locationForm.get('useCurrentLocation').patchValue(this.useCurrentLocationOnLoad);
    let permissionDenied = false;
    if (typeof navigator.permissions !== 'undefined') {
      const geolocationStatus = await navigator.permissions.query({ name: 'geolocation' });
      permissionDenied = geolocationStatus.state === 'denied';
    }
    this.setDefaultLocation(permissionDenied);

    if (this.dossiers) {
      this.checkCurrentLocation();

      for (const dossier of this.dossiers) {
        const coords: [number, number] = [0, 0];
        if (dossier.longitude && dossier.latitude) {
          coords[0] = dossier.longitude;
          coords[1] = dossier.latitude;
        } else {
          coords[0] = dossier.relation.longitude;
          coords[1] = dossier.relation.latitude;
        }

        if (coords[0] && coords[1]) {
          this.markers.push({
            coords,
            dossier: { ...dossier },
          });
        }
      }
    } else {
      this.checkCurrentLocation();
    }

    this.zoom = this.defaultZoom;
    this.mapRender$.next(true);
  }

  loaded(map): void {
    if (map) {
      this.map = map;
      // @ts-ignore
      const control = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // @ts-ignore
        showUserHeading: true,
        showUserLocation: true,
        trackUserLocation: true,
      });

      map.addControl(control);

      this.geoLocateButtonInterval = setInterval(() => {
        // @ts-ignore
        const geoLocateButton = control._geolocateButton || false;
        if (geoLocateButton) {
          clearInterval(this.geoLocateButtonInterval);
          this.geoLocateButton = geoLocateButton;

          if (this.locationForm.get('useCurrentLocation').value) {
            this.geoLocateButton.click();
          }
        }
      }, 100);
    }
  }

  changeView(): void {
    this.viewUrl = this.viewUrl === SATELLITE ? STREET : SATELLITE;
  }

  checkCurrentLocation() {
    // If using device location is allowed, set location.
    if (navigator.geolocation && this.useCurrentLocation.value) {
      this.setDefaultLocation(false);
      this.locationService.getCurrentLocationObserver().subscribe((coords) => {
        if (!this.locationSet) {
          const { longitude: lng, latitude: lat } = coords;
          this.location = [lng, lat];
          this.mapRender$.next(true);
          this.locationSet = true;
        }
      });
    } else {
      this.setDefaultLocation(false);
    }
  }

  mapClick(data: MapMouseEvent): void {
    if (data.lngLat && this.editable) {
      const { lng, lat } = data.lngLat;
      if (!this.dossiers) {
        if (this.placeMarker(lng, lat)) {
          this.getLocation(lng, lat);
        } else {
          this.clearAddress();
        }
      } else {
        const dossiers: Dossier[] = [];
        const range = this.calculateRange();
        for (const marker of this.markers) {
          if (this.isInRange(marker.coords, [lng, lat], range)) {
            dossiers.push(marker.dossier);
          }
        }

        this.clickedDossiers.emit(dossiers);
      }
    }
  }

  // Set marker position, remove marker position upon clicking in defined range.
  placeMarker(lng: number, lat: number): boolean {
    if (this.marker !== null && this.marker !== undefined && this.isInRange(this.marker, [lng, lat], 0.0001)) {
      this.marker = null;
      return false;
    } else {
      this.marker = [lng, lat];
      return true;
    }
  }

  // Check if two positions are in given range.
  isInRange(posA: [number, number], posB: [number, number], range: number): boolean {
    if (Math.abs(posA[0] - posB[0]) <= range) {
      if (Math.abs(posA[1] - posB[1]) <= range) {
        return true;
      }
    }

    return false;
  }

  clearAddress(): void {
    this.locationForm.get('address').setValue(null);
    this._address = null;
    this.marker = null;
    this.location = null;

    this.locationSelected.emit([this._address, this.marker]);
  }

  // Return address attribute in displayable string.
  addressToDisplayString(address: Address): string {
    return `${address.street} ${address.number}, ${address.city}` ?? null;
  }

  private setDefaultLocation(permissionDenied: boolean) {
    if (permissionDenied) {
      const useCurrentLocation = this.useCurrentLocation;
      useCurrentLocation.disable();
      useCurrentLocation.setErrors({ cantUseCurrentLocation: true });
      useCurrentLocation.setValue(false);
    }

    if (this.marker) {
      this.location = this.marker;
    } else {
      if (!this.relationLocation || this.relationLocation[0] === -1337 || this.relationLocation[1] === -1337) {
        this.location = this.centerNetherlands;
        this.zoom = this.zoomOut;
      } else {
        this.location = this.relationLocation;
      }
    }

    this.mapRender$.next(true);
  }

  // Get address for MapMouseEvent, set form with value.
  getLocation(lng: number, lat: number): void {
    this.locationService.reverseGeolocation(lng, lat).subscribe((address) => {
      if (address.street === '' && address.number !== '') {
        address.street = address.number;
        address.number = '';
      }
      this.locationForm.get('address').setValue(this.addressToDisplayString(address));
      this.locationSelected.emit([address, this.marker]);
    });
  }

  // upon toggle change
  toggleUseCurrentLocation($event: MatSlideToggleChange) {
    if ($event.checked) {
      this.locationSet = false;
      this.checkCurrentLocation();
    } else {
      this.setDefaultLocation(false);
    }
  }

  calculateRange() {
    const zoom = this.map.getZoom();
    let range = 0.0005;

    if (zoom >= 15) {
      range = 0.0005;
    } else if (zoom >= 14) {
      range = 0.0009;
    } else if (zoom >= 13.5) {
      range = 0.0011;
    } else if (zoom >= 13) {
      range = 0.0017;
    } else if (zoom >= 12) {
      range = 0.0019;
    } else if (zoom >= 11) {
      range = 0.007;
    } else if (zoom >= 10) {
      range = 0.011;
    } else if (zoom >= 9.5) {
      range = 0.016;
    } else if (zoom >= 9) {
      range = 0.025;
    } else {
      range = 0.04;
    }

    return range;
  }

  zoomEnd(event) {
    this.calculateRange();
  }
}
