import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'debug',
})
export class PPADebugPipe implements PipeTransform {
  constructor() {
  }

  transform(value: any): any {
    return JSON.stringify(value, null, 2);
  }
}
