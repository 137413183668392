import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsModalComponent } from './details-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { CarouselModule } from '../../components/carousel/carousel.module';

@NgModule({
  declarations: [DetailsModalComponent],
  imports: [CommonModule, MatButtonModule, TranslocoModule, CarouselModule],
  exports: [DetailsModalComponent],
})
export class DetailsModalModule {}
