import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'ppa-component-can-deactivate',
  template: ``,
})
export abstract class CanDeactivateComponent {
  abstract canDeactivate(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}
