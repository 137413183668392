<div class="ppa-modal">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h2>{{ 'modules.dossier.modal.map.title' | transloco }}</h2>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <ppa-select-location-map
      [dossiers]="dossiers"
      (clickedDossiers)="clickedDossiers($event)"
    ></ppa-select-location-map>

    <div class="ppa-card-list" *ngIf="clickedDossiers$ | async as clickedDossiers">
      <ng-container *ngIf="clickedDossiers.length > 0">
        <div class="dossier-map__list">
          <h2>{{ 'modules.dossier.modal.map.list' | transloco }}</h2>
          <ng-container *ngFor="let dossier of clickedDossiers">
            <ppa-order-card
              [routerLink]="['/dossier/edit', dossier.id]"
              (click)="close()"
              [orderCardInfo]="dossier"
              [showDetailTitle]="true"
              [attr.data-cy]="'dossier-overview-card-' + dossier.id"
              [cardType]="'overview'"
            >
            </ppa-order-card>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="ppa-modal__fader"></div>
