<ng-container *ngIf="dossier$ | async as dossier">
  <div class="container" *transloco="let t">
    <ppa-header [data]="headerData" class="mb-1"></ppa-header>

    <div class="heading">
      <h1 class="subheading-enabled">
        {{ dossier.relation.companyName }}
        <ppa-relation-status [label]="dossier.relation.status"></ppa-relation-status>
        <ppa-status class="ppa-status--right" [module]="module" *ngIf="status" [label]="status"></ppa-status>
      </h1>
      <h4 *ngIf="dossier.title" class="subheading">{{ dossier.title }}</h4>
    </div>

    <div class="details mb-1">
      <dl>
        <ng-container *ngIf="dossier.contractType === ContractTypes.Pool && dossier.pool">
          <dt>{{ t('modules.dossier.detail.summary.pool') }}</dt>
          <dd data-cy="dossier-detail-contract-type">
            {{ dossier.pool.name }}
          </dd>
        </ng-container>
        <dt>{{ t('modules.dossier.detail.summary.product_and_product_variety') }}</dt>
        <dd data-cy="dossier-detail-product-variety">
          {{ dossier.product.title }}{{ dossier.productVariety ? ', ' : '' }}{{ dossier.productVariety?.title }}
          <ppa-status
            class="ppa-status--inline"
            *ngIf="dossier.bakingFactory"
            [manualLabel]="'modules.dossier.detail.summary.baking_factory' | transloco"
          ></ppa-status>
        </dd>
        <dt>{{ t('modules.dossier.detail.summary.amount') }}</dt>
        <dd data-cy="dossier-detail-amount">
          <ng-container *ngIf="dossier.expectedAmount || dossier.estimatedAmount">
            <ng-container *ngIf="dossier.amountOfUnit">
              {{ dossier.amountOfUnit }} {{ dossier.amountUnit?.title }} -
            </ng-container>
            {{ dossier.expectedAmount ? dossier.expectedAmount : dossier.estimatedAmount }}
            <ng-container *ngIf="showAmountUnitSuffix(dossier)">{{ t('general.ton') }}</ng-container>
          </ng-container>
        </dd>

        <dt>{{ t('modules.dossier.detail.summary.available') }}</dt>
        <dd data-cy="dossier-detail-available">
          <ng-container *ngIf="dossier.availableAmount > 0">
            {{ dossier.availableAmount }}
            <ng-container *ngIf="showAmountUnitSuffix(dossier)">{{ t('general.ton') }}</ng-container> -
            {{ percentFulfilled(dossier.availableAmount, dossier.amount) }}%
          </ng-container>
          <ng-container *ngIf="dossier.availableAmount <= 0">-</ng-container>
        </dd>

        <dt>{{ t('modules.dossier.detail.summary.fulfilled') }}</dt>
        <dd data-cy="dossier-detail-fulfilled">
          <ng-container *ngIf="dossier.fulfilledAmount > 0">
            {{ dossier.fulfilledAmount }}
            <ng-container *ngIf="showAmountUnitSuffix(dossier)">{{ t('general.ton') }}</ng-container> -
            {{ percentFulfilled(dossier.fulfilledAmount, dossier.amount) }}%
            <span
              *ngIf="
                dossier.expectedAmount
                  ? dossier.fulfilledAmount > dossier.expectedAmount
                  : dossier.fulfilledAmount > dossier.estimatedAmount
              "
              matTooltip="{{ t('modules.dossier.card.fulfilled_warning') }}"
              class="exclamation"
            >
              <i class="uil uil-exclamation-triangle"></i>
            </span>
          </ng-container>
          <ng-container *ngIf="dossier.fulfilledAmount <= 0">-</ng-container>
        </dd>

        <dt>{{ t('modules.dossier.detail.summary.mediator') }}</dt>
        <dd data-cy="dossier-detail-mediator">
          <ng-container *ngIf="dossier.mediator.phoneNumber; else no_phone">
            <a class="phone" href="tel:{{ dossier.mediator.phoneNumber }}"
              ><i class="uil uil-phone" href=""></i> {{ dossier.mediator?.firstname }}
              {{ dossier.mediator?.lastname }}</a
            >
          </ng-container>
          <ng-template #no_phone> {{ dossier.mediator?.firstname }} {{ dossier.mediator?.lastname }} </ng-template>
        </dd>
        <dt>{{ t('sorting.created_at') }}:</dt>
        <dd data-cy="dossier-detail-created-at">
          {{ dossier.createdAt | ppaDate: 'DD-MM-YYYY HH:mm' }}
        </dd>
        <dt>{{ t('sorting.updated_at') }}:</dt>
        <dd data-cy="dossier-detail-updated-at">
          {{ dossier.updatedAt | ppaDate: 'DD-MM-YYYY HH:mm' }}
        </dd>
      </dl>
      <ppa-action-card
        [preIcon]="true"
        (contentClicked)="showDossierDetail(dossier)"
        (actionClicked)="showDossierDetail(dossier)"
        icon="uil-file-search-alt"
        actionIcon="uil-search"
        iconSize="medium"
        data-cy="dossier-detail-dossier-quick-view"
      >
        {{ t('modules.dossier.detail.actions.quick_view_dossier') }}
      </ppa-action-card>
      <div class="action-cards--mb"></div>
    </div>

    <ng-container *ngIf="currentAddress$ | async as currentAddress">
      <h2>{{ 'modules.dossier.detail.section.location' | transloco }}</h2>

      <ppa-action-card
        actionIcon="uil-map-marker"
        (contentClicked)="openLocationModal()"
        (actionClicked)="openLocationModal()"
      >
        {{ currentAddress.street }} {{ currentAddress.number }}, <br />
        {{ currentAddress.zipcode }} {{ currentAddress.city }}
      </ppa-action-card>

      <div class="action-cards--mb"></div>
    </ng-container>

    <ng-container *ngIf="currentCertificates$ | async as certificates">
      <ng-container *ngIf="certificates.length > 0">
        <h2 class="clickable" (click)="toggleCertificates()">
          {{ 'modules.dossier.detail.section.certificates' | transloco }} ({{ certificates.length }})
          <i class="uil" [class.uil-angle-down]="!showCertificates" [class.uil-angle-up]="showCertificates"></i>
        </h2>
        <ng-container *ngIf="showCertificates">
          <ng-container *ngFor="let relationCertificate of certificates">
            <ppa-certificate-checkbox
              [id]="relationCertificate.certificate?.id"
              [title]="relationCertificate.certificate.name"
              [expirationDate]="relationCertificate?.date"
              [organic]="relationCertificate.certificate?.organic"
              [certificate]="relationCertificate"
              [checked]="relationCertificate.checked"
              [hideCheckbox]="true"
            ></ppa-certificate-checkbox>
          </ng-container>
        </ng-container>
        <div class="action-cards--mb"></div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="dossier.relation?.contacts?.length > 0">
      <h2 class="clickable" (click)="toggleContacts()">
        {{ t('modules.dossier.detail.section.contact') }} ({{ dossier.relation?.contacts?.length }})
        <i class="uil" [class.uil-angle-down]="!showContacts" [class.uil-angle-up]="showContacts"></i>
      </h2>

      <ng-container *ngIf="showContacts">
        <ppa-action-card
          *ngFor="let contact of dossier.relation.contacts"
          [actionIcon]="phoneIcon(contact.phone)"
          (contentClicked)="callNumber(contact.phone)"
          (actionClicked)="callNumber(contact.phone)"
          data-cy="dossier-detail-contact"
          class="mb-2"
        >
          {{ contact?.firstname }} {{ contact?.lastname }}
          <span class="action-card__content--secondary">{{ contact.phone }}</span>
        </ppa-action-card>

        <div class="action-cards--mb"></div>
      </ng-container>
    </ng-container>

    <h2>{{ t('modules.dossier.detail.section.actions') }}</h2>

    <ppa-action-card
      [state]="'primary'"
      [preIcon]="true"
      [cardClass]="'primary'"
      (contentClicked)="goTo(['dossier', dossier.id, 'contact_moment'])"
      (actionClicked)="goTo(['dossier', dossier.id, 'contact_moment'])"
      textColor="#ffffff"
      icon="uil-comment-alt-plus"
      actionIcon="uil-plus"
      iconSize="medium"
      data-cy="dossier-detail-dossier-contact_moment-action"
    >
      {{ t('modules.dossier.detail.actions.add_contact_moment') }}
    </ppa-action-card>

    <ppa-action-card
      [preIcon]="true"
      (contentClicked)="goTo(['dossier', dossier.id, 'reminder'])"
      (actionClicked)="goTo(['dossier', dossier.id, 'reminder'])"
      icon="uil-calender"
      actionIcon="uil-plus"
      iconSize="medium"
      data-cy="dossier-detail-dossier-reminder-action"
    >
      {{ t('modules.dossier.detail.actions.add_reminder') }}
    </ppa-action-card>

    <ppa-action-card
      [state]="actionStates.edit"
      [preIcon]="true"
      (contentClicked)="goTo(['dossier', dossier.id, 'edit'])"
      (actionClicked)="goTo(['dossier', dossier.id, 'edit'])"
      icon="uil-file-edit-alt"
      actionIcon="uil-pen"
      iconSize="medium"
      data-cy="dossier-detail-dossier-edit-action"
    >
      {{ 'modules.dossier.detail.actions.edit' | transloco }}
    </ppa-action-card>

    <ppa-action-card
      *ngIf="dossier.localPhotos && dossier.localPhotos.length > 0"
      [state]="actionStates.edit"
      [preIcon]="true"
      (contentClicked)="openPhotosPopup(dossier)"
      (actionClicked)="openPhotosPopup(dossier)"
      icon="uil-image"
      actionIcon="uil-eye"
      iconSize="medium"
      data-cy="dossier-detail-dossier-photos-action"
    >
      {{ 'modules.dossier.detail.actions.photos' | transloco }}
    </ppa-action-card>

    <ppa-action-card
      *ngIf="dossier.relation"
      [state]="actionStates.edit"
      [preIcon]="true"
      (contentClicked)="goTo(['relation', dossier.relation.id, 'edit'])"
      (actionClicked)="goTo(['relation', dossier.relation.id, 'edit'])"
      icon="uil-user"
      actionIcon="uil-pen"
      iconSize="medium"
      data-cy="dossier-detail-relation-edit-action"
    >
      {{ 'modules.dossier.detail.actions.relation_edit' | transloco }}
    </ppa-action-card>

    <!-- ORDER -->
    <ng-container *ngIf="activeModules.order && orders$ | async as orders">
      <ppa-action-card
        [state]="orders.length === 0 ? 'primary' : 'active'"
        [actionIcon]="orders.length === 0 ? 'uil-plus' : 'uil-angle-right'"
        [cardClass]="orders.length === 0 ? 'primary' : ''"
        (contentClicked)="orderLinkAction()"
        (actionClicked)="orderLinkAction()"
        [preIcon]="true"
        icon="uil-file-check-alt"
        actionIcon="uil-plus"
        iconSize="medium"
      >
        <div class="order-placeholder" *ngIf="orders.length === 0; else openOrderPlaceholder">
          {{ t('modules.dossier.create.link_order_label') }}
        </div>
        <ng-template #openOrderPlaceholder>
          <ng-container *ngIf="orders.length === 1">
            {{ t('modules.dossier.create.open_order_label') }}
          </ng-container>
          <ng-container *ngIf="orders.length > 1">
            {{ t('modules.dossier.create.open_orders_label', { qty: orders.length }) }}
          </ng-container>
        </ng-template>
      </ppa-action-card>
    </ng-container>

    <ppa-action-card
      [preIcon]="true"
      icon="uil-file-alt"
      iconSize="medium"
      actionIcon="uil-download-alt"
      (contentClicked)="downloadAction()"
      (actionClicked)="downloadAction()"
    >
      {{ 'modules.dossier.create.download_label' | transloco }}
    </ppa-action-card>

    <ppa-action-card
      [preIcon]="true"
      icon="uil-file-copy-alt"
      iconSize="medium"
      actionIcon="uil-copy"
      (contentClicked)="copyAction()"
      (actionClicked)="copyAction()"
    >
      {{ 'modules.dossier.create.copy_label' | transloco }}
    </ppa-action-card>

    <ng-container *ngIf="status !== StatusLabel.Closed">
      <ppa-action-card
        [preIcon]="true"
        (contentClicked)="closeAction(dossier)"
        (actionClicked)="closeAction(dossier)"
        iconSize="medium"
        icon="uil-file-times-alt"
        actionIcon="uil-times"
        data-cy="dossier-detail-dossier-close"
      >
        {{ t('modules.dossier.detail.actions.close') }}
      </ppa-action-card>
    </ng-container>

    <ng-container *ngIf="status === StatusLabel.Closed">
      <ppa-action-card
        [preIcon]="true"
        (contentClicked)="openAction(dossier)"
        (actionClicked)="openAction(dossier)"
        iconSize="medium"
        icon="uil-file-check-alt"
        actionIcon="uil-check"
        data-cy="dossier-detail-dossier-open"
      >
        {{ t('modules.dossier.detail.actions.open') }}
      </ppa-action-card>
    </ng-container>

    <div class="action-cards--mb"></div>

    <ng-container *ngIf="dossier.contactMoments.length > 0">
      <h2>{{ t('modules.dossier.detail.section.contact_moments') }}</h2>

      <ppa-card
        *ngFor="let contactMoment of dossier.contactMoments"
        [title]="
          contactMoment.mediator
            ? contactMoment.mediator?.firstname + ' ' + contactMoment.mediator?.lastname
            : 'Bezoekmoment'
        "
        [subtitle]="contactMoment.contactDate | ppaDate: 'LLLL'"
        [attr.data-cy]="'dossier-contact-moment-card-' + contactMoment.id"
        (click)="openContactMoment(contactMoment, dossier)"
        [actionLabel]="
          contactMoment.manualDossierEdit
            ? t('modules.dossier.detail.contact_moment_status.manual_edit')
            : t('modules.dossier.detail.contact_moment_status.contact_moment')
        "
        [actionLabelClass]="contactMoment.manualDossierEdit ? 'incomplete' : 'complete'"
      >
        <div class="ppa-card__content">
          <div class="ppa-card__info" data-cy="dossier-contact-moment-description">
            <ng-container *ngIf="contactMoment.manualDossierEdit; else contactMomentTemplate">
              <span class="ppa-card__info--manual">{{ contactMoment.description }}</span>
            </ng-container>
            <ng-template #contactMomentTemplate>
              {{ contactMoment.description }}
            </ng-template>
          </div>
        </div>
      </ppa-card>

      <div class="action-cards--mb"></div>
    </ng-container>

    <ng-container *ngIf="dossier.reminders.length > 0">
      <h2>{{ t('modules.dossier.detail.section.reminders') }}</h2>

      <ppa-card
        *ngFor="let reminder of dossier.reminders"
        [title]="reminder.reminderDate | ppaDate: 'LLLL'"
        [attr.data-cy]="'dossier-reminder-card-' + reminder.id"
        (click)="deleteReminder(reminder)"
      >
        <div class="ppa-card__content">
          <div class="ppa-card__info" data-cy="dossier-contact-moment-description">
            {{ reminder.description }}
          </div>
        </div>
      </ppa-card>

      <div class="action-cards--mb"></div>
    </ng-container>
  </div>
</ng-container>
