import { NgModule } from '@angular/core';
import { FloatingButtonComponent } from './floating-button.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

const COMPONENTS = [FloatingButtonComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [MatButtonModule, CommonModule],
  exports: [...COMPONENTS],
})
export class FloatingButtonModule {}
