import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryOverviewCardComponent } from './delivery-overview-card.component';
import { MatButtonModule } from '@angular/material/button';
import { LoadingModule } from '../loading';
import { RelationStatusModule } from '../relation-status';
import {TranslocoModule} from "@ngneat/transloco";

@NgModule({
  declarations: [DeliveryOverviewCardComponent],
    imports: [CommonModule, MatButtonModule, LoadingModule, RelationStatusModule, TranslocoModule],
  exports: [DeliveryOverviewCardComponent],
})
export class DeliveryOverviewCardModule {}
