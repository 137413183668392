import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AdministratorGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      const helper = new JwtHelperService();
      if (helper.isTokenExpired(this.authService.getToken().token)) {
        this.authService.redirectOnFailedLogin();
        return false;
      }

      if (this.authService.isMediator() || this.authService.isExternal()) {
        return false;
      }

      return true;
    }

    this.router.navigate(['/auth/login']);
    return false;
  }
}
