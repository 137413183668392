import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Filter } from '../../contracts';
import { FilterService } from '../../services';

@Component({
  selector: 'ppa-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBarComponent implements OnInit, OnDestroy {
  filterBarForm: FormGroup;
  onDestroy$: Subject<void> = new Subject<void>();
  @Input() filters: (Filter & { dataIsObservable: boolean })[] = [];
  @Output() filterValues = new EventEmitter<{ [key: string]: string }>();

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private filterService: FilterService) {}

  ngOnInit(): void {
    const filterControls = {};
    this.filters.forEach((filter) => {
      filter.dataIsObservable = filter.data instanceof Observable;
      filterControls[filter.name] = [];
    });

    this.filterBarForm = this.fb.group(filterControls);

    this.filterBarForm.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged(), debounceTime(400))
      .subscribe((value) => {
        this.filterService.addFilterToRoute(value);
        this.filterValues.emit(value);
      });

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      for (const [key, value] of Object.entries(params)) {
        if (value !== '') {
          if (isNaN(Number(value))) {
            this.filterBarForm.get(key).setValue(value);
          } else {
            this.filterBarForm.get(key).setValue(+value);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
