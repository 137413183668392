import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { SelectModule } from '../select';
import { FilterBarComponent } from './filter-bar.component';

const COMPONENTS = [FilterBarComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [ReactiveFormsModule, MatInputModule, CommonModule, TranslocoModule, SelectModule],
  exports: [...COMPONENTS],
})
export class FilterBarModule {}
