import { Component, Input } from '@angular/core';
import { Modal } from '../../classes';

@Component({
  selector: 'ppa-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent extends Modal {
  @Input() message = '';
  @Input() information = '';

  @Input() cancelButtonText = 'Annuleren';
  @Input() confirmButtonText = 'Verwijderen';
  @Input() buttonColor: 'primary' | 'warn' = 'warn';
  @Input() cancelButtonColor = '';
  @Input() hideCancelButton = false;

  confirmed() {
    this.save(true);
  }

  cancel() {
    this.close();
  }
}
