import { NgModule } from '@angular/core';
import { OrderMatchedCardComponent } from './order-matched-card.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { RelationStatusModule } from '../relation-status';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [OrderMatchedCardComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    RelationStatusModule,
    MatSlideToggleModule,
    MatTooltipModule,
  ],
  exports: [OrderMatchedCardComponent],
})
export class OrderMatchedCardModule {}
