import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService, Modal, ModalService } from '@ppa/layout';
import { CancelContract, Contract } from '@ppa/data';
import { take } from 'rxjs/operators';
import { translate } from '@ngneat/transloco';
import { ContractService } from '../../services/contract.service';

@Component({
  selector: 'ppa-contract-history-modal',
  templateUrl: './contract-history-modal.component.html',
  styleUrls: ['./contract-history-modal.component.scss'],
})
export class ContractHistoryModalComponent extends Modal implements OnInit {
  contracts: Contract[];
  companyName: string;

  public cancelContractOther: string = CancelContract.Other;

  constructor(
    _modalService: ModalService,
    private contractService: ContractService,
    private errorHandler: ErrorHandlerService,
  ) {
    super(_modalService);
  }

  ngOnInit() {}

  private viewContract(contract: Contract): void {
    this.contractService
      .downloadContract(contract)
      .pipe(take(1))
      .subscribe(
        () => {},
        (errorBlob) => {
          errorBlob.error.text().then((errorText) => {
            const error = JSON.parse(errorText);
            this.errorHandler.handleAPIError(translate('modules.contract.download.failed'), { error, status: 400 });
          });
        },
      );
  }
}
