<div class="contract-overview-card card card--elevate-1" [attr.data-cy]="cyIdentifier">
  <div class="card__heading">
    <h4 class="card__title">
      {{ config?.cardInfo.title.to }}
      <ppa-relation-status [label]="config?.cardInfo?.buyer?.status"></ppa-relation-status>
    </h4>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
  <div class="card__buttons">
    <ng-container
      *ngTemplateOutlet="(showLoader$ | async) ? loading : buttons; context: { $implicit: config }"
    ></ng-container>
  </div>
</div>

<ng-template #buttons let-config>
  <button
    mat-flat-button
    *ngFor="let button of config?.buttons"
    (click)="button.action(config.cardInfo)"
    [disabled]="!button.enabled"
    [color]="button.color"
  >
    {{ button.title }}
  </button>
</ng-template>

<ng-template #loading>
  <ppa-loading [noPadding]="true"></ppa-loading>
</ng-template>
