import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  CertificateDataService,
  ContractTemplate,
  ContractTemplateDataService,
  ContractTypes,
  DossierDataService,
  FilterModule,
  KeyValuePairs,
  OrderType,
  Product,
  ProductDataService,
  ProductVarietyOption,
  Relation,
  RelationDataService,
  RelationName,
  UserDataService,
} from '@ppa/data';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private readonly dataSources: { [key: string]: Observable<any> } = {};

  constructor(
    private stateService: StateService,
    private router: Router,
    private productDataService: ProductDataService,
    private relationDataService: RelationDataService,
    private contractTemplateDataService: ContractTemplateDataService,
    private userDataService: UserDataService,
    private certificateDataService: CertificateDataService,
    private dossierDataService: DossierDataService,
  ) {
    this.dataSources['products'] = this.productDataService.getProductOptions().pipe(shareReplay(1));
    this.dataSources['productVarieties'] = this.productDataService.getProductVarietyOptions().pipe(shareReplay(1));
    this.dataSources['relations'] = this.relationDataService.listForFilter().pipe(shareReplay(1));
    this.dataSources['delivery_overview_relations'] = this.relationDataService.list().pipe(
      map((relations) => {
        return relations.map((relation) => {
          return {
            ...relation,
            companyName: `${relation.companyName}, ${relation.city}`,
          };
        });
      }),
      shareReplay(1),
    );
    this.dataSources['contractTemplates'] = this.contractTemplateDataService.list().pipe(shareReplay(1));
    this.dataSources['creditors'] = this.relationDataService.getPPACompanyOptions(false).pipe(shareReplay(1));
    this.dataSources['mediators'] = this.userDataService.options().pipe(shareReplay(1));
    this.dataSources['certificates'] = this.certificateDataService.getCertificateOptions().pipe(shareReplay(1));
    this.dataSources['origins'] = this.dossierDataService.getOrigins().pipe(shareReplay(1));
  }

  public getProductOptions(): Observable<Product[]> {
    return this.dataSources['products'];
  }

  public getProductVarietyOptions(): Observable<ProductVarietyOption[]> {
    return this.dataSources['productVarieties'];
  }

  public getOrigins(): Observable<KeyValuePairs<string>> {
    return this.dataSources['origins'];
  }

  public getStatuses(module): KeyValuePairs<string> {
    if (module === 'dossier') {
      return [
        { key: 'modules.dossier.overview.status_label.incomplete', value: 'incomplete' },
        { key: 'modules.dossier.overview.status_label.complete', value: 'complete' },
        { key: 'modules.dossier.overview.status_label.closed', value: 'closed' },
      ];
    } else if (module === 'order') {
      return [
        { key: 'modules.order.create.current_place_options.created', value: 'created' },
        { key: 'modules.order.create.current_place_options.matched', value: 'matched' },
        { key: 'modules.order.create.current_place_options.fulfilled', value: 'fulfilled' },
        { key: 'modules.order.create.current_place_options.closed', value: 'closed' },
      ];
    }
    return [];
  }

  public getStatusOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.order_match.status.contract_created', value: 'contract_created' },
      { key: 'modules.order_match.status.contract_sent', value: 'contract_sent' },
      { key: 'modules.order_match.status.contract_canceled', value: 'contract_canceled' },
      { key: 'modules.order_match.status.deliveries_planned', value: 'deliveries_planned' },
      { key: 'modules.order_match.status.delivered', value: 'delivered' },
      { key: 'modules.order_match.status.deliveries_processed', value: 'deliveries_processed' },
      { key: 'modules.order_match.status.invoice_created', value: 'invoice_created' },
      { key: 'modules.order_match.status.invoice_billed', value: 'invoice_billed' },
      { key: 'modules.order_match.status.invoice_paid', value: 'invoice_paid' },
    ];
  }

  public getContractTypeOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.order.create.contract_type.' + ContractTypes.Trade, value: ContractTypes.Trade },
      { key: 'modules.order.create.contract_type.' + ContractTypes.Commission, value: ContractTypes.Commission },
    ];
  }

  public getOrderTypeOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.order.create.order_type.' + OrderType.Buy, value: OrderType.Buy },
      { key: 'modules.order.create.order_type.' + OrderType.Sell, value: OrderType.Sell },
    ];
  }

  public getRelationOptions(filterOutPPACompanies = false): Observable<RelationName[]> {
    if (!filterOutPPACompanies) {
      return this.dataSources['relations'];
    }

    return this.dataSources['relations'].pipe(
      map((relations) => relations?.filter((rel) => !rel.companyName.match(/PPA.*/) && rel.status !== 'blocked')),
    );
  }

  public getDeliveryOverviewRelationOptions(filterOutPPACompanies = false): Observable<Relation[]> {
    if (!filterOutPPACompanies) {
      return this.dataSources['delivery_overview_relations'];
    }
    return this.dataSources['delivery_overview_relations'].pipe(
      map((relations) => relations?.filter((rel) => !rel.companyName.match(/PPA.*/))),
    );
  }

  public getCreditorOptions(): Observable<KeyValuePairs<string>> {
    return this.dataSources['creditors'];
  }

  public getMediatorOptions(): Observable<KeyValuePairs<string>> {
    return this.dataSources['mediators'];
  }

  public getCertificateOptions(): Observable<KeyValuePairs<string>> {
    return this.dataSources['certificates'];
  }

  public getContractTemplateOptions(): Observable<ContractTemplate> {
    return this.dataSources['contractTemplates'];
  }

  public getBooleanOptions(): KeyValuePairs<string> {
    return [
      { key: 'filter.true', value: 'true' },
      { key: 'filter.false', value: 'false' },
    ];
  }

  public getYesNoOptions(): KeyValuePairs<number> {
    return [
      { key: 'filter.true', value: 1 },
      { key: 'filter.false', value: 0 },
    ];
  }

  public getDebitCredit(): KeyValuePairs<string> {
    return [
      { key: 'filter.debit', value: 'debit' },
      { key: 'filter.credit', value: 'credit' },
      { key: 'filter.settlement', value: 'settlement' },
    ];
  }

  /** Return the unique set of options to filter on */
  public pluckFilterOptions(
    values$: Observable<any>,
    key: string[],
    translationPrefix?: string,
  ): Observable<{ value: unknown[]; label: string }[] | unknown[]> {
    return values$.pipe(
      map((data) => this.getUniqueValues(data, key)),
      map((uniqueValues) => (translationPrefix ? this.withTranslation(uniqueValues, translationPrefix) : uniqueValues)),
    );
  }

  public addFilterToRoute(filter: { [key: string]: string }): void {
    console.log('addFilterToRoute', 'wat doet dit?');
  }

  public addFilterFormToRoute(
    filter: { [key: string]: string },
    module: FilterModule,
    overwriteRelationId?: number,
  ): void {
    const params = this.convertFilterForm(filter);

    if (overwriteRelationId !== null && overwriteRelationId !== undefined) {
      params['relation_id'] = overwriteRelationId;
    }

    this.stateService.setFilters(module, 'params', params);
  }

  public convertFilterForm(filter): object {
    const filterParams = this.stripNullValues(filter);
    const keys = Object.keys(filterParams);
    const params = {};

    keys.forEach((key) => {
      if (key === 'sort' || key === 'direction') {
        if (typeof filterParams['sort'] !== 'undefined' && filterParams['sort'] !== '') {
          params[key] = filterParams[key];
        }
      } else {
        const parts = key.split('__');
        const nKey = parts[0];
        const isOperator = parts[2] ?? null;

        if (isOperator === null) {
          const operator = filterParams[key + '__operator'] ?? 'eq';
          const value = operator + '~' + filterParams[key];

          if (params.hasOwnProperty(nKey)) {
            if (typeof params[nKey] !== 'object') {
              const val = params[nKey];
              params[nKey] = [val];
            }

            params[nKey].push(value);
          } else {
            params[nKey] = value;
          }
        }
      }
    });

    return params;
  }

  public convertUrlToParams(url: string): Params {
    const urlBase = this.router.parseUrl(url);
    const urlTree = this.router.parseUrl(urlBase.fragment);
    return urlTree.queryParams;
  }

  private stripNullValues(filter: { [key: string]: string }): { [key: string]: string } | {} {
    for (const [key, value] of Object.entries(filter)) {
      if (value === null) {
        delete filter[key];
      }
    }

    return filter;
  }

  private getUniqueValues(arr: unknown[], key: string[]): unknown[] {
    const getPropertyRecursive = (intermediateArr: unknown[], intermediateKey: string[]) => {
      if (intermediateKey.length > 1) {
        return getPropertyRecursive(
          intermediateArr.map((el) => el[intermediateKey[intermediateKey.length - 1]]),
          intermediateKey.slice(0, -1),
        );
      }

      return Array.from(
        new Set(
          intermediateArr.map((el) => el[intermediateKey[intermediateKey.length - 1]]).filter((el) => el !== null),
        ),
      );
    };

    return getPropertyRecursive(arr, key);
  }

  private withTranslation(arr: unknown[], translationPrefix: string): { value: unknown; label: string }[] {
    return arr.map((el) => ({ value: el, label: `${translationPrefix}.${el}` }));
  }
}
