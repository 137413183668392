import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { TranslocoModule } from '@ngneat/transloco';
import {SpinnerModule} from "../spinner";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [LoadingComponent],
  imports: [CommonModule, TranslocoModule, SpinnerModule, MatProgressSpinnerModule],
  exports: [LoadingComponent],
})
export class LoadingModule {}
