import { Product, ProductVariety } from './product';
import { Relation } from './relation';
import { Model } from './model';
import { User } from './user';
import { OrderMatch } from './order-match';
import { Dossier } from './dossier';
import { ContractTemplate } from './contract-template';
import { ContractTypes } from './contract';
import { Certificate } from './certificate';
import { Unit } from './unit';
import { Pool } from './pool';
import { Treatment } from './treatment';
import { Delivery } from './delivery';

export interface Order extends Model {
  title: string;
  dossier?: Partial<Dossier>;
  relation?: Relation;
  orderType: OrderType;
  product?: Product;
  productVariety?: ProductVariety;
  mediator: User;
  amountOfUnit: string;
  amountUnit?: Unit;
  disinfected?: boolean;
  amount: string | number;
  organic: boolean;
  matchedOrders?: OrderMatch[];
  matchedOrdersAmount?: number;
  fulfilledAt: Date;
  hasPurchaseContract: boolean;
  hasContracts: boolean;
  hasContractsWithStorage: boolean;
  hasMatch: boolean;
  hasSentContracts: boolean;
  hasSentDeliveryReports: boolean;
  matchWithSentContracts: boolean;
  matchWithSentDeliveryReports: boolean;
  hasDeliveries: boolean;
  minimumQuality: number;
  minimumGrit: number;
  onCall: boolean;
  onCallListSentAt?: Date;
  expiresAt: string;
  packaging?: string;
  onCallNote?: string;
  certification?: Certificate[];
  pool?: Pool;
  otherRelation?: Relation;
  contractType?: ContractTypes;
  contractTemplate?: ContractTemplate;
  parentOrder?: Order;
  locationLine?: string;
  availableAmount?: number;
  treatment?: Treatment[];
  hasSomeInvoicesPaid?: boolean;
  hasAllInvoicesPaid?: boolean;
  contractState?: string;
}

export enum OrderType {
  Buy = 'buy',
  Sell = 'sell',
}

export interface OrderWithGroupedMatches {
  order: Order;
  groupedMatches: { [key: string]: OrderMatch[] };
  createPurchaseContract: boolean;
  hasContractTypeAndTemplate: boolean;
  orderInvoices: { [key: string]: OrderMatch[] };
}

export interface OrderWithGroupedOrders {
  order: Order;
  groupedMatches: { [key: string]: Order[] };
  createPurchaseContract: boolean;
  hasContractTypeAndTemplate: boolean;
}

export interface OrderWithGroupedDeliveries {
  order: Order;
  groupedMatches: { [key: string]: Delivery[] };
  orderDeliveries: { [key: string]: Delivery[] };
  createPurchaseContract: boolean;
  hasContractTypeAndTemplate: boolean;
}
