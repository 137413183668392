import { Model } from '@ppa/data';
import { InvoiceRow } from './invoice-row';

export interface Invoice extends Model {
  invoiceId: number;
  companyName: string;
  street: string;
  zipcode: string;
  houseNumber: string;
  city: string;
  referenceNumber: string;
  invoiceSentAt: Date;
  mergedInvoice: boolean;
  updatedAt: Date;
  amount: number;
  remarks?: string;
  orderType?: string;
  invoicePaidAt?: Date;
  hasPrice?: boolean;
}

export enum InvoiceTypes {
  credit = 'Credit',
  debit = 'Debit',
  settlement = 'Settlement',
}
