import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ResolveEnd, Router, RouterEvent } from '@angular/router';
import { translate, TranslocoService } from '@ngneat/transloco';
import { ConfirmDialogComponent, MenuItems, MenuService, ModalService } from '@ppa/layout';
import { Observable, Subject } from 'rxjs';
import { filter, pluck, takeUntil } from 'rxjs/operators';
import { AuthService } from '@ppa/data';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ppa-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject();
  readonly menu: { primary: MenuItems; secondary: MenuItems };
  menuVisible$: Observable<boolean>;

  constructor(
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private transloco: TranslocoService,
    private authService: AuthService,
    private modalService: ModalService,
  ) {
    this.menu = this.createMenuItems();
  }

  ngOnInit(): void {
    this.menuVisible$ = this.menuService.isMenuVisible();

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((evt: RouterEvent) => evt instanceof ResolveEnd),
      )
      .subscribe(() => {
        this.menuService.setMenuVisible(true);
      });

    if (environment.env === 'dev') {
      if (document.title === ':appname') {
        document.title = 'DEVUI';
      }

      const elements = document.querySelectorAll('.version');
      for (const e in elements) {
        if (elements.hasOwnProperty(e)) {
          const element = elements[e];
          if (element.innerHTML === ':version') {
            element.innerHTML = 'development';
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createMenuItems(): { primary: MenuItems; secondary: MenuItems } {
    const params = {};

    const activeModules = environment.activeModules;
    const keys = Object.keys(activeModules);
    const role = this.authService.getRoleFromToken();

    keys.forEach((key) => {
      const allowed = this.authService.componentOrModuleAllowed(key);
      activeModules[key] = allowed;
    });

    return {
      primary: [
        {
          label: this.transloco.selectTranslate('menu.dashboard'),
          icon: 'uil-home-alt',
          routerLink: '/',
          cyIdentifier: 'menu-dashboard',
        },
        ...(activeModules.dossier
          ? [
              {
                label: this.transloco.selectTranslate('menu.dossiers'),
                icon: 'uil-folder-open',
                routerLink: '/dossier',
                queryParams: params,
                cyIdentifier: 'menu-dossier',
                exact: false,
              },
            ]
          : []),
        ...(activeModules.order
          ? [
              {
                label: this.transloco.selectTranslate('menu.orders'),
                icon: 'uil-file-check-alt',
                routerLink: '/order',
                queryParams: params,
                cyIdentifier: 'menu-order',
                exact: false,
              },
            ]
          : []),
        ...(activeModules.relation
          ? [
              {
                label: this.transloco.selectTranslate('menu.relations'),
                icon: 'uil-user',
                routerLink: '/relation',
                queryParams: params,
                cyIdentifier: 'menu-relation',
                exact: false,
              },
            ]
          : []),
        ...(activeModules.contract
          ? [
              {
                label: this.transloco.selectTranslate('menu.contracts'),
                icon: 'uil-file-share-alt',
                routerLink: '/administration/contract',
                queryParams: params,
                cyIdentifier: 'menu-contract',
                exact: false,
              },
            ]
          : []),
        ...(activeModules.delivery
          ? [
              {
                label: this.transloco.selectTranslate('menu.deliveries'),
                icon: 'uil-truck',
                routerLink: '/administration/delivery',
                queryParams: params,
                cyIdentifier: 'menu-deliveries',
                exact: false,
              },
            ]
          : []),
        ...(activeModules.invoice && !this.authService.isMediator()
          ? [
              {
                label: this.transloco.selectTranslate('menu.invoices'),
                icon: 'uil-file-contract-dollar',
                routerLink: '/administration/invoice',
                queryParams: params,
                cyIdentifier: 'menu-invoice',
                exact: false,
              },
            ]
          : []),
        ...(activeModules.search
          ? [
              {
                label: this.transloco.selectTranslate('menu.search'),
                icon: 'uil-search',
                routerLink: '/search',
                queryParams: params,
                cyIdentifier: 'menu-search',
                exact: false,
              },
            ]
          : []),
      ],
      secondary: [
        {
          label: this.transloco.selectTranslate('modules.user.password.menu'),
          icon: 'uil-edit',
          routerLink: '/user/password',
          cyIdentifier: 'menu-password',
          exact: false,
        },
        {
          label: this.transloco.selectTranslate('menu.reload'),
          icon: 'uil-refresh',
          callback: () => {
            this.modalService
              .createModal(ConfirmDialogComponent, {
                message: translate('modules.reload.confirm.message'),
                confirmButtonText: translate('modules.reload.confirm.ok'),
                cancelButtonText: translate('modules.reload.confirm.cancel'),
                buttonColor: 'warn',
              })
              .dialog.onSave()
              .then((confirmed) => {
                if (confirmed) {
                  window.location.reload();
                }
              });
          },
          cyIdentifier: 'menu-refresh',
          exact: false,
        },
        {
          label: this.transloco.selectTranslate('modules.auth.logout'),
          icon: 'uil-signout',
          callback: () => {
            this.modalService
              .createModal(ConfirmDialogComponent, {
                message: translate('modules.auth.confirm.message'),
                confirmButtonText: translate('modules.auth.confirm.ok'),
                cancelButtonText: translate('modules.auth.confirm.cancel'),
                buttonColor: 'warn',
              })
              .dialog.onSave()
              .then((confirmed) => {
                if (confirmed) {
                  this.authService.logout();
                  this.router.navigate(['/auth/login']);
                }
              });
          },
          cyIdentifier: 'menu-refresh',
          exact: false,
        },
      ],
    };
  }
}
