import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Delivery, DeliveryReport, Invoice, Order, OrderMatch } from '../contracts';
import { BaseService } from './base.service';
import { tap } from 'rxjs/operators';
import { DownloadFileService } from './download-file.service';

@Injectable({
  providedIn: 'root',
})
export class OrderMatchDataService extends BaseService<OrderMatch> {
  path = 'order_matches';

  storeDeliveries(id: number, deliveries: Pick<OrderMatch, 'deliveries'>): Observable<OrderMatch> {
    return this.http.post<OrderMatch>(`${this.apiConfig.baseUrl}/${this.path}/${id}/deliveries`, deliveries);
  }

  deliveryInfoToProcess(id: number, orderMatch: Partial<OrderMatch>): Observable<OrderMatch> {
    return this.http.post<OrderMatch>(`${this.apiConfig.baseUrl}/${this.path}/${id}/info_to_process`, orderMatch);
  }

  deliveryDelivered(id: number, delivery: Partial<Delivery>): Observable<Delivery> {
    return this.http.post<Delivery>(`${this.apiConfig.baseUrl}/${this.path}/${id}/delivered`, delivery);
  }

  sendWeightRequests(orderMatch: OrderMatch, messages: any[]) {
    return this.http.post<OrderMatch>(`${this.apiConfig.baseUrl}/${this.path}/${orderMatch.id}/send_weight_requests`, {
      messages,
    });
  }

  downloadInvoice(orderMatch: OrderMatch): Observable<any> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${orderMatch.id}/download_invoice`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  sendInvoice(orderMatch: OrderMatch, data: any) {
    return this.http.post<OrderMatch>(`${this.apiConfig.baseUrl}/${this.path}/${orderMatch.id}/send_invoice`, data);
  }
}
