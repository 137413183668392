export enum ServerSentEventTopic {
  Order = 'order',
  OrderMatch = 'order_match',
  Dossier = 'dossier',
}

export enum ServerSentEventAction {
  StartRefresh = 'start_refresh',
  Refresh = 'refresh',
}

export interface ServerSentEventResponse {
  action: ServerSentEventAction;
  [key: string]: any;
}
