import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Product, ProductVarietyOption } from '../contracts';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductDataService extends BaseService<Product> {
  path = 'products';

  public getProductOptions(): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.apiConfig.baseUrl}/${this.path}/options`);
  }

  public getProductVarietyOptions(): Observable<ProductVarietyOption[]> {
    return this.http.get<ProductVarietyOption[]>(`${this.apiConfig.baseUrl}/${this.path}/variety_options`);
  }
}
