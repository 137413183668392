import { AuthService } from '@ppa/data';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { ConfirmDialogComponent } from '../../modals';
import { ModalService } from '../../services';
import { translate } from '@ngneat/transloco';

export enum MenuState {
  In = 'in',
  Out = 'out',
}

@Component({
  selector: 'ppa-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('toggleNav', [
      state(
        MenuState.Out,
        style({
          marginLeft: '0',
        }),
      ),

      state(
        MenuState.In,
        style({
          marginLeft: '-15rem',
        }),
      ),

      transition('* <=> *', animate('500ms ease-in-out')),
    ]),
  ],
})
export class MenuComponent implements OnInit {
  @Input() primary = [];
  @Input() secondary = [];

  showNav = MenuState.In;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.closeNav();
      }
    });
  }

  get navOpen(): boolean {
    return this.showNav === MenuState.Out;
  }

  get navClosed(): boolean {
    return this.showNav === MenuState.In;
  }

  ngOnInit(): void {}

  toggleNav() {
    this.showNav = this.showNav === MenuState.In ? MenuState.Out : MenuState.In;
  }

  closeNav() {
    this.showNav = MenuState.In;
  }

  password() {
    this.router.navigate(['/user/password']);
  }
}
