<form [formGroup]="locationForm">
  <mat-slide-toggle formControlName="useCurrentLocation" (change)="toggleUseCurrentLocation($event)">
    {{ 'modules.location.modal.location_label' | transloco }}
  </mat-slide-toggle>

  <div class="no-location-error" *ngIf="cantUseCurrentLocation">
    {{ 'modules.location.modal.location_error' | transloco }}
  </div>

  <mat-form-field appearance="fill" *ngIf="!this.dossiers">
    <input
      matInput
      [placeholder]="'modules.location.modal.address_placeholder' | transloco"
      formControlName="address"
      data-cy="create-dossier-selected-location-field"
    />
  </mat-form-field>
</form>

<div class="map-container" *ngIf="mapRender$ | async">
  <mgl-map
    [style]="viewUrl"
    [zoom]="zoom"
    [center]="location"
    (zoomEnd)="zoomEnd($event)"
    (load)="loaded($event)"
    (click)="mapClick($event)"
  >
    <mgl-control mglGeocoder></mgl-control>
    <mgl-marker *ngIf="marker" [lngLat]="marker"></mgl-marker>
    <mgl-marker *ngFor="let marker of markers" [lngLat]="marker.coords"></mgl-marker>
  </mgl-map>
</div>

<mat-slide-toggle checked (change)="changeView()">{{
  'modules.location.modal.satellite' | transloco
}}</mat-slide-toggle>
<button
  *ngIf="!this.dossiers && editable"
  mat-flat-button
  color="warn"
  class="button-remove-location"
  [disabled]="locationCanBeRemoved"
  (click)="clearAddress()"
  data-cy="create-dossier-remove-location"
>
  {{ 'modules.location.modal.remove_location' | transloco }}
</button>
