import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ppa-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() noPadding = false;
  @Input() type = 'default';
  @Input() class = '';

  message = 'general.loading';

  constructor() {}

  ngOnInit(): void {
    if (this.type === 'login') {
      this.message = 'general.logging_in';
    }
  }
}
