import { Component, forwardRef } from '@angular/core';
import { DeliveryPeriod } from './../../contracts/delivery-period';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Period, PeriodService } from './period.service';

@Component({
  selector: 'ppa-delivery-period',
  templateUrl: './delivery-period.component.html',
  styleUrls: ['./delivery-period.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeliveryPeriodComponent),
      multi: true,
    },
  ],
})
export class DeliveryPeriodComponent implements ControlValueAccessor {
  constructor(private fb: FormBuilder, private periodService: PeriodService) {
    this.deliveryPeriodForm = this.fb.group({
      period: [],
      fromDate: [],
      toDate: [],
      onDate: [],
    });

    this.deliveryPeriodForm.get('period').valueChanges.subscribe((value) => {
      const period = this.periods.find((item) => item.key === value);
      this.displayDatePickers$.next(period?.display);
    });

    this.deliveryPeriodForm.valueChanges.subscribe((changes) => {
      const { toDate, fromDate, onDate, period } = changes;
      this.value = {
        toDate: typeof toDate !== 'string' ? toDate?.format('DD-MM-YYYY') ?? null : null,
        fromDate: typeof fromDate !== 'string' ? fromDate?.format('DD-MM-YYYY') ?? null : null,
        onDate: typeof onDate !== 'string' ? onDate?.format('DD-MM-YYYY') ?? null : null,
        periodValue: period?.valueRaw ?? this.periods.find((item) => item.key === period)?.valueRaw,
      };
      this.onTouched(this.value);
      this.onChange(this.value);
    });

    this.displayFromDate$ = this.displayDatePickers$.pipe(map((display) => !!display?.includes('fromDate')));
    this.displayToDate$ = this.displayDatePickers$.pipe(map((display) => !!display?.includes('toDate')));
    this.displayOnDate$ = this.displayDatePickers$.pipe(map((display) => !!display?.includes('onDate')));
    this.periods = this.periodService.getPeriods();
  }

  deliveryPeriodForm: FormGroup;
  displayDatePickers$ = new Subject<string[]>();

  displayFromDate$: Observable<boolean>;
  displayToDate$: Observable<boolean>;
  displayOnDate$: Observable<boolean>;

  periods: Period[] = [];

  value: DeliveryPeriod;

  private static dutchToAmericanDate(value: string) {
    if (!value) {
      return value;
    }

    const [day, month, year] = value.split('-');
    return `${year}-${month}-${day}`;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.deliveryPeriodForm.get('toDate').disable();
      this.deliveryPeriodForm.get('fromDate').disable();
      this.deliveryPeriodForm.get('onDate').disable();
    } else {
      this.deliveryPeriodForm.get('toDate').enable();
      this.deliveryPeriodForm.get('fromDate').enable();
      this.deliveryPeriodForm.get('onDate').enable();
    }
  }

  writeValue(value: DeliveryPeriod): void {
    this.value = value;

    this.deliveryPeriodForm.patchValue({
      toDate: DeliveryPeriodComponent.dutchToAmericanDate(value?.toDate),
      fromDate: DeliveryPeriodComponent.dutchToAmericanDate(value?.fromDate),
      onDate: DeliveryPeriodComponent.dutchToAmericanDate(value?.onDate),
      period: this.periods.find((item) => item.valueRaw === value?.periodValue),
    });

    const periodValue = this.deliveryPeriodForm.get('period').value;
    setTimeout(() => this.displayDatePickers$.next(periodValue?.display));
  }
}
