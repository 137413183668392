import { Observable } from 'rxjs';
import {
  Invoice,
  KeyValuePairs,
  MatchResponse,
  Order,
  OrderMatch,
  OrderType,
  OrderWithGroupedMatches,
  PaginatedResponse,
  PriceUnitIdentifier,
} from '../contracts';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ListResponse } from '../contracts/list-response';
import { ReadResponse } from '../contracts/read-response';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { DownloadFileService } from './download-file.service';
import { OrderTree } from '../contracts/order-tree';
import { translate } from '@ngneat/transloco';
import * as moment from 'moment';
import { DetailField } from '../contracts/detail-field';

@Injectable({
  providedIn: 'root',
})
export class OrderDataService extends BaseService<Order> {
  path = 'orders';

  listPaged(params?: { [key: string]: any }): Observable<PaginatedResponse<Order>> {
    return super.listPaged(params);
  }

  list(params?: { [key: string]: any }): Observable<ListResponse<Order>> {
    return super.list(params).pipe(
      map((orders) => {
        return orders.map((order) => {
          return this.transformLocation(order);
        });
      }),
    );
  }

  read(id: number): Observable<ReadResponse<Order>> {
    return super.read(id).pipe(
      map((order) => {
        order.amount = order.amount.toString().replace('.', ',');
        order.amountOfUnit = order.amountOfUnit.toString().replace('.', ',');
        order.surfaceCultivation = order.surface;

        return this.transformLocation(order);
      }),
    );
  }

  transformLocation(order: Order): Order {
    if (order.dossier && order.dossier.location && order.dossier.location[0] && order.dossier.location[0].street) {
      const location = order.dossier.location[0];
      order.locationLine = `${location.street} ${location.number}, ${location.city}`;
    } else if (order.location && order.location[0] && order.location[0].street) {
      const location = order.location[0];
      order.locationLine = `${location.street} ${location.number}, ${location.city}`;
    }

    return order;
  }

  getChildOrders(id: number): Observable<Partial<Order>[]> {
    return this.http.get<ListResponse<Partial<Order>>>(`${this.apiConfig.baseUrl}/${this.path}/${id}/child_data`);
  }

  getEligibleOrdersToMatch(orderId: number, params: { [header: string]: string }): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.apiConfig.baseUrl}/${this.path}/${orderId}/eligible_orders_to_match`, {
      observe: 'response',
      params,
    });
  }

  getOrderForFulfillOverview(id: number): Observable<ReadResponse<Order>> {
    return this.http.get<ReadResponse<Order>>(
      `${this.apiConfig.baseUrl}/${this.path}/${id}/order_for_fulfill_overview`,
    );
  }

  addMatch(orderId: number, orderMatchId: number): Observable<MatchResponse> {
    return this.http.post<MatchResponse>(`${this.apiConfig.baseUrl}/${this.path}/match/${orderId}/${orderMatchId}`, {});
  }

  removeMatch(orderId: number, orderMatchId: number): Observable<MatchResponse> {
    return this.http.delete<MatchResponse>(
      `${this.apiConfig.baseUrl}/${this.path}/match/${orderId}/${orderMatchId}`,
      {},
    );
  }

  updateMatch(orderId: number, orderMatchId: number, amount: number): Observable<MatchResponse> {
    return this.http.put<MatchResponse>(`${this.apiConfig.baseUrl}/${this.path}/match/${orderId}/${orderMatchId}`, {
      amount,
    });
  }

  updateMatches(orderId: number, orderMatches: { orderMatchId: number; amount: number }[]): Observable<MatchResponse> {
    return this.http.put<MatchResponse>(`${this.apiConfig.baseUrl}/${this.path}/matches/${orderId}`, {
      orderMatches,
    });
  }

  confirmMatches(orderId: number, data: any): Observable<void> {
    return this.http.put<void>(`${this.apiConfig.baseUrl}/${this.path}/${orderId}/confirm`, data);
  }

  getOrderForDeliveryOverview(orderId: number): Observable<ReadResponse<OrderWithGroupedMatches>> {
    return this.http.get<ReadResponse<OrderWithGroupedMatches>>(
      `${this.apiConfig.baseUrl}/${this.path}/${orderId}/order_for_delivery_overview`,
    );
  }

  getOrderForContractOverview(orderId: number): Observable<ReadResponse<OrderWithGroupedMatches>> {
    return this.http.get<ReadResponse<OrderWithGroupedMatches>>(
      `${this.apiConfig.baseUrl}/${this.path}/${orderId}/order_for_contract_overview`,
    );
  }

  getOrderForInvoiceOverview(orderId: number): Observable<ReadResponse<OrderWithGroupedMatches>> {
    return this.http.get<ReadResponse<OrderWithGroupedMatches>>(
      `${this.apiConfig.baseUrl}/${this.path}/${orderId}/order_for_invoice_overview`,
    );
  }

  getOrderForPoolOverview(orderId: number): Observable<ReadResponse<OrderWithGroupedMatches>> {
    return this.http.get<ReadResponse<OrderWithGroupedMatches>>(
      `${this.apiConfig.baseUrl}/${this.path}/${orderId}/order_for_pool_overview`,
    );
  }

  getOrderHistory(orderId: number): Observable<ReadResponse<OrderTree>> {
    return this.http.get<ReadResponse<OrderTree>>(`${this.apiConfig.baseUrl}/${this.path}/${orderId}/history`);
  }

  sendOnCallList(order: Order, message: string): Observable<void> {
    return this.http.post<void>(`${this.apiConfig.baseUrl}/${this.path}/${order.id}/send_on_call_list`, message);
  }

  downloadOnCallList(order: Order): Observable<any> {
    return this.http
      .get(`${this.apiConfig.baseUrl}/${this.path}/${order.id}/download_on_call_list`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }

  getMergableInvoices(order: Order, invoice: Invoice): Observable<Invoice[]> {
    const params = new HttpParams().set('exclude', invoice.id.toString());
    return this.http.get<Invoice[]>(`${this.apiConfig.baseUrl}/${this.path}/${order.id}/invoices_to_merge`, {
      params,
    });
  }

  createBulkOrders(values: KeyValuePairs<number>): Observable<ReadResponse<Order>> {
    return this.http.post<ReadResponse<Order>>(`${this.apiConfig.baseUrl}/${this.path}/create_bulk_orders`, values);
  }

  saveContactType(order: Order, data: string, deleteContracts: boolean = false): Observable<void> {
    return this.http.put<void>(`${this.apiConfig.baseUrl}/${this.path}/${order.id}/save_contract_type`, {
      data,
      deleteContracts,
    });
  }

  showAmountUnitSuffix(order: Order): boolean {
    const amountUnit = order.amountUnit && order.amountUnit.identifier !== PriceUnitIdentifier.Price_unit_per_stuk;
    const productForce = order.product?.defaultValues?.forceAmount || false;
    return amountUnit && !productForce;
  }

  percentFulfilled(fulfilled: number, amount: number): string {
    return ((fulfilled / amount) * 100).toFixed(0);
  }

  addDossierToOrder(orderId: number, dossierId: number): void {
    this.http
      .post<ReadResponse<Order>>(`${this.apiConfig.baseUrl}/${this.path}/add_dossier`, { orderId, dossierId })
      .subscribe((order) => {});
  }

  addParentToOrder(orderId: number, parentId: number): Observable<ReadResponse<Order>> {
    return this.http.post<ReadResponse<Order>>(`${this.apiConfig.baseUrl}/${this.path}/add_parent`, {
      orderId,
      parentId,
    });
  }

  openOrder(order: Order) {
    return this.http.post<ReadResponse<Order>>(`${this.apiConfig.baseUrl}/${this.path}/${order.id}/open_order`, {});
  }

  closeOrder(order: Order) {
    return this.http.post<ReadResponse<Order>>(`${this.apiConfig.baseUrl}/${this.path}/${order.id}/close_order`, {});
  }

  storeDeliveries(id: number, deliveries: Pick<Order, 'deliveries'>): Observable<Order> {
    return this.http.post<Order>(`${this.apiConfig.baseUrl}/${this.path}/${id}/deliveries`, deliveries);
  }

  getDetailFields(details: Order): DetailField[] {
    return [
      {
        label: 'modules.order.create.sections.common',
        section: true,
        show: ['relation.companyName', 'currentPlace', 'mediator.lastname', 'createdAt', 'updatedAt'],
      },
      {
        label: 'modules.order.create.order_relation_label',
        field: 'relation',
        bindLabel: 'companyName',
      },
      {
        label: 'modules.order.create.current_place_label',
        field: 'currentPlace',
        valueCallback: (value) => translate('modules.order.create.current_place_options.' + value),
      },
      {
        label: 'modules.order.create.mediator_label',
        field: 'mediator',
        valueCallback: (value) => value.firstname + ' ' + value.lastname,
      },
      {
        label: 'sorting.created_at',
        field: 'createdAt',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          return moment(date).format('DD-MM-YYYY HH:mm');
        },
      },
      {
        label: 'sorting.updated_at',
        field: 'updatedAt',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          return moment(date).format('DD-MM-YYYY HH:mm');
        },
      },
      {
        label: 'modules.order.create.sections.product',
        section: true,
        show: [
          'product.title',
          'productVariety.title',
          'organic',
          'harvestYear',
          'amountOfUnit',
          'amountUnit.title',
          'amount',
          'treatment',
        ],
      },
      {
        label: 'modules.order.create.product_label',
        field: 'product',
        bindLabel: 'title',
      },
      {
        label: 'modules.order.create.product_variety_label',
        field: 'productVariety',
        bindLabel: 'title',
      },
      {
        label: 'modules.dossier.create.organic_label',
        field: 'organic',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.harvest_year_label',
        field: 'harvestYear',
      },
      {
        label: 'modules.order.create.amount_label',
        field: 'amountOfUnit',
        suffix: {
          field: 'amountUnit',
          bindLabel: 'title',
        },
      },
      {
        label: false,
        field: 'amount',
        suffix: {
          value: 'ton',
        },
      },
      {
        label: 'modules.dossier.create.treatment_label',
        field: 'treatmentCollection',
        bindLabel: 'name',
      },
      {
        label: 'modules.relations.create.note',
        field: 'productNote',
      },
      {
        label: 'modules.dossier.create.sections.cultivation',
        section: true,
        show: ['surfaceCultivation', 'cultivationNote'],
      },
      {
        label: 'modules.dossier.create.surface_label',
        field: 'surfaceCultivation',
        suffix: {
          value: 'ha',
        },
      },
      {
        label: 'modules.relations.create.note',
        field: 'cultivationNote',
      },
      {
        label: 'modules.dossier.create.sections.sale',
        section: true,
        show: ['sizeSorting', 'packaging', 'onCall', 'onCallNote', 'saleNote'],
      },
      {
        label: 'modules.dossier.create.size_sorting_label',
        field: 'sizeSorting',
      },
      {
        label: 'modules.dossier.create.packaging_label',
        field: 'packaging',
      },
      {
        label: 'modules.order.create.on_call_label',
        field: 'onCall',
        showCallback: (d) => d.orderType === OrderType.Sell && d.breedingSelling !== 'breeding',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.order.create.on_call_note_label',
        field: 'onCallNote',
      },
      {
        label: 'modules.relations.create.note',
        field: 'saleNote',
      },
      {
        label: 'modules.dossier.create.sections.seeds',
        section: true,
        show: ['fungicideCoating', 'seedsNote'],
      },
      {
        label: 'modules.order.create.on_call_note_label',
        field: 'fungicideCoating',
      },
      {
        label: 'modules.relations.create.note',
        field: 'seedsNote',
      },
      {
        label: 'modules.dossier.create.sections.stage',
        section: true,
        show: [
          'stage',
          'barnDiseases',
          'preferredDeliveryTime',
          'storageBox',
          'colorGrade',
          'meadowDiseases',
          'preferenceDelivery',
          'expectedHarvest',
        ],
      },
      {
        label: 'modules.dossier.create.stage_label',
        field: 'stage',
        valueCallback: (value) => translate('modules.dossier.create.stage_options.' + value),
      },
      {
        label: 'modules.dossier.create.preferred_delivery_time_label',
        field: 'preferredDeliveryTime',
        valueCallback: (value) => translate('months.' + value),
        showCallback: (d) => d.stage === 'barn',
      },
      {
        label: 'modules.dossier.create.barn_diseases_label',
        field: 'barnDiseases',
        bindLabel: 'name',
        showCallback: (d) => d.stage === 'barn',
      },
      {
        label: 'modules.dossier.create.storage_box_label',
        field: 'storageBox',
        valueCallback: (value) => translate('modules.dossier.create.storage_box_options.' + value),
        showCallback: (d) => d.stage === 'barn',
      },
      {
        label: 'modules.dossier.create.color_grade_label',
        field: 'colorGrade',
        showCallback: (d) => d.stage === 'barn',
      },
      {
        label: 'modules.dossier.create.expected_harvest_exact_date_label',
        field: 'expectedHarvestExactDate',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
        showCallback: (d) => d.stage === 'off_meadow',
      },
      {
        label: 'modules.dossier.create.preference_delivery_label',
        field: 'preferenceDelivery',
        valueCallback: (value) => translate('modules.dossier.create.preference_delivery_options.' + value),
      },
      {
        label: 'modules.dossier.create.expected_harvest_label',
        field: 'expectedHarvest',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          if (details.expectedHarvestExactDate) {
            return moment(date).format('DD-MM-YYYY');
          }
          return 'Week ' + moment(date).format('W');
        },
        showCallback: (d) => d.stage === 'off_meadow',
      },
      {
        label: 'modules.dossier.create.meadow_diseases_label',
        field: 'meadowDiseases',
        bindLabel: 'name',
        showCallback: (d) => d.stage === 'off_meadow',
      },
      {
        label: 'modules.dossier.create.surface_label',
        field: 'surface',
        suffix: {
          value: 'ton',
        },
        showCallback: (d) => d.surfaceCultivation === null,
      },
      {
        label: 'modules.relations.create.note',
        field: 'stageNote',
      },
      {
        label: 'modules.dossier.create.sections.quality',
        section: true,
        show: [
          'tare',
          'tareTestSample',
          'gritGrade',
          'grit10Onions',
          'grit10OnionsMeadow',
          'gritTestSample',
          'quality',
          'industryQuality',
          'primed',
          'fungicideTreated',
          'checmical',
          'fungicideAndInsectTreated',
          'piled',
          'sproutInhibitor',
          'chlorineProfam',
          'qualityNote',
        ],
      },
      {
        label: 'modules.dossier.create.tare_label',
        field: 'tare',
        valueCallback: (value) => translate('modules.dossier.create.tare_options.' + value),
      },
      {
        label: 'modules.dossier.create.tare_test_sample_label',
        field: 'tareTestSample',
        suffix: {
          value: '%',
        },
      },
      {
        label: 'modules.dossier.create.grit_grade_label',
        field: 'gritGrade',
        valueCallback: (value) => translate('modules.dossier.create.grit_grade_options.' + value),
      },
      {
        label: 'modules.dossier.create.grit10_onions_meadow_label',
        field: 'grit10Onions',
        suffix: {
          value: 'cm',
        },
        showCallback: (d) => d.stage === 'off_meadow',
      },
      {
        label: 'modules.dossier.create.grit10_onions_label',
        field: 'grit10Onions',
      },
      {
        label: 'modules.dossier.create.grit_test_sample_label',
        field: 'gritTestSample',
        suffix: {
          value: '%',
        },
      },
      {
        label: 'modules.dossier.create.quality_label',
        field: 'quality',
        valueCallback: (value) => translate('modules.dossier.create.quality_options.' + value),
      },
      {
        label: 'modules.dossier.create.industry_quality_label',
        field: 'industryQuality',
        valueCallback: (value) => translate('modules.dossier.create.industry_quality_options.' + value),
        showCallback: (d) => d.quality === 'industry',
      },
      {
        label: 'modules.dossier.create.primed_label',
        field: 'primed',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.fungicide_treated_label',
        field: 'fungicideTreated',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.chemical_label',
        field: 'checmical',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.fungicide_and_insect_treated_label',
        field: 'fungicideAndInsectTreated',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.piled_label',
        field: 'piled',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.dossier.create.sprout_inhibitor_label',
        field: 'sproutInhibitor',
        valueCallback: (value) => translate('modules.dossier.create.sprout_inhibitor_options.' + value),
      },
      {
        label: 'modules.dossier.create.chlorine_profam',
        field: 'chlorineProfam',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.relations.create.note',
        field: 'qualityNote',
      },
      {
        label: 'modules.dossier.create.sections.trade',
        section: true,
        show: ['expiresAt', 'indicationPrice', 'indicationPriceUnit', 'tradeNote'],
      },
      {
        label: 'modules.dossier.create.expires_at_label',
        field: 'expiresAt',
        valueCallback: (value) => {
          const date = new Date(Date.parse(value));
          return moment(date).format('DD-MM-YYYY HH:mm').replace(' 00:00', '');
        },
      },
      {
        label: 'modules.dossier.create.indication_price',
        field: 'indicationPrice',
        valueCallback: (value) => {
          if (value) {
            if (details.indicationPriceUnit?.prefix) {
              value = value + ' ' + details.indicationPriceUnit.prefix.toLowerCase();
            }
            if (details.indicationPriceUnit?.title !== 'eenheden') {
              value = value + ' ' + details.indicationPriceUnit?.title;
            }
            return '€' + value;
          }

          return false;
        },
      },
      {
        label: 'modules.dossier.create.participation',
        field: 'participation',
        valueCallback: (value) => translate('data_table.boolean.' + (value ? 'true' : 'false')),
      },
      {
        label: 'modules.relations.create.note',
        field: 'tradeNote',
      },
      {
        label: 'modules.dossier.create.sections.others',
        section: true,
        show: ['certification', 'otherNote'],
      },
      {
        label: 'modules.dossier.create.certificates',
        field: 'certification',
        bindLabel: 'name',
      },
      {
        label: 'modules.relations.create.note',
        field: 'otherNote',
      },
    ];
  }
}
