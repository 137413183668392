import { DownloadFileService, Photo } from '@ppa/data';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'ppa-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition('* => void', [animate('300ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CarouselComponent implements OnInit, OnChanges {
  @Input() slides: Photo[] = [];
  @Input() showText = false;
  @Input() class = '';

  currentSlide = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes('slides')) {
      this.currentSlide = 0;
    }
  }

  ngOnInit(): void {}

  previous() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  next() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }

  openPhoto(slide): void {
    const slideUrl = slide.id < 0 ? DownloadFileService.getBlobUrl(slide) : slide.url;
    window.open(slideUrl, '_blank');
  }
}
