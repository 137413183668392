import { Component } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ppa-required-control',
  template: ``,
})
export abstract class RequiredControlComponent {
  abstract control: NgControl;

  isRequired(): Observable<boolean> {
    try {
      if (this.control?.control) {
        // @ts-ignore
        const validators = this.control?.control?.validator('');
        if (validators.required) {
          return of(true);
        }
      }
    } catch (e) {
      return of(false);
    }

    return of(false);
  }
}
