import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractWizardStepComponent } from '@ppa/layout';
import { Photo } from '@ppa/data';
import { DossierService } from '../../services/dossier.service';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'ppa-dossier-wizard-add-photo',
  templateUrl: './dossier-wizard-add-photo.component.html',
  styleUrls: ['./dossier-wizard-add-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DossierWizardAddPhotoComponent extends AbstractWizardStepComponent implements OnInit {
  localPhotos$: Observable<Photo[]>;

  constructor(private dossierService: DossierService) {
    super();
  }

  ngOnInit() {
    this.localPhotos$ = this.dossierService.getDossierWizardState().pipe(
      take(1),
      switchMap((dossier) => of(dossier.localPhotos || [])),
    );
  }

  handleUpdate(localPhotos: Photo[]) {
    this.dossierService.setDossierWizardState({ localPhotos });

    this.dirty$.next(true);
  }

  handleUnload() {
    super.handleUnload();

    this.dossierService.clearDossierWizardState();
  }
}
