import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Relation } from '@ppa/data';

@Component({
  selector: 'ppa-overview-filtered-on-relation',
  templateUrl: './overview-filtered-on-relation.component.html',
  styleUrls: ['./overview-filtered-on-relation.component.scss'],
})
export class OverviewFilteredOnRelationComponent implements OnInit {
  @Input() relation: Relation;
  @Output() backClicked = new EventEmitter<void>();
  @Output() showAllClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
