import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoolOverviewCardComponent } from './pool-overview-card.component';
import { MatButtonModule } from '@angular/material/button';
import { LoadingModule } from '../loading';
import { RelationStatusModule } from "../relation-status";

@NgModule({
  declarations: [PoolOverviewCardComponent],
  imports: [CommonModule, MatButtonModule, LoadingModule, RelationStatusModule],
  exports: [PoolOverviewCardComponent],
})
export class PoolOverviewCardModule {}
