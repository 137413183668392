import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TareTemplate } from '@ppa/data';
import { ListResponse } from '../contracts/list-response';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TareTemplateDataService extends BaseService<TareTemplate> {
  path = 'tare_templates';

  list(params?: { [p: string]: any }): Observable<ListResponse<TareTemplate>> {
    return super.listPaginated({}).pipe(switchMap((templates) => of(templates.data)));
  }

  getTareTemplateOptions(): Observable<ListResponse<TareTemplate>> {
    return this.http.get<ListResponse<TareTemplate>>(`${this.apiConfig.baseUrl}/${this.path}/options`);
  }
}
