import { NgModule } from '@angular/core';
import { ButtonToggleComponent } from './button-toggle.component';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [ButtonToggleComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule
  ],
  exports: [ButtonToggleComponent],
  providers: [],
})
export class ButtonToggleModule {}
