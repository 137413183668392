import { Component } from '@angular/core';
import { AbstractWizardStepComponent } from '@ppa/layout';
import { DossierService } from '../../services/dossier.service';
import { Relation } from '@ppa/data';

@Component({
  selector: 'ppa-select-relation-wizard-step',
  templateUrl: './dossier-wizard-select-relation.component.html',
  styleUrls: ['./dossier-wizard-select-relation.component.scss'],
})
export class DossierWizardSelectRelationComponent extends AbstractWizardStepComponent {
  showAddRelation = false;

  constructor(private dossierService: DossierService) {
    super();
  }

  handleRelationSelected(relation: Relation) {
    this.dossierService.setDossierWizardState({ relation });

    // This is only because this step has no visible next button
    this.requestNext$.next();
  }

  handleRelationCreated(relation: Relation) {
    this.handleRelationSelected(relation);
  }

  handleUnload() {
    super.handleUnload();

    this.dossierService.clearDossierWizardState();
  }

  toggleAddRelation($event) {
    this.showAddRelation = $event;
  }
}
