import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractFieldComponent } from './contract-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SelectModule } from '../select';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BUTTONS, NgxWigModule } from 'ngx-wig';
import { EditorButtonService } from '../../services/editor-button.service';

@NgModule({
  declarations: [ContractFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SelectModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSlideToggleModule,
    NgxWigModule,
  ],
  exports: [ContractFieldComponent],
  providers: [{ provide: BUTTONS, multi: true, useClass: EditorButtonService }],
})
export class ContractFieldModule {}
