import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Observable, of, Subject } from 'rxjs';
import { ContractTemplateField } from '@ppa/data';
import { ModalService } from '../../services';

const NORMAL_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'ppa-contract-field',
  templateUrl: './contract-field.component.html',
  styleUrls: ['./contract-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractFieldComponent),
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useValue: NORMAL_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  ],
})
export class ContractFieldComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() field: any;
  @Input() defaultTemplate$: Observable<boolean> = of(false);
  @Input() required$: Observable<boolean> = of(false);
  @Input() loading$ = new Subject<boolean>();
  @Output() fieldData = new EventEmitter<ContractTemplateField>();

  fieldForm: FormGroup;

  destroy$ = new Subject<void>();

  value: string;
  positionOverwritten = false;
  overwrite = true;
  allowOverwrite = false;
  identifier = '';
  type = '';
  currentSelection;
  positionOverwritten$: Observable<boolean> = of(false);

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private fb: FormBuilder, public modalService: ModalService, private cdr: ChangeDetectorRef) {
    this.fieldForm = this.fb.group({
      label: { value: null, disabled: true },
      content: { value: null, disabled: true },
      active: { value: null, disabled: true },
      required: { value: null, disabled: true },
      position: { value: null, disabled: true },
    });
  }

  get controls() {
    return this.fieldForm.controls;
  }

  get fieldType(): string {
    if (this.type === 'position') {
      return this.field.default.type;
    }

    return this.type;
  }

  ngOnInit(): void {
    if (this.field.variant && this.field.variant.hasOwnProperty('identifier')) {
      this.fieldForm.get('label').patchValue(this.field.variant.label);
      this.fieldForm.get('content').patchValue(this.field.variant.content);
      this.fieldForm.get('active').patchValue(this.field.variant.active);
      this.fieldForm.get('required').patchValue(this.field.variant.required);
      this.fieldForm.get('position').patchValue(this.field.variant.position);

      this.identifier = this.field.variant.identifier || this.field.default.identifier;
      this.type = this.field.variant.type;

      if (this.field.variant?.overwritten) {
        this.overwriteContent();
      } else if (!this.field.hasOwnProperty('default')) {
        this.overwriteContent();
      } else {
        this.clearContent();
      }

      this.positionOverwritten = this.field.variant.positionOverwritten;
    } else {
      this.fieldForm.get('label').patchValue(this.field.default.label);
      this.fieldForm.get('content').patchValue(this.field.default.content);
      this.fieldForm.get('active').patchValue(this.field.default.active);
      this.fieldForm.get('required').patchValue(this.field.default.required);
      this.fieldForm.get('position').patchValue(this.field.default.position);

      this.identifier = this.field.default.identifier;
      this.type = this.field.default.type;

      this.clearContent();
    }

    this.defaultTemplate$.subscribe((defaultTemplate) => {
      this.allowOverwrite = defaultTemplate === false;
    });

    this.fieldForm.valueChanges.subscribe((value) => {
      this.emitFormData();
    });

    this.loading$.subscribe(() => {
      if (this.field.variant && this.field.variant.hasOwnProperty('identifier')) {
        this.positionOverwritten = this.field.variant.positionOverwritten;
        this.positionOverwritten$ = of(this.positionOverwritten);
      }
      this.cdr.detectChanges();
      this.emitFormData();
    });

    this.cdr.detectChanges();
  }

  emitFormData() {
    const value = this.fieldForm.value;
    if (!this.field.variant.hasOwnProperty('identifier')) {
      this.field.variant = {
        ...this.field.default,
        id: null,
      };
    }

    this.field.variant.label = value.label;
    this.field.variant.content = value.content;
    this.field.variant.active = value.active;
    this.field.variant.required = value.required;
    this.field.variant.overwritten = this.overwrite;

    if (this.field.hasOwnProperty('default')) {
      if (this.overwrite) {
        this.field.variant.type = this.field.default.type;
      } else {
        if (this.field.variant?.position !== this.field.default?.position) {
          this.field.variant.positionOverwritten = true;
          this.positionOverwritten = true;
          this.positionOverwritten$ = of(this.positionOverwritten);
          this.cdr.detectChanges();
        }
      }
    }

    this.fieldData.emit(this.field.variant);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.fieldForm.disable() : this.fieldForm.enable();
  }

  writeValue(value: string): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  clearContent() {
    this.fieldForm.reset();

    this.fieldForm.get('label').patchValue(this.field.default.label);
    this.fieldForm.get('content').patchValue(this.field.default.content);
    this.fieldForm.get('active').patchValue(this.field.default.active);
    this.fieldForm.get('required').patchValue(this.field.default.required);
    this.fieldForm.get('position').patchValue(this.field.default.position);

    this.fieldForm.get('label').disable();
    this.fieldForm.get('content').disable();
    this.fieldForm.get('active').disable();
    this.fieldForm.get('required').disable();
    this.fieldForm.get('position').disable();

    this.overwrite = false;
    this.emitFormData();
  }

  overwriteContent() {
    this.fieldForm.get('label').enable();
    this.fieldForm.get('content').enable();
    this.fieldForm.get('active').enable();
    this.fieldForm.get('required').enable();
    this.fieldForm.get('position').enable();

    this.overwrite = true;
    this.emitFormData();
  }

  label(label: string): string {
    if (label) {
      if (label.indexOf('text_') > -1) {
        label = 'freeText';
      }
      const key = 'modules.dossier_properties.' + label;
      let translation = translate(key);

      if (translation === key) {
        translation = '';
      }

      return translation;
    }

    return label;
  }
}
