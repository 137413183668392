import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileService } from './file.service';
import { translate } from '@ngneat/transloco';
import { ErrorHandlerService } from '../../services';

@Component({
  selector: 'ppa-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit, ControlValueAccessor, OnDestroy {
  private readonly destroy$ = new Subject();
  fileUploadForm: FormGroup;
  value: FormArray;

  get attachments() {
    return this.fileUploadForm.get('attachments') as FormArray;
  }

  @Input() items: any[] = [];
  @Input() multiple = false;
  @Input() showHeading = true;

  @Output() handleAttachments = new EventEmitter<FormArray>();

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(
    protected fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private fileService: FileService,
    private errorHandler: ErrorHandlerService,
  ) {
    this.fileUploadForm = this.fb.group({
      attachments: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.fileUploadForm.get('attachments').patchValue(this.items);
    this.addAttachmentsFormControl(this.items);

    this.fileUploadForm
      .get('attachments')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((t) => {
        this.handleAttachments.emit(this.attachments.value);
      });
  }

  ngOnDestroy(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  addAttachment(event: any) {
    const { files } = event.target;

    for (const item of files) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = (_event) => {
        const attachment = {
          id: -1,
          base64: _event.target.result as string,
          mime: item.type,
          file: item.name,
        };
        this.attachments.push(this.fb.group(attachment));
      };
    }
  }

  removeAttachment(event: Event, index: number) {
    const element = (event.target as HTMLElement).parentElement;
    const listItem = element.closest('.list__item');
    if (listItem) {
      listItem.classList.add('list__item--delete');
    }
    this.attachments.removeAt(index);
  }

  private addAttachmentsFormControl(attachments: any[]): void {
    this.attachments.clear();
    attachments.map((attachment) => {
      this.attachments.push(attachment);
    });
  }

  downloadAttachment(attachment) {
    if (attachment.id < 0 && attachment.base64) {
      this.fileService.downloadBlob(attachment);
    } else {
      return this.fileService
        .downloadFile(attachment)
        .pipe(take(1))
        .subscribe(
          (res) => {},
          (errorBlob) => {
            errorBlob.error.text().then((errorText) => {
              const error = JSON.parse(errorText);
              this.errorHandler.handleAPIError(translate('modules.dossier.download.failed'), { error, status: 400 });
            });
          },
        );
    }
  }
}
