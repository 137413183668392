import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Modal, ModalService, ConfirmDialogComponent, Intention, PPAToastrService } from '@ppa/layout';
import { translate } from '@ngneat/transloco';
import { COMMISSION_SHORTCODE, Contract, Order, OrderType, Relation } from '@ppa/data';
import { ContractService } from '../../services/contract.service';
import { ErrorHandlerService } from '@ppa/layout';

@Component({
  selector: 'ppa-contract-send-modal',
  templateUrl: './contract-send-modal.component.html',
})
export class ContractSendModalComponent extends Modal implements OnInit {
  sendContractForm: FormGroup;
  order: Order;
  matchedOrder: Order;
  contract: Contract;
  cyIdentifiers: string[];

  attentionOf: Relation[];

  relationChangedAfterContract = false;
  formChecked = false;

  constructor(
    _modalService: ModalService,
    private fb: FormBuilder,
    private contractService: ContractService,
    private toastrService: PPAToastrService,
    private errorHandler: ErrorHandlerService,
  ) {
    super(_modalService);

    this.sendContractForm = this.fb.group({
      messages: this.fb.array([]),
    });

    this.cyIdentifiers = ['first-message', 'second-message'];
  }

  ngOnInit() {
    if (this.contract.contractTemplate.shortCode === COMMISSION_SHORTCODE) {
      this.attentionOf = [this.order.relation, this.matchedOrder.relation];
      this.messages.push(this.createMessageFormGroup(this.order.relation));
      this.messages.push(this.createMessageFormGroup(this.matchedOrder.relation));
    } else {
      if (this.order.orderType === OrderType.Buy) {
        this.attentionOf = [this.order.relation];
        this.messages.push(this.createMessageFormGroup(this.order.relation));
      } else {
        this.attentionOf = [this.matchedOrder.relation];
        this.messages.push(this.createMessageFormGroup(this.matchedOrder.relation));
      }
    }

    this.relationChangedAfterContract = new Date(this.contract.relation.updatedAt) > new Date(this.contract.updatedAt);
  }

  get messages() {
    return this.sendContractForm.get('messages') as FormArray;
  }

  send() {
    if (this.sendContractForm.valid) {
      this.modalService
        .createModal(ConfirmDialogComponent, {
          message: translate('modules.contract.send.modal.confirm'),
          confirmButtonText: translate('modules.contract.send.modal.ok'),
          buttonColor: 'primary',
        })
        .dialog.onSave()
        .then(() => {
          this.contractService.sendContract(this.contract, this.sendContractForm.value).subscribe(
            () => {
              this.toastrService.displayToastr({
                intention: Intention.Success,
                title: translate('modules.contract.send.confirmation'),
                icon: 'uil-check',
                duration: 3000,
              });
              this.save(true);
            },
            (error) => {
              this.errorHandler.handleAPIError(translate(''), error);
            },
          );
        });
    } else {
      this.formChecked = true;
    }
  }

  private createMessageFormGroup(relation?: Relation) {
    let email = null;

    if (relation) {
      email = relation.companyName + ' <' + relation.email + '>';
    }

    return this.fb.group({
      to: [email, [Validators.required]],
      cc: [null],
      bcc: [null],
      subject: [null],
      message: [translate('modules.contract.send.default_send_message'), [Validators.required]],
    });
  }
}
