import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimePickerComponent } from './datetime-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SelectModule } from '../select';
import { TranslocoModule } from '@ngneat/transloco';
import { TimePickerModule } from '../time-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DatetimePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SelectModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatDatepickerModule,
    TimePickerModule,
    MatButtonModule,
  ],
  exports: [DatetimePickerComponent],
})
export class DatetimePickerModule {}
