<ppa-spinner [status$]="saving$" message="global.saving" icon="save"></ppa-spinner>

<form #form="ngForm" [formGroup]="dossierFormSection">
  <ng-container *transloco="let t">
    <ng-container *ngIf="dossierProductMatrix$ | async as dossierProductMatrix">
      <div class="form-section" *ngIf="qualitySection$ | async">
        <h3>{{ t('modules.dossier.create.sections.quality') }}</h3>

        <ng-container *ngIf="dossierProductMatrix.has('tare')">
          <ppa-select
            [items]="tareGradeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.tare_label"
            formControlName="tare"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.tare.touched) && controls.tare.invalid">
            <div class="form-error" *ngIf="controls.tare.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('tareTestSample')">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('modules.dossier.create.tare_test_sample_label') }}</mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="tareTestSample" />
            <span matSuffix>%</span>
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.tareTestSample.touched) && controls.tareTestSample.invalid">
            <div class="form-error" *ngIf="controls.tareTestSample.errors.min">
              <span>{{ 'form_errors.min' | transloco: { min: 0 } }}</span>
            </div>
            <div class="form-error" *ngIf="controls.tareTestSample.errors.max">
              <span>{{ 'form_errors.max' | transloco: { max: 100 } }}</span>
            </div>
            <div class="form-error" *ngIf="controls.tareTestSample.errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="dossierProductMatrix.has('grit10Onions') && currentStage">
          <ng-container>
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.dossier.create.grit10_onions_label') }}
              </mat-label>
              <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="grit10Onions" />
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.grit10Onions.touched) && controls.grit10Onions.invalid">
              <div class="form-error" *ngIf="controls.grit10Onions.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controls.grit10Onions.errors.pattern">
                <span>{{ 'form_errors.invalid_number' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('grit10OnionsMeadow') && currentStage === 'off_meadow'">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.dossier.create.grit10_onions_meadow_label') }}
              </mat-label>
              <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="grit10OnionsMeadow" />
              <span matSuffix>{{ t('modules.dossier.create.grit10_onions_off_meadow_unit_label') }}</span>
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.grit10OnionsMeadow.touched) && controls.grit10OnionsMeadow.invalid">
              <div class="form-error" *ngIf="controls.grit10OnionsMeadow.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controls.grit10OnionsMeadow.errors.pattern">
                <span>{{ 'form_errors.invalid_number' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('gritGrade')">
          <ppa-select
            [items]="gritGradeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.grit_grade_label"
            formControlName="gritGrade"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.gritGrade.touched) && controls.gritGrade.invalid">
            <div class="form-error" *ngIf="controls.gritGrade.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('gritTestSample')">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.dossier.create.grit_test_sample') }}
            </mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="gritTestSample" />
            <span matSuffix>%</span>
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.gritTestSample.touched) && controls.gritTestSample.invalid">
            <div class="form-error" *ngIf="controls.gritTestSample.errors.min">
              <span>{{ 'form_errors.min' | transloco: { min: 0 } }}</span>
            </div>
            <div class="form-error" *ngIf="controls.gritTestSample.errors.max">
              <span>{{ 'form_errors.max' | transloco: { max: 100 } }}</span>
            </div>
            <div class="form-error" *ngIf="controls.gritTestSample.errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('quality')">
          <ppa-select
            [items]="qualityOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.quality_label"
            formControlName="quality"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.quality.touched) && controls.quality.invalid">
            <div class="form-error" *ngIf="controls.quality.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <div *ngIf="qualityIsIndustry$ | async">
          <ng-container *ngIf="dossierProductMatrix.has('industryQuality')">
            <ppa-select
              [items]="industryQualityOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.industry_quality_label"
              formControlName="industryQuality"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.industryQuality.touched) && controls.industryQuality.invalid">
              <div class="form-error" *ngIf="controls.industryQuality.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="dossierProductMatrix.has('primed')">
          <div>
            <mat-slide-toggle formControlName="primed">
              {{ t('modules.dossier.create.primed_label') }}
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('fungicideTreated')">
          <div>
            <mat-slide-toggle formControlName="fungicideTreated">
              {{ t('modules.dossier.create.fungicide_treated_label') }}
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('chemical')">
          <div>
            <mat-slide-toggle formControlName="chemical">
              {{ t('modules.dossier.create.chemical_label') }}
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('fungicideAndInsectTreated')">
          <div>
            <mat-slide-toggle formControlName="fungicideAndInsectTreated">
              {{ t('modules.dossier.create.fungicide_and_insect_treated_label') }}
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('piled')">
          <div>
            <mat-slide-toggle formControlName="piled">
              {{ t('modules.dossier.create.piled_label') }}
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('sproutInhibitor')">
          <ppa-select
            [items]="sproutInhibitorOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.sprout_inhibitor_label"
            formControlName="sproutInhibitor"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.sproutInhibitor.touched) && controls.sproutInhibitor.invalid">
            <div class="form-error" *ngIf="controls.sproutInhibitor.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <div *ngIf="(isOrganic$ | async) !== true">
          <ng-container *ngIf="dossierProductMatrix.has('chlorineProfam')">
            <mat-slide-toggle formControlName="chlorineProfam">
              {{ 'modules.dossier.create.chlorine_profam' | transloco }}
            </mat-slide-toggle>
          </ng-container>
        </div>

        <ng-container *ngIf="dossierProductMatrix.has('treatment')">
          <ppa-select
            [items]="treatments$ | async"
            [multiple]="true"
            bindLabel="name"
            bindValue="id"
            label="modules.dossier.create.treatment_label"
            formControlName="treatment"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.treatment.touched) && controls.treatment.invalid">
            <div class="form-error" *ngIf="controls.treatment.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ppa-notation
          [label]="t('modules.dossier.create.notes.quality_note')"
          formControlName="qualityNote"
        ></ppa-notation>
      </div>

      <div class="form-section" *ngIf="tradeSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.trade') }}</h3>

        <ppa-datetime-picker
          formControlName="expiresAt"
          [dateLabel]="'modules.order.create.expires_at_label' | transloco"
          defaultTime="18:00"
          [timeSelect]="true"
        ></ppa-datetime-picker>
        <div class="form-warning" *ngIf="expiresAtBeforeToday">
          {{ 'form_warnings.datetime_in_the_past' | transloco }}
        </div>

        <ng-container *ngIf="dossierProductMatrix.has('indicationPrice')">
          <div class="flex-row">
            <div class="flex-1-2">
              <mat-form-field appearance="fill">
                <mat-label>
                  {{ t('modules.dossier.create.indication_price') }}
                </mat-label>
                <i matSuffix class="uil uil-euro"></i>
                <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="indicationPrice" />
              </mat-form-field>
              <div *ngIf="(form.submitted || controls.indicationPrice.touched) && controls.indicationPrice.invalid">
                <div class="form-error" *ngIf="controls.indicationPrice.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
            <div class="flex-1-2">
              <ppa-select
                [items]="priceUnitOptions$ | async"
                bindLabel="localTitle"
                label="modules.dossier.create.indication_price_unit_label"
                formControlName="indicationPriceUnit"
              ></ppa-select>
            </div>
          </div>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.trade_note')" formControlName="tradeNote"></ppa-notation>
        <div *ngIf="(form.submitted || controls.tradeNote.touched) && controls.tradeNote.invalid">
          <div class="form-error" *ngIf="controls.tradeNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <div class="form-section" *ngIf="othersSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.others') }}</h3>

        <ng-container *ngIf="relationCertificates$ | async as relationCertificates">
          <ul
            class="certificate-list"
            formArrayName="certification"
            *ngIf="relationCertificates.length > 0; else noCertificates"
          >
            <li
              class="list__item"
              *ngFor="let relationCertificate of relationCertificates; index as i"
              [formGroupName]="i"
            >
              <ppa-certificate-checkbox
                [id]="relationCertificate.certificate.id"
                [title]="relationCertificate.certificate.name"
                [expirationDate]="relationCertificate.date"
                [organic]="relationCertificate.certificate.organic"
                [certificate]="relationCertificate"
                formControlName="certificate"
              ></ppa-certificate-checkbox>
            </li>
          </ul>
          <ng-template #noCertificates>
            {{ 'certificate.no_certificates' | transloco }}
          </ng-template>
        </ng-container>

        <ppa-notation
          [label]="t('modules.dossier.create.notes.others_note')"
          formControlName="othersNote"
        ></ppa-notation>
        <div *ngIf="(form.submitted || controls.othersNote.touched) && controls.othersNote.invalid">
          <div class="form-error" *ngIf="controls.othersNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>
