import { filter } from 'rxjs/operators';
import { ServerSentEventAction, ServerSentEventResponse } from '../contracts';
import { Observable } from 'rxjs';
import { Inject, Injectable, NgZone } from '@angular/core';
import { API_CONFIG, ENV } from './token';
import { ApiConfig } from '../contracts/api-config';

@Injectable({
  providedIn: 'root',
})
export class ServerSentEventService {
  constructor(
    private _zone: NgZone,
    @Inject(API_CONFIG) public apiConfig: ApiConfig,
    @Inject(ENV) public env: string,
  ) {}

  private getEventSource(url: string | string[]): EventSource {
    if (this.env !== 'prod') {
      url = `${this.env}-${url}`;
    }

    if (!Array.isArray(url)) {
      url = `${this.apiConfig.serverSentEventUrl}?topic=${url}`;
    } else {
      url = `${this.apiConfig.serverSentEventUrl}`;

      Array.from(url).forEach((item, index) => {
        if (index === 0) {
          url += `?topic=${item}`;
        } else {
          url += `&topic=${item}`;
        }
      });
    }
    return new EventSource(url);
  }

  getServerSentEvent(url: string): Observable<ServerSentEventResponse> {
    return new Observable<ServerSentEventResponse>((observer) => {
      const eventSource = this.getEventSource(url);

      eventSource.onmessage = (event) => {
        this._zone.run((_) => {
          observer.next(JSON.parse(event.data) as ServerSentEventResponse);
        });
      };

      eventSource.onerror = (error) => {
        this._zone.run((_) => {
          observer.error(error);
        });
      };
    });
  }

  getActionObserver(
    url: string,
    action: ServerSentEventAction,
  ): Observable<{ action: ServerSentEventAction; [key: string]: any }> {
    return this.getServerSentEvent(url).pipe(filter((data) => data.action === action));
  }
}
