import { Component, Input } from '@angular/core';
import { RelationStatusLabel } from '../../contracts';

@Component({
  selector: 'ppa-relation-status',
  templateUrl: './relation-status.component.html',
  styleUrls: ['./relation-status.component.scss'],
})
export class RelationStatusComponent {
  /** The status that should be displayed */
  @Input() label: RelationStatusLabel = null;
}
