import { Component, Input, OnInit } from '@angular/core';
import { Modal } from '../../classes';
import { Observable, of, Subject } from 'rxjs';
import { ModalService } from '../../services';
import { DomSanitizer } from '@angular/platform-browser';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'ppa-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.scss'],
})
export class DetailsModalComponent extends Modal implements OnInit {
  @Input() header = '';
  @Input() details: Observable<any>;
  @Input() fields: any[];
  @Input() compare: {
    oldHeader: string;
    oldData: any;
    oldFields: any;
    newHeader: string;
    newData: any;
    newFields: any;
  };

  @Input() closeButtonText = 'Sluiten';
  @Input() buttonColor: 'primary' | 'warn' = 'warn';

  fields$: Observable<any[]>;
  details$: Observable<any>;
  currentCompare = 'new';
  noDiffs$ = new Subject<boolean>();

  constructor(_modalService: ModalService, private domSanitizer: DomSanitizer) {
    super(_modalService);
  }

  ngOnInit() {
    this.currentCompare = 'new';
    this.loadData(of(this.fields), of(this.details));
  }

  toggleCompare() {
    if (this.currentCompare === 'new') {
      this.currentCompare = 'old';
      this.header = this.compare.oldHeader;
      this.details = this.compare.oldData;
      this.loadData(of(this.compare.oldFields), of(this.compare.oldData));
    } else {
      this.currentCompare = 'new';
      this.header = this.compare.newHeader;
      this.details = this.compare.newData;
      this.loadData(of(this.compare.newFields), of(this.compare.newData));
    }
  }

  loadData(fields, details) {
    this.noDiffs$.next(true);
    this.fields$ = fields;
    this.details$ = details;
  }

  hasValue(details, field) {
    if (field.hasOwnProperty('showCallback') && !field.showCallback(details)) {
      return false;
    }
    const value = this.getFieldValue(details, field);
    if (!value) {
      return false;
    }

    const hasValue = typeof value === 'string' ? value.length >= 0 : true;
    if (hasValue) {
      this.noDiffs$.next(false);
    }
    return hasValue;
  }

  getPhotos(details, field) {
    return details[field.files];
  }

  hasPhotos(details, field) {
    if (field.hasOwnProperty('photos') && field.photos) {
      if (details.hasOwnProperty(field.files)) {
        if (this.getPhotos(details, field).length > 0) {
          return true;
        }
        return false;
      }
    }
    return false;
  }

  showSection(details, field, fields) {
    let show = false;
    const debug = false;

    if (field.show && field.show.length > 0) {
      for (const f in field.show) {
        if (field.show.hasOwnProperty(f)) {
          const check = field.show[f];
          let value = null;
          let hasValue = false;

          try {
            value = check.split('.').reduce((o, i) => o[i], details);
            hasValue = true;
            if (debug) {
              console.log(field.show[f], 'Split', value);
            }
          } catch (e) {
            if (debug) {
              console.log(field.show[f], e);
            }
            // do nothing
          }

          if (value === null && hasValue) {
            if (this.compare) {
              value = false;
            }
            if (debug) {
              console.log(field.show[f], 'Set value = false');
            }
          }

          const checkField = fields.find((fField) => fField.field === check);

          if (checkField && checkField.hasOwnProperty('showCallback') && !checkField.showCallback(details)) {
            if (debug) {
              console.log(field.show[f], 'Callback false');
            }
            // Do nothing
          } else if (value && Array.isArray(value) && value.length === 0) {
            if (debug) {
              console.log(field.show[f], 'Empty Array');
            }
            // Do nothing
          } else if (value || value === false) {
            if (debug) {
              console.log(field.show[f], 'Value || value === false');
            }
            show = true;
            break;
          }
        }
      }
    } else {
      if (debug) {
        console.log('ELSE');
      }
      show = true;
    }

    if (field.hasOwnProperty('photos') && field.photos) {
      if (!this.hasPhotos(details, field)) {
        show = false;
      }
    }

    if (debug) {
      console.log(field, show);
    }

    return show;
  }

  getFieldValue(details, field, prefix = null) {
    const key = field.field;
    const bindLabel = field.bindLabel || false;

    if (field.value) {
      if (prefix) {
        return prefix + field.value;
      }

      return field.value;
    } else if (details && details.hasOwnProperty(key)) {
      const fieldValue = details[key];

      if (this.compare && !fieldValue) {
        return translate('global.compare.empty');
      }

      if (fieldValue || fieldValue === false) {
        let value = fieldValue;

        if (typeof field.valueCallback === 'function') {
          value = field.valueCallback(value);
        } else if (value && Array.isArray(value)) {
          if (value.length === 0) {
            return false;
          }
          if (typeof value[0] === 'object' && bindLabel) {
            const values = [];
            for (const v in value) {
              if (value[v]) {
                const val = value[v];
                values.push(val[bindLabel]);
              }
            }
            value = values.join('<br />- ');
          } else {
            value = value.join('<br />- ');
          }

          value = '- ' + value;
          value = this.domSanitizer.bypassSecurityTrustHtml(value);
        } else if (bindLabel && fieldValue.hasOwnProperty(bindLabel)) {
          value = fieldValue[bindLabel];
        }

        if (prefix) {
          return prefix + value;
        }

        return value;
      }
    }

    return '';
  }
}
