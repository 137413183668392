import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'ppa-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnChanges {
  progress = 0;

  @Input() min = 0;
  @Input() current = 0;
  @Input() max = 100;

  @Output() percentageChange = new EventEmitter<number>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const progress = (this.current / this.max) * 100;
    this.progress = progress > this.min ? progress : this.min;
    this.percentageChange.emit(this.progress);
  }
}
