import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { InvoiceOverviewCard } from '../../contracts';
import { OverviewCard } from '../../classes';

@Component({
  selector: 'ppa-invoice-overview-card',
  templateUrl: './invoice-overview-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceOverviewCardComponent extends OverviewCard {
  @Input() config: InvoiceOverviewCard;
  @Input() cyIdentifier: string;
}
