import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Reminder } from '@ppa/data';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ReminderDataService extends BaseService<Reminder> {
  path = 'reminders';

  public getDossierAndOrderReminders(mediatorId: string): Observable<{ [key: string]: Reminder[] }> {
    const params = new HttpParams({
      fromObject: {
        mediator: mediatorId,
      },
    });

    return this.http.get<{ [key: string]: Reminder[] }>(
      `${this.apiConfig.baseUrl}/${this.path}/get_dossier_order_reminders`,
      { params },
    );
  }

  public deleteReminder(reminder: Reminder): Observable<Reminder> {
    const params = new HttpParams({
      fromObject: {
        reminder: JSON.stringify(reminder),
      },
    });

    return this.http.delete<Reminder>(`${this.apiConfig.baseUrl}/${this.path}/${reminder.id}`, { params });
  }

  public getOpenStatusReminderCounts(mediatorId: string): Observable<{ [key: string]: string }> {
    const params = new HttpParams({
      fromObject: {
        mediator: mediatorId,
      },
    });

    return this.http.get<{ [key: string]: string }>(
      `${this.apiConfig.baseUrl}/${this.path}/get_open_status_reminder_counts`,
      { params },
    );
  }
}
