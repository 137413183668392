<div class="order-matched-card">
  <div class="order-matched-card__section">
    <div class="order-matched-card__details" *ngIf="orderMatch.matchedOrder">
      <h3>
        {{ orderMatch.matchedOrder.relation?.companyName }}
        <ppa-relation-status [label]="orderMatch.matchedOrder.relation?.status"></ppa-relation-status>
      </h3>
      <h4 class="subheading">
        {{ orderMatch.matchedOrder.relation.addressLine }}
        <br />
        {{ orderMatch.matchedOrder.product.title
        }}<ng-container *ngIf="orderMatch.matchedOrder.productVariety"
          >, {{ orderMatch.matchedOrder.productVariety.title }}</ng-container
        >
      </h4>
      <a class="order-matched-card__phone" href="tel:{{ orderMatch.matchedOrder.mediator.phoneNumber }}"
        ><i class="uil uil-phone"></i> {{ orderMatch.matchedOrder.mediator.firstname }}
        {{ orderMatch.matchedOrder.mediator.lastname }}</a
      >
    </div>
    <div class="order-matched-card__actions">
      <button
        *ngIf="!orderMatch.disabled"
        class="order-matched-card__action"
        type="button"
        (click)="delete(orderMatch)"
      >
        <i class="uil uil-trash-alt"></i>
      </button>
    </div>
  </div>
  <div class="order-matched-card__section order-matched-card__section--fd-column order-matched-card__section--pb-0">
    <div class="amount-ok" *ngIf="showAmountToggle">
      <mat-slide-toggle (change)="amountToggle($event)">
        {{ 'modules.order.fulfill.amount_ok' | transloco }}
      </mat-slide-toggle>
      <span
        matTooltip="Is de hoeveelheid meer/minder en wil je toch bevestigen dat het matchen voltooid is?"
        class="info"
      >
        <i class="uil uil-info-circle"></i>
      </span>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'modules.order.fulfill.matched_order.added_weight' | transloco }}</mat-label>
      <input type="tel" matInput [value]="value" [disabled]="disabled" (input)="updateAmount($event)" />
      <span matSuffix>{{ maximum }}</span>
    </mat-form-field>
    <div *ngIf="control?.touched && control?.invalid">
      <div class="form-error" *ngIf="control.errors.required">
        <span>{{ 'form_errors.required' | transloco }}</span>
      </div>
      <div class="form-error" *ngIf="control.errors.pattern">
        <span>{{ 'form_errors.invalid_number' | transloco }}</span>
      </div>
      <div class="form-error" *ngIf="control.errors.min">
        <span>{{ 'form_errors.min' | transloco: { min: 1 } }}</span>
      </div>
      <div class="form-error" *ngIf="control.errors.max">
        <span>{{ 'form_errors.max' | transloco: { max: orderMatch.matchedOrder.amount } }}</span>
      </div>
    </div>
  </div>
</div>
