<ppa-spinner [status$]="saving$" message="global.sending" icon="envelope-send"></ppa-spinner>

<div class="ppa-modal ppa-modal--auto-scale-from-bottom ppa-modal--confirm">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h3>{{ 'modules.pool.modal.send.form.title' | transloco }}</h3>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <div class="ppa-modal__intro mb-1">
      {{ 'modules.pool.modal.send.form.intro' | transloco: { receiver: relation.companyName } }}
    </div>

    <form #form="ngForm" [formGroup]="sendPoolForm">
      <ppa-mail-header [form]="form" [formChecked]="formChecked" [formGroup]="sendPoolForm" [relation]="relation">
      </ppa-mail-header>

      <mat-form-field appearance="fill">
        <mat-label>
          {{ 'modules.pool.modal.send.form.message_label' | transloco }}
          <span class="required" *ngIf="isRequiredField('message') | async">*</span>
        </mat-label>
        <textarea matInput cdkTextareaAutosize formControlName="message" rows="10"></textarea>
      </mat-form-field>
      <div *ngIf="(form.submitted || sendPoolForm.controls.message.touched) && sendPoolForm.controls.message.invalid">
        <div class="form-error" *ngIf="sendPoolForm.controls.message.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
      </div>

      <button mat-raised-button class="button--full-width" color="primary" (click)="send()">
        {{ 'modules.pool.modal.send.form.button_send' | transloco }}
      </button>
    </form>
  </div>
</div>

<div class="ppa-modal__fader"></div>
