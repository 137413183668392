<ppa-spinner [status$]="exporting$" message="filter.downloading"></ppa-spinner>
<ppa-spinner [status$]="saving$" message="global.saving" icon="save"></ppa-spinner>

<div class="container" *transloco="let t">
  <form #form="ngForm" [formGroup]="dossierForm" autocomplete="off" (ngSubmit)="handleSubmit()">
    <ppa-header [data]="headerData" [floating]="true" (toggleNoRedirect)="handleToggleNoRedirect($event)"></ppa-header>

    <ng-container *ngIf="!isCreateComponent; else createHeader">
      <div class="heading">
        <h1 class="subheading-enabled">
          <ng-container *ngIf="!isContactMoment; else contactHeader">
            {{ t('modules.dossier.edit.title') }}
          </ng-container>
          <ppa-status *ngIf="status" class="ppa-status--right" [label]="status"></ppa-status>
        </h1>
        <h4 class="subheading">{{ t('modules.dossier.edit.intro') }}</h4>
      </div>
    </ng-container>
    <ng-template #contactHeader>
      {{ t('modules.dossier.edit.add_contact_moment') }}
    </ng-template>
    <ng-template #createHeader>
      <div class="heading">
        <h1 class="subheading-enabled">{{ t('modules.dossier.create.title') }}</h1>
        <h4 class="subheading">{{ t('modules.dossier.create.intro') }}</h4>
      </div>
    </ng-template>

    <div class="tabbar-content">
      <ng-container *ngIf="isContactMoment">
        <div class="flex-1-2">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ 'modules.dossier.create.contact_moments.date' | transloco }}
              <span class="required" *ngIf="isRequiredField('cmContactDate') | async">*</span>
            </mat-label>
            <input matInput [matDatepicker]="picker" formControlName="cmContactDate" readonly />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
          </mat-form-field>
          <div
            *ngIf="
              (form.submitted || dossierForm.get('cmContactDate').touched) && dossierForm.get('cmContactDate').invalid
            "
          >
            <div class="form-error" *ngIf="dossierForm.get('cmContactDate').errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>

          <ppa-select
            [items]="mediators$ | async"
            bindLabel="label"
            label="modules.dossier.create.contact_moments.mediator"
            formControlName="cmMediator"
          ></ppa-select>
          <div
            *ngIf="(form.submitted || dossierForm.get('cmMediator').touched) && dossierForm.get('cmMediator').invalid"
          >
            <div class="form-error" *ngIf="dossierForm.get('cmMediator').errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>

          <mat-form-field appearance="fill">
            <mat-label>
              {{ 'modules.dossier.create.contact_moments.description' | transloco }}
              <span class="required" *ngIf="isRequiredField('cmDescription') | async">*</span>
            </mat-label>
            <textarea matInput cdkTextareaAutosize rows="10" formControlName="cmDescription"></textarea>
          </mat-form-field>
          <div
            *ngIf="
              (form.submitted || dossierForm.get('cmDescription').touched) && dossierForm.get('cmDescription').invalid
            "
          >
            <div class="form-error" *ngIf="dossierForm.get('cmDescription').errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- RELATION -->
      <div class="form-section relation-form-section">
        <h3>{{ t('modules.order.create.sections.common') }}</h3>

        <ppa-action-card
          actionIcon="uil-search"
          (contentClicked)="openRelationModal()"
          (actionClicked)="openRelationModal()"
          [disabled]="this.isContactMoment"
        >
          <ng-container *ngIf="currentRelation$ | async as currentRelation; else relationPlaceholder">
            <div class="relation-company">{{ currentRelation.companyName }}</div>
            <div class="relation-address">{{ currentRelation.addressLine }}</div>
          </ng-container>
          <ng-template #relationPlaceholder>
            <div class="relation-placeholder">
              {{ 'modules.order.create.client_label' | transloco }}
              <span class="required" *ngIf="isRequiredField('relation') | async">*</span>
            </div>
          </ng-template>
        </ppa-action-card>

        <div
          class="form-error-push-away"
          *ngIf="(form.submitted || controls.relation.touched) && controls.relation.invalid"
        >
          <div class="form-error" *ngIf="controls.relation.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>
      </div>

      <!-- LOCATION -->
      <div class="form-section">
        <div class="mb-1">
          <ppa-action-card
            actionIcon="uil-map-marker"
            (contentClicked)="openLocationModal()"
            (actionClicked)="openLocationModal()"
          >
            <ng-container *ngIf="currentAddress$ | async as currentAddress; else locationPlaceholder">
              <!--                <a target="_blank" class="location-link subheader" [href]="locationUrl">-->
              {{ currentAddress.street }} {{ currentAddress.number }}, <br />
              {{ currentAddress.zipcode }} {{ currentAddress.city }}
              <!--                </a>-->
            </ng-container>
            <ng-template #locationPlaceholder>
              <div class="location-placeholder">
                {{ 'modules.dossier.create.location_label' | transloco }}
                <span class="required" *ngIf="isRequiredField('location') | async">*</span>
              </div>
            </ng-template>
          </ppa-action-card>
        </div>

        <!-- ORDER -->
        <ng-container
          *ngIf="!isCreateComponent && !isContactMoment && activeModules.order && orders$ | async as orders"
        >
          <ppa-action-card
            [actionIcon]="orders.length === 0 ? 'uil-plus' : 'uil-angle-right'"
            [cardClass]="orders.length === 0 ? 'primary' : ''"
            (contentClicked)="orderLinkAction()"
            (actionClicked)="orderLinkAction()"
          >
            <div class="order-placeholder" *ngIf="orders.length === 0; else openOrderPlaceholder">
              {{ t('modules.dossier.create.link_order_label') }}
            </div>
            <ng-template #openOrderPlaceholder>
              <ng-container *ngIf="orders.length === 1">
                {{ t('modules.dossier.create.open_order_label') }}
              </ng-container>
              <ng-container *ngIf="orders.length > 1">
                {{ t('modules.dossier.create.open_orders_label', { qty: orders.length }) }}
              </ng-container>
            </ng-template>
          </ppa-action-card>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.location_note')" formControlName="locationNote">
        </ppa-notation>
        <div *ngIf="(form.submitted || controls.locationNote.touched) && controls.locationNote.invalid">
          <div class="form-error" *ngIf="controls.locationNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <!-- MEDIATOR + TITLE -->
      <div class="form-section">
        <ppa-select
          [items]="mediators$ | async"
          bindLabel="label"
          label="modules.dossier.create.mediator_label"
          formControlName="mediator"
        ></ppa-select>
        <div *ngIf="(form.submitted || controls.mediator.touched) && controls.mediator.invalid">
          <div class="form-error" *ngIf="controls.mediator.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>

        <ng-container>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{ t('modules.dossier.create.title_label') }}</mat-label>
              <input matInput formControlName="title" />
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.title.touched) && controls.title.invalid">
              <div class="form-error" *ngIf="controls.title.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- PRODUCT -->
      <div class="form-section" *ngIf="productSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.product') }}</h3>

        <ppa-select
          [items]="products$ | async"
          bindLabel="title"
          label="modules.dossier.create.product_label"
          formControlName="product"
        ></ppa-select>
        <div *ngIf="(form.submitted || controls.product.touched) && controls.product.invalid">
          <div class="form-error" *ngIf="controls.product.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>

        <ng-container *ngIf="productConditionalFields.has('productVariety')">
          <ppa-select
            [items]="(selectedProduct$ | async)?.productVarieties"
            bindLabel="title"
            label="modules.dossier.create.product_variety_label"
            formControlName="productVariety"
            [abstractControl]="controls.productVariety"
          ></ppa-select>
        </ng-container>

        <div>
          <ng-container *ngIf="productConditionalFields.has('bakingFactory')">
            <mat-slide-toggle formControlName="bakingFactory">
              {{ 'modules.dossier.create.baking_factory_label' | transloco }}
            </mat-slide-toggle>
          </ng-container>
        </div>

        <ng-container>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{ t('modules.dossier.create.bad_onions_label') }}</mat-label>
              <input matInput formControlName="badOnions" />
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.badOnions.touched) && controls.badOnions.invalid">
              <div class="form-error" *ngIf="controls.badOnions.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </div>
        </ng-container>

        <div>
          <ng-container *ngIf="productConditionalFields.has('organic')">
            <mat-slide-toggle formControlName="organic">
              {{ 'modules.dossier.create.organic_label' | transloco }}
            </mat-slide-toggle>
          </ng-container>
        </div>

        <ng-container *ngIf="productConditionalFields.has('breedingSelling')">
          <ppa-select
            [items]="breedingSellingOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.buying_selling_breeding"
            formControlName="breedingSelling"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.breedingSelling.touched) && controls.breedingSelling.invalid">
            <div class="form-error" *ngIf="controls.breedingSelling.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('sellType')">
          <ppa-select
            [items]="sellTypeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.sell_type_label"
            formControlName="sellType"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.sellType.touched) && controls.sellType.invalid">
            <div class="form-error" *ngIf="controls.sellType.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('rentType')">
          <ppa-select
            [items]="rentTypeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.rent_type_label"
            formControlName="rentType"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.rentType.touched) && controls.rentType.invalid">
            <div class="form-error" *ngIf="controls.rentType.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('harvestYear')">
          <ppa-select
            label="modules.dossier.create.harvest_year_label"
            formControlName="harvestYear"
            [yearSelect]="true"
            years="deliveryYears"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.harvestYear.touched) && controls.harvestYear.invalid">
            <div class="form-error" *ngIf="controls.harvestYear.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.harvestYear.errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('deliveryYear')">
          <ppa-select
            label="modules.dossier.create.delivery_year_label"
            formControlName="deliveryYear"
            [yearSelect]="true"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.deliveryYear.touched) && controls.deliveryYear.invalid">
            <div class="form-error" *ngIf="controls.deliveryYear.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.deliveryYear.errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.product_note')" formControlName="productNote">
        </ppa-notation>
        <div *ngIf="(form.submitted || controls.productNote.touched) && controls.productNote.invalid">
          <div class="form-error" *ngIf="controls.productNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <!-- CULTIVATION -->
      <div class="form-section" *ngIf="cultivationSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.cultivation') }}</h3>

        <ng-container *ngIf="displaySurface$ | async">
          <ng-container *ngIf="productConditionalFields.has('surfaceCultivation')">
            <div>
              <mat-form-field appearance="fill">
                <mat-label>
                  {{ t('modules.dossier.create.surface_label') }}
                  <span class="required" *ngIf="isRequiredField('surfaceCultivation') | async">*</span>
                </mat-label>
                <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="surfaceCultivation" />
                <span matSuffix>ha</span>
              </mat-form-field>
              <div
                *ngIf="(form.submitted || controls.surfaceCultivation.touched) && controls.surfaceCultivation.invalid"
              >
                <div class="form-error" *ngIf="controls.surfaceCultivation.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
                <div class="form-error" *ngIf="controls.surfaceCultivation.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('plantsPerHa')">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.dossier.create.plants_per_ha_label') }}
              <span class="required" *ngIf="isRequiredField('plantsPerHa') | async">*</span>
            </mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="plantsPerHa" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.plantsPerHa.touched) && controls.plantsPerHa.invalid">
            <div class="form-error" *ngIf="controls.plantsPerHa.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('sowingDate')">
          <ppa-datepicker-week-exact-switch
            [useExactDate]="true"
            formControlName="sowingDate"
            label="modules.dossier.create.sowing_date_label"
            [required$]="isRequiredField('sowingDate')"
            [clearable]="true"
          ></ppa-datepicker-week-exact-switch>
          <div *ngIf="(form.submitted || controls.sowingDate.touched) && controls.sowingDate.invalid">
            <div class="form-error" *ngIf="controls.sowingDate.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.cultivation_note')" formControlName="cultivationNote">
        </ppa-notation>
        <div *ngIf="(form.submitted || controls.cultivationNote.touched) && controls.cultivationNote.invalid">
          <div class="form-error" *ngIf="controls.cultivationNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <!-- SALE -->
      <div class="form-section" *ngIf="saleSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.sale') }}</h3>

        <ng-container *ngIf="displayExpectedAmount$ | async">
          <ng-container *ngIf="productConditionalFields.has('expectedAmountSale')">
            <ng-container *ngTemplateOutlet="amountUnitSelect; context: { form: dossierForm }"></ng-container>
            <div class="flex-row">
              <div class="flex-1-1">
                <div class="amount__field">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.dossier.create.expected_amount_label') }}
                      <span class="required" *ngIf="isRequiredField('expectedAmountSale') | async">*</span>
                    </mat-label>
                    <input
                      type="tel"
                      inputmode="decimal"
                      matInput
                      ppaNumberInput
                      formControlName="expectedAmountSale"
                      (blur)="calculateAmount()"
                    />
                    <span matSuffix *ngIf="displayTonSuffix$ | async">{{ 'general.ton' | transloco }}</span>
                  </mat-form-field>

                  <button
                    type="button"
                    matSuffix
                    mat-mini-fab
                    matTooltip="{{ 'general.calculate_amount' | transloco }}"
                    aria-label="{{ 'general.calculate_amount' | transloco }}"
                    (click)="calculateAmount(true)"
                    class="amount__button"
                  >
                    <i class="uil uil-calculator"></i>
                  </button>
                </div>

                <div
                  *ngIf="(form.submitted || controls.expectedAmountSale.touched) && controls.expectedAmountSale.invalid"
                >
                  <div class="form-error" *ngIf="controls.expectedAmountSale.errors.required">
                    <span>{{ 'form_errors.required' | transloco }}</span>
                  </div>
                  <div class="form-error" *ngIf="controls.expectedAmountSale.errors.pattern">
                    <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('sizeSorting')">
            <ppa-select
              [items]="sizeSorting$ | async"
              [multiple]="true"
              bindLabel="name"
              bindValue="id"
              label="modules.dossier.create.size_sorting_label"
              formControlName="sizeSorting"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.sizeSorting.touched) && controls.sizeSorting.invalid">
              <div class="form-error" *ngIf="controls.sizeSorting.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('packaging')">
          <ppa-select
            [items]="packagingOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.packaging_label"
            formControlName="packaging"
          ></ppa-select>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('disinfected')">
          <mat-slide-toggle formControlName="disinfected">
            {{ t('modules.dossier.create.disinfected_label') }}
            <span class="required" *ngIf="isRequiredField('disinfected') | async">*</span>
          </mat-slide-toggle>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.sale_note')" formControlName="saleNote"></ppa-notation>
        <div *ngIf="(form.submitted || controls.saleNote.touched) && controls.saleNote.invalid">
          <div class="form-error" *ngIf="controls.saleNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <!-- SEEDS -->
      <div class="form-section" *ngIf="seedsSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.seeds') }}</h3>

        <ng-container *ngIf="productConditionalFields.has('expectedAmountSeed')">
          <ng-container
            *ngTemplateOutlet="amountUnitSelect; context: { form: dossierForm, calculate: true }"
          ></ng-container>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('fungicideCoating')">
          <ppa-select
            [items]="fungicideCoatingOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.fungicide_coating_label"
            formControlName="fungicideCoating"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.fungicideCoating.touched) && controls.fungicideCoating.invalid">
            <div class="form-error" *ngIf="controls.fungicideCoating.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.seeds_note')" formControlName="seedsNote"></ppa-notation>
        <div *ngIf="(form.submitted || controls.seedsNote.touched) && controls.seedsNote.invalid">
          <div class="form-error" *ngIf="controls.seedsNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <!-- STAGE -->
      <div class="form-section" *ngIf="stageSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.stage') }}</h3>

        <ng-container *ngIf="productConditionalFields.has('stage')">
          <ppa-select
            [items]="stageOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.stage_label"
            formControlName="stage"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.stage.touched) && controls.stage.invalid">
            <div class="form-error" *ngIf="controls.stage.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <div *ngIf="['off_meadow', 'land'].indexOf(selectedStage$ | async) != -1">
          <ng-container *ngIf="productConditionalFields.has('expectedHarvest')">
            <div>
              <mat-slide-toggle #useExactDateRef formControlName="expectedHarvestExactDate">
                {{ t('modules.dossier.create.expected_harvest_exact_date_label') }}
              </mat-slide-toggle>
              <ppa-datepicker-week-exact-switch
                [useExactDate]="useExactDateRef.checked"
                formControlName="expectedHarvest"
                [required$]="isRequiredField('expectedHarvest')"
                [clearable]="true"
              ></ppa-datepicker-week-exact-switch>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('finalHarvest')">
            <div>
              <ppa-datetime-picker
                formControlName="finalHarvest"
                [dateLabel]="'modules.dossier.create.final_harvest_label' | transloco"
                [timeSelect]="false"
                [required$]="isRequiredField('finalHarvest')"
              ></ppa-datetime-picker>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('preferenceDelivery')">
            <ppa-select
              [items]="preferenceDeliveryOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.preference_delivery_label"
              formControlName="preferenceDelivery"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.preferenceDelivery.touched) && controls.preferenceDelivery.invalid">
              <div class="form-error" *ngIf="controls.preferenceDelivery.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('surface')">
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.dossier.create.surface_label') }}
                <span class="required" *ngIf="isRequiredField('surface') | async">*</span>
              </mat-label>
              <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="surface" />
              <span matSuffix>ha</span>
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.surface.touched) && controls.surface.invalid">
              <div class="form-error" *ngIf="controls.surface.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controls.surface.errors.pattern">
                <span>{{ 'form_errors.invalid_number' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('expectedAmount')">
            <ng-container *ngTemplateOutlet="amountUnitSelect; context: { form: dossierForm }"></ng-container>
            <div class="flex-row">
              <div class="flex-1-1">
                <div class="amount__field">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.dossier.create.expected_amount_label') }}
                      <span class="required" *ngIf="isRequiredField('expectedAmount') | async">*</span>
                    </mat-label>
                    <input
                      type="tel"
                      inputmode="decimal"
                      matInput
                      ppaNumberInput
                      formControlName="expectedAmount"
                      (blur)="calculateAmount()"
                    />
                    <span matSuffix *ngIf="displayTonSuffix$ | async">{{ 'general.ton' | transloco }}</span>
                  </mat-form-field>

                  <button
                    type="button"
                    matSuffix
                    mat-mini-fab
                    matTooltip="{{ 'general.calculate_amount' | transloco }}"
                    aria-label="{{ 'general.calculate_amount' | transloco }}"
                    (click)="calculateAmount(true)"
                    class="amount__button"
                  >
                    <i class="uil uil-calculator"></i>
                  </button>
                </div>

                <div *ngIf="(form.submitted || controls.expectedAmount.touched) && controls.expectedAmount.invalid">
                  <div class="form-error" *ngIf="controls.expectedAmount.errors.required">
                    <span>{{ 'form_errors.required' | transloco }}</span>
                  </div>
                  <div class="form-error" *ngIf="controls.expectedAmount.errors.pattern">
                    <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('meadowDiseases')">
            <ppa-select
              [items]="offMeadowDiseases$ | async"
              [multiple]="true"
              bindLabel="name"
              label="modules.dossier.create.meadow_diseases_label"
              formControlName="meadowDiseases"
            ></ppa-select>
          </ng-container>
        </div>

        <div *ngIf="['barn', 'storage_short', 'storage_long'].indexOf(selectedStage$ | async) != -1">
          <ng-container *ngIf="productConditionalFields.has('preferredDeliveryTime')">
            <ppa-select
              [items]="monthOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.preferred_delivery_time_label"
              formControlName="preferredDeliveryTime"
            ></ppa-select>
            <div
              *ngIf="
                (form.submitted || controls.preferredDeliveryTime.touched) && controls.preferredDeliveryTime.invalid
              "
            >
              <div class="form-error" *ngIf="controls.preferredDeliveryTime.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('estimatedAmount')">
            <ng-container *ngTemplateOutlet="amountUnitSelect; context: { form: dossierForm }"></ng-container>
            <div class="flex-row">
              <div class="flex-1-1">
                <div class="amount__field">
                  <mat-form-field appearance="fill">
                    <mat-label>
                      {{ t('modules.dossier.create.estimated_amount_label') }}
                      <span class="required" *ngIf="isRequiredField('estimatedAmount') | async">*</span>
                    </mat-label>
                    <input
                      type="tel"
                      inputmode="decimal"
                      matInput
                      ppaNumberInput
                      formControlName="estimatedAmount"
                      (blur)="calculateAmount()"
                    />
                    <span matSuffix *ngIf="displayTonSuffix$ | async">{{ 'general.ton' | transloco }}</span>
                  </mat-form-field>

                  <button
                    type="button"
                    matSuffix
                    mat-mini-fab
                    matTooltip="{{ 'general.calculate_amount' | transloco }}"
                    aria-label="{{ 'general.calculate_amount' | transloco }}"
                    (click)="calculateAmount(true)"
                    class="amount__button"
                  >
                    <i class="uil uil-calculator"></i>
                  </button>
                </div>

                <div *ngIf="(form.submitted || controls.estimatedAmount.touched) && controls.estimatedAmount.invalid">
                  <div class="form-error" *ngIf="controls.estimatedAmount.errors.required">
                    <span>{{ 'form_errors.required' | transloco }}</span>
                  </div>
                  <div class="form-error" *ngIf="controls.estimatedAmount.errors.pattern">
                    <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('barnDiseases')">
            <ppa-select
              [items]="barnDiseases$ | async"
              [multiple]="true"
              bindLabel="name"
              label="modules.dossier.create.barn_diseases_label"
              formControlName="barnDiseases"
            ></ppa-select>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('storageBox')">
            <ppa-select
              [items]="storageBoxOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.storage_box_label"
              formControlName="storageBox"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.storageBox.touched) && controls.storageBox.invalid">
              <div class="form-error" *ngIf="controls.storageBox.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="productConditionalFields.has('colorGrade')">
            <ppa-select
              [items]="colorGrades"
              [translateLabel]="true"
              translatePrefix="modules.dossier.create.color_options"
              label="modules.dossier.create.color_grade_label"
              formControlName="colorGrade"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.colorGrade.touched) && controls.colorGrade.invalid">
              <div class="form-error" *ngIf="controls.colorGrade.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </div>

        <ppa-notation [label]="t('modules.dossier.create.notes.stage_note')" formControlName="stageNote"></ppa-notation>
        <div *ngIf="(form.submitted || controls.stageNote.touched) && controls.stageNote.invalid">
          <div class="form-error" *ngIf="controls.stageNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <!-- QUALITY -->
      <div class="form-section" *ngIf="qualitySection$ | async">
        <h3>{{ t('modules.dossier.create.sections.quality') }}</h3>

        <ng-container *ngIf="productConditionalFields.has('tare')">
          <ppa-select
            [items]="tareGradeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.tare_label"
            formControlName="tare"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.tare.touched) && controls.tare.invalid">
            <div class="form-error" *ngIf="controls.tare.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('tareTestSample')">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.dossier.create.tare_test_sample_label') }}
              <span class="required" *ngIf="isRequiredField('tareTestSample') | async">*</span>
            </mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="tareTestSample" />
            <span matSuffix>%</span>
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.tareTestSample.touched) && controls.tareTestSample.invalid">
            <div class="form-error" *ngIf="controls.tareTestSample.errors.min">
              <span>{{ 'form_errors.min' | transloco: { min: 0 } }}</span>
            </div>
            <div class="form-error" *ngIf="controls.tareTestSample.errors.max">
              <span>{{ 'form_errors.max' | transloco: { max: 100 } }}</span>
            </div>
            <div class="form-error" *ngIf="controls.tareTestSample.errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('grit10Onions') && (selectedStage$ | async)">
          <ng-container>
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.dossier.create.grit10_onions_label') }}
                <span class="required" *ngIf="isRequiredField('grit10Onions') | async">*</span>
              </mat-label>
              <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="grit10Onions" />
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.grit10Onions.touched) && controls.grit10Onions.invalid">
              <div class="form-error" *ngIf="controls.grit10Onions.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controls.grit10Onions.errors.pattern">
                <span>{{ 'form_errors.invalid_number' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="productConditionalFields.has('grit10OnionsMeadow') && (selectedStage$ | async) == 'off_meadow'"
          >
            <mat-form-field appearance="fill">
              <mat-label>
                {{ t('modules.dossier.create.grit10_onions_meadow_label') }}
                <span class="required" *ngIf="isRequiredField('grit10OnionsMeadow') | async">*</span>
              </mat-label>
              <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="grit10OnionsMeadow" />
              <span matSuffix>{{ t('modules.dossier.create.grit10_onions_off_meadow_unit_label') }}</span>
            </mat-form-field>
            <div *ngIf="(form.submitted || controls.grit10OnionsMeadow.touched) && controls.grit10OnionsMeadow.invalid">
              <div class="form-error" *ngIf="controls.grit10OnionsMeadow.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controls.grit10OnionsMeadow.errors.pattern">
                <span>{{ 'form_errors.invalid_number' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('grit10Roots')">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.dossier.create.grit10_roots_label') }}
              <span class="required" *ngIf="isRequiredField('grit10Roots') | async">*</span>
            </mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="grit10Roots" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.grit10Roots.touched) && controls.grit10Roots.invalid">
            <div class="form-error" *ngIf="controls.grit10Roots.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
            <div class="form-error" *ngIf="controls.grit10Roots.errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('gritGrade')">
          <ppa-select
            [items]="gritGradeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.grit_grade_label"
            formControlName="gritGrade"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.gritGrade.touched) && controls.gritGrade.invalid">
            <div class="form-error" *ngIf="controls.gritGrade.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('gritTestSample')">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.dossier.create.grit_test_sample') }}
              <span class="required" *ngIf="isRequiredField('gritTestSample') | async">*</span>
            </mat-label>
            <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="gritTestSample" />
            <span matSuffix>%</span>
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.gritTestSample.touched) && controls.gritTestSample.invalid">
            <div class="form-error" *ngIf="controls.gritTestSample.errors.min">
              <span>{{ 'form_errors.min' | transloco: { min: 0 } }}</span>
            </div>
            <div class="form-error" *ngIf="controls.gritTestSample.errors.max">
              <span>{{ 'form_errors.max' | transloco: { max: 100 } }}</span>
            </div>
            <div class="form-error" *ngIf="controls.gritTestSample.errors.pattern">
              <span>{{ 'form_errors.invalid_number' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('quality')">
          <ppa-select
            [items]="qualityOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.quality_label"
            formControlName="quality"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.quality.touched) && controls.quality.invalid">
            <div class="form-error" *ngIf="controls.quality.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <div *ngIf="qualityIsIndustry$ | async">
          <ng-container *ngIf="productConditionalFields.has('industryQuality')">
            <ppa-select
              [items]="industryQualityOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.industry_quality_label"
              formControlName="industryQuality"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.industryQuality.touched) && controls.industryQuality.invalid">
              <div class="form-error" *ngIf="controls.industryQuality.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="productConditionalFields.has('naktNumber')">
          <mat-form-field appearance="fill">
            <mat-label>
              {{ t('modules.dossier.create.nakt_number_label') }}
              <span class="required" *ngIf="isRequiredField('naktNumber') | async">*</span>
            </mat-label>
            <input type="text" matInput formControlName="naktNumber" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.naktNumber.touched) && controls.naktNumber.invalid">
            <div class="form-error" *ngIf="controls.naktNumber.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('primed')">
          <div>
            <mat-slide-toggle formControlName="primed">
              {{ t('modules.dossier.create.primed_label') }}
              <span class="required" *ngIf="isRequiredField('primed') | async">*</span>
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('fungicideTreated')">
          <div>
            <mat-slide-toggle formControlName="fungicideTreated">
              {{ t('modules.dossier.create.fungicide_treated_label') }}
              <span class="required" *ngIf="isRequiredField('fungicideTreated') | async">*</span>
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('chemical')">
          <div>
            <mat-slide-toggle formControlName="chemical">
              {{ t('modules.dossier.create.chemical_label') }}
              <span class="required" *ngIf="isRequiredField('chemical') | async">*</span>
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('fungicideAndInsectTreated')">
          <div>
            <mat-slide-toggle formControlName="fungicideAndInsectTreated">
              {{ t('modules.dossier.create.fungicide_and_insect_treated_label') }}
              <span class="required" *ngIf="isRequiredField('fungicideAndInsectTreated') | async">*</span>
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('piled')">
          <div>
            <mat-slide-toggle formControlName="piled">
              {{ t('modules.dossier.create.piled_label') }}
              <span class="required" *ngIf="isRequiredField('piled') | async">*</span>
            </mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('sproutInhibitor')">
          <div>
            <ppa-select
              [items]="sproutInhibitorOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.sprout_inhibitor_label"
              formControlName="sproutInhibitor"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.sproutInhibitor.touched) && controls.sproutInhibitor.invalid">
              <div class="form-error" *ngIf="controls.sproutInhibitor.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="(isOrganic$ | async) === false">
          <ng-container *ngIf="productConditionalFields.has('chlorineProfam')">
            <mat-slide-toggle formControlName="chlorineProfam">
              {{ 'modules.dossier.create.chlorine_profam' | transloco }}
              <span class="required" *ngIf="isRequiredField('chlorineProfam') | async">*</span>
            </mat-slide-toggle>
          </ng-container>
        </div>

        <ng-container *ngIf="productConditionalFields.has('treatment')">
          <ppa-select
            [items]="treatments$ | async"
            [multiple]="true"
            bindLabel="name"
            bindValue="id"
            label="modules.dossier.create.treatment_label"
            formControlName="treatment"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.treatment.touched) && controls.treatment.invalid">
            <div class="form-error" *ngIf="controls.treatment.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.quality_note')" formControlName="qualityNote">
        </ppa-notation>
        <div *ngIf="(form.submitted || controls.qualityNote.touched) && controls.qualityNote.invalid">
          <div class="form-error" *ngIf="controls.qualityNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <!-- TRADE -->
      <div class="form-section" *ngIf="tradeSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.trade') }}</h3>

        <ppa-datetime-picker
          formControlName="expiresAt"
          [dateLabel]="'modules.order.create.expires_at_label' | transloco"
          defaultTime="18:00"
          [timeSelect]="true"
        ></ppa-datetime-picker>
        <div class="form-warning" *ngIf="expiresAtBeforeToday">
          {{ 'form_warnings.datetime_in_the_past' | transloco }}
        </div>

        <ng-container *ngIf="productConditionalFields.has('indicationPrice')">
          <div class="flex-row">
            <div class="flex-1-2">
              <mat-form-field appearance="fill">
                <mat-label>
                  {{ t('modules.dossier.create.indication_price') }}
                  <span class="required" *ngIf="isRequiredField('indicationPrice') | async">*</span>
                </mat-label>
                <i matSuffix class="uil uil-euro"></i>
                <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="indicationPrice" />
              </mat-form-field>
              <div *ngIf="(form.submitted || controls.indicationPrice.touched) && controls.indicationPrice.invalid">
                <div class="form-error" *ngIf="controls.indicationPrice.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
            <div class="flex-1-2">
              <ppa-select
                [items]="priceUnitOptions$ | async"
                bindLabel="localTitle"
                label="modules.dossier.create.indication_price_unit_label"
                formControlName="indicationPriceUnit"
              ></ppa-select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="productConditionalFields.has('participation')">
          <mat-slide-toggle formControlName="participation">
            {{ 'modules.dossier.create.participation' | transloco }}
            <span class="required" *ngIf="isRequiredField('participation') | async">*</span>
          </mat-slide-toggle>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.trade_note')" formControlName="tradeNote"></ppa-notation>
        <div *ngIf="(form.submitted || controls.tradeNote.touched) && controls.tradeNote.invalid">
          <div class="form-error" *ngIf="controls.tradeNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <!-- OTHERS -->
      <div class="form-section" *ngIf="othersSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.others') }}</h3>

        <ng-container *ngIf="relationCertificates$ | async as relationCertificates">
          <ul
            class="certificate-list"
            formArrayName="certification"
            *ngIf="relationCertificates?.length > 0; else noCertificates"
          >
            <li
              class="list__item"
              *ngFor="let relationCertificate of relationCertificates; index as i"
              [formGroupName]="i"
            >
              <ppa-certificate-checkbox
                [id]="relationCertificate.certificate?.id"
                [title]="relationCertificate.certificate.name"
                [expirationDate]="relationCertificate?.date"
                [organic]="relationCertificate.certificate?.organic"
                [certificate]="relationCertificate"
                formControlName="certificate"
              ></ppa-certificate-checkbox>
            </li>
          </ul>
          <ng-template #noCertificates>
            {{ 'certificate.no_certificates' | transloco }}
          </ng-template>
        </ng-container>

        <ppa-notation
          [label]="t('modules.dossier.create.notes.others_note')"
          formControlName="othersNote"
        ></ppa-notation>
        <div *ngIf="(form.submitted || controls.othersNote.touched) && controls.othersNote.invalid">
          <div class="form-error" *ngIf="controls.othersNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <div class="form-section">
        <ng-container *ngIf="carouselPhotos$ | async as carouselPhotos">
          <div class="carousel" *ngIf="carouselPhotos.length > 0">
            <ppa-carousel [slides]="carouselPhotos" [showText]="true"></ppa-carousel>
          </div>

          <button
            type="button"
            mat-flat-button
            color="primary"
            pre-icon
            fixed
            class="button-manage-photos"
            (click)="openPhotoModal()"
          >
            <i class="uil uil-images"></i>
            <ng-container *ngIf="carouselPhotos.length > 0; else noPhotosButton">
              {{ 'modules.dossier.create.manage_photos' | transloco }}
            </ng-container>
            <ng-template #noPhotosButton>
              {{ 'modules.photo.modal.add_photo' | transloco }}
            </ng-template>
          </button>
        </ng-container>
      </div>
    </div>
  </form>
</div>

<ng-template #amountUnitSelect let-form="form" let-calculate="calculate || false">
  <div class="flex-row" [formGroup]="form">
    <div class="flex-1-2">
      <mat-form-field appearance="fill">
        <mat-label>
          {{ 'form.elements.unit.amount_of_unit_label' | transloco }}
          <span class="required" *ngIf="isRequiredField('amountOfUnit') | async">*</span>
        </mat-label>
        <input
          type="tel"
          inputmode="decimal"
          matInput
          ppaNumberInput
          (change)="calculate ? calculateAmount(true) : null"
          formControlName="amountOfUnit"
        />
      </mat-form-field>
      <div *ngIf="(form.submitted || controls.amountOfUnit.touched) && controls.amountOfUnit.invalid">
        <div class="form-error" *ngIf="controls.amountOfUnit.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
        <div class="form-error" *ngIf="controls.amountOfUnit.errors.pattern">
          <span>{{ 'form_errors.invalid_number' | transloco }}</span>
        </div>
      </div>
    </div>
    <div class="flex-1-2">
      <ppa-select
        [items]="amountUnitOptions$ | async"
        bindLabel="title"
        label="form.elements.unit.amount_unit"
        formControlName="amountUnit"
        (selectionChange)="calculate ? calculateAmount(true) : null"
      >
      </ppa-select>
      <div *ngIf="(form.submitted || controls.amountUnit.touched) && controls.amountUnit.invalid">
        <div class="form-error" *ngIf="controls.amountUnit.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
