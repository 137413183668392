<form #form="ngForm" [formGroup]="dossierFormSection">
  <ng-container *transloco="let t">
    <ng-container *ngIf="dossierProductMatrix$ | async as dossierProductMatrix">
      <div class="form-section" *ngIf="cultivationSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.cultivation') }}</h3>

        <ng-container *ngIf="displaySurface$ | async">
          <ng-container *ngIf="dossierProductMatrix.has('surfaceCultivation')">
            <div>
              <mat-form-field appearance="fill">
                <mat-label>{{ t('modules.dossier.create.surface_label') }}</mat-label>
                <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="surfaceCultivation" />
                <span matSuffix>ha</span>
              </mat-form-field>
              <div
                *ngIf="(form.submitted || controls.surfaceCultivation.touched) && controls.surfaceCultivation.invalid"
              >
                <div class="form-error" *ngIf="controls.surfaceCultivation.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
                <div class="form-error" *ngIf="controls.surfaceCultivation.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.cultivation_note')" formControlName="cultivationNote">
        </ppa-notation>
        <div *ngIf="(form.submitted || controls.cultivationNote.touched) && controls.cultivationNote.invalid">
          <div class="form-error" *ngIf="controls.cultivationNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <div class="form-section" *ngIf="saleSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.sale') }}</h3>

        <ng-container *ngIf="displayExpectedAmount$ | async">
          <ng-container *ngIf="dossierProductMatrix.has('expectedAmountSale')">
            <ng-container *ngTemplateOutlet="amountUnitSelect; context: { form: dossierFormSection }"></ng-container>
            <div class="flex-row">
              <div class="flex-1-1">
                <div class="amount__field">
                  <mat-form-field appearance="fill">
                    <mat-label>{{ t('modules.dossier.create.expected_amount_label') }}</mat-label>
                    <input
                      type="tel"
                      inputmode="decimal"
                      matInput
                      ppaNumberInput
                      formControlName="expectedAmountSale"
                      (blur)="calculateAmount()"
                    />
                    <span matSuffix *ngIf="displayTonSuffix$ | async">{{ 'general.ton' | transloco }}</span>
                  </mat-form-field>

                  <button
                    type="button"
                    matSuffix
                    mat-mini-fab
                    matTooltip="{{ 'general.calculate_amount' | transloco }}"
                    aria-label="{{ 'general.calculate_amount' | transloco }}"
                    (click)="calculateAmount(true)"
                    class="amount__button"
                  >
                    <i class="uil uil-calculator"></i>
                  </button>
                </div>

                <div
                  *ngIf="(form.submitted || controls.expectedAmountSale.touched) && controls.expectedAmountSale.invalid"
                >
                  <div class="form-error" *ngIf="controls.expectedAmountSale.errors.pattern">
                    <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('sizeSorting')">
            <ppa-select
              [items]="sizeSorting$ | async"
              [multiple]="true"
              bindLabel="name"
              bindValue="id"
              label="modules.dossier.create.size_sorting_label"
              formControlName="sizeSorting"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.sizeSorting.touched) && controls.sizeSorting.invalid">
              <div class="form-error" *ngIf="controls.sizeSorting.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('packaging')">
          <ppa-select
            [items]="packagingOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.packaging_label"
            formControlName="packaging"
          ></ppa-select>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.sale_note')" formControlName="saleNote"></ppa-notation>
        <div *ngIf="(form.submitted || controls.saleNote.touched) && controls.saleNote.invalid">
          <div class="form-error" *ngIf="controls.saleNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <div class="form-section" *ngIf="seedsSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.seeds') }}</h3>

        <ng-container *ngIf="dossierProductMatrix.has('expectedAmountSeed')">
          <ng-container *ngTemplateOutlet="amountUnitSelect; context: { form: dossierFormSection }"></ng-container>
          <div class="flex-row">
            <div class="flex-1-1">
              <div class="amount__field">
                <mat-form-field appearance="fill">
                  <mat-label>{{ t('modules.dossier.create.expected_amount_label') }}</mat-label>
                  <input
                    type="tel"
                    inputmode="decimal"
                    matInput
                    ppaNumberInput
                    formControlName="expectedAmountSeed"
                    (blur)="calculateAmount()"
                  />
                  <span matSuffix *ngIf="displayTonSuffix$ | async">{{ 'general.ton' | transloco }}</span>
                </mat-form-field>

                <button
                  type="button"
                  matSuffix
                  mat-mini-fab
                  matTooltip="{{ 'general.calculate_amount' | transloco }}"
                  aria-label="{{ 'general.calculate_amount' | transloco }}"
                  (click)="calculateAmount(true)"
                  class="amount__button"
                >
                  <i class="uil uil-calculator"></i>
                </button>
              </div>

              <div
                *ngIf="(form.submitted || controls.expectedAmountSeed.touched) && controls.expectedAmountSeed.invalid"
              >
                <div class="form-error" *ngIf="controls.expectedAmountSeed.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('fungicideCoating')">
          <ppa-select
            [items]="fungicideCoatingOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.fungicide_coating_label"
            formControlName="fungicideCoating"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.fungicideCoating.touched) && controls.fungicideCoating.invalid">
            <div class="form-error" *ngIf="controls.fungicideCoating.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.seeds_note')" formControlName="seedsNote"></ppa-notation>
        <div *ngIf="(form.submitted || controls.seedsNote.touched) && controls.seedsNote.invalid">
          <div class="form-error" *ngIf="controls.seedsNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>

      <div class="form-section" *ngIf="stageSection$ | async">
        <h3>{{ t('modules.dossier.create.sections.stage') }}</h3>

        <ppa-select
          [items]="stageOptions"
          [translateLabel]="true"
          bindLabel="key"
          bindValue="value"
          label="modules.dossier.create.stage_label"
          formControlName="stage"
        ></ppa-select>
        <div *ngIf="(form.submitted || controls.stage.touched) && controls.stage.invalid">
          <div class="form-error" *ngIf="controls.stage.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>

        <div *ngIf="(form.submitted || controls.stage.touched) && controls.stage.invalid">
          <div class="form-error" *ngIf="controls.stage.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>

        <ng-container *ngIf="(selectedStage$ | async) == 'off_meadow'">
          <ng-container *ngIf="dossierProductMatrix.has('expectedHarvest')">
            <div>
              <mat-slide-toggle #useExactDateRef formControlName="expectedHarvestExactDate">
                {{ t('modules.dossier.create.expected_harvest_exact_date_label') }}
              </mat-slide-toggle>
              <ppa-datepicker-week-exact-switch
                [useExactDate]="useExactDateRef.checked"
                formControlName="expectedHarvest"
              ></ppa-datepicker-week-exact-switch>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('finalHarvest')">
            <div>
              <ppa-datetime-picker
                formControlName="finalHarvest"
                [dateLabel]="'modules.dossier.create.final_harvest_label' | transloco"
                [timeSelect]="false"
              ></ppa-datetime-picker>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('preferenceDelivery')">
            <ppa-select
              [items]="preferenceDeliveryOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.preference_delivery_label"
              formControlName="preferenceDelivery"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.preferenceDelivery.touched) && controls.preferenceDelivery.invalid">
              <div class="form-error" *ngIf="controls.preferenceDelivery.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('surface')">
            <div>
              <mat-form-field appearance="fill">
                <mat-label>{{ t('modules.dossier.create.surface_label') }}</mat-label>
                <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="surface" />
                <span matSuffix>ha</span>
              </mat-form-field>
              <div *ngIf="(form.submitted || controls.surface.touched) && controls.surface.invalid">
                <div class="form-error" *ngIf="controls.surface.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
                <div class="form-error" *ngIf="controls.surface.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('expectedAmount')">
            <ng-container *ngTemplateOutlet="amountUnitSelect; context: { form: dossierFormSection }"></ng-container>
            <div class="flex-row">
              <div class="flex-1-1">
                <div class="amount__field">
                  <mat-form-field appearance="fill">
                    <mat-label>{{ t('modules.dossier.create.expected_amount_label') }}</mat-label>
                    <input
                      type="tel"
                      inputmode="decimal"
                      matInput
                      ppaNumberInput
                      formControlName="expectedAmount"
                      (blur)="calculateAmount()"
                    />
                    <span matSuffix *ngIf="displayTonSuffix$ | async">{{ 'general.ton' | transloco }}</span>
                  </mat-form-field>

                  <button
                    type="button"
                    matSuffix
                    mat-mini-fab
                    matTooltip="{{ 'general.calculate_amount' | transloco }}"
                    aria-label="{{ 'general.calculate_amount' | transloco }}"
                    (click)="calculateAmount(true)"
                    class="amount__button"
                  >
                    <i class="uil uil-calculator"></i>
                  </button>
                </div>

                <div *ngIf="(form.submitted || controls.expectedAmount.touched) && controls.expectedAmount.invalid">
                  <div class="form-error" *ngIf="controls.expectedAmount.errors.pattern">
                    <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('meadowDiseases')">
            <ppa-select
              [items]="offMeadowDiseases$ | async"
              [multiple]="true"
              bindLabel="name"
              label="modules.dossier.create.meadow_diseases_label"
              formControlName="meadowDiseases"
            ></ppa-select>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="['barn', 'storage_short', 'storage_long'].indexOf(selectedStage$ | async) != -1">
          <ng-container *ngIf="dossierProductMatrix.has('preferredDeliveryTime')">
            <ppa-select
              [items]="monthOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.preferred_delivery_time_label"
              formControlName="preferredDeliveryTime"
            ></ppa-select>
            <div
              *ngIf="
                (form.submitted || controls.preferredDeliveryTime.touched) && controls.preferredDeliveryTime.invalid
              "
            >
              <div class="form-error" *ngIf="controls.preferredDeliveryTime.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('estimatedAmount')">
            <ng-container *ngTemplateOutlet="amountUnitSelect; context: { form: dossierFormSection }"></ng-container>
            <div class="flex-row">
              <div class="flex-1-1">
                <div class="amount__field">
                  <mat-form-field appearance="fill">
                    <mat-label>{{ t('modules.dossier.create.estimated_amount_label') }}</mat-label>
                    <input
                      type="tel"
                      inputmode="decimal"
                      matInput
                      ppaNumberInput
                      formControlName="estimatedAmount"
                      (blur)="calculateAmount()"
                    />
                    <span matSuffix *ngIf="displayTonSuffix$ | async">{{ 'general.ton' | transloco }}</span>
                  </mat-form-field>

                  <button
                    type="button"
                    matSuffix
                    mat-mini-fab
                    matTooltip="{{ 'general.calculate_amount' | transloco }}"
                    aria-label="{{ 'general.calculate_amount' | transloco }}"
                    (click)="calculateAmount(true)"
                    class="amount__button"
                  >
                    <i class="uil uil-calculator"></i>
                  </button>
                </div>

                <div *ngIf="(form.submitted || controls.estimatedAmount.touched) && controls.estimatedAmount.invalid">
                  <div class="form-error" *ngIf="controls.estimatedAmount.errors.required">
                    <span>{{ 'form_errors.required' | transloco }}</span>
                  </div>
                  <div class="form-error" *ngIf="controls.estimatedAmount.errors.pattern">
                    <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('barnDiseases')">
            <ppa-select
              [items]="barnDiseases$ | async"
              [multiple]="true"
              bindLabel="name"
              label="modules.dossier.create.barn_diseases_label"
              formControlName="barnDiseases"
            ></ppa-select>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('storageBox')">
            <ppa-select
              [items]="storageBoxOptions"
              [translateLabel]="true"
              bindLabel="key"
              bindValue="value"
              label="modules.dossier.create.storage_box_label"
              formControlName="storageBox"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.storageBox.touched) && controls.storageBox.invalid">
              <div class="form-error" *ngIf="controls.storageBox.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('colorGrade')">
            <ppa-select
              [items]="colorGrades"
              label="modules.dossier.create.color_grade_label"
              formControlName="colorGrade"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.colorGrade.touched) && controls.colorGrade.invalid">
              <div class="form-error" *ngIf="controls.colorGrade.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="(selectedStage$ | async) == 'land'">
          <ng-container *ngIf="dossierProductMatrix.has('surface')">
            <div>
              <mat-form-field appearance="fill">
                <mat-label>{{ t('modules.dossier.create.surface_label') }}</mat-label>
                <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="surface" />
                <span matSuffix>ha</span>
              </mat-form-field>
              <div *ngIf="(form.submitted || controls.surface.touched) && controls.surface.invalid">
                <div class="form-error" *ngIf="controls.surface.errors.required">
                  <span>{{ 'form_errors.required' | transloco }}</span>
                </div>
                <div class="form-error" *ngIf="controls.surface.errors.pattern">
                  <span>{{ 'form_errors.invalid_number' | transloco }}</span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="dossierProductMatrix.has('meadowDiseases')">
            <ppa-select
              [items]="offMeadowDiseases$ | async"
              [multiple]="true"
              bindLabel="name"
              label="modules.dossier.create.meadow_diseases_label"
              formControlName="meadowDiseases"
            ></ppa-select>
          </ng-container>
        </ng-container>

        <ppa-notation [label]="t('modules.dossier.create.notes.stage_note')" formControlName="stageNote"></ppa-notation>
        <div *ngIf="(form.submitted || controls.stageNote.touched) && controls.stageNote.invalid">
          <div class="form-error" *ngIf="controls.stageNote.errors.maxlength">
            <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>

<ng-template #amountUnitSelect let-form="form">
  <div class="flex-row" [formGroup]="form">
    <div class="flex-1-2">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'form.elements.unit.amount_of_unit_label' | transloco }}</mat-label>
        <input type="tel" inputmode="decimal" matInput ppaNumberInput formControlName="amountOfUnit" />
      </mat-form-field>
      <div *ngIf="(form.submitted || controls.amountOfUnit.touched) && controls.amountOfUnit.invalid">
        <div class="form-error" *ngIf="controls.amountOfUnit.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
        <div class="form-error" *ngIf="controls.amountOfUnit.errors.pattern">
          <span>{{ 'form_errors.invalid_number' | transloco }}</span>
        </div>
      </div>
    </div>
    <div class="flex-1-2">
      <ppa-select
        [items]="amountUnitOptions$ | async"
        bindLabel="title"
        label="form.elements.unit.amount_unit"
        formControlName="amountUnit"
      >
      </ppa-select>
      <div *ngIf="(form.submitted || controls.amountUnit.touched) && controls.amountUnit.invalid">
        <div class="form-error" *ngIf="controls.amountUnit.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
