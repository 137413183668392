import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Modal } from '../../classes';
import { WizardStep } from '../../contracts';
import { ModalService, WizardService } from '../../services';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ppa-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardComponent extends Modal implements OnInit, OnDestroy {
  @ViewChild('wizardStep', { read: ViewContainerRef, static: true })
  wizardStepContainer: ViewContainerRef;

  steps: WizardStep[] = [];

  activeWizardStep$: Observable<WizardStep>;
  reachedLastWizardStep$: Observable<boolean>;

  constructor(protected modalService: ModalService, private wizardService: WizardService) {
    super(modalService);
  }

  ngOnInit(): void {
    this.wizardService.initialize(this.wizardStepContainer, this.steps);
    this.activeWizardStep$ = this.wizardService.getActiveWizardStep();
    this.reachedLastWizardStep$ = this.wizardService.hasReachedLastWizardStep();
    this.wizardService
      .shouldSave()
      .pipe(take(1))
      .subscribe(() => {
        this.save(true);
      });
  }

  ngOnDestroy(): void {
    this.wizardService.cleanup();
  }

  close() {
    this.wizardService
      .unload()
      .then((canUnload) => {
        if (canUnload) {
          super.close();
        } else {
          const modal = this.modalService.createModal(ConfirmDialogComponent, {
            message: 'Weet je zeker dat je wilt stoppen met het aanmaken van dit dossier?',
            confirmButtonText: 'Stoppen',
          });

          return modal.dialog.onSave().then((confirmed) => {
            if (confirmed) {
              super.close();
            }
          });
        }
      })
      .catch((reason) => {
        // close is blocked
      });
  }

  next() {
    this.wizardService
      .next()
      .then(() => {
        // next is allowed and performed
      })
      .catch((reason) => {
        // next is blocked
      });
  }

  previous() {
    this.wizardService
      .previous()
      .then(() => {
        // previous is allowed and performed
      })
      .catch((reason) => {
        // previous is blocked
      });
  }

  finish() {
    this.wizardService
      .finish()
      .then(() => {
        this.wizardService.markAsPristine();
      })
      .catch((reason) => {
        // finish is blocked
      });
  }
}
