import { Component, OnInit } from '@angular/core';
import { Modal, ModalService } from '@ppa/layout';
import { Order } from '@ppa/data';
import { Router } from '@angular/router';

@Component({
  selector: 'ppa-order-select-modal',
  templateUrl: './order-select-modal.component.html',
})
export class OrderSelectModalComponent extends Modal implements OnInit {
  orders: Order[];
  dossier?: number;
  sellType?: string;

  constructor(_modalService: ModalService, private router: Router) {
    super(_modalService);
  }

  ngOnInit() {}

  viewOrder(orderId): void {
    this.save(orderId);
  }

  createOrder() {
    this.close();

    this.router.navigate(['/order', 'create'], {
      queryParams: {
        ...(this.dossier && { dossier: this.dossier }),
        ...(this.sellType && { sellType: this.sellType }),
      },
    });
  }
}
