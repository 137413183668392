import { Injectable } from '@angular/core';

const STORAGE_KEY = 'BACK_URL';
const ROUTE_STORAGE_KEY = 'BACK_ROUTE';

@Injectable({
  providedIn: 'root',
})
export class NavigateBackService {
  constructor() {}

  storeBackUrl() {
    sessionStorage.setItem(STORAGE_KEY, document.location.href);
  }
  navigateBack() {
    const backUrl = sessionStorage.getItem(STORAGE_KEY);

    sessionStorage.removeItem(STORAGE_KEY);
    document.location.href = backUrl;
  }

  hasBackUrl() {
    return !!sessionStorage.getItem(STORAGE_KEY);
  }

  storeBackRoute(route) {
    sessionStorage.setItem(ROUTE_STORAGE_KEY, route);
  }

  getBackRoute(remove = true) {
    const backRoute = sessionStorage.getItem(ROUTE_STORAGE_KEY);
    if (remove) {
      sessionStorage.removeItem(ROUTE_STORAGE_KEY);
    }
    return backRoute;
  }

  hasBackRoute() {
    return !!sessionStorage.getItem(ROUTE_STORAGE_KEY);
  }
}
