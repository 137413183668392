import { NgModule } from '@angular/core';
import { CardComponent } from './card.component';
import { MatCardModule } from '@angular/material/card';
import { StatusModule } from '../status';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

const COMPONENTS = [CardComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [MatCardModule, StatusModule, CommonModule, TranslocoModule],
  exports: [...COMPONENTS],
})
export class CardModule {}
