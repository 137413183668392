import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractWizardStepComponent } from '@ppa/layout';
import { DossierService } from '../../services/dossier.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ConnectableObservable, Observable, Subscription } from 'rxjs';
import {
  AuthService,
  ConfigService,
  KeyValuePairs,
  Model,
  Product,
  ProductDataService,
  UserDataService,
  UserOption,
} from '@ppa/data';
import { distinctUntilChanged, publishReplay, take, takeUntil, tap } from 'rxjs/operators';
@Component({
  selector: 'ppa-dossier-wizard-specifications-one',
  templateUrl: './dossier-wizard-specifications-one.component.html',
  styleUrls: ['./dossier-wizard-specifications-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DossierWizardSpecificationsOneComponent extends AbstractWizardStepComponent implements OnInit, OnDestroy {
  dossierFormSection: FormGroup;
  sellTypeOptions: KeyValuePairs<string>;
  rentTypeOptions: KeyValuePairs<string>;
  breedingSellingOptions: KeyValuePairs<string>;

  selectedProductSubscription: Subscription;

  products$: Observable<Product[]>;
  mediators$: Observable<UserOption[]>;
  selectedProduct$: Observable<Product>;
  dossierProductMatrix$: Observable<Map<string, string | null>>;

  @ViewChild('form') form: NgForm;

  get controls() {
    return this.dossierFormSection.controls;
  }

  constructor(
    private dossierService: DossierService,
    private userDataService: UserDataService,
    private productDataService: ProductDataService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private authService: AuthService,
    private configService: ConfigService,
  ) {
    super();
    const dossierFormSectionFields = this.dossierService.clearValidators(
      [Validators.required],
      {
        ...this.dossierService.dossierFormSectionFields['product'],
      },
      ['product', 'productVariety'],
    );

    this.dossierFormSection = this.fb.group({
      mediator: [{ id: this.authService.getUserIdFromToken() }, [Validators.required]],
      title: [null],
      ...dossierFormSectionFields,
    });
  }

  ngOnInit(): void {
    this.sellTypeOptions = this.dossierService.sellTypeOptions;
    this.rentTypeOptions = this.dossierService.rentTypeOptions;
    this.breedingSellingOptions = this.dossierService.breedingSellingOptions;

    this.dossierProductMatrix$ = this.dossierService.dossierProductMatrix;

    this.mediators$ = this.userDataService.options();
    this.products$ = this.productDataService.getProductOptions();

    this.selectedProduct$ = this.controls.product.valueChanges.pipe(
      distinctUntilChanged(),
      tap(this.onProductChange.bind(this)),
      publishReplay(1),
    );
    this.selectedProductSubscription = (this.selectedProduct$ as ConnectableObservable<Product>).connect();

    this.dossierFormSection.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleFormChanges.bind(this));
    this.onNext$.pipe(takeUntil(this.destroy$)).subscribe(this.handleSubmit.bind(this));
    this.onPrevious$.pipe(takeUntil(this.destroy$)).subscribe(this.handleReset.bind(this));

    this.dossierService
      .getDossierWizardState()
      .pipe(take(1))
      .subscribe((dossierWizardState) => {
        this.dossierFormSection.patchValue(dossierWizardState);
        this.dirty$.next(this.dossierFormSection.invalid || this.dossierFormSection.touched);
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.selectedProductSubscription) {
      this.selectedProductSubscription.unsubscribe();
    }
  }

  onProductChange(product: Product) {
    if (product.defaultValues?.harvestYear) {
      this.controls.harvestYear.patchValue(
        this.configService.harvest_year + parseInt(product.defaultValues?.harvestYear, 10),
      );
    } else {
      this.controls.harvestYear.setValue(this.configService.harvest_year);
    }

    if (product.defaultValues) {
      const keys = Object.keys(product.defaultValues);
      for (const key of keys) {
        if (key !== 'harvestYear') {
          if (this.controls[key]) {
            this.controls[key].patchValue(product.defaultValues[key]);
          }
        }
      }
    }

    this.dossierService.setProductMatrix(this.dossierFormSection, product);
    this.controls.product.markAsTouched();
  }

  handleSubmit() {
    this.dossierFormSection.markAllAsTouched();
    this.cdr.detectChanges();
    if (this.dossierFormSection.valid) {
      this.dossierService.setDossierWizardState({ ...this.dossierFormSection.value });
      this.blockingNext$.next(false);
    } else {
      this.blockingNext$.next(true);
    }
  }

  handleReset() {
    this.dossierService.resetDossierProductMatrix();
  }

  handleFormChanges() {
    this.dossierService.setDossierWizardState({ ...this.dossierFormSection.value });

    if (this.dossierFormSection.touched) {
      this.dirty$.next(true);
    }
  }

  handleUnload() {
    super.handleUnload();

    this.dossierService.clearDossierWizardState();
  }

  idCompare(o1: Model, o2: Model) {
    return o1?.id === o2?.id;
  }
}
