import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Directive({
  selector: '[ppaBackButton]',
})
export class BackButtonDirective {
  @Input() defaultRoute: string = '/';

  constructor(private navigation: NavigationService) {}

  @HostListener('click', ['$event'])
  onClick(): void {
    this.navigation.back(this.defaultRoute);
  }
}
