<div class="button-container">
  <button
    mat-raised-button
    color="primary"
    [attr.data-cy]="cyIdentifier"
    pre-icon
    (click)="handleClick()"
    [disabled]="disabled"
  >
    <i [ngClass]="[icon]"></i>
    {{ title }}
  </button>
</div>
