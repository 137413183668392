import { Component, HostListener, OnInit } from '@angular/core';
import { MenuService } from '../../services';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'ppa-orientation-indication',
  templateUrl: './orientation-indication.component.html',
  styleUrls: ['./orientation-indication.component.scss'],
})
export class OrientationIndicationComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.toggleMenuOnLandscape();
  }

  constructor(private menuService: MenuService, private deviceDetectorService: DeviceDetectorService) {
    this.toggleMenuOnLandscape();
  }

  ngOnInit(): void {}

  toggleMenuOnLandscape() {
    // if (this.deviceDetectorService.isMobile() && window.outerHeight < window.outerWidth) {
    //   this.menuService.setMenuVisible(true);
    // } else {
    //   this.menuService.setMenuVisible(true);
    // }
  }
}
