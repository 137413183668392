import { NotFoundComponent } from './components/not-found/not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { NgModule } from '@angular/core';
import { AuthenticatedGuard } from '@ppa/data';
import { SearchComponent } from './components/search/search.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'dossier',
        loadChildren: () => import('./modules/dossier/dossier.module').then((m) => m.DossierModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'order',
        loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'relation',
        loadChildren: () => import('./modules/relation/relation.module').then((m) => m.RelationModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./modules/administration/administration.module').then((m) => m.AdministrationModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [AuthenticatedGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/administration/administration.module').then((m) => m.AdministrationModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'history',
        loadChildren: () => import('./modules/history/history.module').then((m) => m.HistoryModule),
        canActivate: [AuthenticatedGuard],
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
