import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ppa-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss'],
})
export class FloatingButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() icon = '';
  @Input() title = '';
  /** cyIdentifier for data-cy */
  @Input() cyIdentifier = '';
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleClick() {
    this.onClick.emit(null);
  }
}
