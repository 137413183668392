import { Injectable } from '@angular/core';
import { Photo } from '../contracts';
import heic2any from 'heic2any';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  resizePhoto(photo: Photo, maxWidth = 1920, maxHeight = 1080): Promise<Photo> {
    return new Promise((resolve, reject) => {
      if (!photo.base64) {
        return reject(photo);
      }

      // Create and initialize two canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const canvasCopy = document.createElement('canvas');
      const copyContext = canvasCopy.getContext('2d');

      // Create original image
      const img = new Image();
      img.src = photo.base64;

      // Wait till the image is loaded, otherwise the size won't be available
      img.addEventListener('load', () => {
        // Determine new ratio based on max size
        let ratio;
        if (img.width > img.height) {
          ratio = maxWidth / img.width;
        } else {
          ratio = maxHeight / img.height;
        }

        // Draw original image in second canvas
        canvasCopy.width = img.width;
        canvasCopy.height = img.height;
        copyContext.drawImage(img, 0, 0);

        // if (ratio < 1) { // weer uitgezet, was origineel als fix door jool erin gezet, maar geen idee waarom. afbeeldingen worden hierdoor heel klein
        // Copy and resize second canvas to first canvas
        canvas.width = img.width * ratio;
        canvas.height = img.height * ratio;
        // }
        ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

        // downgrade image quality to 0.6 for better uploads
        // const imageData = canvas.toDataURL('image/jpeg', 0.9);
        const imageData = canvas.toDataURL(photo.mime, 0.4);

        // Overwrite photo object with new photo data
        photo.mime = imageData.split(':')[1].split(';')[0];
        photo.base64 = imageData;

        resolve(photo);
      });
    });
  }

  convertIfHEIC(file) {
    return new Promise((resolve) => {
      if (!this.isHEIC(file)) {
        return resolve(file);
      }
      heic2any({
        blob: file,
        toType: 'image/jpg',
      }).then((convertedFile) => {
        /* @ts-ignore */
        convertedFile.name = file.name.substring(0, file.name.lastIndexOf('.')) + '.jpeg';
        resolve(convertedFile);
      });
    });
  }

  isHEIC(file) {
    // check file extension since windows returns blank mime for heic
    const x = file.type ? file.type.split('image/').pop() : file.name.split('.').pop().toLowerCase();
    return x === 'heic' || x === 'heif';
  }
}
