import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ContractTemplateVariant } from '../contracts/contract-template-variant';
import { ListResponse } from '../contracts/list-response';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PaginatedResponse, PaginationOptions} from '../contracts';
import {HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class ContractTemplateVariantDataService extends BaseService<ContractTemplateVariant> {
  path = 'contract_template_variants';

  list(params?: { [p: string]: any }): Observable<ListResponse<ContractTemplateVariant>> {
    return super.listPaginated({}).pipe(switchMap((templates) => of(templates.data)));
  }

  getContractTemplateVariants(options: PaginationOptions): Observable<PaginatedResponse<ContractTemplateVariant>> {
    return super.listPaginated(options);
  }

  previewPdf(templateVariant: ContractTemplateVariant, relationId: number = 0,
             productId: number = 0, productVarietyId: number = 0): Observable<HttpResponse<Blob>> {
    const id = templateVariant.id;
    const relation = relationId !== 0 ? relationId : templateVariant.relation?.id ?? 0;
    const product = productId !== 0 ? productId : templateVariant.product?.id ?? 0;
    const productVariety = productVarietyId !== 0 ? productVarietyId : 0;

    return this.http.get(`${this.apiConfig.baseUrl}/${this.path}/preview/${id}/${relation}/${product}/${productVariety}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
