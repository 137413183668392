import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'ppa-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
})
export class ActionCardComponent implements OnInit {
  @ViewChild('contentWrapper', { read: ElementRef, static: true }) content: ElementRef;

  @Input() state: 'active' | 'in-progress' | 'primary' | 'completed' = 'active';

  @Input() textColor = '#0A2F52';
  @Input() backgroundColor = '#fff';
  @Input() icon: string;
  @Input() preIcon = false;
  @Input() iconSize: 'small' | 'medium' = 'small';
  @Input() actionIcon: string;
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() cardClass = '';

  /** Whether to add 1.5rem margin-bottom to the action-card */
  @Input() addMarginBottom: boolean;

  @Output() actionClicked = new EventEmitter<void>();
  @Output() contentClicked = new EventEmitter<void>();
  @Output() clearClicked = new EventEmitter<void>();

  iconBackgroundColor = 'transparent';
  iconTextColor = '#0A2F52';

  ngOnInit(): void {
    let iconBackgroundColor;
    let iconTextColor;
    let icon = this.icon;

    switch (this.state) {
      case 'active':
        iconBackgroundColor = 'transparent';
        iconTextColor = '#0A2F52';
        break;
      case 'in-progress':
        iconBackgroundColor = '#FFB800';
        iconTextColor = '#FFFFFF';
        icon = 'uil-minus';
        break;
      case 'completed':
        iconBackgroundColor = '#70CB29';
        iconTextColor = '#FFFFFF';
        break;
      case 'primary':
        iconBackgroundColor = 'transparent';
        iconTextColor = '#FFFFFF';
        break;
    }

    this.iconBackgroundColor = iconBackgroundColor;
    this.iconTextColor = iconTextColor;
    this.icon = icon;
  }

  handleActionClick() {
    if (!this.disabled) {
      this.actionClicked.emit();
    }
  }

  handleContentClick() {
    if (!this.disabled) {
      this.contentClicked.emit();
    }
  }

  handleClearClick() {
    if (!this.disabled) {
      this.clearClicked.emit();
    }
  }
}
