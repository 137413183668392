import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatButtonToggleChange } from "@angular/material/button-toggle";

@Component({
  selector: 'ppa-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
})
export class ButtonToggleComponent implements OnInit, OnDestroy {
  @Input() active = false;

  @Input() color: ThemePalette = 'primary';
  @Input() text = 'Aan';

  @Input() activeColor: ThemePalette = 'warn';
  @Input() activeText = 'Uit';

  @Input() extraClass = '';

  @Output() toggleChanged = new EventEmitter<any>();

  checked: boolean;
  buttonText = '';
  buttonColor = 'primary';

  constructor() {
    this.checked = false;
    this.buttonText = this.text;
  }

  ngOnInit() {
    this.checked = this.active;
    this.checkColors();
  }
  ngOnDestroy() {}

  checkColors() {
    this.buttonText = this.checked ? this.activeText : this.text;
    this.buttonColor = this.checked ? this.activeColor : this.color;
  }

  reset() {
    this.checked = false;
    this.checkColors();
  }

  toggle($event: MatButtonToggleChange): void {
    this.checked = $event.source.checked;
    const event = {
      event: $event,
      component: this
    }
    this.toggleChanged.next(event);
    this.checkColors();
  }
}
