<div data-cy="datepicker-week-exact">
  <form [formGroup]="dateTimeForm">
    <button
      *ngIf="_displayDate !== null && clearable"
      class="clear-input"
      mat-button
      color="warn"
      type="button"
      (click)="clear()"
    >
      <i class="uil uil-times"></i>
      <ng-contianer *ngIf="label; else defautlLabel">
        {{ label | transloco }}
      </ng-contianer>
      <ng-template #defautlLabel>
        {{ 'modules.dossier.create.expected_harvest_' + (useExactDate ? 'date' : 'week') | transloco }}
      </ng-template>
      {{ 'form.elements.datetime_picker.reset' | transloco }}
    </button>

    <mat-form-field appearance="fill">
      <mat-label>
        <ng-contianer *ngIf="label; else defautlLabel">
          {{ label | transloco }}
        </ng-contianer>
        <ng-template #defautlLabel>
          {{ 'modules.dossier.create.expected_harvest_' + (useExactDate ? 'date' : 'week') | transloco }}
        </ng-template>
        <span class="required" *ngIf="required$ | async">*</span>
      </mat-label>
      <input
        readonly
        [hidden]="true"
        matInput
        [matDatepicker]="picker"
        (dateInput)="onDateChange($event)"
        [value]="_value"
        formControlName="date"
      />
      <input
        readonly
        matInput
        [value]="_displayDate ? (_displayDate | ppaDate: (useExactDate ? 'DD-MM-YYYY' : 'W')) : _displayDate"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>
  </form>
</div>
