<div class="ppa-modal ppa-modal--free-form ppa-modal--nested">
  <div class="ppa-modal__heading">
    <h2>{{ 'modules.photo.edit_modal.title' | transloco }}</h2>
  </div>

  <div class="ppa-modal__content">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>
          {{ 'modules.photo.edit_modal.form.date' | transloco }}
          <span class="required" *ngIf="isRequiredField('date') | async">*</span>
        </mat-label>
        <input readonly matInput [matDatepicker]="picker" data-cy="photo-edit-modal-date" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>
          {{ 'modules.photo.edit_modal.form.description' | transloco }}
          <span class="required" *ngIf="isRequiredField('description') | async">*</span>
        </mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          data-cy="photo-edit-modal-description"
          formControlName="description"
        ></textarea>
      </mat-form-field>
    </form>
  </div>

  <div class="ppa-modal__actions">
    <button mat-flat-button (click)="close()">
      {{ 'modules.photo.edit_modal.actions.cancel' | transloco }}
    </button>
    <button mat-flat-button color="primary" data-cy="photo-edit-modal-save-button" (click)="returnPhoto()">
      {{ 'modules.photo.edit_modal.actions.save' | transloco }}
    </button>
  </div>
</div>

<div class="ppa-modal__fader ppa-modal__fader--nested"></div>
