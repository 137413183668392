import { Component, OnInit } from '@angular/core';
import { Intention, ModalService, PPAToastrService } from '@ppa/layout';
import { ContractService } from '../../services/contract.service';
import { Contract, purify } from '@ppa/data';
import { translate } from '@ngneat/transloco';
import { ErrorHandlerService } from '@ppa/layout';
import { ContractEditModalComponent } from '../contract-edit-modal/contract-edit-modal.component';

@Component({
  selector: 'ppa-contract-duplicate-modal',
  templateUrl: './contract-duplicate-modal.component.html',
})
export class ContractDuplicateModalComponent extends ContractEditModalComponent implements OnInit {
  constructor(
    _modalService: ModalService,
    _contractService: ContractService,
    _toastrService: PPAToastrService,
    _errorHandler: ErrorHandlerService,
  ) {
    super(_modalService, _contractService, _toastrService, _errorHandler);
    this.saving$.next(false);
  }

  handleDuplicateContractSave(contract: Contract) {
    this.saving$.next(true);

    const original_id = this.contract.id;
    contract.sentContract = this.contract.sentContract;
    contract.certification.forEach((certificate) => certificate?.id && purify(certificate));
    this.contractService.duplicate(contract, original_id).subscribe(
      () => {
        this.saving$.next(false);
        this.save(true);
        this.toastrService.displayToastr({
          icon: 'uil-check',
          title: translate('modules.contract.edit.edit_success.title'),
          intention: Intention.Success,
          duration: 3000,
        });
      },
      (error) => {
        this.saving$.next(false);
        this.errorHandler.handleAPIError(translate('modules.contract.edit.edit_failed.title'), error);
      },
    );
  }
}
