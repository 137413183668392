<ppa-spinner [status$]="exporting$" message="filter.exporting"></ppa-spinner>

<span *ngIf="export" class="circle-button filter-form--button filter-form--export-button" (click)="exportResults()">
  <i [class]="'uil uil-download-alt'"></i>
</span>
<span
  class="circle-button filter-form--button"
  (click)="toggleForm()"
  [class.circle-button--active]="formVisible || activeFilters > 0 || sorted"
>
  <i [class]="'uil uil-filter'"></i>
  <span class="circle-button__badge" *ngIf="sorted && activeFilters === 0"><i [class]="'uil uil-sort'"></i></span>
  <i class="circle-button__badge" *ngIf="activeFilters > 0 && activeFilters <= 9">{{ activeFilters }}</i>
  <i class="circle-button__badge" *ngIf="activeFilters > 9">9+</i>
</span>
<div class="filter-container" *ngIf="formVisible">
  <form [formGroup]="savedFilterForm" class="filter-form--load">
    <ppa-select
      label="filter.saved.load"
      [items]="userFilters$ | async"
      bindLabel="label"
      [clearable]="true"
      class="filter-form__load-filter"
      (selectionChange)="loadFilter($event)"
      [formControlName]="'savedFilter'"
    >
    </ppa-select>
    <span
      *ngIf="deletable"
      class="circle-button circle-button__margin circle-button__relative circle-button--disabled"
      (click)="deleteSavedFilter()"
    >
      <i [class]="'uil uil-trash'"></i>
    </span>
  </form>
  <div class="filter-form mb-1" *ngIf="filterForm && !saveVisible">
    <h4>
      {{ 'filter.header_title' | transloco }}
      <span class="filter--save" (click)="showSaveFilterForm()" *ngIf="activeFilters > 0 || sorted">
        <i [class]="'uil uil-save'"></i> {{ 'filter.save' | transloco }}
      </span>
      <span class="filter--reset" (click)="clearFilters()" *ngIf="activeFilters > 0 || sorted">
        <i [class]="'uil uil-refresh'"></i> {{ 'filter.reset' | transloco }}
      </span>
    </h4>

    <form [formGroup]="filterForm">
      <div class="filter-form__item">
        <span class="circle-button circle-button__margin circle-button__relative circle-button--disabled">
          <i [class]="'uil uil-sort'"></i>
        </span>
        <ppa-select
          label="sorting.direction"
          [items]="sortingDirections"
          bindLabel="key"
          bindValue="value"
          formControlName="direction"
          [translateLabel]="true"
          [clearable]="false"
          class="filter-form__operator"
        >
        </ppa-select>
        <ppa-select
          label="sorting.sorting"
          [items]="sortingFields"
          bindLabel="key"
          bindValue="value"
          formControlName="sort"
          [translateLabel]="true"
          class="filter-form__filter"
        >
        </ppa-select>
      </div>

      <div *ngFor="let filter of usedFilters" class="filter-form__item">
        <div [ngSwitch]="filter.type">
          <div *ngSwitchCase="'search'">
            <ng-container *ngTemplateOutlet="search; context: { $implicit: filter, form: filterForm }"> </ng-container>
          </div>
          <div *ngSwitchCase="'fixed'">
            <ng-container *ngTemplateOutlet="fixed; context: { $implicit: filter, form: filterForm }"> </ng-container>
          </div>
          <div *ngSwitchCase="'date'">
            <ng-container *ngTemplateOutlet="date; context: { $implicit: filter, form: filterForm }"> </ng-container>
          </div>
          <div *ngSwitchCase="'year'">
            <ng-container *ngTemplateOutlet="year; context: { $implicit: filter, form: filterForm }"> </ng-container>
          </div>
          <div *ngSwitchCase="'number'">
            <ng-container *ngTemplateOutlet="number; context: { $implicit: filter, form: filterForm }"> </ng-container>
          </div>
        </div>
      </div>
    </form>

    <form [formGroup]="addFilterForm">
      <div class="filter-form__item filter-form__item--flex">
        <span
          class="circle-button circle-button__position circle-button__relative"
          (click)="addFilter()"
          [class.circle-button--active]="chooseFilter"
        >
          <i *ngIf="!chooseFilter" [class]="'uil uil-plus'"></i>
          <i *ngIf="chooseFilter" [class]="'uil uil-times'"></i>
        </span>
        <ng-container *ngIf="chooseFilter">
          <ppa-select
            label="filter.filter"
            [items]="filters"
            bindLabel="label"
            bindValue="name"
            [formControlName]="'selectFilter'"
            [translateLabel]="true"
            [clearable]="false"
            class="filter-form__item--addfilter"
          >
          </ppa-select>
          <button
            [attr.data-cy]="'button-filter-select'"
            type="button"
            mat-raised-button
            color="primary"
            (click)="selectFilter()"
          >
            {{ 'filter.select' | transloco }}
          </button>
        </ng-container>
      </div>
    </form>
  </div>

  <div class="filter-form mb-1" *ngIf="saveVisible">
    <form [formGroup]="saveFilterForm">
      <h4>
        {{ 'filter.saved.header_title' | transloco }}
        <span class="filter--save" (click)="clearSaveFilter()">
          <i [class]="'uil uil-times'"></i> {{ 'filter.cancel' | transloco }}
        </span>
      </h4>

      <mat-slide-toggle [formControlName]="'overwrite'">
        {{ 'filter.saved.overwrite' | transloco }}
      </mat-slide-toggle>

      <ppa-select
        label="filter.saved.overwrite"
        [items]="ownedFilters$ | async"
        bindLabel="label"
        [clearable]="false"
        class="filter-form__load-filter filter-form__save--item"
        [formControlName]="'savedFilter'"
        *ngIf="saveFilterForm.controls.overwrite.value"
      >
      </ppa-select>

      <mat-form-field appearance="fill" class="filter-form__save--item">
        <mat-label>{{ 'filter.saved.title' | transloco }}</mat-label>
        <input data-cy="order-edit-amount" autocomplete="off" type="text" matInput [formControlName]="'title'" />
      </mat-form-field>

      <mat-slide-toggle [formControlName]="'public'">
        {{ 'filter.public' | transloco }}
      </mat-slide-toggle>

      <button
        [attr.data-cy]="'button-filter-sav'"
        type="button"
        mat-raised-button
        color="primary"
        (click)="saveFilter()"
        class="filter-form__save--item"
      >
        {{ 'filter.save' | transloco }}
      </button>
    </form>
  </div>
</div>
<ng-template #search let-filter let-form="form">
  <div [formGroup]="form">
    <div class="search-bar filter-form__search">
      <span class="circle-button circle-button__margin circle-button--active" (click)="removeFilter(filter.key)">
        <i [class]="'uil uil-times'"></i>
      </span>
      <ppa-select
        label="filter.operator"
        [items]="filter.operators || operators"
        bindLabel="key"
        bindValue="value"
        [formControlName]="filter.key + '__operator'"
        [translateLabel]="true"
        [clearable]="false"
        class="filter-form__operator"
      >
      </ppa-select>
      <div class="search-bar__search-field filter-form__filter">
        <mat-form-field appearance="fill">
          <mat-label>{{ filter.label | transloco }}</mat-label>
          <input [formControlName]="filter.key" autocomplete="off" matInput />
          <i *ngIf="filter.name === 'search'" matSuffix class="uil uil-search"></i>
          <span *ngIf="filter.name !== 'search' && filter.suffix" matSuffix>{{ filter.suffix }} </span>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fixed let-filter let-form="form">
  <div [formGroup]="form">
    <span
      class="circle-button circle-button__margin circle-button__relative circle-button--active"
      (click)="removeFilter(filter.key)"
    >
      <i [class]="'uil uil-times'"></i>
    </span>
    <ppa-select
      label="filter.operator"
      [items]="filter.operators || operators"
      bindLabel="key"
      bindValue="value"
      [formControlName]="filter.key + '__operator'"
      [translateLabel]="true"
      [clearable]="false"
      class="filter-form__operator"
    >
    </ppa-select>
    <ppa-select
      [label]="filter.label"
      [items]="filter.dataIsObservable ? (filter.data | async) : filter.data"
      [bindLabel]="filter.dataBindLabel"
      [bindValue]="filter.dataBindValue"
      [formControlName]="filter.key"
      [translateLabel]="filter.translateLabel || false"
      [clearable]="false"
      class="filter-form__filter"
    >
    </ppa-select>
  </div>
</ng-template>

<ng-template #date let-filter let-form="form">
  <div [formGroup]="form">
    <div class="search-bar filter-form__search">
      <span class="circle-button circle-button__margin circle-button--active" (click)="removeFilter(filter.key)">
        <i [class]="'uil uil-times'"></i>
      </span>
      <ppa-select
        label="filter.operator"
        [items]="filter.operators || operators"
        bindLabel="key"
        bindValue="value"
        [formControlName]="filter.key + '__operator'"
        [translateLabel]="true"
        [clearable]="false"
        class="filter-form__operator"
      >
      </ppa-select>
      <div class="search-bar__search-field filter-form__filter">
        <mat-form-field appearance="fill">
          <mat-label>{{ filter.label | transloco }}</mat-label>
          <input readonly matInput [formControlName]="filter.key" autocomplete="off" [matDatepicker]="picker" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #number let-filter let-form="form">
  <div [formGroup]="form">
    <div class="search-bar filter-form__search">
      <span class="circle-button circle-button__margin circle-button--active" (click)="removeFilter(filter.key)">
        <i [class]="'uil uil-times'"></i>
      </span>
      <ppa-select
        label="filter.operator"
        [items]="filter.operators || operators"
        bindLabel="key"
        bindValue="value"
        [formControlName]="filter.key + '__operator'"
        [translateLabel]="true"
        [clearable]="false"
        class="filter-form__operator"
      >
      </ppa-select>
      <div class="search-bar__search-field filter-form__filter">
        <mat-form-field appearance="fill">
          <mat-label>{{ filter.label | transloco }}</mat-label>
          <input type="number" min="1" autocomplete="off" [formControlName]="filter.key" matInput />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #year let-filter let-form="form">
  <div [formGroup]="form">
    <span
      class="circle-button circle-button__margin circle-button__relative circle-button--active"
      (click)="removeFilter(filter.key)"
    >
      <i [class]="'uil uil-times'"></i>
    </span>
    <ppa-select
      label="filter.operator"
      [items]="filter.operators || operators"
      bindLabel="key"
      bindValue="value"
      [formControlName]="filter.key + '__operator'"
      [translateLabel]="true"
      [clearable]="false"
      class="filter-form__operator"
    >
    </ppa-select>
    <ppa-select
      [label]="filter.label"
      [formControlName]="filter.key"
      [yearSelect]="true"
      [clearable]="false"
      class="filter-form__filter"
    >
    </ppa-select>
  </div>
</ng-template>
