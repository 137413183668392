import { ErrorHandler, Injectable } from '@angular/core';
import {
  Dossier,
  DossierDataService,
  ConfigService,
  KeyValuePairs,
  Product,
  ProductMatrixRow,
  Disease,
  DiseaseDataService,
  SizeSortingDataService,
  TreatmentDataService,
  PriceUnitIdentifier,
  Unit,
  TreatmentTypes,
  SizeSortingTypes,
  Treatment,
  DossierReminder,
} from '@ppa/data';
import { Observable, BehaviorSubject } from 'rxjs';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UnitService } from '../../../services/unit.service';
import { Filter } from '@ppa/layout';

@Injectable({
  providedIn: 'root',
})
export class DossierService {
  private _dossierWizardState: BehaviorSubject<Dossier> = new BehaviorSubject<Dossier>(null);
  private _dossierProductMatrix: BehaviorSubject<Map<string, string | null>> = new BehaviorSubject<
    Map<string, string | null>
  >(new Map<string, string | null>());
  private _sectionVisibility: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();

  dossierFormSectionFields: { [key: string]: { [key: string]: { [key: string]: any } } };
  private lastSavedDossier$ = new BehaviorSubject<Dossier>(null);
  private units: Unit[] = [];

  get filterDate(): Filter {
    return {
      name: 'date',
      label: 'filter.date',
      type: 'date',
      translateLabel: true,
      allowedOperators: ['gt', 'eq', 'lt'],
    };
  }

  get filterPeriod(): Filter {
    return {
      name: 'period',
      label: 'filter.period',
      type: 'fixed',
      data: this.periodsForFilter,
      dataBindLabel: 'key',
      dataBindValue: 'value',
      translateLabel: true,
      allowedOperators: ['eq'],
    };
  }

  get periodsForFilter(): KeyValuePairs<string> {
    return [
      { key: 'period.today', value: 'today' },
      { key: 'period.yesterday', value: 'yesterday' },
      { key: 'period.this-week', value: 'this-week' },
      { key: 'period.last-week', value: 'last-week' },
      { key: 'period.this-month', value: 'this-month' },
      { key: 'period.last-month', value: 'last-month' },
      { key: 'period.this-year', value: 'this-year' },
      { key: 'period.last-year', value: 'last-year' },
    ];
  }

  get harvestYears(): KeyValuePairs<string> {
    return this.dossierDataService.harvestYears;
  }

  get deliveryYears(): KeyValuePairs<string> {
    return this.dossierDataService.deliveryYears;
  }

  get booleanOptions(): KeyValuePairs<boolean> {
    return [
      { key: 'modules.dossier.create.organic_options.true', value: true },
      { key: 'modules.dossier.create.organic_options.false', value: false },
    ];
  }

  get numericBooleanOptions(): KeyValuePairs<number> {
    return [
      { key: 'modules.dossier.create.organic_options.true', value: 1 },
      { key: 'modules.dossier.create.organic_options.false', value: 0 },
    ];
  }

  get monthOptions(): KeyValuePairs<number> {
    return [
      { key: 'months.1', value: 1 },
      { key: 'months.2', value: 2 },
      { key: 'months.3', value: 3 },
      { key: 'months.4', value: 4 },
      { key: 'months.5', value: 5 },
      { key: 'months.6', value: 6 },
      { key: 'months.7', value: 7 },
      { key: 'months.8', value: 8 },
      { key: 'months.9', value: 9 },
      { key: 'months.10', value: 10 },
      { key: 'months.11', value: 11 },
      { key: 'months.12', value: 12 },
    ];
  }

  get stageOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.stage_options.barn', value: 'barn' },
      { key: 'modules.dossier.create.stage_options.off_meadow', value: 'off_meadow' },
      { key: 'modules.dossier.create.stage_options.land', value: 'land' },
      { key: 'modules.dossier.create.stage_options.storage_short', value: 'storage_short' },
      { key: 'modules.dossier.create.stage_options.storage_long', value: 'storage_long' },
    ];
  }

  stageOptionsForProduct(product: Product, control: AbstractControl): KeyValuePairs<string> {
    let options = this.stageOptions;

    if (product.defaultValues?.forceStageLand) {
      options = options.filter((option) => {
        return option.value === 'land';
      });
    } else if (product.defaultValues?.forceStageStorage) {
      options = options.filter((option) => {
        return option.value === 'storage_short' || option.value === 'storage_long';
      });
    } else {
      options = options.filter((option) => {
        return option.value !== 'land' && option.value !== 'storage_short' && option.value !== 'storage_long';
      });
    }

    if (control) {
      const stageOption = control.value;
      if (!options.find((option) => option.value === stageOption)) {
        control.patchValue(null);
      }
    }

    return options;
  }

  get preferenceDeliveryOptions(): KeyValuePairs<string> {
    return [
      {
        key: 'modules.dossier.create.preference_delivery_options.off_meadow',
        value: 'off_meadow',
      },
      {
        key: 'modules.dossier.create.preference_delivery_options.dry_from_the_barn',
        value: 'dry_from_the_barn',
      },
    ];
  }

  get colorGrades(): number[] {
    return [6, 7, 7.5, 8, 9];
  }

  get tareGradeOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.tare_options.info.low', value: 'low' },
      { key: 'modules.dossier.create.tare_options.info.moderate', value: 'moderate' },
      { key: 'modules.dossier.create.tare_options.info.high', value: 'high' },
      { key: 'modules.dossier.create.tare_options.na', value: 'n/a' },
    ];
  }

  get gritGradeOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.grit_grade_options.info.fine', value: 'fine' },
      { key: 'modules.dossier.create.grit_grade_options.info.medium', value: 'medium' },
      { key: 'modules.dossier.create.grit_grade_options.info.coarse', value: 'coarse' },
      { key: 'modules.dossier.create.grit_grade_options.na', value: 'n/a' },
    ];
  }

  get qualityOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.quality_options.class_1', value: 'class_1' },
      { key: 'modules.dossier.create.quality_options.class_2', value: 'class_2' },
      { key: 'modules.dossier.create.quality_options.industry', value: 'industry' },
      { key: 'modules.dossier.create.quality_options.nakt', value: 'nakt' },
      { key: 'modules.dossier.create.quality_options.na', value: 'n/a' },
    ];
  }

  get industryQualityOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.industry_quality_options.good', value: 'good' },
      { key: 'modules.dossier.create.industry_quality_options.average', value: 'average' },
      { key: 'modules.dossier.create.industry_quality_options.bad', value: 'bad' },
    ];
  }

  get sproutInhibitorOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.sprout_inhibitor_options.yes', value: 'yes' },
      { key: 'modules.dossier.create.sprout_inhibitor_options.no', value: 'no' },
      { key: 'modules.dossier.create.sprout_inhibitor_options.in_consultation', value: 'in_consultation' },
    ];
  }

  get sellTypeOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.sell_type_options.buy', value: 'buy' },
      { key: 'modules.dossier.create.sell_type_options.sell', value: 'sell' },
    ];
  }

  get rentTypeOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.rent_type_options.rent', value: 'rent' },
      { key: 'modules.dossier.create.rent_type_options.rent_out', value: 'rent_out' },
    ];
  }

  get storageBoxOptions(): KeyValuePairs<string> {
    return [
      {
        key: 'modules.dossier.create.storage_box_options.separately',
        value: 'separately',
      },
      { key: 'modules.dossier.create.storage_box_options.chests', value: 'chests' },
    ];
  }

  get breedingSellingOptions(): KeyValuePairs<string> {
    return [
      {
        key: 'modules.dossier.create.breeding_selling_options.breeding',
        value: 'breeding',
      },
      {
        key: 'modules.dossier.create.breeding_selling_options.selling',
        value: 'selling',
      },
      {
        key: 'modules.dossier.create.breeding_selling_options.buying',
        value: 'buying',
      },
    ];
  }

  get fungicideCoatingOptions(): KeyValuePairs<boolean> {
    return [
      { key: 'modules.dossier.create.fungicide_coating_options.true', value: true },
      { key: 'modules.dossier.create.fungicide_coating_options.false', value: false },
    ];
  }

  get packagingOptions(): KeyValuePairs<string> {
    return [
      { key: 'modules.dossier.create.packaging_options.chests', value: 'chests' },
      { key: 'modules.dossier.create.packaging_options.bigbags', value: 'bigbags' },
      { key: 'modules.dossier.create.packaging_options.individually', value: 'individually' },
      { key: 'modules.dossier.create.packaging_options.twentyfive_kg_packaged', value: 'twentyfive_kg_packaged' },
    ];
  }

  get barnDiseases(): Disease[] {
    const result = [];

    this.diseaseDataService.listBarnDiseases().subscribe((diseases) => {
      diseases.map((disease) => {
        result.push(disease);
      });
    });

    return result;
  }

  get offMeadowDiseases(): Disease[] {
    const result = [];

    this.diseaseDataService.listOffMeadowDiseases().subscribe((diseases) => {
      diseases.map((disease) => {
        result.push(disease);
      });
    });

    return result;
  }

  get dossierProductMatrix(): Observable<Map<string, string | null>> {
    return this._dossierProductMatrix.asObservable();
  }

  setUnits(units: Unit[]): void {
    this.units = units;
  }

  constructor(
    private dossierDataService: DossierDataService,
    private config: ConfigService,
    private sizeSortingService: SizeSortingDataService,
    private treatmentService: TreatmentDataService,
    private diseaseDataService: DiseaseDataService,
    private unitService: UnitService,
  ) {
    const decimalCommaOrDotSeperatedFloatPattern = /^\d+(([.,])\d{1,5})?$/;
    this.dossierFormSectionFields = {
      product: {
        product: [null, [Validators.required]],
        productVariety: [{ value: null, disabled: true }],
        organic: [{ value: false, disabled: true }],
        breedingSelling: [{ value: 'buying', disabled: true }, [Validators.required]],
        sellType: [{ value: 'sell', disabled: true }, [Validators.required]],
        rentType: [{ value: 'rent', disabled: true }, [Validators.required]],
        harvestYear: [{ value: this.getConfigCurrentYear(), disabled: true }, [Validators.required]],
        deliveryYear: [{ value: null, disabled: true }],
        productNote: [{ value: null, disabled: false }, [Validators.maxLength(65535)]],
        bakingFactory: [{ value: null, disabled: false }],
        badOnions: [{ value: null, disabled: false }],
      },
      cultivation: {
        surfaceCultivation: [{ value: null, disabled: true }],
        sowingDate: [{ value: null, disabled: true }],
        plantsPerHa: [{ value: null, disabled: true }],
        cultivationNote: [{ value: null, disabled: false }, [Validators.maxLength(65535)]],
      },
      sale: {
        expectedAmountSale: [
          { value: null, disabled: true },
          [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)],
        ],
        sizeSorting: [{ value: null, disabled: true }],
        packaging: [{ value: null, disabled: true }],
        saleNote: [{ value: null, disabled: false }, [Validators.maxLength(65535)]],
        disinfected: [{ value: null, disabled: false }],
      },
      seeds: {
        fungicideCoating: [{ value: null, disabled: true }],
        expectedAmountSeed: [
          { value: null, disabled: true },
          [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)],
        ],
        seedsNote: [{ value: null, disabled: false }, [Validators.maxLength(65535)]],
      },
      stage: {
        stage: [{ value: null, disabled: true }],
        expectedHarvestExactDate: [false],
        expectedHarvest: [{ value: null, disabled: true }],
        finalHarvest: [{ value: null, disabled: true }],
        preferenceDelivery: [{ value: null, disabled: true }],
        surface: [{ value: null, disabled: true }, [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)]],
        expectedAmount: [{ value: null, disabled: true }, [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)]],
        meadowDiseases: [{ value: null, disabled: true }],
        estimatedAmount: [
          { value: null, disabled: true },
          [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)],
        ],
        barnDiseases: [{ value: null, disabled: true }],
        preferredDeliveryTime: [{ value: null, disabled: true }],
        colorGrade: [{ value: null, disabled: true }],
        storageBox: [{ value: null, disabled: true }],
        stageNote: [{ value: null, disabled: false }, [Validators.maxLength(65535)]],
      },
      quality: {
        tare: [{ value: null, disabled: true }],
        tareTestSample: [
          { value: null, disabled: true },
          [Validators.min(0), Validators.max(100), Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)],
        ],
        gritGrade: [{ value: null, disabled: true }],
        gritTestSample: [
          { value: null, disabled: true },
          [Validators.min(0), Validators.max(100), Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)],
        ],
        grit10Roots: [{ value: null, disabled: true }, [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)]],
        grit10Onions: [{ value: null, disabled: true }, [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)]],
        grit10OnionsMeadow: [
          { value: null, disabled: true },
          [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)],
        ],
        quality: [{ value: null, disabled: true }],
        industryQuality: [{ value: null, disabled: true }],
        naktNumber: [{ value: null, disabled: true }],
        primed: [{ value: false, disabled: true }],
        sproutInhibitor: [{ value: null, disabled: true }],
        chlorineProfam: [{ value: false, disabled: true }],
        qualityNote: [{ value: null, disabled: false }, [Validators.maxLength(65535)]],
        fungicideTreated: [{ value: true, disabled: true }],
        chemical: [{ value: null, disabled: true }],
        fungicideAndInsectTreated: [{ value: true, disabled: true }],
        piled: [{ value: true, disabled: true }],
        treatment: [{ value: null, disabled: true }],
      },
      trade: {
        expiresAt: [null],
        indicationPrice: [
          { value: null, disabled: true },
          [Validators.pattern(decimalCommaOrDotSeperatedFloatPattern)],
        ],
        indicationPriceUnit: [{ value: null, disabled: false }],
        participation: [{ value: null, disabled: false }],
        tradeNote: [{ value: null, disabled: false }, [Validators.maxLength(65535)]],
      },
      others: {
        certification: [{ value: [], disabled: true }],
        othersNote: [{ value: null, disabled: false }, [Validators.maxLength(65535)]],
      },
    };

    this._sectionVisibility = new Map<string, BehaviorSubject<boolean>>();
    Object.keys(this.dossierFormSectionFields).forEach((section) => {
      this._sectionVisibility.set(section, new BehaviorSubject<boolean>(true));
    });
  }

  openDossier(dossier: Dossier) {
    return this.dossierDataService.openDossier(dossier);
  }

  closeDossier(dossier: Dossier) {
    return this.dossierDataService.closeDossier(dossier);
  }

  checkSectionVisibility(form: FormGroup, excludeFields: string[] = [], checkForValue = false) {
    this._sectionVisibility.forEach((visibilityTrigger, section) => {
      let visible = false;
      Object.keys(this.dossierFormSectionFields[section])
        .filter((f) => !excludeFields.includes(f))
        .some((field) => {
          const formField = form.get(field);
          if (checkForValue && formField && formField.value !== null) {
            visible = true;
            return true;
          } else if (formField && formField.status !== 'DISABLED') {
            visible = true;
            return true;
          } else {
          }
        });
      visibilityTrigger.next(visible);
    });
  }

  checkDateTimeBeforeNow(date: string): boolean {
    return date ? new Date(date) < new Date() : false;
  }

  getSectionVisibility(section: string): Observable<boolean> {
    return this._sectionVisibility.get(section).asObservable();
  }

  getDossierWizardState(): Observable<Dossier> {
    return this._dossierWizardState.asObservable();
  }

  getLastSavedDossier(): Observable<Dossier> {
    return this.lastSavedDossier$.asObservable();
  }

  getDossierById(id: number): Observable<Dossier> {
    return this.dossierDataService.read(id);
  }

  setDossierWizardState(dossier: Partial<Dossier>) {
    if (dossier.product && dossier.product.defaultValues?.forceStage) {
      dossier.stage = dossier.product.defaultValues.forceStage;
    }
    this._dossierWizardState.next({ ...this._dossierWizardState.value, ...dossier } as Dossier);
  }

  clearDossierWizardState() {
    this._dossierWizardState.next(null);
  }

  saveDossier(dossier: Dossier): Observable<Dossier> {
    dossier = this.dossierTransformer(dossier);
    return this.dossierDataService
      .create(dossier)
      .pipe(tap((savedDossier) => this.lastSavedDossier$.next(savedDossier)));
  }

  copyDossier(dossier: Dossier): Observable<Dossier> {
    return this.dossierDataService
      .create(this.dossierCopyTransformer(dossier))
      .pipe(tap((savedDossier) => this.lastSavedDossier$.next(savedDossier)));
  }

  updateDossier(dossier: Dossier): Observable<Dossier> {
    dossier = this.dossierTransformer(dossier);
    return this.dossierDataService.update(dossier);
  }

  clearValidators(validatorsToClear: any[], fields: { [key: string]: any }, except: (keyof Dossier)[] = []) {
    const clonedFields = {};

    Object.keys(fields).forEach((fieldKey) => {
      if (except.includes(fieldKey)) {
        clonedFields[fieldKey] = fields[fieldKey];
      } else {
        if (fields[fieldKey].length === 2 && Array.isArray(fields[fieldKey][1])) {
          clonedFields[fieldKey] = [
            fields[fieldKey][0],
            [...fields[fieldKey][1].filter((validator) => !validatorsToClear.includes(validator))],
          ];
        } else {
          clonedFields[fieldKey] = fields[fieldKey];
        }
      }
    });

    return clonedFields;
  }

  setProductMatrix(form: FormGroup, product: Product) {
    this._dossierProductMatrix.value.forEach((stage: string | null, fieldName: string) => {
      const field = form.get(fieldName);
      if (field) {
        field.updateValueAndValidity();
        field.disable();
      }
    });
    this._dossierProductMatrix.next(new Map<string, string | null>());

    if (!product) {
      return;
    }

    product.productMatrix?.forEach((productMatrixRow: ProductMatrixRow) => {
      const field = form.get(productMatrixRow.property);
      if (!field) {
        return;
      }

      if (!productMatrixRow.stage) {
        field.enable();
      }

      this._dossierProductMatrix.value.set(productMatrixRow.property, productMatrixRow.stage);
    });

    this.setDefaultValues(form, product.defaultValues);
  }

  resetDossierProductMatrix() {
    this._dossierProductMatrix.next(new Map<string, string | null>());
  }

  getEligibleDossiersToMatch(params: { [header: string]: string }) {
    return this.dossierDataService.getEligibleDossiersToMatch(params);
  }

  promoteDossierToOrder(dossier: Dossier) {
    return this.dossierDataService.promoteDossierToOrder(dossier);
  }

  private dossierTransformer(dossier: Dossier): Dossier {
    return {
      ...dossier,
      ...(dossier.expectedAmountSeed && { expectedAmount: dossier.expectedAmountSeed.toString().replace(',', '.') }),
      ...(dossier.expectedAmountSale && { expectedAmount: dossier.expectedAmountSale.toString().replace(',', '.') }),
      ...(dossier.surfaceCultivation && { surface: dossier.surfaceCultivation }),
      ...(dossier.indicationPrice && { indicationPrice: dossier.indicationPrice.toString().replace(',', '.') }),
      ...(dossier.estimatedAmount && { estimatedAmount: dossier.estimatedAmount.toString().replace(',', '.') }),
      ...(dossier.expectedAmount && { expectedAmount: dossier.expectedAmount.toString().replace(',', '.') }),
      ...(dossier.amountOfUnit && { amountOfUnit: dossier.amountOfUnit.toString().replace(',', '.') }),
    };
  }

  private dossierCopyTransformer(dossier: Dossier): Dossier {
    return this.removeKeys(this.dossierTransformer(dossier), [
      'id',
      'localPhotos',
      'photos',
      'order',
      'reminders',
      'contactMoments',
    ]);
  }

  private removeKeys(dossier: Dossier, keys: string[]) {
    keys.forEach((key) => {
      if (typeof dossier[key] !== 'undefined') delete dossier[key];
    });
    return dossier;
  }

  private getConfigCurrentYear(): number {
    let date = new Date().getFullYear();

    if (this.config.hasOwnProperty('harvest_year')) {
      date = parseInt(this.config.harvest_year, 10);
    }

    return date;
  }

  downloadDossier(dossier: Dossier) {
    return this.dossierDataService.downloadDossier(dossier);
  }

  setDefaultValues(formGroup, defaultValues) {
    if (defaultValues) {
      const keys = Object.keys(defaultValues);
      for (let key of keys) {
        let value = defaultValues[key];
        if (value) {
          let force = false;
          if (key === 'forceStage') {
            key = 'stage';
            force = true;
          } else if (key === 'forceUnit' && this.units.length > 0) {
            value = null;
            for (const u in this.units) {
              if (this.units[u] && this.units[u].title === defaultValues[key]) {
                value = this.units[u];
                break;
              }
            }
            if (value !== null) {
              key = 'amountUnit';
              force = true;
            }
          } else if (key === 'harvestYear') {
            value = this.config.harvest_year + parseInt(value, 10);
            force = true;
          }
          const field = formGroup.get(key);
          if (field && (field.value === null || force)) {
            field.patchValue(value);
          }
        }
      }
    }
  }

  getDefaultValue(defaultValues, getKey) {
    if (defaultValues) {
      const keys = Object.keys(defaultValues);
      for (const key of keys) {
        if (getKey === key) {
          return defaultValues[key];
        }
      }
    }
  }

  setSizeSortingOptions(defaultValues: object): Observable<string[]> {
    const sizeSortingOptionsType = this.getDefaultValue(defaultValues, 'sizeSortingOptionsType');
    if (sizeSortingOptionsType && sizeSortingOptionsType === SizeSortingTypes.ChicoryPens) {
      return this.sizeSortingService.listChicoryPensSizeSorting();
    } else {
      return this.sizeSortingService.listDefaultSizeSorting();
    }
  }

  setTreatmentOptions(defaultValues: object): Observable<Treatment[]> {
    const treatmentOptionsType = this.getDefaultValue(defaultValues, 'treatmentOptionsType');
    if (treatmentOptionsType && treatmentOptionsType === TreatmentTypes.ChicorySeed) {
      return this.treatmentService.listChicorySeedTreatments();
    } else {
      return this.treatmentService.listDefaultTreatments();
    }
  }

  showAmountUnitSuffix(dossier: Dossier): boolean {
    const amountUnit = dossier.amountUnit && dossier.amountUnit.identifier !== PriceUnitIdentifier.Price_unit_per_stuk;
    const productForce = dossier.product?.defaultValues?.forceAmount || false;
    return amountUnit && !productForce;
  }
}
