<ppa-spinner [status$]="loading$" icon="upload-alt" message="general.images_loading"></ppa-spinner>

<div class="add-action">
  <input style="display: none" type="file" #hiddenfileinput multiple (change)="renderPhoto($event)" />

  <button
    mat-raised-button
    color="primary"
    pre-icon
    fixed
    class="button--full-width"
    data-cy="button-wizard-add-photo"
    (click)="hiddenfileinput.click()"
  >
    <i class="uil uil-images"></i>
    {{ 'modules.photo.modal.add_photo' | transloco }}
  </button>
</div>

<mat-grid-list
  cols="2"
  rowHeight="1:1"
  class="photos"
  gutterSize="10"
  [class.remove-padding]="localPhotos.length < 1 ? '0' : ''"
>
  <mat-grid-tile class="photo" *ngFor="let localPhoto of localPhotos">
    <ng-container *ngTemplateOutlet="photo; context: { $implicit: localPhoto }"></ng-container>
  </mat-grid-tile>
</mat-grid-list>

<ng-template #photo let-photo>
  <img *ngIf="photo.id < 0" alt="" [src]="photo.base64" />
  <img *ngIf="photo.id > 0" alt="" [src]="photo.url" />
  <span class="action info-action"><i class="uil uil-pen" (click)="editPhoto(photo)"></i></span>
  <span class="action delete-action" (click)="removePhoto(photo)"><i class="uil uil-trash-alt"></i></span>
</ng-template>
