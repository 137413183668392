<form #form="ngForm" [formGroup]="dossierFormSection">
  <ng-container *transloco="let t">
    <div class="form-section">
      <h3>{{ t('modules.dossier.create.sections.common') }}</h3>

      <ppa-select
        [items]="mediators$ | async"
        bindLabel="label"
        label="modules.dossier.create.mediator_label"
        formControlName="mediator"
      ></ppa-select>
      <div *ngIf="(form.submitted || controls.mediator.touched) && controls.mediator.invalid">
        <div class="form-error" *ngIf="controls.mediator.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
      </div>

      <ng-container>
        <div>
          <mat-form-field appearance="fill">
            <mat-label>{{ t('modules.dossier.create.title_label') }}</mat-label>
            <input matInput formControlName="title" />
          </mat-form-field>
          <div *ngIf="(form.submitted || controls.title.touched) && controls.title.invalid">
            <div class="form-error" *ngIf="controls.title.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="form-section">
      <h3>{{ t('modules.dossier.create.sections.product') }}</h3>

      <ppa-select
        [items]="products$ | async"
        bindLabel="title"
        label="modules.dossier.create.product_label"
        formControlName="product"
      ></ppa-select>
      <div *ngIf="(form.submitted || controls.product.touched) && controls.product.invalid">
        <div class="form-error" *ngIf="controls.product.errors.required">
          <span>{{ 'form_errors.required' | transloco }}</span>
        </div>
      </div>

      <ng-container *ngIf="dossierProductMatrix$ | async as dossierProductMatrix">
        <ng-container *ngIf="dossierProductMatrix.has('productVariety')">
          <ppa-select
            [items]="(selectedProduct$ | async)?.productVarieties"
            bindLabel="title"
            label="modules.dossier.create.product_variety_label"
            formControlName="productVariety"
            [abstractControl]="controls.productVariety"
          ></ppa-select>
        </ng-container>

        <div>
          <ng-container *ngIf="dossierProductMatrix.has('bakingFactory')">
            <mat-slide-toggle formControlName="bakingFactory">
              {{ 'modules.dossier.create.baking_factory_label' | transloco }}
            </mat-slide-toggle>
          </ng-container>
        </div>

        <div>
          <ng-container *ngIf="dossierProductMatrix.has('organic')">
            <mat-slide-toggle formControlName="organic">
              {{ 'modules.dossier.create.organic_label' | transloco }}
            </mat-slide-toggle>
          </ng-container>
        </div>

        <ng-container *ngIf="dossierProductMatrix.has('breedingSelling')">
          <ppa-select
            [items]="breedingSellingOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.buying_selling_breeding"
            formControlName="breedingSelling"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.breedingSelling.touched) && controls.breedingSelling.invalid">
            <div class="form-error" *ngIf="controls.breedingSelling.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('sellType')">
          <ppa-select
            [items]="sellTypeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.sell_type_label"
            formControlName="sellType"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.sellType.touched) && controls.sellType.invalid">
            <div class="form-error" *ngIf="controls.sellType.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('rentType')">
          <ppa-select
            [items]="rentTypeOptions"
            [translateLabel]="true"
            bindLabel="key"
            bindValue="value"
            label="modules.dossier.create.rent_type_label"
            formControlName="rentType"
          ></ppa-select>
          <div *ngIf="(form.submitted || controls.rentType.touched) && controls.rentType.invalid">
            <div class="form-error" *ngIf="controls.rentType.errors.required">
              <span>{{ 'form_errors.required' | transloco }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('harvestYear')">
          <div>
            <ppa-select
              label="modules.dossier.create.harvest_year_label"
              formControlName="harvestYear"
              [yearSelect]="true"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.harvestYear.touched) && controls.harvestYear.invalid">
              <div class="form-error" *ngIf="controls.harvestYear.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controls.harvestYear.errors.pattern">
                <span>{{ 'form_errors.invalid_number' | transloco }}</span>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dossierProductMatrix.has('deliveryYear')">
          <div>
            <ppa-select
              label="modules.dossier.create.delivery_year_label"
              formControlName="deliveryYear"
              [yearSelect]="true"
            ></ppa-select>
            <div *ngIf="(form.submitted || controls.deliveryYear.touched) && controls.deliveryYear.invalid">
              <div class="form-error" *ngIf="controls.deliveryYear.errors.required">
                <span>{{ 'form_errors.required' | transloco }}</span>
              </div>
              <div class="form-error" *ngIf="controls.deliveryYear.errors.pattern">
                <span>{{ 'form_errors.invalid_number' | transloco }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ppa-notation
        [label]="t('modules.dossier.create.notes.product_note')"
        formControlName="productNote"
      ></ppa-notation>
      <div *ngIf="(form.submitted || controls.productNote.touched) && controls.productNote.invalid">
        <div class="form-error" *ngIf="controls.productNote.errors.maxlength">
          <span>{{ 'form_errors.max_length' | transloco: { max: 65535 } }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</form>
