import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bindLabel',
})
export class BindLabelPipe implements PipeTransform {
  transform(value: any, dotPath: string): unknown {
    let retVal = value;

    dotPath.split('.').forEach((part) => {
      if (retVal[part]) {
        retVal = retVal[part];
      }
    });

    return retVal;
  }
}
