import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, takeUntil, shareReplay } from 'rxjs/operators';
import { Filter } from '../../contracts/filter';
import { ErrorHandlerService } from '../../services/error-handler-service';
import { FilterService } from '../../services/filter.service';
import { ModalService } from '../../services/modal.service';
import { StateService } from '../../services/state.service';
import { AuthService, FilterModule, KeyValuePairs, UserFilter, UserFilterService } from '@ppa/data';
import { translate, TranslocoService } from '@ngneat/transloco';
import { ConfirmDialogComponent } from '../../modals/confirm-dialog/confirm-dialog.component';
import { ExportService } from '../../services/export.service';
import * as moment from 'moment';

@Component({
  selector: 'ppa-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFormComponent implements OnInit, OnDestroy {
  filterForm: FormGroup;
  addFilterForm: FormGroup;
  savedFilterForm: FormGroup;
  saveFilterForm: FormGroup;

  reloadFilters$: Subject<any> = new ReplaySubject<any>(1);
  onDestroy$: Subject<void> = new Subject<void>();
  @Input() filters: Filter[] = [];
  @Input() sortingFields: KeyValuePairs<string>;
  @Input() module: FilterModule;
  @Input() export = false;
  @Input() relationQueryParam$: Observable<number>;
  @Output() filterValues = new EventEmitter<{ [key: string]: string }>();
  activeFilters: number;
  userFilters$: Observable<UserFilter[]>;
  ownedFilters$: Observable<UserFilter[]>;

  usedFilters: Filter[] = [];

  chooseFilter: boolean;
  formVisible: boolean;
  sorted: boolean;
  saveVisible: boolean;
  deletable: boolean;
  exporting$: Subject<boolean> = new Subject<boolean>();

  readonly operators: KeyValuePairs<string>;
  readonly sortingDirections: KeyValuePairs<string>;

  constructor(
    private stateService: StateService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private filterService: FilterService,
    private userFilterService: UserFilterService,
    private authService: AuthService,
    private modalService: ModalService,
    private exportService: ExportService,
    private errorHandler: ErrorHandlerService,
    private transloco: TranslocoService,
  ) {
    this.operators = this.operatorOptions();
    this.sortingDirections = this.sortingOptions();
    this.chooseFilter = false;
    this.formVisible = false;
    this.saveVisible = false;
    this.deletable = false;
    this.activeFilters = 0;
    this.sorted = false;
    this.exporting$.next(false);
  }

  ngOnInit(): void {
    this.userFilters$ = this.reloadFilters$;
    this.loadFilters();

    const userId = this.authService.getUserIdFromToken();
    this.ownedFilters$ = this.userFilters$.pipe(
      map((ownedFilters) => ownedFilters.filter((ownedFilter) => ownedFilter.user.id === userId)),
    );

    const filterControls = {
      sort: [null],
      direction: ['asc'],
    };

    this.sorted = false;
    this.filters.forEach((filter) => {
      const allowedOperators = this.allowedOperators(filter);
      let operator = allowedOperators[0].value;
      allowedOperators.forEach((allowedOperator) => {
        if (allowedOperator.value === 'eq') {
          operator = 'eq';
        }
      });

      filter.dataIsObservable = filter.data instanceof Observable;
      filter.operator = operator;
      filter.key = filter.name;
      filter.operators = allowedOperators;
    });

    this.transloco.selectTranslate('menu.dashboard').subscribe(() => {
      this.filters.forEach((filter) => {
        filter.translatedLabel = translate(filter.label);
      });

      this.filters.sort((a, b) => {
        return a.translatedLabel < b.translatedLabel ? -1 : 1;
      });
    });

    this.filterForm = this.fb.group(filterControls);

    this.addFilterForm = this.fb.group({
      selectFilter: [null, [Validators.required]],
    });

    const savedControls = {
      savedFilter: [null],
    };
    this.savedFilterForm = this.fb.group(savedControls);

    const saveControls = {
      savedFilter: [null],
      overwrite: [null],
      title: [null, [Validators.required]],
      public: [null],
    };
    this.saveFilterForm = this.fb.group(saveControls);

    this.filterForm.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged(), debounceTime(400))
      .subscribe((value) => {
        let overwriteRelationId = null;
        this.relationQueryParam$?.subscribe((relationId) => {
          if (relationId !== undefined) {
            overwriteRelationId = relationId;
          }
        });

        Object.keys(value).map((key) => {
          if (key.indexOf('__') > -1 && key.indexOf('__') === key.lastIndexOf('__')) {
            if (value[key] !== null && typeof value[key] === 'object' && moment.isMoment(value[key])) {
              value[key] = value[key].format('DD-MM-YYYY');
            }
          }
        });

        this.filterService.addFilterFormToRoute(value, this.module, overwriteRelationId);
        this.filterValues.emit(value);
        this.activeFilters = 0;

        Object.keys(value).map((key) => {
          if (key.indexOf('__') > -1 && key.indexOf('__') === key.lastIndexOf('__')) {
            if (value[key] !== null && value[key] !== '') {
              this.activeFilters++;
            }
          }
        });

        if (value.hasOwnProperty('sort') && value['sort'] !== null && value['sort'] !== '') {
          this.sorted = true;
        }

        setTimeout(() => {
          this.savedFilterSelect();
        }, 100);
      });

    const params = this.stateService.getFilters(this.module, 'params');

    if (this.router.url.indexOf('/mine') !== -1) {
      const mediator = this.getMediatorFromFilter();
      if (mediator) {
        params.mediator = 'eq~' + mediator;
      }
    }

    if (params) {
      this.loadQueryParams(params);
    }

    this.saveFilterForm.controls.overwrite.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged(), debounceTime(400))
      .subscribe((value) => {
        const selectedFilter = this.saveFilterForm.controls.savedFilter.value;
        if (value && selectedFilter) {
          this.saveFilterForm.controls.title.patchValue(selectedFilter.title);
          this.saveFilterForm.controls.public.patchValue(selectedFilter.public);
        }
      });

    this.saveFilterForm.controls.savedFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged(), debounceTime(400))
      .subscribe((selectedFilter) => {
        if (selectedFilter && this.saveFilterForm.controls.overwrite.value) {
          this.saveFilterForm.controls.title.patchValue(selectedFilter.title);
          this.saveFilterForm.controls.public.patchValue(selectedFilter.public);
        }
      });

    this.savedFilterForm.controls.savedFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged(), debounceTime(400))
      .subscribe((selectedFilter) => {
        this.deletable = false;

        if (selectedFilter && selectedFilter.user.id === this.authService.getUserIdFromToken()) {
          this.deletable = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  allowedOperators(filter: Filter): KeyValuePairs<any> {
    return this.operatorOptions()
      .filter((operator) => {
        if (!filter.allowedOperators || filter.allowedOperators.length === 0) {
          return filter;
        }
        if (filter.allowedOperators && filter.allowedOperators.indexOf(operator.value) > -1) {
          return filter;
        }
      })
      .map((operator) => {
        if (['date'].indexOf(filter.name) > -1) {
          const key = '' + operator.key;
          operator.key = key.replace('.', '.date-');
        } else if (['dateWeek', 'dateMonth', 'dateYear'].indexOf(filter.name) > -1) {
          const key = '' + operator.key;
          operator.key = key.replace('.', '.period-');
        }
        return operator;
      });
  }

  operatorOptions(): KeyValuePairs<any> {
    return [
      { key: 'operators.eq', value: 'eq' },
      { key: 'operators.neq', value: 'neq' },
      { key: 'operators.gt', value: 'gt' },
      { key: 'operators.lt', value: 'lt' },
      { key: 'operators.li', value: 'li' },
      { key: 'operators.no', value: 'no' },
      { key: 'operators.bool', value: 'bool' },
    ];
  }

  sortingOptions(): KeyValuePairs<any> {
    return [
      { key: 'sorting.asc', value: 'asc' },
      { key: 'sorting.desc', value: 'desc' },
    ];
  }

  toggleForm(): void {
    this.formVisible = !this.formVisible;
  }

  addFilter(): void {
    this.chooseFilter = !this.chooseFilter;
  }

  selectFilter(): void {
    const { selectFilter } = this.addFilterForm.value;
    this.filters.forEach((filter) => {
      if (filter.key === selectFilter) {
        const index = this.getFilterIndex(selectFilter);
        const usedFilter = { ...filter };

        usedFilter.operator = 'eq';
        usedFilter.key = selectFilter + '__' + index;

        if (usedFilter.allowedOperators && usedFilter.allowedOperators.length > 0) {
          usedFilter.operator = usedFilter.allowedOperators[0];
        }

        this.usedFilters.push(usedFilter);

        const control = new FormControl('');
        control.patchValue(null);
        this.filterForm.addControl(usedFilter.key, control);

        const operatorControl = new FormControl([null, [Validators.required]]);
        operatorControl.patchValue(usedFilter.operator);
        this.filterForm.addControl(usedFilter.key + '__operator', operatorControl);
      }
    });

    this.addFilterForm.reset();
    this.chooseFilter = false;
    this.savedFilterSelect();
  }

  removeFilter(key): void {
    this.usedFilters.forEach((filter, index) => {
      if (filter.key === key) {
        this.usedFilters.splice(index, 1);
        this.filterForm.removeControl(key);
        this.filterForm.removeControl(key + '__operator');
      }
    });

    this.filterForm.updateValueAndValidity();
  }

  clearFilters(): void {
    this.usedFilters.forEach((filter) => {
      const key = filter.key;
      this.filterForm.removeControl(key);
      this.filterForm.removeControl(key + '__operator');
    });

    this.sorted = false;
    this.usedFilters = [];

    this.filterForm.controls.sort.patchValue(null);
    this.filterForm.controls.direction.patchValue('asc');

    this.stateService.setFilters(this.module, 'user_filter_id', null);

    this.filterForm.updateValueAndValidity();
  }

  getFilterIndex(key): number {
    let index = 0;
    this.usedFilters.forEach((filter) => {
      if (filter.name === key) {
        const parts = filter.key.split('__');
        index = parseInt(parts[1], 10) ?? 0;
      }
    });

    index++;
    return index;
  }

  loadFilter(event): void {
    if (event && event.hasOwnProperty('url')) {
      const params = this.filterService.convertUrlToParams(event.url);
      this.clearFilters();
      this.loadQueryParams(params);
      this.stateService.setFilters(this.module, 'user_filter_id', event.id);
    }
  }

  loadQueryParams(params: Params) {
    for (const [key, value] of Object.entries(params)) {
      if (key === 'sort') {
        this.sortingFields.forEach((field) => {
          if (field.value === value) {
            this.filterForm.controls[key].patchValue(value);
          }
        });
      } else if (key === 'direction') {
        this.sortingDirections.forEach((direction) => {
          if (direction.value === value) {
            this.filterForm.controls[key].patchValue(value);
          }
        });
      } else {
        this.filters.forEach((filter) => {
          if (filter.name === key) {
            let index = 0;
            let val = value;
            if (typeof value === 'string') {
              val = [value];
            }

            val.forEach((filterValue) => {
              const usedFilter = { ...filter };
              const parts = ('' + filterValue).split('~');
              let usedValue = parts[1];

              index++;
              usedFilter.operator = parts[0];
              usedFilter.key = key + '__' + index;

              if (usedFilter.type === 'date') {
                /* @ts-ignore */
                usedValue = moment(usedValue, 'DD-MM-YYYY');
              }

              this.usedFilters.push(usedFilter);

              const control = new FormControl('');
              control.patchValue(usedValue);
              this.filterForm.addControl(usedFilter.key, control);

              const operatorControl = new FormControl([null, [Validators.required]]);
              operatorControl.patchValue(usedFilter.operator);
              this.filterForm.addControl(usedFilter.key + '__operator', operatorControl);
            });
          }
        });

        if (value !== '' && this.filterForm.get(key)) {
          if (isNaN(Number(value))) {
            this.filterForm.get(key).setValue(value);
          } else {
            this.filterForm.get(key).setValue(+value);
          }
        }
      }
    }

    this.savedFilterSelect();
  }

  savedFilterSelect() {
    this.savedFilterForm.controls.savedFilter.patchValue(null);

    const selectedUserFilter = this.stateService.getFilters(this.module, 'user_filter_id');

    if (selectedUserFilter) {
      this.userFilters$.subscribe((filters) => {
        filters.forEach((filter) => {
          if (filter.id === selectedUserFilter) {
            this.savedFilterForm.controls.savedFilter.patchValue(filter);
          }
        });
      });
    }
  }

  showSaveFilterForm() {
    this.saveVisible = true;
    this.saveFilterForm.controls.savedFilter.patchValue(null);
    this.savedFilterForm.disable();

    const selectedUserFilter = this.stateService.getFilters(this.module, 'user_filter_id');

    if (selectedUserFilter) {
      this.ownedFilters$.subscribe((filters) => {
        filters.forEach((filter) => {
          if (filter.id === selectedUserFilter) {
            if (filter.user.id === this.authService.getUserIdFromToken()) {
              this.saveFilterForm.controls.savedFilter.patchValue(filter);
            }
          }
        });
      });
    }
  }

  clearSaveFilter() {
    this.saveVisible = false;
    this.savedFilterForm.enable();
    this.saveFilterForm.reset();
  }

  saveFilter() {
    if (!this.saveFilterForm.valid) {
    } else {
      const values = this.saveFilterForm.value;
      const data = { ...values, url: this.getFilterUrl(), module: this.module };

      if (values.savedFilter) {
        data.filter = data.savedFilter.id;
        delete data.savedFilter;
      } else {
        data.filter = null;
      }

      if (!data.public) {
        data.public = false;
      }

      this.userFilterService.createFilter(data).subscribe((userFilter) => {
        this.loadFilters();
      });
    }
  }

  getFilterUrl() {
    let hash = window.location.hash;
    const urlBase = this.router.parseUrl(hash);
    const urlTree = this.router.parseUrl(urlBase.fragment);
    const filterParams = this.stateService.getFilters(this.module, 'params');

    urlTree.queryParams = { ...urlTree.queryParams, ...filterParams };
    if (urlTree.queryParams.hasOwnProperty('relation_id')) {
      delete urlTree.queryParams.relation_id;
    }
    hash = '#' + this.router.serializeUrl(urlTree);

    return hash;
  }

  loadFilters(): void {
    const userId = this.authService.getUserIdFromToken();

    this.userFilterService
      .filters(this.module)
      .pipe(
        shareReplay(1),
        map((userFilters) => {
          return userFilters.map((userFilter) => {
            const publicLabel = (' (' + translate('filter.public') + ')').toLowerCase();
            userFilter.label = userFilter.title + ' ' + (userFilter.public ? publicLabel : '');
            userFilter.icon = 'share-alt';
            if (userFilter.user && userFilter.user.id === userId) {
              userFilter.icon = 'user';
            }
            return userFilter;
          });
        }),
      )
      .subscribe((data) => {
        this.reloadFilters$.next(data);
        this.clearSaveFilter();
      });
  }

  deleteSavedFilter(): void {
    const filter = this.savedFilterForm.controls.savedFilter.value;
    if (filter) {
      this.modalService
        .createModal(ConfirmDialogComponent, {
          message: translate('filter.saved.delete.confirm.message', { filter: filter.label }),
          confirmButtonText: translate('filter.saved.delete.confirm.yes'),
          buttonColor: 'warn',
        })
        .dialog.onSave()
        .then(() => {
          this.userFilterService.deleteFilter(filter).subscribe((userFilter) => {
            this.savedFilterForm.controls.savedFilter.patchValue(null);
            this.loadFilters();
          });
        });
    }
  }

  exportResults(): void {
    this.exporting$.next(true);
    const overwriteRelationId = this.stateService.getRelation();

    this.exportService
      .exportResults(this.module, this.filterForm.value, overwriteRelationId)
      .pipe(take(1))
      .subscribe(
        (res) => {},
        (errorBlob) => {
          try {
            const json = JSON.parse(errorBlob);

            errorBlob.error.text().then((errorText) => {
              const error = JSON.parse(errorText);
              this.errorHandler.handleAPIError(translate('filter.export_failed'), { error, status: 400 });
            });
          } catch (e) {
            this.errorHandler.handleAPIError(translate('filter.export_failed'), {
              error: 'Page not found',
              status: 404,
            });
          }

          this.exporting$.next(false);
        },
        () => {
          this.exporting$.next(false);
        },
      );
  }

  getMediatorFromFilter(): string {
    let mediator = '';

    for (const filter of this.filters) {
      if (filter.key === 'mediator') {
        const mediatorObject = this.stateService.getMediator();
        if (mediatorObject) {
          mediator = mediatorObject.firstname + ' ' + mediatorObject.lastname;
        }
        break;
      }
    }

    return mediator;
  }
}
