import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Modal } from '../../classes';
import { ModalService } from '../../services';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { Contact, ContactDataService, KeyValuePair, KeyValuePairs, Relation } from '@ppa/data';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {Filters} from "../../../../../data/src/lib/contracts/filters";

@Component({
  selector: 'ppa-contacts-modal',
  templateUrl: './contacts-modal.component.html',
  styleUrls: ['./contacts-modal.component.scss'],
})
export class ContactsModalComponent extends Modal implements OnInit {
  @Input() closeButtonText = 'Sluiten';
  @Input() buttonColor: 'primary' | 'warn' = 'warn';
  @Input() relation: Relation;

  readonly searchForm: FormGroup;

  contacts$: Observable<Contact[]>;
  showLoader = false;
  searchContacts$ = new Subject<string>();
  selectedContacts: KeyValuePairs<Contact>;

  get term(): AbstractControl {
    return this.searchForm.get('term');
  }

  get inRelation(): boolean {
    return this.searchForm.get('inRelation').value;
  }

  constructor(_modalService: ModalService, private fb: FormBuilder, private contactDataService: ContactDataService) {
    super(_modalService);
    this.searchForm = this.fb.group({
      term: [null, [Validators.required]],
      inRelation: [{ value: false }],
    });

    this.selectedContacts = [];
  }

  ngOnInit() {
    this.initSelectors();
  }

  initSelectors(): void {
    this.contacts$ = this.searchContacts$
      .pipe(
        switchMap((term) =>
          this.contactDataService.list({
            query: term,
            filter: { 'c.email': Filters.isNotNull },
            ...{ limit: 50 },
            ...(!term && (!this.relation || !this.inRelation) && { limit: 10 }),
            ...(this.relation && this.inRelation && { relationId: this.relation.id }),
          }),
        ),
      )
      .pipe(shareReplay(1));

    this.contacts$.subscribe(() => {
      this.showLoader = false;
    });

    this.term.valueChanges.pipe(debounceTime(500)).subscribe((term) => {
      this.showLoader = true;
      this.searchContacts$.next(term);
    });

    this.searchForm
      .get('inRelation')
      .valueChanges.pipe(debounceTime(500))
      .subscribe((checkRelation) => {
        this.showLoader = true;
        this.searchContacts$.next(this.term.value);
      });

    if (this.relation) {
      this.searchForm.controls.inRelation.patchValue(true);
    }
  }

  addContacts(): void {
    this.save(this.selectedContacts.map((item) => item.value));
  }

  isChecked(checkContact: Contact): boolean {
    return this.selectedContacts.filter((contact) => contact.key === checkContact.id).length > 0;
  }

  toggleChange(event: MatCheckboxChange, contact: Contact): void {
    const { checked } = event;

    if (checked && !this.isChecked(contact)) {
      this.selectedContacts.push({
        key: contact.id,
        value: contact,
      });
    } else if (!checked) {
      for (const c in this.selectedContacts) {
        if (this.selectedContacts[c]) {
          const selectedContact = this.selectedContacts[c];
          if (selectedContact.key === contact.id) {
            /* @ts-ignore */
            this.selectedContacts.splice(c, 1);
            break;
          }
        }
      }
    }
  }

  toggleContact(event, contact: Contact): void {
    if (!event.target.classList.contains('mat-checkbox-inner-container')) {
      if (this.isChecked(contact)) {
        for (const c in this.selectedContacts) {
          if (this.selectedContacts[c]) {
            const selectedContact = this.selectedContacts[c];
            if (selectedContact.key === contact.id) {
              /* @ts-ignore */
              this.selectedContacts.splice(c, 1);
              break;
            }
          }
        }
      } else {
        this.selectedContacts.push({
          key: contact.id,
          value: contact,
        });
      }
    }
  }
}
