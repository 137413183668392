<div class="breadcrumbs">
  <ng-container *ngFor="let breadcrumb of breadcrumbList; let last = last">
    <ng-container *ngIf="breadcrumb.url !== ''">
      <a href="{{ breadcrumb.url }}">{{ breadcrumb.label }}</a>
    </ng-container>
    <ng-container *ngIf="breadcrumb.url === ''">
      {{ breadcrumb.label }}
    </ng-container>
    <ng-container *ngIf="!last"> {{ separator }} </ng-container>
  </ng-container>
</div>
