<ppa-select-location-map
  [address]="address"
  [marker]="marker"
  [relationLocation]="relationLocation"
  (locationSelected)="locationPicked($event)"
  class="mb-1"
></ppa-select-location-map>

<div [formGroup]="dossierSectionForm">
  <ppa-notation
    [label]="'modules.dossier.create.notes.location_note' | transloco"
    formControlName="locationNote"
  ></ppa-notation>
</div>
