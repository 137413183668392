import { Injectable } from '@angular/core';
import { VatCode } from '../contracts';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class VatCodeDataService extends BaseService<VatCode> {
  path = 'vat_codes';
}
