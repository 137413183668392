import { Directive, HostBinding, Attribute } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[matInput]',
})
export class AutocompleteOffDirective {
  @HostBinding('attr.autocomplete') auto;

  constructor(@Attribute('autocomplete') autocomplete: string) {
    this.auto = autocomplete || 'off';
  }
}
