import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import {
  ActiveModules,
  AuthService,
  Contact,
  ContractTypes,
  FilterModule,
  Dossier,
  DossierDataService,
  PriceUnitIdentifier,
  ContactMoment,
  Reminder,
  Order,
  Relation,
  OrderDataService,
  NavigateBackService,
  Certificate,
  CertificateOption,
  RelationCertificate,
} from '@ppa/data';
import {
  ConfirmDialogComponent,
  DetailsModalComponent,
  HeaderData,
  Intention,
  ModalService,
  PPAToastrService,
  StatusLabel,
} from '@ppa/layout';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorHandlerService } from '@ppa/layout';
import { environment } from '../../../../../environments/environment';
import { DossierService } from '../../services/dossier.service';
import { ReminderService } from '../../../../services/reminder.service';
import { CertificateService } from '../../../../services/certificate.service';
import { OrderSelectModalComponent } from '../../../order/modals/order-select-modal/order-select-modal.component';
import { SelectLocationComponent } from '../../../location/modals/select-location/select-location.component';
import { Address } from '../../../../services/location.service';
import * as moment from 'moment';

@Component({
  selector: 'ppa-dossier-detail',
  templateUrl: './dossier-detail.component.html',
  styleUrls: ['./dossier-detail.component.scss'],
})
export class DossierDetailComponent implements OnInit, OnDestroy {
  dossier$: Observable<Dossier>;
  destroy$ = new Subject<void>();
  private readonly id: number;

  deleteTrigger$ = new Subject<void>();
  deletable$: Observable<boolean>;
  status: StatusLabel;
  orderId$: Observable<number>;
  orders$: Observable<Order[]>;

  headerData: HeaderData;

  actionStates = {
    edit: 'active',
    fulfill: 'active',
    contracts: 'active',
    deliveries: 'active',
    invoices: 'active',
  };

  activeModules: ActiveModules;
  module = FilterModule.dossier;

  dossier: Dossier;
  openDossierCopy$ = new Subject<number>();

  currentRelation$ = new BehaviorSubject<Relation>(null);
  currentAddress$: BehaviorSubject<Address> = new BehaviorSubject<Address>(null);
  currentCertificates$ = new BehaviorSubject<any>(null);
  showContacts = false;
  showCertificates = false;

  protected readonly ContractTypes = ContractTypes;
  protected readonly StatusLabel = StatusLabel;

  constructor(
    private dossierDataService: DossierDataService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private toastrService: PPAToastrService,
    private errorHandler: ErrorHandlerService,
    public authService: AuthService,
    private dossierService: DossierService,
    private reminderService: ReminderService,
    private certificateService: CertificateService,
    private navigateBackService: NavigateBackService,
  ) {
    this.id = this.route.snapshot.params.id;
    this.activeModules = environment.activeModules;
    this.loadDossier();
  }

  loadDossier(): void {
    this.dossier$ = this.route.params.pipe(
      takeUntil(this.destroy$),
      switchMap(({ id }) => this.dossierDataService.read(id)),
      shareReplay(1),
      map((dossier) => {
        return {
          ...dossier,
          amount: parseFloat(`${dossier.amount}`.toString().replace(',', '.')),
        };
      }),
      tap(
        (dossier) => {
          this.status = dossier.currentPlace;
          if (dossier.status === 'closed') {
            this.status = StatusLabel.Closed;
          } else if (dossier.status === 'incomplete') {
            this.status = StatusLabel.Incomplete;
          }

          const reminders = dossier.reminders
            .filter((reminder) => reminder.deleted === false)
            .sort((r1, r2) => (r1.reminderDate <= r2.reminderDate ? -1 : 1));
          dossier.reminders = reminders;

          const contactMoments = dossier.contactMoments.sort((c1, c2) => (c1.contactDate <= c2.contactDate ? 1 : -1));
          dossier.contactMoments = contactMoments;
          this.dossier = dossier;

          this.orderId$ = of(dossier.order ? dossier.order.id : -1);
          this.orders$ = of(dossier.orders);
          this.currentRelation$.next(dossier.relation);

          dossier.amount = dossier.expectedAmount || dossier.estimatedAmount;

          if (dossier.location) {
            const [address] = dossier.location ?? null;
            this.currentAddress$.next(address ? address : null);
          } else {
            this.currentAddress$.next(null);
          }

          const certificates = [];

          if (dossier.certification) {
            const certificateIds = [];
            dossier.relation.certificates.map((relationCertificate) => {
              const dossierCertificate = dossier.certification.find(
                (value) => value.id === relationCertificate.certificate.id,
              );
              const certificate = { ...relationCertificate, ...dossierCertificate, checked: true };
              certificates.push(certificate);
              certificateIds.push(relationCertificate.certificate.id);
            });

            dossier.certification.map((dossierCertificate) => {
              if (certificateIds.indexOf(dossierCertificate.id) === -1) {
                const certificate: RelationCertificate = {
                  id: null,
                  name: dossierCertificate.name,
                  certificate: dossierCertificate,
                  filename: null,
                  checked: true,
                  date: null,
                  number: null,
                  base64: null,
                  mime: null,
                  path: null,
                };
                certificates.push(certificate);
                certificateIds.push(certificate.id);
              }
            });
          }
          this.currentCertificates$.next(certificates);
        },
        (error) => {
          this.errorHandler.handleHTTPErrorResponse({
            error,
            resource: 'modules.dossier.toastr.resource_name',
            navigation: ['/dossier'],
          });
        },
      ),
    );

    this.openDossierCopy$.pipe(takeUntil(this.destroy$)).subscribe((dossierId) => {
      // Small workaround to make the back button go to /dossier en make the menu work properly
      this.router.navigate(['/dossier']).then(() => {
        this.router.navigate(['/dossier', dossierId]);
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.deleteTrigger$
      .pipe(withLatestFrom(this.dossier$), take(1))
      .subscribe(([, dossier]) => this.deleteDossier(dossier));
    this.headerData = this.createHeader();
  }

  deleteDossier(dossier: Dossier) {
    this.modalService
      .createModal(ConfirmDialogComponent, {
        message: translate('modules.dossier.detail.confirm_delete.message'),
      })
      .dialog.onSave()
      .then(() => {
        this.dossierDataService.delete(dossier).subscribe(
          () => {
            this.router.navigate(['dossier']);
            this.toastrService.displayToastr({
              icon: 'uil-check',
              title: translate('modules.dossier.dossier_delete_success.title'),
              intention: Intention.Success,
              duration: 3000,
            });
          },
          (error) => {
            this.errorHandler.handleAPIError(translate('modules.dossier.dossier_delete_failed.title'), error);
          },
        );
      });
  }

  get location(): [Address, [number, number]] {
    return this.dossier.location;
  }

  goTo(route: (string | number)[]) {
    this.navigateBackService.storeBackRoute(this.router.url);
    this.router.navigate(route);
  }

  private createHeader(): HeaderData {
    return {
      left: {
        type: 'back',
        action: {
          icon: 'uil-arrow-left',
          routerLink: `/dossier`,
          label: 'modules.dossier.buttons.cancel',
          type: 'back-button',
        },
      },
      right: [
        {
          type: 'circle-button',
          icon: 'uil-comment-alt-plus',
          color: 'primary',
          routerLink: `/dossier/${this.id}/contact_moment`,
        },
      ],
    };
  }

  callNumber(number: string) {
    if (number && number !== '') {
      document.location.href = `tel:${number}`;
    }
  }

  phoneIcon(number: string) {
    let icon = 'uil-phone';
    if (number && number !== '') {
      number = number.replace(/\s/, '');
    }

    if (
      number &&
      number !== '' &&
      (number.indexOf('+31(0)6') !== -1 ||
        number.indexOf('0031(0)6') !== -1 ||
        number.indexOf('+316') !== -1 ||
        number.indexOf('00316') !== -1 ||
        number.indexOf('06') !== -1)
    ) {
      icon = 'uil-mobile-android';
    }

    return icon;
  }

  showAmountUnitSuffix(dossier: Dossier): boolean {
    return this.dossierService.showAmountUnitSuffix(dossier);
  }

  percentFulfilled(fulfilled: number, amount: number): string {
    return this.dossierDataService.percentFulfilled(fulfilled, amount);
  }

  closeAction(dossier: Dossier) {
    this.modalService
      .createModal(ConfirmDialogComponent, {
        message: translate('modules.dossier.close.confirm.message'),
        confirmButtonText: translate('modules.dossier.close.confirm.yes'),
        buttonColor: 'primary',
      })
      .dialog.onSave()
      .then(() => {
        this.dossierService.closeDossier(dossier).subscribe((res) => {
          this.loadDossier();

          this.toastrService.displayToastr({
            icon: 'uil-check',
            title: translate('modules.dossier.toastr.dossier_closed'),
            intention: Intention.Success,
          });
        });
      });
  }

  openAction(dossier: Dossier) {
    this.modalService
      .createModal(ConfirmDialogComponent, {
        message: translate('modules.dossier.open.confirm.message'),
        confirmButtonText: translate('modules.dossier.open.confirm.yes'),
        buttonColor: 'primary',
      })
      .dialog.onSave()
      .then(() => {
        this.dossierService.openDossier(dossier).subscribe((res) => {
          this.loadDossier();

          this.toastrService.displayToastr({
            icon: 'uil-check',
            title: translate('modules.dossier.toastr.dossier_opened'),
            intention: Intention.Success,
          });
        });
      });
  }

  openPhotosPopup(dossier: Dossier) {
    const details = { photos: dossier.localPhotos };

    const fields = this.dossierDataService.getDetailFields(details);
    const header = translate('modules.dossier.create.title');

    if (details.photos && details.photos.length > 0) {
      this.modalService.createModal(DetailsModalComponent, { header, fields, details });
    }
  }

  openContactMoment(contactMoment: ContactMoment, details: Dossier) {
    const oldData = contactMoment.oldData ?? details;
    const newData = contactMoment.newData ?? details;

    newData.contactMoment = contactMoment;
    details.contactMoment = contactMoment;

    newData.photos = [];

    if (contactMoment.photos && contactMoment.photos.length > 0) {
      details.photos = contactMoment.photos;
      newData.photos = contactMoment.photos;
    } else {
      if (details.localPhotos) {
        details.localPhotos.map((photo) => {
          const photoDate = moment(photo.date);
          const contactDate = moment(contactMoment.contactDate);

          if (photo.contact_moment_id === null && photoDate.format('YYYYMMDD') === contactDate.format('YYYYMMDD')) {
            newData.photos.push(photo);
          }
        });
      }
    }

    let header = translate('modules.dossier.create.title');
    const fields = this.dossierDataService.getDetailFields(details);
    const oldFields = this.dossierDataService.getDetailFields({ ...details, ...oldData });
    const newFields = fields;
    const oldHeader = header + ' (' + translate('modules.dossier.compare.title.old') + ')';
    const newHeader = header + ' (' + translate('modules.dossier.compare.title.new') + ')';

    header = newHeader;

    details = newData;

    const compare = {
      oldHeader,
      oldFields,
      oldData,
      newHeader,
      newFields,
      newData,
    };

    this.modalService.createModal(DetailsModalComponent, { header, fields, details, compare });
  }

  public deleteReminder(reminder: Reminder): void {
    reminder.dossier = this.id;

    this.modalService
      .createModal(ConfirmDialogComponent, {
        message: translate('modules.dossier.reminder.confirm.message'),
        confirmButtonText: translate('modules.dossier.reminder.confirm.yes'),
        buttonColor: 'primary',
      })
      .dialog.onSave()
      .then(() => {
        this.reminderService.deleteReminder(reminder).subscribe(() => {
          this.loadDossier();
        });
      });
  }

  downloadAction() {
    const { id } = this;
    this.dossierService
      .downloadDossier({ id, ...this.dossier })
      .pipe(take(1))
      .subscribe(
        (res) => {},
        (errorBlob) => {
          errorBlob.error.text().then((errorText) => {
            const error = JSON.parse(errorText);
            this.errorHandler.handleAPIError(translate('modules.dossier.download.failed'), { error, status: 400 });
          });
        },
        () => {},
      );
  }

  copyAction() {
    this.modalService
      .createModal(ConfirmDialogComponent, {
        message: translate('modules.dossier.copy.confirm.message'),
        confirmButtonText: translate('modules.dossier.copy.confirm.yes'),
        buttonColor: 'primary',
      })
      .dialog.onSave()
      .then(() => {
        const dossier = {
          copy: true,
          ...this.dossier,
          title: '[KOPIE] ' + this.dossier.title,
        };
        this.dossierService.copyDossier(dossier).subscribe((res) => {
          this.toastrService.displayToastr({
            icon: 'uil-check',
            title: translate('modules.dossier.toastr.dossier_copied'),
            intention: Intention.Success,
            duration: 3000,
            buttons: [
              {
                text: translate('modules.dossier.toastr.view_dossier'),
                sub: this.openDossierCopy$,
                value: res.id,
              },
            ],
          });
        });
      });
  }

  /**
   * Handle order action card click event.
   */
  orderLinkAction() {
    this.orders$.subscribe((orders) => {
      const { sellType } = this.dossier;

      if (orders.length === 0) {
        this.router.navigate(['/order', 'create'], {
          queryParams: { dossier: this.id, ...(sellType && { sellType }) },
        });
      } else {
        const modal = this.modalService.createModal(OrderSelectModalComponent, {
          orders,
          dossier: this.id,
          ...(sellType && { sellType }),
        });
        modal.dialog.onSave().then((orderId) => {
          this.navigateBackService.storeBackRoute(this.router.url);
          this.router.navigate(['/order', orderId]);
        });
      }
    });
  }

  openLocationModal() {
    const [address, marker] = this.location || [null, null];
    const relationLocation$ = this.currentRelation$.pipe(
      map((relation) => {
        return [relation.longitude, relation.latitude];
      }),
    );

    const props = {
      editable: false,
      address: address !== null ? address : null,
      marker: marker !== null ? marker : null,
      relationLocation$,
      useCurrentLocationOnLoad: false,
    };

    const modal = this.modalService.createModal(SelectLocationComponent, props);
  }

  toggleContacts() {
    this.showContacts = !this.showContacts;
  }

  toggleCertificates() {
    this.showCertificates = !this.showCertificates;
  }

  showDossierDetail(details: Dossier) {
    const header = translate('modules.dossier.create.title');
    const fields = this.dossierDataService.getDetailFields(details);

    this.modalService.createModal(DetailsModalComponent, { header, fields, details });
  }
}
