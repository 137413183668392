import { NgModule } from '@angular/core';
import { RelationStatusComponent } from './relation-status.component';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

const COMPONENTS = [RelationStatusComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, TranslocoModule],
  exports: [...COMPONENTS],
})
export class RelationStatusModule {}
