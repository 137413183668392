import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Country, Relation } from '@ppa/data';
import { RelationService } from '../../../relation/services/relation.service';
import { FormIsRequiredComponent } from '../../../../components/form-is-required/form-is-required.component';
import { CountryService } from '../../../../services/country.service';

@Component({
  selector: 'ppa-create-relation',
  templateUrl: './create-relation.component.html',
  styleUrls: ['./create-relation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateRelationComponent extends FormIsRequiredComponent implements OnInit {
  addRelationShown$ = new BehaviorSubject<boolean>(false);

  relationForm: FormGroup;
  countries$: Observable<Country[]>;

  @ViewChild('form') form: NgForm;

  @Output() createRelation = new EventEmitter<Relation>();
  @Output() toggleAddRelation = new EventEmitter<boolean>();

  get controls() {
    return this.relationForm.controls;
  }

  constructor(
    private fb: FormBuilder,
    private relationService: RelationService,
    private countryService: CountryService,
  ) {
    super();
    this.countries$ = this.countryService.getCountries();
  }

  ngOnInit(): void {
    this.relationForm = this.fb.group({
      companyName: [null, [Validators.required, Validators.maxLength(255)]],
      street: [null, [Validators.required, Validators.maxLength(255)]],
      houseNumber: [null, [Validators.required]],
      city: [null, [Validators.required, Validators.maxLength(255)]],
      zipcode: [null, [Validators.required]],
      country: ['NL', [Validators.required]],
      phoneNumber: [null],
    });
  }

  toggleAddRelationPanel() {
    const value = !this.addRelationShown$.value;
    this.addRelationShown$.next(value);
    this.toggleAddRelation.emit(value);
  }

  handleSubmit() {
    if (this.relationForm.invalid) {
      return;
    }

    this.relationService.createRelation(this.relationForm.value).subscribe((relation) => {
      this.toggleAddRelationPanel();
      this.createRelation.emit(relation);
      this.relationForm.reset();
    });
  }
}
