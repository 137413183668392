<div class="ppa-modal ppa-modal__content--fixed ppa-modal--details ppa-modal--nested">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>
  <div class="ppa-modal__heading">
    <h2>{{ header }}</h2>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <ng-container *ngIf="fields$ | async as fields">
      <ng-container *ngIf="fields.length > 0">
        <ng-container *ngIf="details$ | async as details">
          <ng-container *ngFor="let field of fields">
            <ng-container *ngIf="field.field; else section">
              <dl *ngIf="hasValue(details, field)">
                <dt *ngIf="field.label; else emptyLabel">{{ field.label | transloco }}</dt>
                <dd>
                  <span [innerHTML]="getFieldValue(details, field)"></span>
                  <ng-container *ngIf="field.suffix">
                    <span [innerHTML]="getFieldValue(details, field.suffix, ' ')"></span>
                  </ng-container>
                </dd>
              </dl>
            </ng-container>

            <ng-template #emptyLabel>
              <dt>&nbsp;</dt>
            </ng-template>
            <ng-template #section>
              <h3 *ngIf="showSection(details, field, fields)">{{ field.label | transloco }}</h3>
              <ng-container *ngIf="hasPhotos(details, field)">
                <div class="carousel">
                  <ppa-carousel
                    [slides]="getPhotos(details, field)"
                    [class]="'carousel--container__force-cover'"
                    [showText]="true"
                  ></ppa-carousel>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="compare">
      <ng-container *ngIf="noDiffs$ | async">
        {{ 'modules.dossier.compare.no_differences' | transloco }}
      </ng-container>
    </ng-container>

    <div class="action-cards--mb"></div>
  </div>
  <div class="ppa-modal__actions" *ngIf="compare">
    <ng-container>
      <button
        mat-raised-button
        fixed
        type="button"
        [color]="currentCompare === 'new' ? 'warn' : 'primary'"
        class="button--full-width"
        (click)="toggleCompare()"
      >
        {{ 'modules.dossier.compare.button.is_' + currentCompare | transloco }}
      </button>
    </ng-container>
  </div>
</div>

<div class="ppa-modal__fader ppa-modal__fader--nested"></div>
