import { MailHeaderComponent } from './mail-header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '../../layout.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SelectModule } from '../select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [MailHeaderComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslocoModule,
    LayoutModule,
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
    SelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatChipsModule,
    MatIconModule,
  ],
  exports: [MailHeaderComponent],
})
export class MailHeaderModule {}
