import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { ContractOverviewCard } from '../../contracts';
import { OverviewCard } from '../../classes';
import { ContractTypes } from '@ppa/data';

@Component({
  selector: 'ppa-contract-overview-card',
  templateUrl: './contract-overview-card.component.html',
  styleUrls: ['./contract-overview-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractOverviewCardComponent extends OverviewCard implements OnInit {
  @Input() config: ContractOverviewCard;
  @Input() cyIdentifier: string;

  contractTypeTrade: boolean;

  ngOnInit() {
    this.contractTypeTrade = this.config?.cardInfo.order.contractType === ContractTypes.Trade;
  }
}
