import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe, DecimalPipe} from '@angular/common';

@Pipe({
  name: 'ppaCurrency',
})
export class PPACurrencyPipe extends CurrencyPipe implements PipeTransform {
  constructor() {
    super('nl');
  }
}
