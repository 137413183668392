import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLocationComponent } from './modals/select-location/select-location.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { LocationService } from '../../services/location.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../../environments/environment';
import { SelectLocationMapComponent } from './components/select-location-map/select-location-map.component';

@NgModule({
  declarations: [SelectLocationComponent, SelectLocationMapComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgxMapboxGLModule.withConfig({ accessToken: environment.mapBoxToken }),
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatButtonModule,
  ],
  exports: [SelectLocationComponent, SelectLocationMapComponent],
  providers: [LocationService],
})
export class LocationModule {}
