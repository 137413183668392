import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { RequiredControlComponent } from '../required-control/required-control.component';

@Component({
  selector: 'ppa-notation',
  templateUrl: './notation.component.html',
  styleUrls: ['./notation.component.scss'],
})
export class NotationComponent extends RequiredControlComponent implements OnInit, ControlValueAccessor {
  disabled = false;
  showNotation = true;
  value: string;
  @Input() label: string;
  onChanged: any = () => {};
  onTouched: any = () => {};

  constructor(@Self() @Optional() public control: NgControl) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  clearNotation() {
    this.showNotation = false;
    this.value = null;
    this.onChange(this.value);
  }

  setShowNotation() {
    this.showNotation = true;
  }

  ngOnInit(): void {
    if (!this.value) {
      this.showNotation = false;
      this.value = '';
    }
  }

  writeValue(value: string): void {
    this.value = value;
    if (value) {
      this.showNotation = true;
    }
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(value: string) {
    if (!this.disabled) {
      this.onChanged(value);
      this.onTouched();
    }
  }
}
