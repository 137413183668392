import { Disease, DiseaseType } from '@ppa/data';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, of } from 'rxjs';
import { ListResponse } from '../contracts/list-response';
import { translate } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class DiseaseDataService extends BaseService<Disease> {
  path = 'diseases';

  listOffMeadowDiseases(): Observable<ListResponse<Disease>> {
    return this.listDiseases('off_meadow');
  }

  listBarnDiseases(): Observable<ListResponse<Disease>> {
    return this.listDiseases('barn');
  }

  private listDiseases(type: string): Observable<ListResponse<Disease>> {
    return this.http.get(`${this.apiConfig.baseUrl}/${this.path}/type/${type}`) as Observable<ListResponse<Disease>>;
  }

  listDiseaseTypes(): Observable<DiseaseType[]> {
    const offMeadow: DiseaseType = {
      id: 1,
      value: 'off_meadow',
      label: translate('modules.disease.overview.stage_options.off_meadow'),
    };
    const barn: DiseaseType = {
      id: 2,
      value: 'barn',
      label: translate('modules.disease.overview.stage_options.barn'),
    };
    const list = [offMeadow, barn];
    return of(list);
  }
}
