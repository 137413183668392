import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: environment.sentryDsn,
      environment: environment.env,
      beforeSend: (event) => {
        const exception = event.exception?.values && event.exception?.values[0]
        if ([
              'Non-Error exception captured with keys: error, headers, message, name, ok',
              'Non-Error exception captured with keys: currentTarget, isTrusted, target, type'
            ].indexOf(exception?.value) !== -1) {
          return null;
        }
        return event;
      }
    });
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
