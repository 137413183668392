<div class="ppa-modal ppa-modal--auto-scale-from-bottom ppa-modal--confirm ppa-modal--nested">
  <div class="ppa-modal__heading">
    <h3>{{ message }}</h3>
    <p *ngIf="information" [innerHTML]="information"></p>
  </div>

  <div class="ppa-modal__actions ppa-modal__actions--center">
    <ng-container *ngIf="!hideCancelButton">
      <button mat-flat-button *ngIf="cancelButtonColor === 'warn'" color="warn" (click)="cancel()">
        {{ cancelButtonText }}
      </button>
      <button mat-flat-button *ngIf="cancelButtonColor !== 'warn'" (click)="cancel()">
        {{ cancelButtonText }}
      </button>
    </ng-container>
    <button
      mat-flat-button
      *ngIf="buttonColor === 'warn'"
      color="warn"
      data-cy="confirm-dialog-confirm-button"
      (click)="confirmed()"
    >
      {{ confirmButtonText }}
    </button>
    <button
      mat-flat-button
      *ngIf="buttonColor === 'primary'"
      data-cy="confirm-dialog-confirm-button-small"
      color="primary"
      (click)="confirmed()"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</div>

<div class="ppa-modal__fader ppa-modal__fader--nested"></div>
