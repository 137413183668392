import { Order } from './order';
import { Model } from './model';
import {OrderMatch} from "./order-match";

export interface PoolProvisionalStatementDocument extends Model {
  path: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface PoolProvisionalStatementRow extends Model {
  description: string;
  deliveryReportReferenceNumber?: string;
  deliveryWeek?: any;
  amount: number;
  price: number;
  vatCodePercentage: number;
  vatTotal: number;
  netTotal: number;
  type?: PoolProvisionalStatementRowType;
  paid: number;
  createdAt: string;
  updatedAt: string;
}

export enum PoolProvisionalStatementRowType {
  Manual = 'manual',
  DeliveryReport = 'delivery_report',
  WeighingCost = 'weighing_cost',
}

export interface PoolProvisionalStatement extends Model {
  poolProvisionalStatementDocuments: PoolProvisionalStatementDocument[];
  poolProvisionalStatementRows: PoolProvisionalStatementRow[];
  companyName?: string;
  street?: string;
  houseNumber?: string;
  zipCode?: string;
  city?: string;
  vatNumber?: string;
  afasDebtorId?: string;
  statementReferenceNumber?: string;
  date?: string;
  contractReferenceNumber?: string;
  product?: string;
  productVariety?: string;
  certification?: string;
  color?: any;
  comment?: any;
  poolProvisionalStatementSentAt?: any;
  order: Order;
  orderMatch: OrderMatch;
  createdAt: string;
  updatedAt: string;
}
