<div *ngIf="filterBarForm">
  <form [formGroup]="filterBarForm" class="mb-1">
    <div class="filter-bar">
      <div *ngFor="let filter of filters" class="filter-bar__item">
        <div [ngSwitch]="filter.type">
          <div *ngSwitchCase="'search'">
            <ng-container *ngTemplateOutlet="search; context: { $implicit: filter, form: filterBarForm }">
            </ng-container>
          </div>
          <div *ngSwitchCase="'fixed'">
            <ng-container *ngTemplateOutlet="fixed; context: { $implicit: filter, form: filterBarForm }">
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #search let-filter let-form="form">
  <div [formGroup]="form">
    <div class="search-bar">
      <div class="search-bar__search-field">
        <mat-form-field appearance="fill">
          <mat-label>{{ filter.label | transloco }}</mat-label>
          <input [formControlName]="filter.name" matInput />
          <i matSuffix class="uil uil-search"></i>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fixed let-filter let-form="form">
  <div [formGroup]="form">
    <ppa-select
      [label]="filter.label"
      [items]="filter.dataIsObservable ? (filter.data | async) : filter.data"
      [bindLabel]="filter.dataBindLabel"
      [bindValue]="filter.dataBindValue"
      [formControlName]="filter.name"
      [translateLabel]="filter.translateLabel || false"
    >
    </ppa-select>
  </div>
</ng-template>
