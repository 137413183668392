<div class="contract-overview-card card card--elevate-1" [attr.data-cy]="cyIdentifier" *transloco="let t">
  <div class="card__heading card__heading--border">
    <div>
      <div class="card__heading--column">
        <span class="card__heading--label">{{ t('modules.delivery.overview.card.from') }}</span>
        <h4 class="card__title">{{ config?.cardInfo.title.from.companyName }}</h4>
        <span>{{ config?.cardInfo.title.from.addressLine }}</span>
      </div>
      <div class="card__heading--column card__heading--icon">
        <i class="uil uil-arrow-right"></i>
      </div>
      <div class="card__heading--column">
        <span class="card__heading--label">{{ t('modules.delivery.overview.card.to') }}</span>
        <h4 class="card__title">{{ config?.cardInfo.title.to.companyName }}</h4>
        <span>{{ config?.cardInfo.title.to.addressLine }}</span>
      </div>
    </div>
    <div class="card__badge" *ngIf="config?.cardInfo.deliveryCount > 0">
      <i class="uil uil-truck"></i>
      <span>{{ config?.cardInfo.currentDelivery.countDeliveryRowsDelivered }} / {{ config?.cardInfo.deliveryCount }}</span>
    </div>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
  <div class="card__buttons">
    <ng-container
      *ngTemplateOutlet="(showLoader$ | async) ? loading : buttons; context: { $implicit: config }"
    ></ng-container>
  </div>
</div>

<ng-template #buttons let-config>
  <button
    mat-flat-button
    *ngFor="let button of config?.buttons"
    (click)="button.action(config.cardInfo)"
    [disabled]="!button.enabled"
    [color]="button.color"
  >
    <i *ngIf="button.icon?.left" [class]="'uil ' + button.icon.left"></i>
    {{ button.title }}
    <i *ngIf="button.icon?.right" [class]="'uil ' + button.icon.right"></i>
  </button>
</ng-template>

<ng-template #loading>
  <ppa-loading [noPadding]="true"></ppa-loading>
</ng-template>
