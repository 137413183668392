<mat-form-field appearance="fill">
  <mat-label>
    {{ label | transloco }}
    <span class="required" *ngIf="(isRequired() | async) || (required | async)">*</span>
  </mat-label>
  <mtx-select
    #select
    [(ngModel)]="_value"
    [disabled]="disabled"
    (open)="open(select)"
    (close)="close(select)"
    [multiple]="multiple"
    [compareWith]="compare"
    [searchFn]="search.bind(this)"
    [clearable]="!multiple && clearable"
    [closeOnSelect]="!multiple"
    [clearSearchOnAdd]="true"
    [items]="originSelect ? (items$ | async) : items"
    (change)="handleSelectionChange($event)"
    [placeholder]="defaultOptionText ? (defaultOptionText | transloco) : defaultOptionText"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [addTag]="addTag"
  >
    <ng-container *ngIf="translateLabel">
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span *ngIf="multiple" class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
        <span
          class="ng-value-label ppa-select-label"
          [innerHTML]="
            (bindLabel ? (item | bindLabel: bindLabel) : (translatePrefix !== '' ? translatePrefix + '.' : '') + item)
              | transloco
              | keepHtml
          "
        ></span>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="translateLabel">
      <ng-template ng-option-tmp let-item="item">
        <span
          class="ppa-select-label"
          [innerHTML]="
            (bindLabel ? (item | bindLabel: bindLabel) : (translatePrefix !== '' ? translatePrefix + '.' : '') + item)
              | transloco
              | keepHtml
          "
        ></span>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!translateLabel">
      <ng-template ng-option-tmp let-item="item">
        <i *ngIf="item.icon" [class]="'uil uil-' + item.icon"></i>
        {{ bindLabel ? (item | bindLabel: bindLabel) : item }}
      </ng-template>
    </ng-container>
  </mtx-select>
</mat-form-field>
