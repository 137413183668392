<div class="ppa-modal ppa-modal--confirm" *transloco="let t">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h3>{{ t('modules.contract.cancel.modal.title') }}</h3>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column">
    <div class="ppa-modal__intro mb-1">{{ t('modules.contract.cancel.modal.intro') }}</div>

    <form #form="ngForm" [formGroup]="cancelContractForm">
      <ng-container>
        <ppa-select
          [items]="cancelReasonOptions"
          [translateLabel]="true"
          bindLabel="key"
          bindValue="value"
          label="modules.contract.cancel.modal.reason"
          formControlName="cancel_reason"
        ></ppa-select>
        <div *ngIf="(form.submitted || controls.cancel_reason.touched) && controls.cancel_reason.invalid">
          <div class="form-error" *ngIf="controls.cancel_reason.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="enableReasonOther$ | async">
        <mat-form-field appearance="fill">
          <mat-label>{{ t('modules.contract.cancel.modal.reason_other') }}</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            formControlName="cancel_reason_other"
            rows="4"
            data-cy="contract-cancel-reason"
          ></textarea>
        </mat-form-field>
        <div *ngIf="(form.submitted || controls.cancel_reason_other.touched) && controls.cancel_reason_other.invalid">
          <div class="form-error" *ngIf="controls.cancel_reason_other.errors.required">
            <span>{{ t('form_errors.required') }}</span>
          </div>
        </div>
      </ng-container>

      <button
        mat-raised-button
        class="button--full-width"
        color="warn"
        data-cy="contract-cancel-button"
        (click)="send()"
      >
        {{ t('modules.contract.cancel.modal.button_send') }}
      </button>
    </form>
  </div>
</div>

<div class="ppa-modal__fader"></div>
