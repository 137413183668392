import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Only keep the id property
 * @param object
 */
export function purify(object: { [key: string]: any }) {
  if (typeof object['id'] === 'undefined') {
    throw new Error('Unable to purify object, property <id> missing');
  }

  const keys = Object.keys(object);
  keys.forEach((key) => {
    if (key !== 'id') {
      delete object[key];
    }
  });

  return object;
}
