import { Component, OnDestroy, OnInit } from '@angular/core';
import { DossierEditComponent } from '../dossier-edit/dossier-edit.component';

@Component({
  selector: 'ppa-dossier-contact-moment',
  templateUrl: '../dossier-edit/dossier-edit.component.html',
  styleUrls: ['../dossier-edit/dossier-edit.component.scss'],
})
export class DossierContactMomentComponent extends DossierEditComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    this.isContactMoment = true;
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
