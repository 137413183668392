import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Certificate, Relation, RelationCertificate } from '../contracts';
import { Observable } from 'rxjs';
import { ListResponse } from '../contracts/list-response';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CertificateDataService extends BaseService<Certificate> {
  path = 'certificates';

  getCertificateOptions(): Observable<ListResponse<Certificate>> {
    return this.http.get<ListResponse<Certificate>>(`${this.apiConfig.baseUrl}/${this.path}/options`);
  }

  downloadCertificate(relationCertificateId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiConfig.baseUrl}/${this.path}/certificate/${relationCertificateId}/download`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
