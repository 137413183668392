import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Delivery, PaginationOptions } from '../contracts';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDataService extends BaseService<Delivery> {
  path = 'deliveries';

  getAdministrationOverview(options: PaginationOptions) {
    return this.listPaged(options);
  }
}
