import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ppa-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() icon = 'download-alt';
  @Input() message = 'general.loading';
  @Input() status$: Subject<boolean> = new Subject<boolean>();
  @Input() spin = false;

  constructor() {}

  ngOnInit(): void {}
}
