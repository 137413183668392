import { AfterViewInit, Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormCanDeactivateComponent } from '../form-can-deactivate/form-can-deactivate.component';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ppa-form-is-required-control',
  template: ``,
})
export abstract class FormIsRequiredComponent extends FormCanDeactivateComponent implements AfterViewInit {
  abstract get controls(): { [key: string]: AbstractControl };

  // Function is duplicate with ppa-modal-form-is-required-control
  isRequiredField(field: string, controls?: any): Observable<boolean> {
    try {
      if (!controls) {
        controls = this.controls;
      } else if (controls.controls && controls.controls[field]) {
        controls = controls.controls;
      } else if (controls.controls && controls.controls.length > 0) {
        controls = controls.controls[0].controls;
      }
      const control = controls[field];
      if (control) {
        // @ts-ignore
        const validators = control.validator('');
        if (validators.required) {
          return of(true);
        }
      }
    } catch (e) {
      return of(false);
    }

    return of(false);
  }

  // Function is duplicate with ppa-modal-form-is-required-control
  ngAfterViewInit() {
    setInterval(() => {
      const placeholders = document.querySelectorAll('.nw-editor__placeholder:not(.required-replaced)');
      if (placeholders.length > 0) {
        for (const p in placeholders) {
          if (placeholders.hasOwnProperty(p)) {
            const placeholder = placeholders[p];
            if (placeholder.innerHTML.indexOf(' * ') > -1) {
              placeholder.innerHTML = placeholder.innerHTML.replace(' * ', ' <span class="required">*</span> ');
            }
            placeholder.classList.add('required-replaced');
          }
        }
      }
    }, 250);
  }
}
