import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  template: ``,
})
export abstract class AbstractWizardStepComponent implements OnDestroy {
  protected destroy$ = new Subject<void>();
  protected blockingPrev$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected blockingNext$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected blockingFinish$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected dirty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected requestPrevious$: Subject<void> = new Subject<void>();
  protected requestNext$: Subject<void> = new Subject<void>();
  protected requestSave$: Subject<void> = new Subject<void>();

  onPrevious$: Observable<void>;
  onNext$: Observable<void>;
  onFinish$: Observable<void>;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isBlockingPrev(): Observable<boolean> {
    return this.blockingPrev$.asObservable();
  }

  isBlockingNext(): Observable<boolean> {
    return this.blockingNext$.asObservable();
  }

  isBlockingFinish(): Observable<boolean> {
    return this.blockingFinish$.asObservable();
  }

  isDirty(): Observable<boolean> {
    return this.dirty$.asObservable();
  }

  isRequestingPrevious(): Observable<void> {
    return this.requestPrevious$.asObservable();
  }

  isRequestingNext(): Observable<void> {
    return this.requestNext$.asObservable();
  }

  isRequestingSave(): Observable<void> {
    return this.requestSave$.asObservable();
  }

  /**
   * When the wizard is closed
   */
  handleUnload() {
    // Handle any default behavior here
  }
}
