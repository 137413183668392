import { Component, Input, OnInit } from '@angular/core';
import { ConfirmDialogComponent, Intention, ModalService, PPAToastrService } from '@ppa/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { PoolProvisionalStatement, Relation } from '@ppa/data';
import { ErrorHandlerService } from '@ppa/layout';
import { Subject } from 'rxjs';
import { ModalFormIsRequiredComponent } from '../../../../components/modal-form-is-required/modal-form-is-required.component';
import { PoolService } from "../../services/pool.service";

@Component({
  selector: 'ppa-pool-send-modal',
  templateUrl: './pool-send-modal.component.html',
})
export class PoolSendModalComponent extends ModalFormIsRequiredComponent implements OnInit {
  @Input() poolProvisionalStatement: PoolProvisionalStatement;
  @Input() relation: Relation;

  sendPoolForm: FormGroup;
  saving$: Subject<boolean> = new Subject<boolean>();

  formChecked = false;

  constructor(
    _modalService: ModalService,
    _fb: FormBuilder,
    private poolService: PoolService,
    private toastrService: PPAToastrService,
    private errorHandler: ErrorHandlerService,
  ) {
    super(_modalService);

    this.sendPoolForm = _fb.group({
      to: [null, [Validators.required]],
      cc: [null],
      bcc: [null],
      subject: [null],
      message: [translate('modules.pool.modal.send.form.default_message'), [Validators.required]],
    });
    this.saving$.next(false);
  }

  ngOnInit() {
    let email = null;

    if (this.relation) {
      let invoiceEmail = this.relation.invoiceEmail;

      if (invoiceEmail?.length === 0) {
        invoiceEmail = null;
      }

      email = invoiceEmail ?? this.relation.email;
      email = this.relation.companyName + ' <' + email + '>';
    }

    this.sendPoolForm.controls.to.patchValue(email);
  }

  get controls() {
    return this.sendPoolForm.controls;
  }

  send() {
    if (this.sendPoolForm.valid) {
      this.modalService
        .createModal(ConfirmDialogComponent, {
          message: translate('modules.pool.modal.send.send_confirm.message'),
          confirmButtonText: translate('modules.pool.modal.send.send_confirm.confirm_button_text'),
          buttonColor: 'primary',
        })
        .dialog.onSave()
        .then(() => {
          this.saving$.next(true);
          this.poolService
            .sendProvisionalStatement(this.poolProvisionalStatement, this.sendPoolForm.value)
            .subscribe(() => {
              this.saving$.next(false);
              this.save(true);
              this.toastrService.displayToastr({
                intention: Intention.Success,
                title: translate('modules.pool.modal.send.send_success.title'),
                messages: [translate('modules.pool.modal.send.send_success.message')],
                icon: 'uil-check',
                duration: 3000,
              });
            },
            (error) => {
              this.saving$.next(false);
              this.errorHandler.handleAPIError(translate('modules.pool.modal.send.send_failed.title'), error);
            },
          );
        });
    } else {
      this.formChecked = true;
    }
  }
}
