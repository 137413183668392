import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { HTTPErrorActionObject, Intention } from '../contracts';
import { PPAToastrService } from './toastr.service';
import { camelCase } from '@swimlane/ngx-datatable';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private router: Router, private toastr: PPAToastrService) {}

  /**
   * Handle given HTTP Response.
   * @param errorObject
   */
  handleHTTPErrorResponse(errorObject: HTTPErrorActionObject): void {
    const { error, resource, navigation } = errorObject;

    switch (error.status) {
      case 404:
        this.handleResourceNotFound(navigation, resource);
        break;
      default:
        throw new Error(`Handling error with status ${error.status} not yet implemented!`);
    }
  }

  /**
   * Dissect and display error.
   * @param title
   * @param error
   * @param fullWidth
   */
  handleAPIError(title: string, error: any, fullWidth: boolean = false): void {
    try {
      if (error.status !== 400) {
        throw new Error('Unknown error received!');
      }
      const errorMap: Map<string, string> = new Map(Object.entries(error.error));
      const errors = this.translateErrors(errorMap.values());
      this.displayErrors(title, errors, fullWidth);
    } catch (e) {
      this.displayErrors(title, [translate('general.unknown_error')], fullWidth);
    }
  }

  // Navigate to given navigation array, display resourceNotFound toastr message for given resource.
  private handleResourceNotFound(navigation: string[], resource: string): void {
    this.router.navigate(navigation);
    this.toastr.resourceNotFound(resource);
  }

  private displayErrors(title: string, errors: string[], fullWidth: boolean = false) {
    this.toastr.displayToastr({
      title,
      icon: 'uil-exclamation-triangle',
      messages: errors,
      intention: Intention.Error,
      fullWidth,
    });
  }

  private translateErrors(errorList: Iterable<string>) {
    const errors = [];
    Array.from(errorList).forEach((message: string) => {
      let module = '';
      if (!message.startsWith('errors.')) {
        module = 'errors.';
      }
      let translated = translate(module + camelCase(message));
      if (translated.startsWith('errors.')) {
        translated = message;
      }
      errors.push(translated);
    });
    return errors;
  }
}
