import { Component } from '@angular/core';
import { Modal } from '@ppa/layout';
import { Relation, RelationType } from '@ppa/data';

@Component({
  selector: 'ppa-select-relation-modal',
  templateUrl: './select-relation-modal.component.html',
  styleUrls: ['./select-relation-modal.component.scss'],
})
export class SelectRelationModalComponent extends Modal {
  nested = false;
  relationType = null;
  showAddRelation = false;
  hideAddRelation = false;
  hideBlocked = false;

  readonly relationTypeEnum = RelationType;

  handleRelationSelected(relation: Relation) {
    this.save(relation);
  }

  handleRelationCreated(relation: Relation) {
    this.handleRelationSelected(relation);
  }

  toggleAddRelation($event) {
    this.showAddRelation = $event;
  }
}
