import { BackButtonModule } from './../../directives/back-button.module';
import { LayoutModule } from '@ppa/layout';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";

@NgModule({
  declarations: [HeaderComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        RouterModule,
        BackButtonModule,
        MatSlideToggleModule,
        MatMenuModule,
    ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
