import { NgModule } from '@angular/core';
import { ErrorPreviewComponent } from './error-preview.component';

const COMPONENTS = [ErrorPreviewComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [],
  exports: [...COMPONENTS],
})
export class ErrorPreviewModule {}
