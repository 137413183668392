import { PopoverDialogRef } from './popover-dialog';
import { HostListener, Component } from '@angular/core';
import { ModalService } from '../services';

@Component({
  template: '',
})
export abstract class Modal {
  popoverDialog: PopoverDialogRef = null;

  enableHostListener = true;

  constructor(protected modalService: ModalService) {}

  /* Dispose/close on esc key */
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    if (this.enableHostListener) {
      this.close();
    }
  }

  close(): void {
    /* Invoke the promise to tell that the dialog is closed */
    this.popoverDialog.dialog.close();

    this.modalService.close(this.popoverDialog);
  }

  save(data: object | boolean): void {
    /* Invoke the promise to tell that the dialog is saved */
    this.popoverDialog.dialog.save(data);

    this.modalService.close(this.popoverDialog);
  }
}
