<form [formGroup]="deliveryPeriodForm">
  <div class="flex-row">
    <div class="flex-1-1">
      <ppa-select
        [items]="periods"
        bindLabel="value"
        label="delivery_period.periods_label"
        bindValue="key"
        formControlName="period"
      ></ppa-select>
    </div>

    <div class="flex-1-2" *ngIf="displayFromDate$ | async">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'delivery_period.from_date' | transloco }}</mat-label>
        <input readonly matInput [matDatepicker]="fromDatePicker" formControlName="fromDate" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="flex-1-2" *ngIf="displayToDate$ | async">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'delivery_period.to_date' | transloco }}</mat-label>
        <input readonly matInput [matDatepicker]="toDatePicker" formControlName="toDate" />
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="flex-1-1" *ngIf="displayOnDate$ | async">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'delivery_period.on_date' | transloco }}</mat-label>
        <input readonly matInput [matDatepicker]="onDatePicker" formControlName="onDate" />
        <mat-datepicker-toggle matSuffix [for]="onDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #onDatePicker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</form>
