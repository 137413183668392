import { Model } from '@ppa/data';

export interface Unit extends Model {
  title: string;
  prefix: string;
  divisor?: number;
  identifier: PriceUnitIdentifier;
  localTitle?: string;
}

export enum PriceUnitIdentifier {
  Price_unit_per_1_ha = 'price-unit-per-1-ha',
  Price_unit_per_stuk = 'price-unit-per-stuk',
}
