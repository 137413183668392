import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CanDeactivateComponent } from '../component-can-deactivate/can-deactivate.component';

@Component({
  selector: 'ppa-form-can-deactivate',
  template: ``,
})
export abstract class FormCanDeactivateComponent extends CanDeactivateComponent {
  abstract get form(): NgForm;

  canDeactivate(): boolean {
    if (!this.form) return true;

    return !((this.form.submitted && this.form.dirty) || this.form.dirty);
  }
}
