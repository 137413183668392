<ng-container *ngIf="data">
  <div
    [class]="'header' + (class ? ' ' + class : '')"
    [class.header--floating]="floating"
    [class.header--filter]="hasFilter"
  >
    <div class="header__left" [ngSwitch]="data.left?.type">
      <h1 class="header__title" *ngSwitchCase="'title'">
        <ng-container *ngIf="data.left.prefix">{{ data.left.prefix }}</ng-container
        >{{ data.left.title | transloco }}
      </h1>
      <div class="header__back" *ngSwitchCase="'back'">
        <ng-container [ngSwitch]="data.left.action.type">
          <ng-container *ngSwitchCase="'back-button'">
            <button
              [attr.data-cy]="data.left.action?.cyIdentifier"
              mat-flat-button
              pre-icon
              type="button"
              ppaBackButton
              [defaultRoute]="data.left.action?.routerLink"
            >
              <i *ngIf="data.left.action.icon" [class]="'uil ' + data.left.action.icon"></i>
              {{ data.left.action.label | transloco }}
            </button>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="button; context: { $implicit: data.left.action }"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="data.left?.menu">
        <ng-container *ngTemplateOutlet="menu; context: { $implicit: data.left.menu }"></ng-container>
      </ng-container>
    </div>
    <div class="header__right">
      <div class="actions" *ngIf="data.right?.length > 0">
        <div class="actions__action" *ngFor="let action of data.right">
          <ng-container *ngIf="!(action.hidden$ | async)">
            <ng-container [ngSwitch]="action.type">
              <ng-container *ngSwitchCase="'circle-button'">
                <ng-container *ngTemplateOutlet="circleButton; context: { $implicit: action }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'no-redirect-toggle'">
                <ng-container *ngTemplateOutlet="toggleNoRedirect; context: { $implicit: action }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="button; context: { $implicit: action }"></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #button let-config>
  <button
    [attr.data-cy]="config?.cyIdentifier"
    [ngClass]="config?.ngClass ? config.ngClass : 'mat-flat-button'"
    mat-flat-button
    pre-icon
    type="button"
    [type]="config?.type ? config.type : 'button'"
    [routerLink]="config?.routerLink"
    [queryParams]="config?.queryParams"
    [class.primary]="config?.color === 'primary'"
    [attr.color]="config?.color === 'primary' ? 'primary' : null"
    (click)="emitAction(config)"
  >
    <i *ngIf="config.icon" [class]="'uil ' + config.icon"></i>
    {{ config.label | transloco }}
  </button>
</ng-template>

<ng-template #circleButton let-config>
  <span
    class="circle-button"
    [attr.data-cy]="config?.cyIdentifier"
    [class.circle-button--active]="config.activeState$ | async"
    [class.primary]="config?.color === 'primary'"
    [class.circle-primary]="config?.color === 'primary'"
    [routerLink]="config?.routerLink"
    [queryParams]="config?.queryParams"
    [attr.color]="config?.color === 'primary' ? 'primary' : null"
    (click)="emitAction(config)"
    [title]="config?.label | transloco"
    ><i *ngIf="config.icon" [class]="'uil ' + config.icon"></i
  ></span>
</ng-template>

<ng-template #toggleNoRedirect let-config>
  <mat-slide-toggle class="toggle-no-redirect" formControlName="_noRedirect" (change)="handleToggleNoRedirect($event)">
    {{ config.label | transloco }}
  </mat-slide-toggle>
</ng-template>

<ng-template #menu let-config>
  <ng-container *ngIf="config.items?.length > 0">
    <div class="header__menu">
      <button mat-button [matMenuTriggerFor]="submenu">
        <i [class]="'uil ' + (config.icon ? config.icon : 'uil-ellipsis-v')"></i>
      </button>
      <mat-menu #submenu="matMenu">
        <ng-container *ngFor="let action of config.items">
          <ng-container *ngTemplateOutlet="button; context: { $implicit: action }"></ng-container>
        </ng-container>
      </mat-menu>
    </div>
  </ng-container>
</ng-template>
