import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormGroupDirective } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ModalService } from '../../services';
import { ContactsModalComponent } from '../../modals/contacts-modal/contacts-modal.component';
import { Contact, Relation } from '@ppa/data';

@Component({
  selector: 'ppa-mail-header',
  templateUrl: './mail-header.component.html',
  styleUrls: ['./mail-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailHeaderComponent implements OnInit {
  @Input() form: FormGroupDirective;
  @Input() formGroup: any;
  @Input() validator: FormGroup;
  @Input() relation: Relation;
  @Input() formChecked = false;

  toEmails: Set<string>;
  ccEmails: Set<string>;
  bccEmails: Set<string>;

  showCc = false;
  showBcc = false;

  separator = /[\s,;]+/;

  constructor(private fb: FormBuilder, private modalService: ModalService) {
    this.validator = this.fb.group({
      email: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\\.[a-zaZ]{2,4}$')]],
    });
  }

  get controls() {
    return this.formGroup.controls;
  }

  ngOnInit() {
    this.toEmails = new Set(this.emailsToArray(this.controls.to.value));
    this.ccEmails = new Set(this.emailsToArray(this.controls.cc.value));
    this.bccEmails = new Set(this.emailsToArray(this.controls.bcc.value));

    this.showCc = this.ccEmails.size > 0;
    this.showBcc = this.bccEmails.size > 0;
  }

  /**
   * Split emails by separator and return array of emails, optionally with name
   * @param value
   */
  emailsToArray(value: string): string[] {
    const emailRegex = /[^\s<>]+@[^\s<>]+/;
    const nameRegex = /(.*)\<.*\>/;

    const emailMatch = value?.match(emailRegex);
    const nameMatch = value?.match(nameRegex);

    const result: string[] = [];

    if (emailMatch) {
      const emails = emailMatch[0].split(this.separator);
      const name = nameMatch ? nameMatch[1].trim() : '';

      for (const email of emails) {
        result.push(name ? `${name} <${email}>` : email);
      }
    }

    return result;
  }

  removeEmail(list: Set<string>, email: string): void {
    list.delete(email);
    this.updateFormFields();
  }

  addEmail(list: Set<string>, event: MatChipInputEvent | Event): void {
    /* @ts-ignore */
    const value = event.value ?? event.target?.value ?? null;
    /* @ts-ignore */
    const field = event.input ?? event.target ?? null;

    if (value) {
      if (this.validateEmail(value)) {
        list.add(value);
        this.updateFormFields();

        if (field) {
          field.value = '';
          field.classList.remove('error');
        }
      } else {
        if (field) {
          field.classList.add('error');
        }
      }
    }
  }

  keydownValidate(event): void {
    if (event.key !== 'Enter') {
      if (this.validateEmail(event.target.value)) {
        event.target.classList.remove('error');
      }
    }
  }

  validateEmail(email): boolean {
    const validator = this.validator.get('email');
    validator.patchValue(email);
    validator.updateValueAndValidity();
    return validator.valid;
  }

  updateFormFields(): void {
    this.controls.to.patchValue(Array.from(this.toEmails).join(';') || '');
    this.controls.cc.patchValue(Array.from(this.ccEmails).join(';') || '');
    this.controls.bcc.patchValue(Array.from(this.bccEmails).join(';') || '');
  }

  searchContacts(list: Set<string>): void {
    const modal = this.modalService.createModal(ContactsModalComponent, {
      relation: this.relation,
    });

    modal.dialog.onSave().then((contacts: Contact[]) => {
      for (const contact of contacts) {
        if (contact.email && contact.email.length > 0) {
          const parts = [];
          if (contact.firstname && contact.firstname.length > 0) {
            parts.push(contact.firstname);
          }
          if (contact.lastname && contact.lastname.length > 0) {
            parts.push(contact.lastname);
          }

          const name = parts.join(' ').trim();
          const emails = contact.email.split(this.separator);

          for (const email of emails) {
            let contactEmail = '';
            if (emails.length === 1 && name.length > 0) {
              contactEmail = name + ' <' + email + '>';
            } else {
              contactEmail = email;
            }

            if (contactEmail.length > 0) {
              list.add(contactEmail);
            }
          }
        }
      }

      this.updateFormFields();
    });
  }
}
