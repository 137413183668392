import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ContractTemplate, ContractTemplateField, ListResponse, Product, Relation } from '@ppa/data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractTemplateDataService extends BaseService<ContractTemplate> {
  path = 'contract_templates';

  getFields(
    template: ContractTemplate,
    relation: Relation,
    product: Product,
  ): Observable<ListResponse<ContractTemplateField>> {
    return this.http.get<ListResponse<ContractTemplateField>>(
      `${this.apiConfig.baseUrl}/${this.path}/${template.id}/fields/${relation.id}/${product.id}`,
    );
  }
}
