import { Injectable } from '@angular/core';
import {
  ContractType,
  ContractTypes,
  Order,
  OrderDataService,
  OrderWithGroupedMatches,
  PriceUnitIdentifier,
  UserFilter,
  UserFilterData,
  OrderTree,
  Dossier, OrderMatch,
} from '@ppa/data';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  orderFormFields: { [key: string]: any };

  constructor(private orderDataService: OrderDataService) {}

  saveOrder(order: Order): Observable<Order> {
    return this.orderDataService.create(order);
  }

  updateOrder(order: Order): Observable<Order> {
    return this.orderDataService.update(order);
  }

  // Return Order with given id.
  getOrder(id: number): Observable<Order> {
    return this.orderDataService.read(id);
  }

  /** Return Order with given id along with current places for delivery overview. */
  getOrderForDeliveryOverview(orderId: number): Observable<OrderWithGroupedMatches> {
    return this.orderDataService.getOrderForDeliveryOverview(orderId);
  }

  /** Return Order with given id along with current places for contract overview. */
  getOrderForContractOverview(orderId: number): Observable<OrderWithGroupedMatches> {
    return this.orderDataService.getOrderForContractOverview(orderId);
  }

  /** Return Order with given id along with current places for invoice overview. */
  getOrderForInvoiceOverview(orderId: number): Observable<OrderWithGroupedMatches> {
    return this.orderDataService.getOrderForInvoiceOverview(orderId);
  }

  getOrderForPoolOverview(orderId: number): Observable<OrderWithGroupedMatches> {
    return this.orderDataService.getOrderForPoolOverview(orderId);
  }

  getOrderHistory(orderId: number): Observable<OrderTree> {
    return this.orderDataService.getOrderHistory(orderId);
  }

  sendOnCallList(order: Order, message: string): Observable<void> {
    return this.orderDataService.sendOnCallList(order, message);
  }

  downloadOnCallList(order: Order): Observable<any> {
    return this.orderDataService.downloadOnCallList(order);
  }

  getContractTypes(): ContractType[] {
    return [
      {
        type: ContractTypes.Commission,
        color: '#70CB29',
        icon: 'uil-align-center-h',
        label: 'modules.order.create.contract_type.commission',
        disabled: false,
      },
      {
        type: ContractTypes.Trade,
        color: '#70CB29',
        icon: 'uil-arrows-merge',
        label: 'modules.order.create.contract_type.trade',
        disabled: false,
      },
      {
        type: ContractTypes.Pool,
        color: '#70CB29',
        icon: 'uil-users-alt',
        label: 'modules.order.create.contract_type.pool',
        disabled: false,
      },
    ];
  }

  saveContactType(order: Order, data: string, deleteContracts: boolean = false) {
    return this.orderDataService.saveContactType(order, data, deleteContracts);
  }

  showAmountUnitSuffix(order: Order): boolean {
    return this.orderDataService.showAmountUnitSuffix(order);
  }

  addParentToOrder(orderId: number, parentId: number): Observable<Order> {
    return this.orderDataService.addParentToOrder(orderId, parentId);
  }

  openOrder(order: Order) {
    return this.orderDataService.openOrder(order);
  }

  closeOrder(order: Order) {
    return this.orderDataService.closeOrder(order);
  }

  storeDeliveries(id: number, deliveries: Pick<Order, 'deliveries'>) {
    return this.orderDataService.storeDeliveries(id, deliveries);
  }
}
