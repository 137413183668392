import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  NavigateBackService,
  Relation,
  RelationSummary,
  ActiveModules,
  RelationType,
  OrderType,
} from '@ppa/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { RelationService } from '../../modules/relation/services/relation.service';
import { environment } from '../../../environments/environment';
import { StateService } from '@ppa/layout';

@Component({
  selector: 'ppa-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnDestroy {
  currentRelation$: Observable<any>;
  activeModules: ActiveModules;

  showContacts = false;
  showCertificates = false;

  constructor(
    private stateService: StateService,
    private relationService: RelationService,
    private router: Router,
    private navigateBackService: NavigateBackService,
    public authService: AuthService,
  ) {
    this.activeModules = environment.activeModules;

    const relationId$ = this.stateService.getRelation();

    if (relationId$) {
      this.getRelationData(relationId$);
    } else {
      this.openRelationModal();
    }
  }

  ngOnDestroy(): void {}

  openRelationModal() {
    this.relationService
      .selectRelation(false, null, false, true)
      .pipe(take(1))
      .subscribe((relation) => {
        if (typeof relation === 'object') {
          this.stateService.setRelation(relation.id);

          this.getRelationData(relation.id);
        }
      });
  }

  private getRelationData(relation_id) {
    this.currentRelation$ = this.relationService.getRelation(relation_id).pipe(shareReplay(1));
  }

  openLocation(relation: Relation) {
    const location = `https://www.google.nl/maps/place/${relation.street}+${relation.houseNumber},${relation.zipcode}+${relation.city}`;

    window.open(location, '_blank');
  }

  callNumber(number: string) {
    if (number && number !== '') {
      document.location.href = `tel:${number}`;
    }
  }

  phoneIcon(number: string) {
    let icon = 'uil-phone';
    if (number && number !== '') {
      number = number.replace(/\s/, '');
    }

    if (
      number &&
      number !== '' &&
      (number.indexOf('+31(0)6') !== -1 ||
        number.indexOf('0031(0)6') !== -1 ||
        number.indexOf('+316') !== -1 ||
        number.indexOf('00316') !== -1 ||
        number.indexOf('06') !== -1)
    ) {
      icon = 'uil-mobile-android';
    }

    return icon;
  }

  editRelation(relation: Relation) {
    this.navigateBackService.storeBackUrl();
    this.router.navigate(['/relation', relation.id, 'edit']);
  }

  openOverview(relation: Relation, overview: string) {
    this.navigateBackService.storeBackUrl();
    this.router.navigate([`/${overview}`]);
  }

  toggleContacts() {
    this.showContacts = !this.showContacts;
  }

  toggleCertificates() {
    this.showCertificates = !this.showCertificates;
  }
}
