import { OrderTreeComponent } from './order-tree.component';
import { RelationStatusModule } from '../relation-status';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { OrderCardModule } from '../order-card';

@NgModule({
  declarations: [OrderTreeComponent],
  imports: [CommonModule, MatButtonModule, TranslocoModule, RelationStatusModule, OrderCardModule],
  exports: [OrderTreeComponent],
})
export class OrderTreeModule {}
