import { Component, Input, OnInit } from '@angular/core';
import { Modal } from '../../classes/modal';
import { ModalService } from '../../services/modal.service';
import { translate } from '@ngneat/transloco';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ppa-editor-modal',
  templateUrl: './editor-modal.component.html',
  styleUrls: ['./editor-modal.component.scss'],
})
export class EditorModalComponent extends Modal implements OnInit {
  contentType: string;
  contentSelect: string;
  title: string;
  selectedFunction: any;
  selectedVariable: any;
  conditionForm: FormGroup;
  fb: FormBuilder;

  functions = [
    {
      category: 'conditions',
      label: 'Condities',
      functions: [
        {
          key: 'if',
          value: 'Als',
          selectVariable: true,
        },
        {
          key: 'elseif',
          value: 'Of anders',
          selectVariable: true,
        },
        {
          key: 'else',
          value: 'Anders',
          selectVariable: false,
        },
        {
          key: 'endif',
          value: 'Sluiten',
          selectVariable: false,
        },
      ],
    },
  ];

  variables = [
    {
      category: 'order',
      label: 'Opdracht',
      variables: [
        {
          key: 'order.organic',
          value: 'Organisch',
        },
        {
          key: 'origin',
          value: 'Herkomst',
        },
        {
          key: 'harvestYear',
          value: 'Oogstjaar',
        },
        {
          key: 'deliveryYear',
          value: 'Leveringsjaar',
        },
        {
          key: 'amountType',
          value: 'Hoeveelheid kenmerk',
        },
        {
          key: 'amountOfUnit',
          value: 'Hoeveelheid van unit',
        },
        {
          key: 'amountUnit.title',
          value: 'Eenheid (hoeveelheid)',
        },
        {
          key: 'yield',
          value: 'Opbrengst',
        },
        {
          key: 'yieldUnit.title',
          value: 'Eenheid (opbrengst)',
        },
        {
          key: 'priceType',
          value: 'Prijs keuze',
        },
        {
          key: 'priceUnit.prefix',
          value: 'Voorvoegsel (prijs)',
        },
        {
          key: 'priceUnit.title',
          value: 'Eenheid (prijs)',
        },
        {
          key: 'priceAgreement',
          value: 'Prijsafspraak',
        },
        {
          key: 'deliveryCondition',
          value: 'Leveringsvoorwaarde',
        },
        {
          key: 'deliveryPeriod',
          value: 'Leveringsperiode',
        },
        {
          key: 'sproutInhibitor',
          value: 'Kiemremmer',
        },
        {
          key: 'taring',
          value: 'Tarrering',
        },
        {
          key: 'packaging',
          value: 'Verpakking',
        },
        {
          key: 'weighingCosts',
          value: 'Weegkosten',
        },
        {
          key: 'termOfPayment',
          value: 'Betalingsvoorwaarden',
        },
        {
          key: 'poolFinalPaymentDate',
          value: 'Eindafrekening datum pool',
        },
        {
          key: 'priceTypeFixedAndPriceUnit',
          value: 'Vaste prijs met eenheid',
        },
        {
          key: 'priceTypeFixed',
          value: 'Vaste prijs zonder eenheid',
        },
      ],
    },
    {
      category: 'product',
      label: 'Product',
      variables: [
        {
          key: 'order.product.title',
          value: 'Product naam',
        },
        {
          key: 'order.productVariety.title',
          value: 'Productvariant naam',
        },
        {
          key: 'order.product.qualityStandard',
          value: 'Kwaliteit',
        },
        {
          key: 'onionClassification',
          value: 'Uienklasse',
        },
        {
          key: 'minimumGrit',
          value: 'Minimum grofte',
        },
        {
          key: 'sizeSorting',
          value: 'Maatsortering',
        },
        {
          key: 'treatments',
          value: 'Behandelingen',
        },
      ],
    },
    {
      category: 'others',
      label: 'Overig',
      variables: [
        {
          key: 'certification',
          value: 'Certificering',
        },
        {
          key: 'countryOfOrigin',
          value: 'Land van herkomst',
        },
        {
          key: 'vatCode.description',
          value: 'BTW omschrijving',
        },
        {
          key: 'notes',
          value: 'Notities',
        },
      ],
    },
    {
      category: 'user',
      label: 'Bemiddelaar',
      variables: [
        {
          key: 'mediator.fullName',
          value: 'Naam bemiddelaar',
        },
      ],
    },
    {
      category: 'relations',
      label: 'Relaties',
      variables: [
        {
          key: 'buyer',
          value: 'Naam koper',
        },
        {
          key: 'seller',
          value: 'Naam verkoper',
        },
      ],
    },
  ];

  constructor(_modalService: ModalService) {
    super(_modalService);
  }

  ngOnInit() {
    if (this.contentType === 'variables') {
      this.title = translate('modals.editor.title');
    } else {
      this.conditionForm = this.fb.group({
        value: [null, [Validators.required]],
      });
      this.title = translate('modals.editor.functions-title');
    }
  }

  selectFunction(variableFunction) {
    this.selectedFunction = variableFunction;
    if (variableFunction.selectVariable) {
      this.contentSelect = 'variables';
    } else {
      this.save({ function: this.selectedFunction.key });
    }
  }

  selectVariable(variable) {
    if (this.contentType === 'variables') {
      this.save({ variable: variable.key });
    } else {
      if (this.selectedFunction.selectVariable) {
        this.selectedVariable = variable;
        this.contentSelect = 'values';
      } else {
        this.save({ variable: variable.key, function: this.selectedFunction.key });
      }
    }
  }

  selectValue(operator: any): void {
    let selectedOperator = null;
    let selectedValue = null;

    if (operator !== true) {
      selectedOperator = operator;
      selectedValue = this.conditionForm.get('value').value;

      if (!selectedValue) {
        this.conditionForm.get('value').markAsTouched();
        return;
      }
    }

    this.save({
      variable: this.selectedVariable.key,
      function: this.selectedFunction.key,
      operator: selectedOperator,
      value: selectedValue,
    });
  }
}
