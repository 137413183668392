import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { RequiredControlComponent } from '../required-control/required-control.component';
import { DossierDataService } from '@ppa/data';
import { FilterService } from '../../services';

@Component({
  selector: 'ppa-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends RequiredControlComponent implements OnInit, OnChanges, ControlValueAccessor {
  disabled: boolean;
  _value: any;
  visualHeight = 0;
  fixDropdownInterval: any;
  fixDropdownTime = 0;
  items$: Observable<any>;

  set value(val) {
    if (this.yearSelect) {
      if (typeof val === 'number') {
        val = val.toString();
      }
    } else {
      if (typeof val === 'string' && isFinite(Number(val))) {
        val = parseInt(val, 10);
      }
    }
    this._value = val;
    this.cdr.detectChanges();
  }

  @Input() items: any[] = [];
  @Input() label = '';
  @Input() translateLabel = false;
  @Input() translatePrefix = '';
  @Input() bindLabel = null;
  @Input() bindValue = null;
  @Input() multiple = false;
  @Input() clearable = true;
  @Input() defaultOptionText = 'form.elements.select.default_option';
  @Input() abstractControl: AbstractControl = null;
  @Input() compareFn: (v1: any, v2: any) => boolean;
  @Input() addTag: boolean | ((term: string) => any | Promise<any>);
  @Input() yearSelect = false;
  @Input() years: string;
  @Input() originSelect = false;
  @Input() required: Observable<boolean> = of(false);

  @Output() selectionChange = new EventEmitter<any>();

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(
    @Self() @Optional() public control: NgControl,
    private cdr: ChangeDetectorRef,
    private dossierDataService: DossierDataService,
    private filterService: FilterService,
  ) {
    super();

    if (this.control) {
      control.valueAccessor = this;
    }
    this.visualHeight = window.visualViewport.height;
  }

  ngOnInit() {
    if (this.yearSelect) {
      this.bindLabel = 'key';
      this.bindValue = 'value';
      if (this.years !== '' && this.years !== undefined && this.years !== null && this.dossierDataService[this.years]) {
        this.items = this.dossierDataService[this.years];
      } else {
        this.items = this.dossierDataService.harvestYears;
      }
    } else if (this.originSelect) {
      this.clearable = false;
      this.bindLabel = 'key';
      this.bindValue = 'value';
      this.items$ = this.filterService.getOrigins();
      this.items$.subscribe((items) => {
        this.items = items;
        let found = false;
        let defaultOrigin = 'OTHER';
        for (const item of items) {
          if (item.value === this._value) {
            found = true;
          }
          if (item.defaultOrigin) {
            defaultOrigin = item.value;
          }
        }
        if (!found) {
          this.writeValue(defaultOrigin);
          this.onChange(defaultOrigin);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!Object.keys(changes).includes('items') || !this.abstractControl) return;
    if (
      this.abstractControl.value === null ||
      (Array.isArray(changes.items.currentValue) &&
        !changes.items.currentValue.some((item) => item.id === this.abstractControl.value.id))
    ) {
      if (this._value !== null) {
        this._value = null;
        this.onChange(null);
      }
    }
  }

  search(term: string, item: any) {
    term = term.toLocaleLowerCase();
    if (this.bindLabel && item.hasOwnProperty(this.bindLabel)) {
      item = item[this.bindLabel];
    }
    if (this.translateLabel) {
      const prefix = this.translatePrefix !== '' ? this.translatePrefix + '.' : '';
      item = translate(prefix + item);
    }
    if (typeof item === 'number') {
      item = item.toString();
    }
    if (typeof item === 'string') {
      item = item.toLocaleLowerCase();
      return item.indexOf(term) > -1;
    }
    // tslint:disable-next-line:triple-equals
    return item == term;
  }

  compare = (v1: any, v2: any) => {
    if (this.compareFn) {
      return this.compareFn(v1, v2);
    }
    if (this.bindValue && v1.hasOwnProperty(this.bindValue)) {
      v1 = v1[this.bindValue];
    }
    if (typeof v1 === 'object' && typeof v2 === 'object') {
      return v1?.id === v2?.id;
    } else {
      return v1 === v2;
    }
  };

  handleSelectionChange(value: any) {
    if (value === undefined) {
      value = null;
    }
    if (value && this.bindValue) {
      value = value[this.bindValue];
    }
    if (value === undefined) {
      value = this._value;
    }
    this.selectionChange.emit(value);
    this.onChange(value);
    this.onTouch(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  isMobile(): boolean {
    return window.innerWidth <= 800;
  }

  changeBodyOverflow(value): void {
    const bodies = document.getElementsByTagName('body');
    if (bodies) {
      for (const b in bodies) {
        if (bodies.item(0)) {
          const body = bodies.item(0);
          body.style.overflow = value;
        }
      }
    }
  }

  changeModalOverflowY(value): void {
    const modals = document.getElementsByClassName('ppa-modal__content');
    if (modals) {
      [].forEach.call(modals, (modal) => {
        modal.style.overflowY = value;
      });
    }
  }

  fixDropdown(elSelect): void {
    const container = elSelect._elementRef.nativeElement;
    const select = container.getElementsByClassName('ng-select-container').item(0);
    const panel = container.getElementsByClassName('ng-dropdown-panel').item(0);
    const visual = window.visualViewport;
    const top = visual.offsetTop;
    select.style.top = top + 'px';
    panel.style.top = top + 48 + 'px';
  }

  resetDropdown(elSelect): void {
    const container = elSelect._elementRef.nativeElement;
    const select = container.getElementsByClassName('ng-select-container').item(0);
    const panel = container.getElementsByClassName('ng-dropdown-panel').item(0);

    select.style.top = '';
    panel.style.top = '';
  }

  open(select): void {
    if (this.isMobile()) {
      this.changeBodyOverflow('hidden');
      this.changeModalOverflowY('unset');
      const interval = 200;

      this.fixDropdownInterval = setInterval(() => {
        this.fixDropdownTime += interval;
        this.fixDropdown(select);
      }, interval);
    }
  }

  close(select): void {
    select.blur();
    this.changeBodyOverflow('auto');
    this.changeModalOverflowY('auto');
    this.resetDropdown(select);

    clearInterval(this.fixDropdownInterval);
  }
}
