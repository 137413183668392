import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsModalComponent } from './contacts-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { LayoutModule } from '../../layout.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SelectModule } from '../../components/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LoadingModule } from '../../components/loading';
import { NoResultModule } from '../../components/no-result';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [ContactsModalComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslocoModule,
    LayoutModule,
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
    SelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    LoadingModule,
    NoResultModule,
    MatCheckboxModule,
  ],
  exports: [ContactsModalComponent],
})
export class ContactsModalModule {}
