<form [formGroup]="timeForm">
  <div class="time-picker">
    <ng-container *ngIf="!timeSelect; else timeElement">
      <div class="time-picker__hour flex-1-2">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'form.elements.time_picker.hour' | transloco }}</mat-label>
          <mat-select formControlName="hour">
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{ hour }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="time-picker__minute flex-1-2">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'form.elements.time_picker.minute' | transloco }}</mat-label>
          <mat-select formControlName="minute">
            <mat-option *ngFor="let minute of minutes" [value]="minute">
              {{ minute }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-template #timeElement>
      <div class="time-picker__time flex-1-1">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'form.elements.time_picker.time' | transloco }}</mat-label>
          <mat-select formControlName="time">
            <mat-option *ngFor="let time of times" [value]="time">
              {{ time }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>
  </div>
</form>
