import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { PoolAdvanceEntry } from '@ppa/data';

@Injectable({
  providedIn: 'root',
})
export class PoolAdvanceEntryDataService extends BaseService<PoolAdvanceEntry> {
  path = 'pool_advance_entries';
}
