<div class="ppa-modal ppa-modal--no-bottom-padding" data-cy="modal-contract-type" *transloco="let t">
  <i class="uil uil-times ppa-modal__close-handler" (click)="close()"></i>

  <div class="ppa-modal__heading">
    <h2 class="subheading-enabled">{{ 'modules.contract.modal.contracttype.title' | transloco }}</h2>
    <h4 class="subheading">{{ t('modules.contract.modal.contracttype.subheading') }}</h4>
  </div>

  <div class="ppa-modal__content ppa-modal__content--flex-direction-column pb-1">
    <form #form="ngForm" [formGroup]="sendContractTypeForm">
      <ng-container>
        <ppa-select
          [items]="contractTypes"
          bindLabel="label"
          bindValue="type"
          label="modules.contract.modal.contracttype.contracttype_label"
          formControlName="contractType"
          data-cy="contract-create-contracttype"
          [clearable]="false"
          [translateLabel]="true"
        ></ppa-select>
        <div *ngIf="(form.submitted || controls.contractType.touched) && controls.contractType.invalid">
          <div class="form-error" *ngIf="controls.contractType.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="displayPool$ | async">
        <ppa-select
          [items]="pools$ | async"
          bindLabel="name"
          label="modules.contract.modal.contracttype.pool_label"
          formControlName="pool"
        ></ppa-select>
        <div *ngIf="(form.submitted || controls.pool.touched) && controls.pool.invalid">
          <div class="form-error" *ngIf="controls.pool.errors.required">
            <span>{{ 'form_errors.required' | transloco }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container>
        <button
          mat-raised-button
          class="button--full-width"
          color="primary"
          data-cy="button-contract-type-send"
          (click)="send()"
        >
          {{ 'modules.contract.modal.contracttype.button_send' | transloco }}
        </button>
      </ng-container>
    </form>
  </div>
</div>

<div class="ppa-modal__fader"></div>
