import { Model } from './model';
import { OrderType } from './order';

export const COMMISSION_SHORTCODE = 'CM';
export const PURCHASE_SHORTCODE = 'IN';
export const SALES_SHORTCODE = 'VK';

export interface ContractTemplate extends Model {
  orderType: OrderType;
  title: string;
  shortCode: string;
}

export interface ContractTemplateField extends Model {
  type: string;
  label: string;
  content: string;
  active: boolean;
  required: boolean;
  hidden: boolean;
  position?: number;
  overwritten: boolean;
  positionOverwritten?: boolean;
}
