import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@ppa/layout';
import { Observable, of } from 'rxjs';
import { Card, OverviewCardButton } from '@ppa/layout';
import {
  Delivery,
  Order,
  OrderMatch,
  OrderWithGroupedDeliveries,
  OrderWithGroupedMatches,
  OrderWithGroupedOrders
} from '@ppa/data';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OverviewCardService {
  constructor(private errorHandler: ErrorHandlerService) {}

  /**
   * Transforms the grouped matches from the backend into
   * frontend overview cards and retains the same grouping.
   * @param orderForOverview$
   * @param infoResolver
   * @param buttonResolver
   */
  transformToOverviewCards<CardType>(
    orderForOverview$: Observable<OrderWithGroupedMatches>,
    infoResolver: (groupedOrder: Order) => CardType,
    buttonResolver: (group: string, info: CardType) => OverviewCardButton[],
  ): Observable<{ [key: string]: Card<CardType>[] }> {
    return orderForOverview$.pipe(
      catchError((err) => this.handleAPIError(err)),
      switchMap((orderWithGroupedMatches: OrderWithGroupedOrders) => {
        const overviewCards: { [key: string]: Card<CardType>[] } = {};
        const { groupedMatches } = orderWithGroupedMatches;
        Object.keys(groupedMatches).forEach((group) => {
          overviewCards[group] = groupedMatches[group].map((groupedOrder: Order) => {
            const cardInfo = infoResolver(groupedOrder);
            return {
              group,
              cardInfo,
              buttons: buttonResolver(group, cardInfo),
            };
          });
        });
        return of(overviewCards);
      }),
    );
  }

  transformToOverviewInvoiceCards<CardType>(
    orderForOverview$: Observable<OrderWithGroupedMatches>,
    infoResolver: (order: Order, orderMatch: OrderMatch) => CardType,
    buttonResolver: (group: string, info: CardType) => OverviewCardButton[],
  ): Observable<{ [key: string]: Card<CardType>[] }> {
    return orderForOverview$.pipe(
      catchError((err) => this.handleAPIError(err)),
      switchMap((orderWithGroupedMatches: OrderWithGroupedMatches) => {
        const overviewCards: { [key: string]: Card<CardType>[] } = {};
        const { order, groupedMatches , orderInvoices } = orderWithGroupedMatches;
        Object.keys(groupedMatches).forEach((group) => {
          overviewCards[group] = groupedMatches[group].map((orderMatch: OrderMatch) => {
            const cardInfo = infoResolver(order, orderMatch);
            return {
              group,
              cardInfo,
              buttons: buttonResolver(group, cardInfo),
            };
          });
        });
        Object.keys(orderInvoices).forEach((group) => {
          overviewCards[group] = orderInvoices[group].map((invoiceOrder: OrderMatch) => {
            const cardInfo = infoResolver(order, invoiceOrder);
            return {
              group,
              cardInfo,
              buttons: buttonResolver(group, cardInfo),
            };
          });
        });
        return of(overviewCards);
      }),
    );
  }

  transformToOverviewDeliveryCards<CardType>(
    orderForOverview$: Observable<OrderWithGroupedMatches>,
    infoResolver: (order: Order, delivery: Delivery) => CardType,
    buttonResolver: (group: string, info: CardType) => OverviewCardButton[],
  ): Observable<{ [key: string]: Card<CardType>[] }> {
    return orderForOverview$.pipe(
      catchError((err) => this.handleHTTPErrorResponse(err)),
      switchMap((orderWithGroupedMatches: OrderWithGroupedDeliveries) => {
        const overviewCards: { [key: string]: Card<CardType>[] } = {};
        const { order, groupedMatches, orderDeliveries } = orderWithGroupedMatches;
        Object.keys(groupedMatches).forEach((group) => {
          overviewCards[group] = groupedMatches[group].map((delivery: Delivery) => {
            const cardInfo = infoResolver(order, delivery);
            return {
              group,
              cardInfo,
              buttons: buttonResolver(group, cardInfo),
            };
          });
        });
        Object.keys(orderDeliveries).forEach((group) => {
          overviewCards[group] = orderDeliveries[group].map((delivery: Delivery) => {
            const cardInfo = infoResolver(order, delivery);
            return {
              group,
              cardInfo,
              buttons: buttonResolver(group, cardInfo),
            };
          });
        });
        return of(overviewCards);
      }),
    );
  }

  transformToOverviewPoolCards<CardType>(
    orderForOverview$: Observable<OrderWithGroupedMatches>,
    infoResolver: (order: Order, orderMatch: OrderMatch) => CardType,
    buttonResolver: (group: string, info: CardType) => OverviewCardButton[],
  ): Observable<{ [key: string]: Card<CardType>[] }> {
    return orderForOverview$.pipe(
      catchError((err) => this.handleAPIError(err)),
      switchMap((orderWithGroupedMatches: OrderWithGroupedMatches) => {
        const overviewCards: { [key: string]: Card<CardType>[] } = {};
        const { order, groupedMatches } = orderWithGroupedMatches;
        Object.keys(groupedMatches).forEach((group) => {
          overviewCards[group] = groupedMatches[group].map((orderMatch: OrderMatch) => {
            const cardInfo = infoResolver(order, orderMatch);
            return {
              group,
              cardInfo,
              buttons: buttonResolver(group, cardInfo),
            };
          });
        });
        return of(overviewCards);
      }),
    );
  }

  private handleHTTPErrorResponse(err: unknown): Observable<unknown> {
    if (err instanceof HttpErrorResponse) {
      this.errorHandler.handleHTTPErrorResponse({
        error: err,
        navigation: ['order'],
        resource: 'order',
      });
    }

    throw err;
  }

  private handleAPIError(err: unknown): Observable<unknown> {
    if (err instanceof HttpErrorResponse) {
      this.errorHandler.handleAPIError('Error getting data from server', err);
    }

    throw err;
  }
}
