import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Breadcrumb } from '@ppa/data';

@Component({
  selector: 'ppa-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnChanges {
  @Input() breadcrumbs: any[];
  @Input() separator: string;

  breadcrumbList: Breadcrumb[] = [];

  constructor() {
    if (this.separator === undefined) {
      this.separator = '>';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.breadcrumbs) {
      this.breadcrumbs.forEach((item) => {
        if (typeof item === 'string') {
          this.breadcrumbList.push(this.createBreadcrumb(item));
        } else {
          this.breadcrumbList.push(this.createBreadcrumb(item[0], item[1]));
        }
      });
    }
  }

  createBreadcrumb(label: string, url: string = ''): Breadcrumb {
    return {
      label,
      url,
    };
  }
}
