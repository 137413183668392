<nav class="side-nav" [@toggleNav]="showNav">
  <div class="side-nav__wrapper">
    <i *ngIf="navOpen" class="uil uil-times side-nav__close-handler" (click)="toggleNav()"></i>
    <i *ngIf="navClosed" class="uil uil-bars side-nav__open-handler" (click)="toggleNav()"></i>

    <ul class="side-nav__items primary" data-cy="menu-primary">
      <ng-container *ngFor="let item of primary">
        <li [attr.data-cy]="item.cyIdentifier">
          <ng-container *ngIf="item.callback && !item.routerLink">
            <ng-container *ngTemplateOutlet="withCallback; context: { $implicit: item }"></ng-container>
          </ng-container>
          <ng-container *ngIf="item.routerLink && !item.callback">
            <ng-container *ngTemplateOutlet="withLink; context: { $implicit: item }"></ng-container>
          </ng-container>
          <ng-container *ngIf="!item.callback && !item.routerLink">
            <ng-container *ngTemplateOutlet="noAction; context: { $implicit: item }"></ng-container>
          </ng-container>
        </li>
      </ng-container>
    </ul>

    <ul class="side-nav__items side-nav__bottom">
      <ng-container *ngFor="let item of secondary">
        <li [attr.data-cy]="item.cyIdentifier">
          <ng-container *ngIf="item.callback && !item.routerLink">
            <ng-container *ngTemplateOutlet="withCallback; context: { $implicit: item }"></ng-container>
          </ng-container>
          <ng-container *ngIf="item.routerLink && !item.callback">
            <ng-container *ngTemplateOutlet="withLink; context: { $implicit: item }"></ng-container>
          </ng-container>
          <ng-container *ngIf="!item.callback && !item.routerLink">
            <ng-container *ngTemplateOutlet="noAction; context: { $implicit: item }"></ng-container>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>

<ng-template #withLink let-item>
  <a
    [routerLink]="item.routerLink"
    [queryParams]="item.queryParams"
    class="side-nav__item"
    routerLinkActive="isActive"
    [routerLinkActiveOptions]="{ exact: item.hasOwnProperty('exact') ? item.exact : true }"
  >
    <span class="side-nav__label">{{ item.label | async }}</span>
    <ng-container *ngTemplateOutlet="icon; context: { $implicit: item }"></ng-container>
  </a>
</ng-template>

<ng-template #withCallback let-item>
  <span (click)="item.callback()" class="side-nav__item">
    <span class="side-nav__label">{{ item.label | async }}</span>
    <ng-container *ngTemplateOutlet="icon; context: { $implicit: item }"></ng-container>
  </span>
</ng-template>

<ng-template #noAction let-item>
  <div class="side-nav__item">
    <span class="side-nav__label">{{ item.label | async }}</span>
    <ng-container *ngTemplateOutlet="icon; context: { $implicit: item }"></ng-container>
  </div>
</ng-template>

<ng-template #icon let-item>
  <i class="uil side-nav__icon" [ngClass]="item.icon" *ngIf="item.icon"></i>
</ng-template>
