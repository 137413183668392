import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private prevRoute: string;

  constructor(private router: Router, private location: Location) {
    this.prevRoute = sessionStorage.getItem('prevRoute') ?? null;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.prevRoute = event.urlAfterRedirects;
        sessionStorage.setItem('prevRoute', this.prevRoute);
      }
    });
  }

  back(defaultRoute: string): void {
    // prevRoute doet niet wat het moet doen: na opslaan (van bv contract) en je klikt op terug (op opdracht detail) dan ga je terug naar edit scherm, dan moet je eigenlijk naar opdracht overzicht
    // if (this.prevRoute) {
    //   this.location.back();
    // } else {
      this.router.navigateByUrl(defaultRoute);
    // }
  }
}
