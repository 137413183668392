import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PPADateAdapter } from '@ppa/data';
import { Observable, of } from 'rxjs';

export let NORMAL_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'w',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'ppa-datepicker-week-exact-switch',
  templateUrl: './datepicker-week-exact.component.html',
  styleUrls: ['./datepicker-week-exact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerWeekExactSwitchComponent),
      multi: true,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: PPADateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: NORMAL_FORMAT },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerWeekExactSwitchComponent implements ControlValueAccessor {
  _disabled = false;
  _value: any;
  _displayDate: string;

  @Input() useExactDate = false;
  @Input() required$: Observable<boolean> = of(false);
  @Input() label: string;
  @Input() clearable = false;

  @ViewChild('picker') picker: any;

  onChanged: any = () => {};
  onTouched: any = () => {};
  dateTimeForm: FormGroup;

  constructor(private cdr: ChangeDetectorRef, private fb: FormBuilder) {
    this.dateTimeForm = this.fb.group({
      date: null,
    });
  }

  writeValue(value: any): void {
    this.dateTimeForm.get('date').setValue(value);
    this._value = value;
    this._displayDate = value;
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  onDateChange(selection: any): void {
    const { year, month, date } = selection.value._i;
    const datetimeString = `${year}-${(month + 1).toString().padStart(2, '0')}-${date
      .toString()
      .padStart(2, '0')}T00:00:00.000Z`;

    if (!this._disabled) {
      this._displayDate = datetimeString;
      this.onChanged(datetimeString);
      this.onTouched();
    }

    this.cdr.detectChanges();
  }

  clear() {
    this.dateTimeForm.reset();
    this.writeValue(null);
    this.onChanged(null);
    this.onTouched();
    this.cdr.detectChanges();
  }
}
