import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { Product } from '../contracts';
import { Observable } from 'rxjs';
import { ListResponse } from '../contracts/list-response';

@Injectable({
  providedIn: 'root',
})
export class ProductDataServiceMock extends BaseService<Product> {
  path = 'products';

  list(): Observable<ListResponse<Product>> {
    return new Observable<ListResponse<Product>>((subscriber) => {
      subscriber.next([
        {
          id: 1,
          title: 'plantUi',
          productVarieties: [
            {
              id: 1,
              title: 'Arenal',
            },
            {
              id: 2,
              title: 'Avanti',
            },
            {
              id: 3,
              title: 'Centro',
            },
            {
              id: 4,
              title: 'Hybelle',
            },
          ],
          productMatrix: [
            {
              property: 'productVariety',
            },
            {
              property: 'organic',
            },
            {
              property: 'harvestYear',
            },
          ],
        },
      ]);
      subscriber.complete();
    });
  }
}
