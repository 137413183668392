import { Inject, Injectable } from '@angular/core';
import { API_CONFIG, ApiConfig, FilterModule, KeyValuePairs, DownloadFileService } from '@ppa/data';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(protected http: HttpClient, @Inject(API_CONFIG) public apiConfig: ApiConfig) {}

  public exportResults(
    module: FilterModule,
    params: KeyValuePairs<string>,
    relationId: any,
  ): Observable<HttpResponse<Blob>> {
    let queryParams: HttpParams = new HttpParams();

    Object.keys(params).forEach((key) => {
      if (params[key] !== '' && params[key] !== null) {
        queryParams = queryParams.set(key, params[key]);
      }
    });

    if (relationId) {
      queryParams = queryParams.set('relation', relationId);
    }

    let path = `${module}s`;

    if (path.indexOf('ys') > -1) {
      path = path.replace('ys', 'ies');
    }

    return this.http
      .get(`${this.apiConfig.baseUrl}/${path}/export`, {
        observe: 'response',
        responseType: 'blob',
        params: queryParams,
      })
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }
}
