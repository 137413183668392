import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { LayoutModule } from '../../layout.module';
import { MtxSelectModule } from '@ng-matero/extensions';
import { FormsModule } from '@angular/forms';

const COMPONENTS = [SelectComponent];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [CommonModule, MatSelectModule, TranslocoModule, LayoutModule, MtxSelectModule, FormsModule],
})
export class SelectModule {}
