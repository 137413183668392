import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { OrderMatch } from '@ppa/data';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'ppa-order-matched-card',
  templateUrl: './order-matched-card.component.html',
  styleUrls: ['./order-matched-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderMatchedCardComponent implements ControlValueAccessor {
  disabled = false;
  value: any;

  @Input() orderMatch: OrderMatch = null;
  @Input() maximum = 0;
  @Input() showAmountToggle = false;

  @Output() orderMatchDelete = new EventEmitter<OrderMatch>();
  @Output() phoneNumberClicked = new EventEmitter<string>();
  @Output() amountOK = new EventEmitter<boolean>();

  onChanged: any = () => {};
  onTouched: any = () => {};

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      control.valueAccessor = this;
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: number): void {
    this.value = value;
  }

  updateAmount(event: Event) {
    const value = (event.target as HTMLInputElement).value.replace(',', '.');
    this.value = value;
    this.onChanged(+value);
    this.onTouched();
  }

  delete(orderMatch: OrderMatch) {
    this.orderMatchDelete.emit(orderMatch);
  }

  amountToggle(event: MatSlideToggleChange) {
    this.amountOK.next(event.checked);
  }
}
