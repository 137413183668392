import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent, Intention, Modal, ModalService, PPAToastrService } from '@ppa/layout';
import { Contract, Order, OrderMatch } from '@ppa/data';
import { ContractService } from '../../services/contract.service';
import { translate } from '@ngneat/transloco';
import { ErrorHandlerService } from '@ppa/layout';
import { ContractFormComponent } from '../../components/contract-form/contract-form.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'ppa-contract-create-modal',
  templateUrl: './contract-create-modal.component.html',
})
export class ContractCreateModalComponent extends Modal implements OnInit {
  order: Order;
  orderMatch: OrderMatch;
  isPurchaseContract = false;
  companyName = '';
  saving$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('form', { read: ContractFormComponent }) form: ContractFormComponent;

  constructor(
    _modalService: ModalService,
    private contractService: ContractService,
    private toastrService: PPAToastrService,
    private errorHandler: ErrorHandlerService,
  ) {
    super(_modalService);

    this.saving$.next(false);
  }

  ngOnInit(): void {
    if (this.orderMatch) {
      this.companyName = this.order?.relation?.companyName;
    } else if (!this.orderMatch && this.order) {
      this.companyName = this.order.relation?.companyName;
    }
  }

  close() {
    if (this.form.contractForm.touched) {
      this.modalService
        .createModal(ConfirmDialogComponent, {
          message: translate('navigation_guard.title'),
          cancelButtonText: translate('navigation_guard.buttons.cancel'),
          confirmButtonText: translate('navigation_guard.buttons.discard'),
        })
        .dialog.onSave()
        .then(() => {
          super.close();
        });
    } else {
      super.close();
    }
  }

  handleCreateContractSave(contract: Contract) {
    this.saving$.next(true);
    if (this.order) {
      contract.order = this.order;
    }
    if (this.orderMatch) {
      contract.orderMatch = this.orderMatch;
    }
    this.contractService.saveContract(contract).subscribe(
      () => {
        this.saving$.next(false);
        this.save(true);
        this.toastrService.displayToastr({
          icon: 'uil-check',
          title: translate('modules.contract.create.create_success.title'),
          intention: Intention.Success,
          duration: 3000,
          identifier: 'modules-contract-toastr-contract_added',
        });
      },
      (error) => {
        this.saving$.next(false);
        this.errorHandler.handleAPIError(translate('modules.contract.create.create_failed.title'), error);
      },
    );
  }
}
