import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Contact, ListResponse, Relation } from '../contracts';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContactDataService extends BaseService<Contact> {
  path = 'contacts';

  list(params?: { [key: string]: any }): Observable<ListResponse<Contact>> {
    return this.listPaginated(params ?? {}).pipe(
      pluck('data'),
      map((contacts: Contact[]) => {
        return contacts.map((contact) => {
          return contact;
        });
      }),
    );
  }
}
