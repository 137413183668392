import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { ToastrConfig } from '../../contracts/toastr-config';
import { MenuService } from '../../services/menu.service';
import { ToastrService, Toast, ToastPackage } from 'ngx-toastr';
import { HostBinding } from '@angular/core';

@Component({
  selector: 'ppa-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PPAToastrComponent extends Toast implements OnInit {
  config: ToastrConfig;
  onClick$ = new EventEmitter<void>();
  menuOpen$: Observable<string>;

  @HostBinding('class.error') errorClass: boolean = false;
  @HostBinding('class.success') successClass: boolean = false;

  constructor(
    private menuService: MenuService,
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {
    this.menuOpen$ = this.menuService.isMenuVisible().pipe(
      map((value) => (!!this.config.fullWidth ? false : value)),
      map((visible) => (visible ? 'menu-open' : 'menu-closed')),
    );

    this.errorClass = this.config.intention === 'ERROR';
    this.successClass = !this.errorClass;
  }

  handleClick(index: number) {
    this.config.buttons[index].sub.next(this.config.buttons[index].value);
  }
}
