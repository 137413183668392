import { Component, Input } from '@angular/core';
import { StatusLabel } from '../../contracts';
import { FilterModule } from '@ppa/data';

@Component({
  selector: 'ppa-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
  /** The status that should be displayed */
  @Input() label: StatusLabel = null;
  @Input() class = '';
  @Input() module: FilterModule;
  @Input() manualLabel = '';
}
