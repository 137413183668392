import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PoolOverviewCard } from '../../contracts';
import { OverviewCard } from '../../classes';

@Component({
  selector: 'ppa-pool-overview-card',
  templateUrl: './pool-overview-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoolOverviewCardComponent extends OverviewCard {
  @Input() config: PoolOverviewCard;
  @Input() cyIdentifier: string;
}
