import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const NORMAL_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'ppa-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useValue: NORMAL_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
  ],
})
export class DatetimePickerComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() dateLabel = translate('form.elements.datetime_picker.date_label');
  @Input() timeSelect = false;
  @Input() defaultTime = null;
  @Input() required$: Observable<boolean> = of(false);
  @Input() hideReset = false;

  dateTimeForm: FormGroup;

  destroy$ = new Subject<void>();

  value: string;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private fb: FormBuilder) {
    this.dateTimeForm = this.fb.group({
      date: null,
      time: { value: null, disabled: true },
    });
  }

  ngOnInit(): void {
    this.dateTimeForm.controls.date.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((date) => {
      if (date) {
        let time = this.dateTimeForm.controls.time.value;
        if (time === undefined || time === null || time === '') {
          time = this.defaultTime;
          this.dateTimeForm.controls.time.patchValue(time);
        }
      }
    });

    this.dateTimeForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const { date, time } = value;

      // Only emit when date has a value
      if (date) {
        let datetime = `${typeof date === 'string' ? date : date.format('YYYY-MM-DD')}`;
        if (this.timeSelect) {
          datetime += ` ${time ?? this.defaultTime}`;
        } else {
          datetime += `T00:00:00.000Z`;
        }
        datetime = datetime.replace(' null', '');
        this.value = datetime;
        this.onChange(datetime);
        this.onTouch(datetime);

        if (!this.dateTimeForm.get('time').enabled) {
          this.dateTimeForm.get('time').enable({ emitEvent: false });
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.dateTimeForm.disable() : this.dateTimeForm.enable();
  }

  writeValue(value: string): void {
    this.value = value;

    if (value) {
      const [date, time] = value.split(' ');
      this.dateTimeForm.get('date').setValue(date, { emitEvent: false });
      this.dateTimeForm.get('time').setValue(time, { emitEvent: false });

      if (date) {
        if (!this.dateTimeForm.get('time').enabled) {
          this.dateTimeForm.get('time').enable({ emitEvent: false });
        }
      }
    }
  }

  clearForm() {
    this.dateTimeForm.reset();
    this.value = null;
    this.onChange(null);
  }
}
