<ppa-spinner [status$]="saving$" message="global.saving" icon="save"></ppa-spinner>

<div class="container" *transloco="let t">
  <form #form="ngForm" [formGroup]="dossierForm" autocomplete="off" (ngSubmit)="handleSubmit()">
    <ppa-header [data]="headerData" [floating]="true"></ppa-header>

    <div class="reminder__moment-title mb-1">
      <i class="uil uil-calender type-icon"></i>
      {{ 'modules.dossier.create.reminders.title' | transloco }}
    </div>

    <ppa-datetime-picker
      formControlName="reminderDate"
      [required$]="isRequiredField('reminderDate')"
      defaultTime="09:00"
      [timeSelect]="true"
    ></ppa-datetime-picker>
    <div class="form-error" *ngIf="(controls.submitted || controls.touched) && controls.invalid">
      <span>{{ 'form_errors.required' | transloco }}</span>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'modules.dossier.create.reminders.description' | transloco }}
        <span class="required" *ngIf="isRequiredField('description') | async">*</span>
      </mat-label>
      <textarea cdkTextareaAutosize formControlName="description" matInput rows="10"></textarea>
    </mat-form-field>
    <div class="form-error" *ngIf="(controls.submitted || controls.touched) && controls.invalid">
      <span>{{ 'form_errors.required' | transloco }}</span>
    </div>
  </form>
</div>
