import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Intention } from '../../contracts';
import { PPAToastrService } from '../../services/toastr.service';

@Component({
  selector: 'ppa-error-preview',
  templateUrl: './error-preview.component.html',
  styleUrls: ['./error-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPreviewComponent implements OnInit {
  constructor(private toastr: PPAToastrService) {
    this.toastr.displayToastr({
      intention: Intention.Error,
      title: 'Test Waarschuwing!',
      icon: 'uil-check',
    });
  }

  ngOnInit(): void {}
}
